import { t } from 'i18next';

export const validatePassword = (
  password: string,
  passwordConfirm: string,
  oobCode: string,
) => {
  if (typeof password !== 'string') {
    throw new Error(t('createNewPassword.errors.passwordNotString'));
  }

  if (typeof passwordConfirm !== 'string') {
    throw new Error(t('createNewPassword.errors.confirmPasswordNotString'));
  }

  if (password.length < 6) {
    throw new Error(t('createNewPassword.errors.passwordLength'));
  }

  if (password !== passwordConfirm) {
    throw new Error(t('createNewPassword.errors.notEqual'));
  }

  if (!oobCode || oobCode.length === 0) {
    throw new Error(t('createNewPassword.errors.missingOobCode'));
  }
};
