import { TFunction } from 'i18next';

export class AuthError extends Error {
  constructor(
    readonly code: string,
    message: string,
  ) {
    super(message);
  }

  getLabel(t: TFunction) {
    switch (this.code) {
      case 'auth/invalid-credential':
        return t('auth.errors.auth/invalid-credential');
      case 'auth/invalid-login-credentials': // Firebase does not expose this error code
        return t('auth.errors.auth/invalid-login-credentials');
      case 'auth/email-already-in-use':
        return t('auth.errors.auth/email-already-in-use');
      case 'auth/invalid-email':
        return t('auth.errors.auth/invalid-email');
      case 'auth/operation-not-allowed':
        return t('auth.errors.auth/operation-not-allowed');
      case 'auth/internal-error':
        return t('auth.errors.auth/internal-error');
      case 'auth/invalid-provider-id':
        return t('auth.errors.auth/invalid-provider-id');
      case 'auth/too-many-requests':
        return t('auth.errors.auth/too-many-requests');
      default:
        return t('auth.errors.default');
    }
  }
}
