import { useUser } from '~/shared/hooks/useUser';
import { UserModel } from '~/shared/models/user/UserModel';

export const TERMS_AND_CONDITIONS_VERSION = '1.0.0';

export const hasAcceptedTerms = (user: UserModel | undefined) =>
  user?.termsAcceptedVersion === TERMS_AND_CONDITIONS_VERSION;

export const useUserHasAcceptedTerms = () => {
  const user = useUser();

  if (!user) {
    return false;
  }

  return hasAcceptedTerms(user);
};
