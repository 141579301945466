// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file wavin/sentio/v1/device_config_source.proto (package wavin.sentio.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Sentio specific config source representing the raw object tree
 *
 * @generated from message wavin.sentio.v1.DeviceConfigSource
 */
export class DeviceConfigSource extends Message<DeviceConfigSource> {
  /**
   * Object tree, traverse children to build tree (only included when allData is true)
   *
   * @generated from field: wavin.sentio.v1.ObjectNode object_tree = 1;
   */
  objectTree?: ObjectNode;

  /**
   * Write only, object changes to apply
   *
   * @generated from field: repeated wavin.sentio.v1.ObjectNode changes = 2;
   */
  changes: ObjectNode[] = [];

  constructor(data?: PartialMessage<DeviceConfigSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfigSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "object_tree", kind: "message", T: ObjectNode },
    { no: 2, name: "changes", kind: "message", T: ObjectNode, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfigSource {
    return new DeviceConfigSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfigSource {
    return new DeviceConfigSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfigSource {
    return new DeviceConfigSource().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfigSource | PlainMessage<DeviceConfigSource> | undefined, b: DeviceConfigSource | PlainMessage<DeviceConfigSource> | undefined): boolean {
    return proto3.util.equals(DeviceConfigSource, a, b);
  }
}

/**
 * @generated from message wavin.sentio.v1.ObjectNode
 */
export class ObjectNode extends Message<ObjectNode> {
  /**
   * OID
   *
   * i.e 14680635 "0XE0023B" (no type)
   *
   * @generated from field: wavin.sentio.v1.Hex id = 1;
   */
  id?: Hex;

  /**
   * OIDX
   *
   * i.e. 224 "0xE0" DEVICE_TYPE_CCU208
   *
   * @generated from field: wavin.sentio.v1.Hex index = 2;
   */
  index?: Hex;

  /**
   * DeviceType if object is a device
   *
   * @generated from field: optional wavin.sentio.v1.Hex device_type = 3;
   */
  deviceType?: Hex;

  /**
   * ObjectName
   *
   * @generated from field: optional string name = 4;
   */
  name?: string;

  /**
   * Properties are the key value pairs of the object
   *
   * @generated from field: repeated wavin.sentio.v1.Property properties = 5;
   */
  properties: Property[] = [];

  /**
   * Children
   *
   * @generated from field: repeated wavin.sentio.v1.ObjectNode children = 6;
   */
  children: ObjectNode[] = [];

  constructor(data?: PartialMessage<ObjectNode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.ObjectNode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "message", T: Hex },
    { no: 2, name: "index", kind: "message", T: Hex },
    { no: 3, name: "device_type", kind: "message", T: Hex, opt: true },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "properties", kind: "message", T: Property, repeated: true },
    { no: 6, name: "children", kind: "message", T: ObjectNode, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ObjectNode {
    return new ObjectNode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ObjectNode {
    return new ObjectNode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ObjectNode {
    return new ObjectNode().fromJsonString(jsonString, options);
  }

  static equals(a: ObjectNode | PlainMessage<ObjectNode> | undefined, b: ObjectNode | PlainMessage<ObjectNode> | undefined): boolean {
    return proto3.util.equals(ObjectNode, a, b);
  }
}

/**
 * @generated from message wavin.sentio.v1.Property
 */
export class Property extends Message<Property> {
  /**
   * i.e. 32912 "0X8090" VID_OBJECT_NAME
   *
   * @generated from field: wavin.sentio.v1.Hex id = 1;
   */
  id?: Hex;

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;

  /**
   * @generated from oneof wavin.sentio.v1.Property.value
   */
  value: {
    /**
     * 1 byte signed integer
     *
     * @generated from field: int32 val_d1 = 5;
     */
    value: number;
    case: "valD1";
  } | {
    /**
     * 2 bytes signed integer
     *
     * @generated from field: int32 val_d2 = 6;
     */
    value: number;
    case: "valD2";
  } | {
    /**
     * 4 bytes signed integer
     *
     * @generated from field: int32 val_d4 = 7;
     */
    value: number;
    case: "valD4";
  } | {
    /**
     * 1 byte unsigned integer
     *
     * @generated from field: uint32 val_u1 = 8;
     */
    value: number;
    case: "valU1";
  } | {
    /**
     * 2 bytes unsigned integer
     *
     * @generated from field: uint32 val_u2 = 9;
     */
    value: number;
    case: "valU2";
  } | {
    /**
     * 4 bytes unsigned integer
     *
     * @generated from field: uint32 val_u4 = 10;
     */
    value: number;
    case: "valU4";
  } | {
    /**
     * max 256 ASCII value
     *
     * @generated from field: string val_text = 11;
     */
    value: string;
    case: "valText";
  } | {
    /**
     * max 256 bytes of data
     *
     * @generated from field: bytes val_data = 12;
     */
    value: Uint8Array;
    case: "valData";
  } | {
    /**
     * 2 bytes signed decimal fixed point number. (value * 10) (-1.1 -> -11)
     *
     * @generated from field: int32 val_d2_fp10 = 13;
     */
    value: number;
    case: "valD2Fp10";
  } | {
    /**
     * 2 bytes signed fixed point number. (value * 100) (-1.11 -> -111)
     *
     * @generated from field: int32 val_d2_fp100 = 14;
     */
    value: number;
    case: "valD2Fp100";
  } | {
    /**
     * 2 bytes unsigned fixed point number. (value * 10) (1.1 -> 11)
     *
     * @generated from field: uint32 val_u2_fp10 = 15;
     */
    value: number;
    case: "valU2Fp10";
  } | {
    /**
     * 2 bytes unsigned fixed point number. (value * 100) (1.11 -> 111)
     *
     * @generated from field: uint32 val_u2_fp100 = 16;
     */
    value: number;
    case: "valU2Fp100";
  } | {
    /**
     * max 512 bytes of data
     *
     * @generated from field: bytes val_service_command = 17;
     */
    value: Uint8Array;
    case: "valServiceCommand";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Property>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.Property";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "message", T: Hex },
    { no: 2, name: "timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "val_d1", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "value" },
    { no: 6, name: "val_d2", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "value" },
    { no: 7, name: "val_d4", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "value" },
    { no: 8, name: "val_u1", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "value" },
    { no: 9, name: "val_u2", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "value" },
    { no: 10, name: "val_u4", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "value" },
    { no: 11, name: "val_text", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
    { no: 12, name: "val_data", kind: "scalar", T: 12 /* ScalarType.BYTES */, oneof: "value" },
    { no: 13, name: "val_d2_fp10", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "value" },
    { no: 14, name: "val_d2_fp100", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "value" },
    { no: 15, name: "val_u2_fp10", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "value" },
    { no: 16, name: "val_u2_fp100", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "value" },
    { no: 17, name: "val_service_command", kind: "scalar", T: 12 /* ScalarType.BYTES */, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Property {
    return new Property().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Property {
    return new Property().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Property {
    return new Property().fromJsonString(jsonString, options);
  }

  static equals(a: Property | PlainMessage<Property> | undefined, b: Property | PlainMessage<Property> | undefined): boolean {
    return proto3.util.equals(Property, a, b);
  }
}

/**
 * @generated from message wavin.sentio.v1.Hex
 */
export class Hex extends Message<Hex> {
  /**
   * value, i.e. 32912
   *
   * @generated from field: uint32 value = 1;
   */
  value = 0;

  /**
   * hex value as string, i.e. "0X8090"
   *
   * @generated from field: string string_value = 2;
   */
  stringValue = "";

  /**
   * type, i.e. VID_OBJECT_NAME
   *
   * @generated from field: string type = 3;
   */
  type = "";

  constructor(data?: PartialMessage<Hex>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.Hex";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "string_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Hex {
    return new Hex().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Hex {
    return new Hex().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Hex {
    return new Hex().fromJsonString(jsonString, options);
  }

  static equals(a: Hex | PlainMessage<Hex> | undefined, b: Hex | PlainMessage<Hex> | undefined): boolean {
    return proto3.util.equals(Hex, a, b);
  }
}

