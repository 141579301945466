import { ConnectError } from '@connectrpc/connect';
import { Box, Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useRouteError } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createRoutes } from '~/routes';
import { useLogError } from '~/pages/ErrorPage/hooks/useLogError';

export default function ErrorPage() {
  const error = useRouteError();
  const { t } = useTranslation();

  useLogError(error);

  const errorMessage =
    error instanceof ConnectError ? error.rawMessage : t('errorPage.message');

  return (
    <Box
      sx={{
        py: 8,
      }}
    >
      <Helmet>
        <title>{t('errorPage.title')}</title>
      </Helmet>
      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h4"
          sx={{
            color: 'inherit',
            fontWeight: 'bold',
            mb: 4,
          }}
        >
          Oops!
        </Typography>
        <Typography
          component="h2"
          variant="h6"
          sx={{
            color: 'inherit',
            fontWeight: 'bold',
            mb: 8,
          }}
        >
          {errorMessage}
        </Typography>

        <Button
          component={RouterLink}
          variant="contained"
          to={createRoutes.Devices}
        >
          {t('errorPage.navigateHome')}
        </Button>
      </Container>
    </Box>
  );
}
