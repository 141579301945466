export enum BlazeDeviceQueryKey {
  CREATE_FIRMWARE = 'createFirmware',
  DELETE_DEVICE_ACCESS_KEY = 'deleteDeviceAccessKey',
  DELETE_DEVICE_CLAIM = 'deleteDeviceClaim',
  DEVICE = 'device',
  DEVICE_PLATFORM_STATUS = 'devicePlatformStatus',
  DOWNLOAD_FIRMWARE = 'downloadFirmware',
  GET_DEVICE_UPDATER = 'getDeviceUpdater',
  LIST_DEVICE_ACCESS_KEYS = 'listDeviceAccessKeys',
  LIST_DEVICE_CLAIMS = 'listDeviceClaims',
  LIST_DEVICES = 'listDevices',
  LIST_FIRMWARES = 'listFirmwares',
  PATCH_FIRMWARE = 'patchFirmware',
  RELEASE_FIRMWARE_GA = 'releaseFirmwareGA',
  RESET_DEVICE = 'resetDevice',
  SEND_DEVICE_CONFIG = 'sendDeviceConfig',
  SET_DEVICE_RELEASE_MODE = 'setDeviceReleaseMode',
  START_DEVICE_UPDATER = 'startDeviceUpdater',
  UPDATE_DEVICE_PLATFORM = 'updateDevicePlatform',
  UPDATE_FIRMWARE = 'updateFirmware',
}

export enum IgniteUserQueryKey {
  USER = 'user',
  USER_BY_EMAIL = 'userByEmail',
  DELETE_USER = 'deleteUser',
  PATCH_USER = 'patchUser',
  LIST_USERS = 'listUsers',
  USER_ROLES = 'userRoles',
}
