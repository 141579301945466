import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IgniteUserQueryKey } from '~/services/queryKeys';
import { patchUser } from '~/shared/api/user.api';
import { userQuery } from '~/shared/api/user.queries';
import { RoleModel } from '~/shared/models/role/RoleModel';

export function useUser() {
  const { data: user } = useQuery(userQuery());
  return user ?? undefined;
}

export function useUserRoles() {
  const user = useUser();

  return user?.roles ?? [];
}

export function useUserRequiredRole(requiredRole: RoleModel) {
  const userRoles = useUserRoles();

  return userRoles.some((role) => requiredRole.isEqual(role));
}

export const useUpdateUserTermsAndConditionsVersion = () => {
  const user = useUser();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [IgniteUserQueryKey.PATCH_USER],
    mutationFn: async ({
      termsAcceptedVersion,
    }: {
      termsAcceptedVersion: string;
    }) => {
      if (!user || user.termsAcceptedVersion === termsAcceptedVersion) return;
      await patchUser({
        user: {
          name: user.name,
          termsAcceptedVersion,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [IgniteUserQueryKey.USER],
      });
    },
  });
};
