import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material';
import type { To } from 'react-router-dom';
import { NavLink as RouterLink } from 'react-router-dom';

type NavItemProps = {
  text: string;
  icon: JSX.Element;
} & ({ path: To; onClick?: never } | { path?: never; onClick: () => void });

export function NavItem({ text, path, onClick, icon }: NavItemProps) {
  const defaultButtonProps = {
    sx: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: (theme: Theme) => theme.palette.text.secondary,
      '&:hover, &.active': {
        color: (theme: Theme) => theme.palette.text.primary,
        backgroundColor: 'unset',
      },
    },
    disableRipple: true,
  };

  const buttonProps = onClick
    ? { onClick, ...defaultButtonProps }
    : { component: RouterLink, to: path as To, ...defaultButtonProps };

  return (
    <ListItem key={text} disablePadding>
      <ListItemButton {...buttonProps}>
        <ListItemIcon
          sx={{
            display: 'flex',
            justifyContent: 'center',
            color: 'inherit',
            mb: 1,
          }}
        >
          {icon}
        </ListItemIcon>

        <ListItemText
          primary={
            <Typography
              variant="body-xs"
              sx={{
                textAlign: 'center',
              }}
            >
              {text}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}
