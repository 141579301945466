import { Timestamp } from '@bufbuild/protobuf';
import { TFunction } from 'i18next';
import {
  Device,
  Device_Platform,
  Device_ReleaseMode,
  Device_Status,
} from '~/types/wavin/blaze/v1/device_pb';
import { normalizeString } from '~/shared/utils/normalizeString';
import { BrokerValuesModel } from '~/shared/models/device/BrokerValuesModel';
import { DeviceConfig } from '~/types/wavin/sentio/v1/device_config_pb';
import { DeviceFirmwareModel } from '~/shared/models/device/DeviceFirmwareModel';

export class DeviceModel {
  readonly brokerValues?: BrokerValuesModel;

  readonly firmwareModel: DeviceFirmwareModel;

  readonly isInManualReleaseMode: boolean;

  readonly isOnline: boolean;

  readonly isUsingLegacyPlatform: boolean;

  readonly lastConfig?: DeviceConfig;

  readonly lastHeartbeat?: Timestamp;

  readonly name: string;

  readonly rawDevice?: Device;

  readonly registrationKey: string;

  readonly releaseMode: Device_ReleaseMode;

  readonly searchKeywords: string;

  readonly serialNumber: string;

  readonly title: string;

  constructor(device: Device) {
    this.rawDevice = device;
    this.name = device.name;
    this.serialNumber = device.serialNumber;
    this.registrationKey = device.registrationKey;
    this.title =
      device.lastConfig?.data.value?.titlePersonalized ||
      device.lastConfig?.data.value?.title ||
      '';

    this.firmwareModel = new DeviceFirmwareModel(
      device.firmwareAvailable,
      device.firmwareInstalled,
      device.firmwareUpdateStatus,
    );

    this.isUsingLegacyPlatform = device.platform === Device_Platform.LEGACY;

    const { lastConfig } = device;

    const objectTree = lastConfig?.source.value?.objectTree;

    if (objectTree) {
      this.brokerValues = new BrokerValuesModel(objectTree);
    }

    this.lastHeartbeat = device.lastHeartbeat;

    this.searchKeywords = [
      this.title,
      device.serialNumber,
      device.registrationKey,
    ]
      .filter(Boolean)
      .map(normalizeString)
      .join(' | ');

    this.isOnline = device.status === Device_Status.OK;

    this.releaseMode = device.releaseMode;

    this.isInManualReleaseMode =
      device.releaseMode === Device_ReleaseMode.MANUAL;
  }

  getReleaseModeChangeDialogTexts = (t: TFunction) => {
    const { releaseMode } = this;

    return {
      title: t('devicePage.firmware.releaseMode.dialogTitle'),
      description:
        releaseMode === Device_ReleaseMode.AUTOMATIC
          ? t('devicePage.firmware.releaseMode.changeToManualMessage')
          : t('devicePage.firmware.releaseMode.changeToAutomaticMessage'),
    };
  };

  // eslint-disable-next-line class-methods-use-this
  getReleaseModeOptions = (t: TFunction) => [
    {
      label: t('devicePage.firmware.releaseMode.automatic'),
      value: Device_ReleaseMode.AUTOMATIC,
    },
    {
      label: t('devicePage.firmware.releaseMode.manual'),
      value: Device_ReleaseMode.MANUAL,
    },
  ];
}
