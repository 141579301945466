import { FirebaseError, initializeApp } from 'firebase/app';
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  OAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  reauthenticateWithCredential,
  signOut,
  updatePassword,
  EmailAuthProvider,
} from 'firebase/auth';
import { AuthService } from '~/services/auth/AuthService';
import { AuthError } from '~/services/auth/AuthError';
import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MICROSOFT_TENANT,
} from '~/services/environment/environment';

export class FirebaseAuthService implements AuthService {
  private firebaseApp = initializeApp({
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
  });

  private auth = getAuth(this.firebaseApp);

  isSignedIn: boolean;

  initialized = this.auth.authStateReady();

  constructor() {
    this.isSignedIn = !!this.auth.currentUser;
    this.onAuthStateChanged((isSignedIn) => {
      this.isSignedIn = isSignedIn;
    });
  }

  async getIdentityToken(): Promise<string | null> {
    await this.initialized;
    return this.auth.currentUser?.getIdToken() ?? null;
  }

  async register(email: string, password: string): Promise<AuthError | null> {
    try {
      await createUserWithEmailAndPassword(this.auth, email, password);
    } catch (error) {
      if (error instanceof FirebaseError) {
        return new AuthError(error.code, error.message);
      }
      throw error;
    }
    return null;
  }

  async signIn(email: string, password: string): Promise<AuthError | null> {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
    } catch (error) {
      if (error instanceof FirebaseError) {
        return new AuthError(error.code, error.message);
      }
      throw error;
    }
    return null;
  }

  async signInWithMicrosoft(email: string): Promise<AuthError | null> {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: FIREBASE_MICROSOFT_TENANT,
      // Pre-fill the email address field.
      login_hint: email,
    });
    try {
      await signInWithPopup(this.auth, provider);
    } catch (error) {
      if (error instanceof FirebaseError) {
        return new AuthError(error.code, error.message);
      }
      throw error;
    }
    return null;
  }

  async changePassword(currentPassword: string, newPassword: string) {
    const { currentUser } = this.auth;
    if (!currentUser || !currentUser.email) {
      throw new Error('auth/no-current-user');
    }
    const credential = EmailAuthProvider.credential(
      currentUser.email,
      currentPassword,
    );

    try {
      // We need to reauthenticate the user first otherwise the updatePassword method can fail when login was too long ago.
      await reauthenticateWithCredential(currentUser, credential);

      await updatePassword(currentUser, newPassword);
    } catch (error) {
      if (error instanceof FirebaseError) {
        return new AuthError(error.code, error.message);
      }
      throw error;
    }
    return null;
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    await sendPasswordResetEmail(this.auth, email);
  }

  async confirmPasswordReset(
    oobCode: string,
    newPassword: string,
  ): Promise<void> {
    await confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  signOut(): Promise<void> {
    return signOut(this.auth);
  }

  onAuthStateChanged(callback: (isSignedIn: boolean) => void): () => void {
    return onAuthStateChanged(this.auth, async (user) => {
      callback(!!user);
    });
  }
}
