import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { checkAuth } from '~/shared/auth/auth-utils';
import { userQuery } from '~/shared/api/user.queries';

export const appContentLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    await checkAuth(request);

    await queryClient.ensureQueryData(userQuery());

    return null;
  };
