import { Timestamp } from '@bufbuild/protobuf';
import { formatTimestamp } from '~/shared/utils/formatTimestamp';
import {
  Hex,
  Property,
  ObjectNode,
  DeviceConfigSource,
} from '~/types/wavin/sentio/v1/device_config_source_pb';
import { DeviceConfig } from '~/types/wavin/blaze/v1/device_config_pb';
import { ValueModel } from './ValueModel';

export type TableProperty = {
  rawOid?: Hex;
  rawVid?: Hex;
  oid?: string;
  vid?: string;
  timestamp: string;
  value?: ValueModel;
  timestampSeconds?: bigint;
};

export type TableObject = {
  oid?: string;
  name?: string;
  deviceType?: string;
  subRows: (TableProperty | TableObject)[];
};

const hexToDisplay = (hex: Hex) =>
  [hex.stringValue, hex.type].filter(Boolean).join(' - ');

const getDeviceType = (row: ObjectNode) =>
  row.deviceType && hexToDisplay(row.deviceType);

const getOID = (row?: ObjectNode) => row?.id?.stringValue;

const getVID = (row: Property) => row.id && hexToDisplay(row.id);

export class BrokerValuesModel {
  readonly data: TableObject;

  constructor(objectTree: ObjectNode) {
    this.data = BrokerValuesModel.createTableData(objectTree);
  }

  static createTableData(node: ObjectNode, parent?: ObjectNode): TableObject;
  static createTableData(node: Property, parent?: ObjectNode): TableProperty;
  static createTableData(
    node: ObjectNode | Property,
    parent?: ObjectNode,
  ): TableObject | TableProperty {
    if (node instanceof ObjectNode) {
      return {
        oid: getOID(node),
        name: node.name,
        deviceType: getDeviceType(node),
        subRows: [
          ...node.children.map((child) =>
            BrokerValuesModel.createTableData(child),
          ),
          ...node.properties.map((child) =>
            BrokerValuesModel.createTableData(child, node),
          ),
        ],
      };
    }

    return {
      rawOid: parent?.id,
      rawVid: node.id,
      name: parent?.name,
      oid: getOID(parent),
      vid: getVID(node),
      deviceType:
        parent && parent.deviceType && hexToDisplay(parent.deviceType),
      timestamp: formatTimestamp(node.timestamp),
      timestampSeconds: node.timestamp?.seconds,
      value: new ValueModel(node.value),
    };
  }

  static createDeviceConfigDelta(newValue: string, property: TableProperty) {
    const timestamp = Timestamp.fromDate(new Date());
    return new DeviceConfig({
      timestamp,
      source: {
        case: 'sentioSource',
        value: new DeviceConfigSource({
          changes: [
            new ObjectNode({
              id: new Hex({ value: property.rawOid?.value }),
              properties: [
                new Property({
                  id: new Hex({ value: property.rawVid?.value }),
                  timestamp,
                  value: property.value?.createNewOriginalValue(newValue),
                }),
              ],
            }),
          ],
        }),
      },
    });
  }
}
