import { useEffect } from 'react';
import { logError } from '~/shared/utils/log-error';

export function useLogError(error: unknown) {
  useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);
}
