// @generated by protoc-gen-connect-web v0.8.6 with parameter "target=ts"
// @generated from file wavin/ignite/v1/ignite_user_service.proto (package wavin.ignite.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DeleteUserRequest, GetUserByEmailRequest, GetUserRequest, ListUsersRequest, ListUsersResponse, PatchUserRequest, SetUserRolesRequest } from "./ignite_user_service_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";
import { User } from "./user_pb.js";

/**
 * IgniteUserService is the service that is responsible for user information
 *
 * @generated from service wavin.ignite.v1.IgniteUserService
 */
export const IgniteUserService = {
  typeName: "wavin.ignite.v1.IgniteUserService",
  methods: {
    /**
     * Deletes a user account
     *
     * @generated from rpc wavin.ignite.v1.IgniteUserService.DeleteUser
     */
    deleteUser: {
      name: "DeleteUser",
      I: DeleteUserRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieves a user account
     *
     * @generated from rpc wavin.ignite.v1.IgniteUserService.GetUser
     */
    getUser: {
      name: "GetUser",
      I: GetUserRequest,
      O: User,
      kind: MethodKind.Unary,
    },
    /**
     * Patches a user account
     *
     * @generated from rpc wavin.ignite.v1.IgniteUserService.PatchUser
     */
    patchUser: {
      name: "PatchUser",
      I: PatchUserRequest,
      O: User,
      kind: MethodKind.Unary,
    },
    /**
     * List users with roles (admin role only)
     *
     * @generated from rpc wavin.ignite.v1.IgniteUserService.ListUsers
     */
    listUsers: {
      name: "ListUsers",
      I: ListUsersRequest,
      O: ListUsersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get user by email (admin role only)
     *
     * @generated from rpc wavin.ignite.v1.IgniteUserService.GetUserByEmail
     */
    getUserByEmail: {
      name: "GetUserByEmail",
      I: GetUserByEmailRequest,
      O: User,
      kind: MethodKind.Unary,
    },
    /**
     * Set roles for a user account (admin role only)
     *
     * @generated from rpc wavin.ignite.v1.IgniteUserService.SetUserRoles
     */
    setUserRoles: {
      name: "SetUserRoles",
      I: SetUserRolesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

