import { ActionFunction } from 'react-router-dom';
import { t } from 'i18next';
import { AuthService } from '~/services/auth/AuthService';

export type PasswordResetAction = {
  didSendPasswordResetRequest: boolean;
};

export type PasswordResetByEmailFormData = {
  email: string;
};

export const forgetPasswordAction =
  (auth: AuthService): ActionFunction =>
  async ({ request }): Promise<PasswordResetAction | Error> => {
    const formData = await request.formData();
    const { email } = Object.fromEntries(
      formData,
    ) as PasswordResetByEmailFormData;

    if (typeof email !== 'string') {
      throw new Error(t('forgotPassword.errors.emailNotString'));
    }

    await auth.sendPasswordResetEmail(email.toLowerCase());
    return { didSendPasswordResetRequest: true };
  };
