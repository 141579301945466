// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file wavin/blaze/v1/device_config.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { DeviceConfig as DeviceConfig$1 } from "../../sentio/v1/device_config_pb.js";
import { DeviceConfigSource } from "../../sentio/v1/device_config_source_pb.js";

/**
 * DeviceConfig is a snapshot in time of the config and metrics of a device including connected peripherals (atomic)
 * The device config contains all the topology information at that point in time.
 * Data contracts are automatically versioned by following proto spec compatibility
 *
 * @generated from message wavin.blaze.v1.DeviceConfig
 */
export class DeviceConfig extends Message<DeviceConfig> {
  /**
   * DeviceConfig resource name, format: devices/{device}/configs/{config}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;

  /**
   * @generated from oneof wavin.blaze.v1.DeviceConfig.data
   */
  data: {
    /**
     * @generated from field: wavin.sentio.v1.DeviceConfig sentio = 3;
     */
    value: DeviceConfig$1;
    case: "sentio";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * Source of the mapped data
   *
   * @generated from oneof wavin.blaze.v1.DeviceConfig.source
   */
  source: {
    /**
     * @generated from field: wavin.sentio.v1.DeviceConfigSource sentio_source = 10;
     */
    value: DeviceConfigSource;
    case: "sentioSource";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<DeviceConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.DeviceConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "timestamp", kind: "message", T: Timestamp },
    { no: 3, name: "sentio", kind: "message", T: DeviceConfig$1, oneof: "data" },
    { no: 10, name: "sentio_source", kind: "message", T: DeviceConfigSource, oneof: "source" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig {
    return new DeviceConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig {
    return new DeviceConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig {
    return new DeviceConfig().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig | PlainMessage<DeviceConfig> | undefined, b: DeviceConfig | PlainMessage<DeviceConfig> | undefined): boolean {
    return proto3.util.equals(DeviceConfig, a, b);
  }
}

