import { blazeDevicePromiseClient } from '~/services/blaze-device-client';

export const listDeviceAccessKeys = async ({
  deviceId,
}: {
  deviceId: string;
}) =>
  await blazeDevicePromiseClient.listDeviceAccessKeys({
    pageSize: BigInt(200),
    parent: deviceId,
  });
