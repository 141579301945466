import { queryOptions } from '@tanstack/react-query';
import { IgniteUserQueryKey } from '~/services/queryKeys';
import { listUsers } from '~/services/api/fetchers/listUsers.api';
import { UsersListModel } from '~/shared/models/usersList/UsersListModel';

export const listUsersQuery = queryOptions({
  queryKey: [IgniteUserQueryKey.LIST_USERS],
  queryFn: async () => {
    const response = await listUsers();
    return new UsersListModel(response.users);
  },
});
