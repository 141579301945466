import { queryOptions } from '@tanstack/react-query';
import { authService } from '~/services/auth/auth-service';
import { IgniteUserQueryKey } from '~/services/queryKeys';
import { getUser } from '~/shared/api/user.api';
import { UserModel } from '~/shared/models/user/UserModel';

export const userQuery = () =>
  queryOptions({
    queryKey: [IgniteUserQueryKey.USER, { isSignedIn: authService.isSignedIn }],
    queryFn: async () => {
      const response = await getUser();
      return new UserModel(response);
    },
    enabled: authService.isSignedIn,
  });
