import {
  createConnectTransport,
  createGrpcWebTransport,
} from '@connectrpc/connect-web';
import { Interceptor } from '@connectrpc/connect';
import { authInterceptor } from '~/services/auth/auth-interceptor';
import {
  BLAZE_TRANSPORT_URL,
  ENABLE_MOCKS,
  IGNITE_TRANSPORT_URL,
} from '~/services/environment/environment';

const igniteBaseUrl = IGNITE_TRANSPORT_URL;
const blazeBaseUrl = BLAZE_TRANSPORT_URL;

const interceptors: Interceptor[] = [authInterceptor];

export const igniteTransport = ENABLE_MOCKS
  ? createConnectTransport({
      baseUrl: igniteBaseUrl,
      interceptors,
    })
  : createGrpcWebTransport({
      baseUrl: igniteBaseUrl,
      interceptors,
    });

export const blazeTransport = ENABLE_MOCKS
  ? createConnectTransport({
      baseUrl: blazeBaseUrl,
      interceptors,
    })
  : createGrpcWebTransport({
      baseUrl: blazeBaseUrl,
      interceptors,
    });
