// @generated by protoc-gen-connect-web v0.8.6 with parameter "target=ts"
// @generated from file wavin/blaze/v1/blaze_device_service.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateDeviceAccessKeyRequest, CreateDeviceClaimByKeyRequest, CreateDeviceClaimRequest, CreateFirmwareRequest, DeleteAllDeviceClaimsRequest, DeleteDeviceAccessKeyRequest, DeleteDeviceClaimRequest, DownloadFirmwareRequest, GetDevicePlatformStatusRequest, GetDeviceRequest, GetDeviceUpdaterRequest, ListDeviceAccessKeysRequest, ListDeviceAccessKeysResponse, ListDeviceClaimsRequest, ListDeviceClaimsResponse, ListDevicesRequest, ListDevicesResponse, ListFirmwaresRequest, ListFirmwaresResponse, PatchFirmwareRequest, ReleaseFirmwareGARequest, ReleaseFirmwareRequest, ResetDeviceRequest, SendDeviceConfigRequest, SendSentioBrokerConfigRequest, SetDeviceReleaseModeRequest, StartDeviceUpdaterRequest, StreamDeviceRequest, StreamDeviceResponse, StreamSentioBrokerConfigRequest, UpdateDeviceFirmwareRequest, UpdateDevicePlatformRequest } from "./blaze_device_service_pb.js";
import { Device } from "./device_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";
import { DeviceAccessKey } from "./device_access_key_pb.js";
import { DeviceClaim } from "./device_claim_pb.js";
import { Firmware } from "./firmware_pb.js";
import { DeviceUpdater } from "./device_updater_pb.js";
import { BrokerConfig } from "../../sentio/v1/broker_pb.js";
import { DevicePlatformStatus } from "./device_platform_status_pb.js";

/**
 * BlazeDeviceService is the service for connecting clients to blaze.
 * Methods are executed for identity in authentication token.
 *
 * @generated from service wavin.blaze.v1.BlazeDeviceService
 */
export const BlazeDeviceService = {
  typeName: "wavin.blaze.v1.BlazeDeviceService",
  methods: {
    /**
     * Get device
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.GetDevice
     */
    getDevice: {
      name: "GetDevice",
      I: GetDeviceRequest,
      O: Device,
      kind: MethodKind.Unary,
    },
    /**
     * Set release mode device
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.SetDeviceReleaseMode
     */
    setDeviceReleaseMode: {
      name: "SetDeviceReleaseMode",
      I: SetDeviceReleaseModeRequest,
      O: Device,
      kind: MethodKind.Unary,
    },
    /**
     * List devices
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.ListDevices
     */
    listDevices: {
      name: "ListDevices",
      I: ListDevicesRequest,
      O: ListDevicesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Reset device (only device data is reset, user device data is kept)
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.ResetDevice
     */
    resetDevice: {
      name: "ResetDevice",
      I: ResetDeviceRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * SendDeviceConfig sends a config update to device.
     * Device config changes and errors will be sent back over stream, returns error if device is unavailable.
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.SendDeviceConfig
     */
    sendDeviceConfig: {
      name: "SendDeviceConfig",
      I: SendDeviceConfigRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Start stream with one or more devices
     * On stream start the last known device information is streamed back for given resources (or error if offline).
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.StreamDevice
     */
    streamDevice: {
      name: "StreamDevice",
      I: StreamDeviceRequest,
      O: StreamDeviceResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Create a device access key that can be used for claiming a device
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.CreateDeviceAccessKey
     */
    createDeviceAccessKey: {
      name: "CreateDeviceAccessKey",
      I: CreateDeviceAccessKeyRequest,
      O: DeviceAccessKey,
      kind: MethodKind.Unary,
    },
    /**
     * Delete a device access key to revoke option to claim a device
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.DeleteDeviceAccessKey
     */
    deleteDeviceAccessKey: {
      name: "DeleteDeviceAccessKey",
      I: DeleteDeviceAccessKeyRequest,
      O: DeviceAccessKey,
      kind: MethodKind.Unary,
    },
    /**
     * List access keys for device including users who generated the access key
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.ListDeviceAccessKeys
     */
    listDeviceAccessKeys: {
      name: "ListDeviceAccessKeys",
      I: ListDeviceAccessKeysRequest,
      O: ListDeviceAccessKeysResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Create a device claim gives user permission to access a device
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.CreateDeviceClaim
     */
    createDeviceClaim: {
      name: "CreateDeviceClaim",
      I: CreateDeviceClaimRequest,
      O: DeviceClaim,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Create a device claim by key gives user instant permission to access a device if the key is correct.
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.CreateDeviceClaimByKey
     */
    createDeviceClaimByKey: {
      name: "CreateDeviceClaimByKey",
      I: CreateDeviceClaimByKeyRequest,
      O: DeviceClaim,
      kind: MethodKind.Unary,
    },
    /**
     * Delete a device claim removes user permission to access a device
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.DeleteDeviceClaim
     */
    deleteDeviceClaim: {
      name: "DeleteDeviceClaim",
      I: DeleteDeviceClaimRequest,
      O: DeviceClaim,
      kind: MethodKind.Unary,
    },
    /**
     * Delete all device claims removes all user permissions to access all devices
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.DeleteAllDeviceClaims
     */
    deleteAllDeviceClaims: {
      name: "DeleteAllDeviceClaims",
      I: DeleteAllDeviceClaimsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * List claims for device including users who claimed the device
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.ListDeviceClaims
     */
    listDeviceClaims: {
      name: "ListDeviceClaims",
      I: ListDeviceClaimsRequest,
      O: ListDeviceClaimsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Create firmware allows users to upload a firmware package
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.CreateFirmware
     */
    createFirmware: {
      name: "CreateFirmware",
      I: CreateFirmwareRequest,
      O: Firmware,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateFirmware will start firmware update to latest available version.
     * Status can be checked in device.firmware_update_status
     * Firmware update status will also be sent back over stream, but device can be unavailable during update.
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.UpdateDeviceFirmware
     */
    updateDeviceFirmware: {
      name: "UpdateDeviceFirmware",
      I: UpdateDeviceFirmwareRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * ListFirmwares lists available firmware packages, excluding the package bytes
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.ListFirmwares
     */
    listFirmwares: {
      name: "ListFirmwares",
      I: ListFirmwaresRequest,
      O: ListFirmwaresResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DownloadFirmware returns the specified firmware package, including package bytes
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.DownloadFirmware
     */
    downloadFirmware: {
      name: "DownloadFirmware",
      I: DownloadFirmwareRequest,
      O: Firmware,
      kind: MethodKind.Unary,
    },
    /**
     * ReleaseFirmware starts the release process for given device if in RELEASE_MODE_MANUAL
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.ReleaseFirmware
     */
    releaseFirmware: {
      name: "ReleaseFirmware",
      I: ReleaseFirmwareRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * ReleaseFirmwareAutomatic starts the release process for all devices in RELEASE_MODE_AUTOMATIC
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.ReleaseFirmwareGA
     */
    releaseFirmwareGA: {
      name: "ReleaseFirmwareGA",
      I: ReleaseFirmwareGARequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * PatchFirmware allows users to update firmware fields
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.PatchFirmware
     */
    patchFirmware: {
      name: "PatchFirmware",
      I: PatchFirmwareRequest,
      O: Firmware,
      kind: MethodKind.Unary,
    },
    /**
     * DeviceUpdater return device updater to track firmware update process
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.GetDeviceUpdater
     */
    getDeviceUpdater: {
      name: "GetDeviceUpdater",
      I: GetDeviceUpdaterRequest,
      O: DeviceUpdater,
      kind: MethodKind.Unary,
    },
    /**
     * StartDeviceUpdater will start firmware flash of given components
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.StartDeviceUpdater
     */
    startDeviceUpdater: {
      name: "StartDeviceUpdater",
      I: StartDeviceUpdaterRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * StreamSentioBrokerConfig starts broker config stream with a device.
     * On stream start the full broker config is streamed or error if offline.
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.StreamSentioBrokerConfig
     */
    streamSentioBrokerConfig: {
      name: "StreamSentioBrokerConfig",
      I: StreamSentioBrokerConfigRequest,
      O: BrokerConfig,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * SendSentioBrokerConfig sends broker config changes to device.
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.SendSentioBrokerConfig
     */
    sendSentioBrokerConfig: {
      name: "SendSentioBrokerConfig",
      I: SendSentioBrokerConfigRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Get device platform status
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.GetDevicePlatformStatus
     */
    getDevicePlatformStatus: {
      name: "GetDevicePlatformStatus",
      I: GetDevicePlatformStatusRequest,
      O: DevicePlatformStatus,
      kind: MethodKind.Unary,
    },
    /**
     * Update device platform from legacy to current
     *
     * @generated from rpc wavin.blaze.v1.BlazeDeviceService.UpdateDevicePlatform
     */
    updateDevicePlatform: {
      name: "UpdateDevicePlatform",
      I: UpdateDevicePlatformRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

