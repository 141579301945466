import { ListFirmwaresResponse } from '~/types/wavin/blaze/v1/blaze_device_service_pb';
import { FirmwareModel } from '~/shared/models/firmwareList/FirmwareModel';

export class FirmwareListModel {
  readonly firmware: FirmwareModel[] = [];

  constructor(listFirmwaresResponse: ListFirmwaresResponse) {
    this.firmware = listFirmwaresResponse.firmwares.map(
      (firmware) => new FirmwareModel(firmware),
    );
  }
}
