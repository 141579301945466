import { blazeDevicePromiseClient } from '~/services/blaze-device-client';

export const getDevice = async ({
  deviceId,
  includeSource,
}: {
  deviceId: string;
  includeSource?: boolean;
}) =>
  await blazeDevicePromiseClient.getDevice({ name: deviceId, includeSource });
