import { UserModel } from '~/shared/models/user/UserModel';
import { User } from '~/types/wavin/ignite/v1/user_pb';

export class UsersListModel {
  users: UserModel[];

  constructor(users: User[]) {
    this.users = users
      .map((user) => new UserModel(user))
      .sort((a, b) => a.email.localeCompare(b.email));
  }

  addUser(user: UserModel) {
    this.users = [user, ...this.users].sort((a, b) =>
      a.email.localeCompare(b.email),
    );
    return this;
  }
}
