// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file wavin/sentio/v1/broker.proto (package wavin.sentio.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { BrokerValue } from "./heartbeat_pb.js";

/**
 * OIDX (Object Index) of OID (Object ID)
 * Valid for CCU-208, EU-208-A, DHW-21x and generic devices
 *
 * @generated from enum wavin.sentio.v1.OIDX
 */
export enum OIDX {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: OIDX_DEVICE = 0;
   */
  OIDX_DEVICE = 0,

  /**
   * @generated from enum value: OIDX_PUMP_1 = 1;
   */
  OIDX_PUMP_1 = 1,

  /**
   * @generated from enum value: OIDX_PUMP_2 = 2;
   */
  OIDX_PUMP_2 = 2,

  /**
   * @generated from enum value: OIDX_ANALOG_0_10V = 3;
   */
  OIDX_ANALOG_0_10V = 3,

  /**
   * @generated from enum value: OIDX_PWM_OUTPUT = 4;
   */
  OIDX_PWM_OUTPUT = 4,

  /**
   * @generated from enum value: OIDX_GPIO_1 = 5;
   */
  OIDX_GPIO_1 = 5,

  /**
   * @generated from enum value: OIDX_GPIO_2 = 6;
   */
  OIDX_GPIO_2 = 6,

  /**
   * @generated from enum value: OIDX_VFR_1 = 7;
   */
  OIDX_VFR_1 = 7,

  /**
   * @generated from enum value: OIDX_VFR_2 = 8;
   */
  OIDX_VFR_2 = 8,

  /**
   * @generated from enum value: OIDX_SERVO_1 = 9;
   */
  OIDX_SERVO_1 = 9,

  /**
   * @generated from enum value: OIDX_SERVO_2 = 10;
   */
  OIDX_SERVO_2 = 10,

  /**
   * @generated from enum value: OIDX_INPUT_1 = 11;
   */
  OIDX_INPUT_1 = 11,

  /**
   * @generated from enum value: OIDX_INPUT_2 = 12;
   */
  OIDX_INPUT_2 = 12,

  /**
   * @generated from enum value: OIDX_INPUT_3 = 13;
   */
  OIDX_INPUT_3 = 13,

  /**
   * @generated from enum value: OIDX_INPUT_4 = 14;
   */
  OIDX_INPUT_4 = 14,

  /**
   * @generated from enum value: OIDX_INPUT_5 = 15;
   */
  OIDX_INPUT_5 = 15,

  /**
   * @generated from enum value: OIDX_ITC_1 = 16;
   */
  OIDX_ITC_1 = 16,

  /**
   * @generated from enum value: OIDX_ITC_2 = 17;
   */
  OIDX_ITC_2 = 17,

  /**
   * @generated from enum value: OIDX_DHW_TANK = 20;
   */
  OIDX_DHW_TANK = 20,

  /**
   * @generated from enum value: OIDX_HCC_1 = 21;
   */
  OIDX_HCC_1 = 21,

  /**
   * @generated from enum value: OIDX_HCC_2 = 22;
   */
  OIDX_HCC_2 = 22,

  /**
   * @generated from enum value: OIDX_HCC_3 = 23;
   */
  OIDX_HCC_3 = 23,

  /**
   * @generated from enum value: OIDX_RJ_B_BUS = 26;
   */
  OIDX_RJ_B_BUS = 26,

  /**
   * @generated from enum value: OIDX_RJ_A = 27;
   */
  OIDX_RJ_A = 27,

  /**
   * @generated from enum value: OIDX_ETHERNET = 28;
   */
  OIDX_ETHERNET = 28,

  /**
   * @generated from enum value: OIDX_ROOM_1 = 32;
   */
  OIDX_ROOM_1 = 32,

  /**
   * @generated from enum value: OIDX_ROOM_2 = 33;
   */
  OIDX_ROOM_2 = 33,

  /**
   * @generated from enum value: OIDX_ROOM_3 = 34;
   */
  OIDX_ROOM_3 = 34,

  /**
   * @generated from enum value: OIDX_ROOM_4 = 35;
   */
  OIDX_ROOM_4 = 35,

  /**
   * @generated from enum value: OIDX_ROOM_5 = 36;
   */
  OIDX_ROOM_5 = 36,

  /**
   * @generated from enum value: OIDX_ROOM_6 = 37;
   */
  OIDX_ROOM_6 = 37,

  /**
   * @generated from enum value: OIDX_ROOM_7 = 38;
   */
  OIDX_ROOM_7 = 38,

  /**
   * @generated from enum value: OIDX_ROOM_8 = 39;
   */
  OIDX_ROOM_8 = 39,

  /**
   * @generated from enum value: OIDX_ROOM_9 = 40;
   */
  OIDX_ROOM_9 = 40,

  /**
   * @generated from enum value: OIDX_ROOM_10 = 41;
   */
  OIDX_ROOM_10 = 41,

  /**
   * @generated from enum value: OIDX_ROOM_11 = 42;
   */
  OIDX_ROOM_11 = 42,

  /**
   * @generated from enum value: OIDX_ROOM_12 = 43;
   */
  OIDX_ROOM_12 = 43,

  /**
   * @generated from enum value: OIDX_ROOM_13 = 44;
   */
  OIDX_ROOM_13 = 44,

  /**
   * @generated from enum value: OIDX_ROOM_14 = 45;
   */
  OIDX_ROOM_14 = 45,

  /**
   * @generated from enum value: OIDX_ROOM_15 = 46;
   */
  OIDX_ROOM_15 = 46,

  /**
   * @generated from enum value: OIDX_ROOM_16 = 47;
   */
  OIDX_ROOM_16 = 47,

  /**
   * @generated from enum value: OIDX_ROOM_17 = 48;
   */
  OIDX_ROOM_17 = 48,

  /**
   * @generated from enum value: OIDX_ROOM_18 = 49;
   */
  OIDX_ROOM_18 = 49,

  /**
   * @generated from enum value: OIDX_ROOM_19 = 50;
   */
  OIDX_ROOM_19 = 50,

  /**
   * @generated from enum value: OIDX_ROOM_20 = 51;
   */
  OIDX_ROOM_20 = 51,

  /**
   * @generated from enum value: OIDX_ROOM_21 = 52;
   */
  OIDX_ROOM_21 = 52,

  /**
   * @generated from enum value: OIDX_ROOM_22 = 53;
   */
  OIDX_ROOM_22 = 53,

  /**
   * @generated from enum value: OIDX_ROOM_23 = 54;
   */
  OIDX_ROOM_23 = 54,

  /**
   * @generated from enum value: OIDX_ROOM_24 = 55;
   */
  OIDX_ROOM_24 = 55,

  /**
   * @generated from enum value: OIDX_ROOM_25 = 56;
   */
  OIDX_ROOM_25 = 56,

  /**
   * @generated from enum value: OIDX_ROOM_26 = 57;
   */
  OIDX_ROOM_26 = 57,

  /**
   * @generated from enum value: OIDX_ROOM_27 = 58;
   */
  OIDX_ROOM_27 = 58,

  /**
   * @generated from enum value: OIDX_ROOM_28 = 59;
   */
  OIDX_ROOM_28 = 59,

  /**
   * @generated from enum value: OIDX_ROOM_29 = 60;
   */
  OIDX_ROOM_29 = 60,

  /**
   * @generated from enum value: OIDX_ROOM_30 = 61;
   */
  OIDX_ROOM_30 = 61,

  /**
   * @generated from enum value: OIDX_ROOM_31 = 62;
   */
  OIDX_ROOM_31 = 62,

  /**
   * @generated from enum value: OIDX_ROOM_32 = 63;
   */
  OIDX_ROOM_32 = 63,

  /**
   * @generated from enum value: OIDX_OUTPUT_1 = 64;
   */
  OIDX_OUTPUT_1 = 64,

  /**
   * @generated from enum value: OIDX_OUTPUT_2 = 65;
   */
  OIDX_OUTPUT_2 = 65,

  /**
   * @generated from enum value: OIDX_OUTPUT_3 = 66;
   */
  OIDX_OUTPUT_3 = 66,

  /**
   * @generated from enum value: OIDX_OUTPUT_4 = 67;
   */
  OIDX_OUTPUT_4 = 67,

  /**
   * @generated from enum value: OIDX_OUTPUT_5 = 68;
   */
  OIDX_OUTPUT_5 = 68,

  /**
   * @generated from enum value: OIDX_OUTPUT_6 = 69;
   */
  OIDX_OUTPUT_6 = 69,

  /**
   * @generated from enum value: OIDX_OUTPUT_7 = 70;
   */
  OIDX_OUTPUT_7 = 70,

  /**
   * @generated from enum value: OIDX_OUTPUT_8 = 71;
   */
  OIDX_OUTPUT_8 = 71,

  /**
   * @generated from enum value: OIDX_PWM_INPUT = 72;
   */
  OIDX_PWM_INPUT = 72,

  /**
   * @generated from enum value: OIDX_UPDATE_CTRL = 73;
   */
  OIDX_UPDATE_CTRL = 73,

  /**
   * @generated from enum value: OIDX_PROFILE = 74;
   */
  OIDX_PROFILE = 74,

  /**
   * @generated from enum value: OIDX_AGGREGATED = 75;
   */
  OIDX_AGGREGATED = 75,

  /**
   * @generated from enum value: OIDX_HC_WATER_SOURCE = 76;
   */
  OIDX_HC_WATER_SOURCE = 76,

  /**
   * @generated from enum value: OIDX_HEAT_PUMP_1 = 77;
   */
  OIDX_HEAT_PUMP_1 = 77,

  /**
   * @generated from enum value: OIDX_HEAT_PUMP_2 = 78;
   */
  OIDX_HEAT_PUMP_2 = 78,

  /**
   * @generated from enum value: OIDX_VELUX_CTRL = 80;
   */
  OIDX_VELUX_CTRL = 80,

  /**
   * @generated from enum value: OIDX_DEHUMIDIFIER_1 = 81;
   */
  OIDX_DEHUMIDIFIER_1 = 81,

  /**
   * @generated from enum value: OIDX_DEHUMIDIFIER_2 = 82;
   */
  OIDX_DEHUMIDIFIER_2 = 82,

  /**
   * @generated from enum value: OIDX_DEHUMIDIFIER_3 = 83;
   */
  OIDX_DEHUMIDIFIER_3 = 83,

  /**
   * @generated from enum value: OIDX_DEHUMIDIFIER_4 = 84;
   */
  OIDX_DEHUMIDIFIER_4 = 84,

  /**
   * @generated from enum value: OIDX_DRYER_1 = 85;
   */
  OIDX_DRYER_1 = 85,

  /**
   * @generated from enum value: OIDX_DRYER_2 = 86;
   */
  OIDX_DRYER_2 = 86,

  /**
   * @generated from enum value: OIDX_DRYER_3 = 87;
   */
  OIDX_DRYER_3 = 87,

  /**
   * @generated from enum value: OIDX_DRYER_4 = 88;
   */
  OIDX_DRYER_4 = 88,

  /**
   * @generated from enum value: OIDX_INTEGRATION_1 = 89;
   */
  OIDX_INTEGRATION_1 = 89,

  /**
   * @generated from enum value: OIDX_INTEGRATION_2 = 90;
   */
  OIDX_INTEGRATION_2 = 90,

  /**
   * @generated from enum value: OIDX_INTEGRATION_3 = 91;
   */
  OIDX_INTEGRATION_3 = 91,

  /**
   * @generated from enum value: OIDX_INTEGRATION_4 = 92;
   */
  OIDX_INTEGRATION_4 = 92,

  /**
   * @generated from enum value: OIDX_MECH_VENTILATION_1 = 93;
   */
  OIDX_MECH_VENTILATION_1 = 93,

  /**
   * @generated from enum value: OIDX_MECH_VENTILATION_2 = 94;
   */
  OIDX_MECH_VENTILATION_2 = 94,

  /**
   * @generated from enum value: OIDX_LOCATION = 254;
   */
  OIDX_LOCATION = 254,
}
// Retrieve enum metadata with: proto3.getEnumType(OIDX)
proto3.util.setEnumType(OIDX, "wavin.sentio.v1.OIDX", [
  { no: 0, name: "OIDX_DEVICE" },
  { no: 1, name: "OIDX_PUMP_1" },
  { no: 2, name: "OIDX_PUMP_2" },
  { no: 3, name: "OIDX_ANALOG_0_10V" },
  { no: 4, name: "OIDX_PWM_OUTPUT" },
  { no: 5, name: "OIDX_GPIO_1" },
  { no: 6, name: "OIDX_GPIO_2" },
  { no: 7, name: "OIDX_VFR_1" },
  { no: 8, name: "OIDX_VFR_2" },
  { no: 9, name: "OIDX_SERVO_1" },
  { no: 10, name: "OIDX_SERVO_2" },
  { no: 11, name: "OIDX_INPUT_1" },
  { no: 12, name: "OIDX_INPUT_2" },
  { no: 13, name: "OIDX_INPUT_3" },
  { no: 14, name: "OIDX_INPUT_4" },
  { no: 15, name: "OIDX_INPUT_5" },
  { no: 16, name: "OIDX_ITC_1" },
  { no: 17, name: "OIDX_ITC_2" },
  { no: 20, name: "OIDX_DHW_TANK" },
  { no: 21, name: "OIDX_HCC_1" },
  { no: 22, name: "OIDX_HCC_2" },
  { no: 23, name: "OIDX_HCC_3" },
  { no: 26, name: "OIDX_RJ_B_BUS" },
  { no: 27, name: "OIDX_RJ_A" },
  { no: 28, name: "OIDX_ETHERNET" },
  { no: 32, name: "OIDX_ROOM_1" },
  { no: 33, name: "OIDX_ROOM_2" },
  { no: 34, name: "OIDX_ROOM_3" },
  { no: 35, name: "OIDX_ROOM_4" },
  { no: 36, name: "OIDX_ROOM_5" },
  { no: 37, name: "OIDX_ROOM_6" },
  { no: 38, name: "OIDX_ROOM_7" },
  { no: 39, name: "OIDX_ROOM_8" },
  { no: 40, name: "OIDX_ROOM_9" },
  { no: 41, name: "OIDX_ROOM_10" },
  { no: 42, name: "OIDX_ROOM_11" },
  { no: 43, name: "OIDX_ROOM_12" },
  { no: 44, name: "OIDX_ROOM_13" },
  { no: 45, name: "OIDX_ROOM_14" },
  { no: 46, name: "OIDX_ROOM_15" },
  { no: 47, name: "OIDX_ROOM_16" },
  { no: 48, name: "OIDX_ROOM_17" },
  { no: 49, name: "OIDX_ROOM_18" },
  { no: 50, name: "OIDX_ROOM_19" },
  { no: 51, name: "OIDX_ROOM_20" },
  { no: 52, name: "OIDX_ROOM_21" },
  { no: 53, name: "OIDX_ROOM_22" },
  { no: 54, name: "OIDX_ROOM_23" },
  { no: 55, name: "OIDX_ROOM_24" },
  { no: 56, name: "OIDX_ROOM_25" },
  { no: 57, name: "OIDX_ROOM_26" },
  { no: 58, name: "OIDX_ROOM_27" },
  { no: 59, name: "OIDX_ROOM_28" },
  { no: 60, name: "OIDX_ROOM_29" },
  { no: 61, name: "OIDX_ROOM_30" },
  { no: 62, name: "OIDX_ROOM_31" },
  { no: 63, name: "OIDX_ROOM_32" },
  { no: 64, name: "OIDX_OUTPUT_1" },
  { no: 65, name: "OIDX_OUTPUT_2" },
  { no: 66, name: "OIDX_OUTPUT_3" },
  { no: 67, name: "OIDX_OUTPUT_4" },
  { no: 68, name: "OIDX_OUTPUT_5" },
  { no: 69, name: "OIDX_OUTPUT_6" },
  { no: 70, name: "OIDX_OUTPUT_7" },
  { no: 71, name: "OIDX_OUTPUT_8" },
  { no: 72, name: "OIDX_PWM_INPUT" },
  { no: 73, name: "OIDX_UPDATE_CTRL" },
  { no: 74, name: "OIDX_PROFILE" },
  { no: 75, name: "OIDX_AGGREGATED" },
  { no: 76, name: "OIDX_HC_WATER_SOURCE" },
  { no: 77, name: "OIDX_HEAT_PUMP_1" },
  { no: 78, name: "OIDX_HEAT_PUMP_2" },
  { no: 80, name: "OIDX_VELUX_CTRL" },
  { no: 81, name: "OIDX_DEHUMIDIFIER_1" },
  { no: 82, name: "OIDX_DEHUMIDIFIER_2" },
  { no: 83, name: "OIDX_DEHUMIDIFIER_3" },
  { no: 84, name: "OIDX_DEHUMIDIFIER_4" },
  { no: 85, name: "OIDX_DRYER_1" },
  { no: 86, name: "OIDX_DRYER_2" },
  { no: 87, name: "OIDX_DRYER_3" },
  { no: 88, name: "OIDX_DRYER_4" },
  { no: 89, name: "OIDX_INTEGRATION_1" },
  { no: 90, name: "OIDX_INTEGRATION_2" },
  { no: 91, name: "OIDX_INTEGRATION_3" },
  { no: 92, name: "OIDX_INTEGRATION_4" },
  { no: 93, name: "OIDX_MECH_VENTILATION_1" },
  { no: 94, name: "OIDX_MECH_VENTILATION_2" },
  { no: 254, name: "OIDX_LOCATION" },
]);

/**
 * OIDX_VFR (Object Index) of OID (Object ID)
 * Valid EU-206-VFR
 * buf:lint:ignore ENUM_PASCAL_CASE
 *
 * @generated from enum wavin.sentio.v1.OIDX_VFR
 */
export enum OIDX_VFR {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: OIDX_VFR_DEVICE = 0;
   */
  OIDX_VFR_DEVICE = 0,

  /**
   * @generated from enum value: OIDX_VFR_OUTPUT_1 = 1;
   */
  OIDX_VFR_OUTPUT_1 = 1,

  /**
   * @generated from enum value: OIDX_VFR_OUTPUT_2 = 2;
   */
  OIDX_VFR_OUTPUT_2 = 2,

  /**
   * @generated from enum value: OIDX_VFR_OUTPUT_3 = 3;
   */
  OIDX_VFR_OUTPUT_3 = 3,

  /**
   * @generated from enum value: OIDX_VFR_OUTPUT_4 = 4;
   */
  OIDX_VFR_OUTPUT_4 = 4,

  /**
   * @generated from enum value: OIDX_VFR_OUTPUT_5 = 5;
   */
  OIDX_VFR_OUTPUT_5 = 5,

  /**
   * @generated from enum value: OIDX_VFR_OUTPUT_6 = 6;
   */
  OIDX_VFR_OUTPUT_6 = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(OIDX_VFR)
proto3.util.setEnumType(OIDX_VFR, "wavin.sentio.v1.OIDX_VFR", [
  { no: 0, name: "OIDX_VFR_DEVICE" },
  { no: 1, name: "OIDX_VFR_OUTPUT_1" },
  { no: 2, name: "OIDX_VFR_OUTPUT_2" },
  { no: 3, name: "OIDX_VFR_OUTPUT_3" },
  { no: 4, name: "OIDX_VFR_OUTPUT_4" },
  { no: 5, name: "OIDX_VFR_OUTPUT_5" },
  { no: 6, name: "OIDX_VFR_OUTPUT_6" },
]);

/**
 * OIDX_DHW_201 (Object Index) of OID (Object ID)
 * Valid for DHW-201
 * buf:lint:ignore ENUM_PASCAL_CASE
 *
 * @generated from enum wavin.sentio.v1.OIDX_DHW_201
 */
export enum OIDX_DHW_201 {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: OIDX_DHW_201_DEVICE = 0;
   */
  OIDX_DHW_201_DEVICE = 0,

  /**
   * @generated from enum value: OIDX_DHW_201_CONTROLLER = 1;
   */
  OIDX_DHW_201_CONTROLLER = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(OIDX_DHW_201)
proto3.util.setEnumType(OIDX_DHW_201, "wavin.sentio.v1.OIDX_DHW_201", [
  { no: 0, name: "OIDX_DHW_201_DEVICE" },
  { no: 1, name: "OIDX_DHW_201_CONTROLLER" },
]);

/**
 * OIDX_DHW_21X (Object Index) of OID (Object ID)
 * Valid for DHW-21X models
 * buf:lint:ignore ENUM_PASCAL_CASE
 *
 * @generated from enum wavin.sentio.v1.OIDX_DHW_21X
 */
export enum OIDX_DHW_21X {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: OIDX_DHW_21X_DEVICE = 0;
   */
  OIDX_DHW_21X_DEVICE = 0,

  /**
   * @generated from enum value: OIDX_DHW_21X_CONTROLLER = 20;
   */
  OIDX_DHW_21X_CONTROLLER = 20,

  /**
   * @generated from enum value: OIDX_DHW_21X_PUMP_1 = 1;
   */
  OIDX_DHW_21X_PUMP_1 = 1,

  /**
   * @generated from enum value: OIDX_DHW_21X_PUMP_2 = 2;
   */
  OIDX_DHW_21X_PUMP_2 = 2,

  /**
   * @generated from enum value: OIDX_DHW_21X_PUMP_3 = 3;
   */
  OIDX_DHW_21X_PUMP_3 = 3,

  /**
   * @generated from enum value: OIDX_DHW_21X_PUMP_PWM = 4;
   */
  OIDX_DHW_21X_PUMP_PWM = 4,

  /**
   * @generated from enum value: OIDX_DHW_21X_SERVO_1 = 9;
   */
  OIDX_DHW_21X_SERVO_1 = 9,

  /**
   * @generated from enum value: OIDX_DHW_21X_SERVO_2 = 10;
   */
  OIDX_DHW_21X_SERVO_2 = 10,

  /**
   * @generated from enum value: OIDX_DHW_21X_INPUT_1 = 11;
   */
  OIDX_DHW_21X_INPUT_1 = 11,

  /**
   * @generated from enum value: OIDX_DHW_21X_INPUT_2 = 12;
   */
  OIDX_DHW_21X_INPUT_2 = 12,

  /**
   * @generated from enum value: OIDX_DHW_21X_INPUT_3 = 13;
   */
  OIDX_DHW_21X_INPUT_3 = 13,

  /**
   * @generated from enum value: OIDX_DHW_21X_ITC_1 = 16;
   */
  OIDX_DHW_21X_ITC_1 = 16,

  /**
   * @generated from enum value: OIDX_DHW_21X_HCC_1 = 21;
   */
  OIDX_DHW_21X_HCC_1 = 21,

  /**
   * @generated from enum value: OIDX_DHW_21X_GUI = 24;
   */
  OIDX_DHW_21X_GUI = 24,

  /**
   * @generated from enum value: OIDX_DHW_21X_RJ_B = 26;
   */
  OIDX_DHW_21X_RJ_B = 26,

  /**
   * @generated from enum value: OIDX_DHW_21X_RJ_A = 27;
   */
  OIDX_DHW_21X_RJ_A = 27,

  /**
   * @generated from enum value: OIDX_DHW_21X_ETHERNET = 28;
   */
  OIDX_DHW_21X_ETHERNET = 28,

  /**
   * @generated from enum value: OIDX_DHW_21X_USB = 29;
   */
  OIDX_DHW_21X_USB = 29,

  /**
   * @generated from enum value: OIDX_DHW_21X_OPEN_THERM = 30;
   */
  OIDX_DHW_21X_OPEN_THERM = 30,

  /**
   * @generated from enum value: OIDX_DHW_21X_MBUS = 31;
   */
  OIDX_DHW_21X_MBUS = 31,

  /**
   * @generated from enum value: OIDX_DHW_21X_ROOM_1 = 32;
   */
  OIDX_DHW_21X_ROOM_1 = 32,

  /**
   * @generated from enum value: OIDX_DHW_21X_UPDATE_CTRL = 73;
   */
  OIDX_DHW_21X_UPDATE_CTRL = 73,
}
// Retrieve enum metadata with: proto3.getEnumType(OIDX_DHW_21X)
proto3.util.setEnumType(OIDX_DHW_21X, "wavin.sentio.v1.OIDX_DHW_21X", [
  { no: 0, name: "OIDX_DHW_21X_DEVICE" },
  { no: 20, name: "OIDX_DHW_21X_CONTROLLER" },
  { no: 1, name: "OIDX_DHW_21X_PUMP_1" },
  { no: 2, name: "OIDX_DHW_21X_PUMP_2" },
  { no: 3, name: "OIDX_DHW_21X_PUMP_3" },
  { no: 4, name: "OIDX_DHW_21X_PUMP_PWM" },
  { no: 9, name: "OIDX_DHW_21X_SERVO_1" },
  { no: 10, name: "OIDX_DHW_21X_SERVO_2" },
  { no: 11, name: "OIDX_DHW_21X_INPUT_1" },
  { no: 12, name: "OIDX_DHW_21X_INPUT_2" },
  { no: 13, name: "OIDX_DHW_21X_INPUT_3" },
  { no: 16, name: "OIDX_DHW_21X_ITC_1" },
  { no: 21, name: "OIDX_DHW_21X_HCC_1" },
  { no: 24, name: "OIDX_DHW_21X_GUI" },
  { no: 26, name: "OIDX_DHW_21X_RJ_B" },
  { no: 27, name: "OIDX_DHW_21X_RJ_A" },
  { no: 28, name: "OIDX_DHW_21X_ETHERNET" },
  { no: 29, name: "OIDX_DHW_21X_USB" },
  { no: 30, name: "OIDX_DHW_21X_OPEN_THERM" },
  { no: 31, name: "OIDX_DHW_21X_MBUS" },
  { no: 32, name: "OIDX_DHW_21X_ROOM_1" },
  { no: 73, name: "OIDX_DHW_21X_UPDATE_CTRL" },
]);

/**
 * Device type for Device Objects
 *
 * @generated from enum wavin.sentio.v1.DeviceType
 */
export enum DeviceType {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * CALEFA-I (District Heating)
   *
   * @generated from enum value: DEVICE_TYPE_DHW201 = 0;
   */
  DHW201 = 0,

  /**
   * Not used
   *
   * @generated from enum value: DEVICE_TYPE_ITC202 = 1;
   */
  ITC202 = 1,

  /**
   * Not used
   *
   * @generated from enum value: DEVICE_TYPE_TH201B = 2;
   */
  TH201B = 2,

  /**
   * Not used
   *
   * @generated from enum value: DEVICE_TYPE_TH201R = 3;
   */
  TH201R = 3,

  /**
   * LCD-200 (Comissioning tool.)
   *
   * @generated from enum value: DEVICE_TYPE_LCD = 4;
   */
  LCD = 4,

  /**
   * Room Thermostat, wired
   *
   * @generated from enum value: DEVICE_TYPE_RT210 = 5;
   */
  RT210 = 5,

  /**
   * Room Thermostat, wireless
   *
   * @generated from enum value: DEVICE_TYPE_RT250 = 6;
   */
  RT250 = 6,

  /**
   * Room Sensor Wired (only sensing Temp+Humidity)
   *
   * @generated from enum value: DEVICE_TYPE_RS211 = 7;
   */
  RS211 = 7,

  /**
   * Room Sensor Wireless
   *
   * @generated from enum value: DEVICE_TYPE_RS251 = 8;
   */
  RS251 = 8,

  /**
   * Room Thermostat Wireless + InfraRed Floor Sensor
   *
   * @generated from enum value: DEVICE_TYPE_RT250IR = 9;
   */
  RT250IR = 9,

  /**
   * Extension unit with 8 additional UFH connectors
   *
   * @generated from enum value: DEVICE_TYPE_EU208A = 10;
   */
  EU208A = 10,

  /**
   * Extension Unit with 6 additional Voltage Free Relays
   *
   * @generated from enum value: DEVICE_TYPE_EU206VFR = 11;
   */
  EU206VFR = 11,

  /**
   * Outdoor Temperature Sensor Wireless
   *
   * @generated from enum value: DEVICE_TYPE_ET250 = 12;
   */
  ET250 = 12,

  /**
   * Outdoor Temperature Sensor Wired
   *
   * @generated from enum value: DEVICE_TYPE_ET210 = 13;
   */
  ET210 = 13,

  /**
   * Wireless Radiator Thermostat (Smart Radiator Thermostat)
   *
   * @generated from enum value: DEVICE_TYPE_VH250 = 14;
   */
  VH250 = 14,

  /**
   * Not used, placeholder for Wired Radiator Thermostat project
   *
   * @generated from enum value: DEVICE_TYPE_VH210 = 15;
   */
  VH210 = 15,

  /**
   * Not used, in development. Room Thermostat Wired With CO2 Sensor
   *
   * @generated from enum value: DEVICE_TYPE_RT21X_CO2 = 16;
   */
  RT21X_CO2 = 16,

  /**
   * Not used, in development. Room Thermostat Wireless With CO2 Sensor
   *
   * @generated from enum value: DEVICE_TYPE_RS21X_CO2 = 20;
   */
  RS21X_CO2 = 20,

  /**
   * Calefa-II Simple = without Display (Cheapest variant)
   *
   * @generated from enum value: DEVICE_TYPE_DHW211_S = 192;
   */
  DHW211_S = 192,

  /**
   * Calefa-II Base model
   *
   * @generated from enum value: DEVICE_TYPE_DHW211 = 195;
   */
  DHW211 = 195,

  /**
   * Calefa-II Double Heat Exchanger (V is something danish for separated heating and tap-water)
   *
   * @generated from enum value: DEVICE_TYPE_DHW211_V = 198;
   */
  DHW211_V = 198,

  /**
   * Calefa-II with ITC controller
   *
   * @generated from enum value: DEVICE_TYPE_DHW212_ITC = 201;
   */
  DHW212_ITC = 201,

  /**
   * Calefa-II with ITC Controller + Double heat exchanger
   *
   * @generated from enum value: DEVICE_TYPE_DHW212_ITC_V = 204;
   */
  DHW212_ITC_V = 204,

  /**
   * Calefa-II with Heat Control
   *
   * @generated from enum value: DEVICE_TYPE_DHW212_HC = 207;
   */
  DHW212_HC = 207,

  /**
   * Calefa-II with Heat Control and double Heat Exchanger
   *
   * @generated from enum value: DEVICE_TYPE_DHW212_HC_V = 210;
   */
  DHW212_HC_V = 210,

  /**
   * CCU 208 Controller (Sentio main device)
   *
   * @generated from enum value: DEVICE_TYPE_CCU208 = 224;
   */
  CCU208 = 224,

  /**
   * Mobile App (used as a peripheral on the bus)
   *
   * @generated from enum value: DEVICE_TYPE_PC_APP = 255;
   */
  PC_APP = 255,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceType)
proto3.util.setEnumType(DeviceType, "wavin.sentio.v1.DeviceType", [
  { no: 0, name: "DEVICE_TYPE_DHW201" },
  { no: 1, name: "DEVICE_TYPE_ITC202" },
  { no: 2, name: "DEVICE_TYPE_TH201B" },
  { no: 3, name: "DEVICE_TYPE_TH201R" },
  { no: 4, name: "DEVICE_TYPE_LCD" },
  { no: 5, name: "DEVICE_TYPE_RT210" },
  { no: 6, name: "DEVICE_TYPE_RT250" },
  { no: 7, name: "DEVICE_TYPE_RS211" },
  { no: 8, name: "DEVICE_TYPE_RS251" },
  { no: 9, name: "DEVICE_TYPE_RT250IR" },
  { no: 10, name: "DEVICE_TYPE_EU208A" },
  { no: 11, name: "DEVICE_TYPE_EU206VFR" },
  { no: 12, name: "DEVICE_TYPE_ET250" },
  { no: 13, name: "DEVICE_TYPE_ET210" },
  { no: 14, name: "DEVICE_TYPE_VH250" },
  { no: 15, name: "DEVICE_TYPE_VH210" },
  { no: 16, name: "DEVICE_TYPE_RT21X_CO2" },
  { no: 20, name: "DEVICE_TYPE_RS21X_CO2" },
  { no: 192, name: "DEVICE_TYPE_DHW211_S" },
  { no: 195, name: "DEVICE_TYPE_DHW211" },
  { no: 198, name: "DEVICE_TYPE_DHW211_V" },
  { no: 201, name: "DEVICE_TYPE_DHW212_ITC" },
  { no: 204, name: "DEVICE_TYPE_DHW212_ITC_V" },
  { no: 207, name: "DEVICE_TYPE_DHW212_HC" },
  { no: 210, name: "DEVICE_TYPE_DHW212_HC_V" },
  { no: 224, name: "DEVICE_TYPE_CCU208" },
  { no: 255, name: "DEVICE_TYPE_PC_APP" },
]);

/**
 * Value ID
 *
 * @generated from enum wavin.sentio.v1.VID
 */
export enum VID {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: VID_INVALID = 0;
   */
  VID_INVALID = 0,

  /**
   * Common (all objects)
   *
   * @generated from enum value: VID_OBJECT_NAME = 32912;
   */
  VID_OBJECT_NAME = 32912,

  /**
   * @generated from enum value: VID_OBJECT_INFO = 32925;
   */
  VID_OBJECT_INFO = 32925,

  /**
   * @generated from enum value: VID_OBJECT_STATUS = 33849;
   */
  VID_OBJECT_STATUS = 33849,

  /**
   * @generated from enum value: VID_OBJECT_RUNTIME_VALUES = 32927;
   */
  VID_OBJECT_RUNTIME_VALUES = 32927,

  /**
   * @generated from enum value: VID_OBJECT_SERVICE_VALUES = 32939;
   */
  VID_OBJECT_SERVICE_VALUES = 32939,

  /**
   * @generated from enum value: VID_OBJECT_PERIPHERAL_LIST = 32942;
   */
  VID_OBJECT_PERIPHERAL_LIST = 32942,

  /**
   * Common Indoor
   *
   * @generated from enum value: VID_INDOOR_AIR_TEMPERATURE = 12;
   */
  VID_INDOOR_AIR_TEMPERATURE = 12,

  /**
   * @generated from enum value: VID_INDOOR_FLOOR_TEMPERATURE = 13;
   */
  VID_INDOOR_FLOOR_TEMPERATURE = 13,

  /**
   * @generated from enum value: VID_INDOOR_HUMIDITY = 2;
   */
  VID_INDOOR_HUMIDITY = 2,

  /**
   * @generated from enum value: VID_INDOOR_DEW_POINT = 75;
   */
  VID_INDOOR_DEW_POINT = 75,

  /**
   * @generated from enum value: VID_INDOOR_VALUES_CO2_LEVEL = 3;
   */
  VID_INDOOR_VALUES_CO2_LEVEL = 3,

  /**
   * @generated from enum value: VID_INDOOR_VALUES_VOC_LEVEL = 4;
   */
  VID_INDOOR_VALUES_VOC_LEVEL = 4,

  /**
   * Common Outdoor
   *
   * @generated from enum value: VID_OUTDOOR_TEMPERATURE = 54;
   */
  VID_OUTDOOR_TEMPERATURE = 54,

  /**
   * @generated from enum value: VID_OUTDOOR_TEMPERATURE_FILTERED = 40;
   */
  VID_OUTDOOR_TEMPERATURE_FILTERED = 40,

  /**
   * @generated from enum value: VID_OUTDOOR_TEMPERATURE_AVERAGE = 94;
   */
  VID_OUTDOOR_TEMPERATURE_AVERAGE = 94,

  /**
   * Common Domestic Hot/Cold Water Distribution
   *
   * @generated from enum value: VID_DOMESTIC_HOT_WATER_TEMPERATURE = 17;
   */
  VID_DOMESTIC_HOT_WATER_TEMPERATURE = 17,

  /**
   * @generated from enum value: VID_DOMESTIC_COLD_WATER_TEMPERATURE = 20;
   */
  VID_DOMESTIC_COLD_WATER_TEMPERATURE = 20,

  /**
   * @generated from enum value: VID_DOMESTIC_COLD_WATER_FLOW = 19;
   */
  VID_DOMESTIC_COLD_WATER_FLOW = 19,

  /**
   * Common ventilation
   *
   * @generated from enum value: VID_VENTILATION_SOURCE_DEMAND = 240;
   */
  VID_VENTILATION_SOURCE_DEMAND = 240,

  /**
   * @generated from enum value: VID_VENTILATION_SOURCE_STATUS = 241;
   */
  VID_VENTILATION_SOURCE_STATUS = 241,

  /**
   * @generated from enum value: VID_VENTILATION_STATE = 245;
   */
  VID_VENTILATION_STATE = 245,

  /**
   * @generated from enum value: VID_VENTILATION_CONSUMER_LIST = 33990;
   */
  VID_VENTILATION_CONSUMER_LIST = 33990,

  /**
   * @generated from enum value: VID_VENTILATION_SUPPLIER_OID = 33991;
   */
  VID_VENTILATION_SUPPLIER_OID = 33991,

  /**
   * Common drying
   *
   * @generated from enum value: VID_DRYING_STATE = 153;
   */
  VID_DRYING_STATE = 153,

  /**
   * Location Object
   *
   * @generated from enum value: VID_LOCATION_NAME = 32768;
   */
  VID_LOCATION_NAME = 32768,

  /**
   * @generated from enum value: VID_LOCATION_LANGUAGE = 32862;
   */
  VID_LOCATION_LANGUAGE = 32862,

  /**
   * @generated from enum value: VID_LOCATION_TIMEZONE = 32879;
   */
  VID_LOCATION_TIMEZONE = 32879,

  /**
   * @generated from enum value: VID_LOCATION_TIMEZONE_OFFSET = 32884;
   */
  VID_LOCATION_TIMEZONE_OFFSET = 32884,

  /**
   * @generated from enum value: VID_LOCATION_TIMEZONE_DST_ENABLED = 32885;
   */
  VID_LOCATION_TIMEZONE_DST_ENABLED = 32885,

  /**
   * @generated from enum value: VID_LOCATION_TIMEZONE_DST_UPCOMING = 275;
   */
  VID_LOCATION_TIMEZONE_DST_UPCOMING = 275,

  /**
   * @generated from enum value: VID_LOCATION_LOCAL_TIME_OFFSET = 46;
   */
  VID_LOCATION_LOCAL_TIME_OFFSET = 46,

  /**
   * @generated from enum value: VID_LOCATION_COUNTRY = 33926;
   */
  VID_LOCATION_COUNTRY = 33926,

  /**
   * @generated from enum value: VID_LOCATION_CONTROL_UNIT_OID = 32988;
   */
  VID_LOCATION_CONTROL_UNIT_OID = 32988,

  /**
   * @generated from enum value: VID_LOCATION_VACATION = 32901;
   */
  VID_LOCATION_VACATION = 32901,

  /**
   * @generated from enum value: VID_LOCATION_VACATION_END_TIME = 32902;
   */
  VID_LOCATION_VACATION_END_TIME = 32902,

  /**
   * @generated from enum value: VID_LOCATION_STANDBY = 32903;
   */
  VID_LOCATION_STANDBY = 32903,

  /**
   * @generated from enum value: VID_LOCATION_HC_MODE = 32829;
   */
  VID_LOCATION_HC_MODE = 32829,

  /**
   * @generated from enum value: VID_LOCATION_HC_MODE_SWITCHING = 32886;
   */
  VID_LOCATION_HC_MODE_SWITCHING = 32886,

  /**
   * @generated from enum value: VID_LOCATION_HC_MODE_PRIORITY = 34015;
   */
  VID_LOCATION_HC_MODE_PRIORITY = 34015,

  /**
   * @generated from enum value: VID_LOCATION_HC_MODE_BMS_OVERRIDE = 34016;
   */
  VID_LOCATION_HC_MODE_BMS_OVERRIDE = 34016,

  /**
   * @generated from enum value: VID_LOCATION_INSTALLER_PASSWORD = 33062;
   */
  VID_LOCATION_INSTALLER_PASSWORD = 33062,

  /**
   * permanent password
   *
   * @generated from enum value: VID_LOCATION_SERVICE_KEY = 126;
   */
  VID_LOCATION_SERVICE_KEY = 126,

  /**
   * @generated from enum value: VID_LOCATION_SERVICE_KEY_ACCESS_ALLOWED = 33076;
   */
  VID_LOCATION_SERVICE_KEY_ACCESS_ALLOWED = 33076,

  /**
   * @generated from enum value: VID_LOCATION_GENERATE_TEMPORARY_PASSWORD = 250;
   */
  VID_LOCATION_GENERATE_TEMPORARY_PASSWORD = 250,

  /**
   * @generated from enum value: VID_LOCATION_TEMPORARY_PASSWORD = 253;
   */
  VID_LOCATION_TEMPORARY_PASSWORD = 253,

  /**
   * @generated from enum value: VID_LOCATION_TEMPORARY_PASSWORD_EXPIRATION = 254;
   */
  VID_LOCATION_TEMPORARY_PASSWORD_EXPIRATION = 254,

  /**
   * @generated from enum value: VID_LOCATION_USER_ENROLLMENT_ENABLED = 331;
   */
  VID_LOCATION_USER_ENROLLMENT_ENABLED = 331,

  /**
   * @generated from enum value: VID_LOCATION_ALARM_HEAT_PUMP_FAULT = 16413;
   */
  VID_LOCATION_ALARM_HEAT_PUMP_FAULT = 16413,

  /**
   * @generated from enum value: VID_LOCATION_ALARM_EXTERNAL_DEVICE_FAULT = 16452;
   */
  VID_LOCATION_ALARM_EXTERNAL_DEVICE_FAULT = 16452,

  /**
   * 0 = Production endpoint, 1 = Develop endpoint with test AES key
   *
   * @generated from enum value: VID_LOCATION_TESTING_ENDPOINT = 34099;
   */
  VID_LOCATION_TESTING_ENDPOINT = 34099,

  /**
   * Device Object
   *
   * @generated from enum value: VID_DEVICE_NAME = 32779;
   */
  VID_DEVICE_NAME = 32779,

  /**
   * @generated from enum value: VID_DEVICE_STATE = 47;
   */
  VID_DEVICE_STATE = 47,

  /**
   * @generated from enum value: VID_DEVICE_OBJECT_LIST = 32944;
   */
  VID_DEVICE_OBJECT_LIST = 32944,

  /**
   * @generated from enum value: VID_DEVICE_SERIAL = 32780;
   */
  VID_DEVICE_SERIAL = 32780,

  /**
   * @generated from enum value: VID_DEVICE_SERIAL_PREFIX = 32991;
   */
  VID_DEVICE_SERIAL_PREFIX = 32991,

  /**
   * @generated from enum value: VID_DEVICE_BOOTLOADER_CODE = 32992;
   */
  VID_DEVICE_BOOTLOADER_CODE = 32992,

  /**
   * @generated from enum value: VID_DEVICE_BOOTLOADER_VERSION = 32993;
   */
  VID_DEVICE_BOOTLOADER_VERSION = 32993,

  /**
   * @generated from enum value: VID_DEVICE_BOOTLOADER_VERSION_MINOR = 32994;
   */
  VID_DEVICE_BOOTLOADER_VERSION_MINOR = 32994,

  /**
   * @generated from enum value: VID_DEVICE_HARDWARE_NAME = 32995;
   */
  VID_DEVICE_HARDWARE_NAME = 32995,

  /**
   * @generated from enum value: VID_DEVICE_HARDWARE_VERSION = 32782;
   */
  VID_DEVICE_HARDWARE_VERSION = 32782,

  /**
   * @generated from enum value: VID_DEVICE_SOFTWARE_NAME = 32996;
   */
  VID_DEVICE_SOFTWARE_NAME = 32996,

  /**
   * @generated from enum value: VID_DEVICE_SOFTWARE_VARIANT = 32997;
   */
  VID_DEVICE_SOFTWARE_VARIANT = 32997,

  /**
   * @generated from enum value: VID_DEVICE_SOFTWARE_VERSION = 32781;
   */
  VID_DEVICE_SOFTWARE_VERSION = 32781,

  /**
   * @generated from enum value: VID_DEVICE_SOFTWARE_VERSION_MINOR = 32926;
   */
  VID_DEVICE_SOFTWARE_VERSION_MINOR = 32926,

  /**
   * @generated from enum value: VID_DEVICE_SOFTWARE_VERSION_BRANCH = 33077;
   */
  VID_DEVICE_SOFTWARE_VERSION_BRANCH = 33077,

  /**
   * @generated from enum value: VID_DEVICE_CONFIG_VERSION = 33931;
   */
  VID_DEVICE_CONFIG_VERSION = 33931,

  /**
   * @generated from enum value: VID_DEVICE_UPDATE_CONTROL = 32871;
   */
  VID_DEVICE_UPDATE_CONTROL = 32871,

  /**
   * @generated from enum value: VID_DEVICE_ALARM_LOW_BATTERY = 16387;
   */
  VID_DEVICE_ALARM_LOW_BATTERY = 16387,

  /**
   * @generated from enum value: VID_DEVICE_UPDATE_PRESET = 332;
   */
  VID_DEVICE_UPDATE_PRESET = 332,

  /**
   * Room Object
   *
   * @generated from enum value: VID_ROOM_TEMP_DESIRED = 29;
   */
  VID_ROOM_TEMP_DESIRED = 29,

  /**
   * @generated from enum value: VID_ROOM_MODE = 32772;
   */
  VID_ROOM_MODE = 32772,

  /**
   * @generated from enum value: VID_ROOM_THERMOSTAT_ACCESS_LEVEL = 32776;
   */
  VID_ROOM_THERMOSTAT_ACCESS_LEVEL = 32776,

  /**
   * @generated from enum value: VID_ROOM_WEEK_SCHEDULE = 32835;
   */
  VID_ROOM_WEEK_SCHEDULE = 32835,

  /**
   * @generated from enum value: VID_ROOM_ALLOW_COOLING = 33848;
   */
  VID_ROOM_ALLOW_COOLING = 33848,

  /**
   * @generated from enum value: VID_ROOM_ALLOW_VACATION_AWAY = 32900;
   */
  VID_ROOM_ALLOW_VACATION_AWAY = 32900,

  /**
   * @generated from enum value: VID_ROOM_ALARM_PERIPHERAL_UNREACHABLE = 16400;
   */
  VID_ROOM_ALARM_PERIPHERAL_UNREACHABLE = 16400,

  /**
   * @generated from enum value: VID_ROOM_ALARM_FROST_PROTECTION = 16427;
   */
  VID_ROOM_ALARM_FROST_PROTECTION = 16427,

  /**
   * @generated from enum value: VID_ROOM_ALARM_BATTERY_OPEN = 16450;
   */
  VID_ROOM_ALARM_BATTERY_OPEN = 16450,

  /**
   * @generated from enum value: VID_ROOM_ALARM_BADLY_INSTALLED = 16451;
   */
  VID_ROOM_ALARM_BADLY_INSTALLED = 16451,

  /**
   * @generated from enum value: VID_ROOM_ALARM_STICKING_VALVE = 16460;
   */
  VID_ROOM_ALARM_STICKING_VALVE = 16460,

  /**
   * @generated from enum value: VID_ROOM_ALARM_GENERAL_PROBLEM = 16386;
   */
  VID_ROOM_ALARM_GENERAL_PROBLEM = 16386,

  /**
   * Room Temperature Controller
   *
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_STANDBY = 32783;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_STANDBY = 32783,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HC_MODE = 32859;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HC_MODE = 32859,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_STATE = 27;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_STATE = 27,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_BLOCKING = 28;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_BLOCKING = 28,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_TEMP_ALARM_LOW = 32784;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_TEMP_ALARM_LOW = 32784,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_TEMP_ALARM_HIGH = 32785;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_TEMP_ALARM_HIGH = 32785,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_TEMP_ALARM_LOW = 33011;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_TEMP_ALARM_LOW = 33011,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_TEMP_ALARM_HIGH = 33012;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_TEMP_ALARM_HIGH = 33012,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_REGULATED_VALUE_SRC = 32788;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_REGULATED_VALUE_SRC = 32788,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SPAN_MIN = 32792;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SPAN_MIN = 32792,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SPAN_MAX = 32793;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SPAN_MAX = 32793,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_ECO = 32795;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_ECO = 32795,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_COMFORT = 32796;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_COMFORT = 32796,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_EXTRA_COMFORT = 32797;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_EXTRA_COMFORT = 32797,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_VACATION = 32799;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_VACATION = 32799,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_STANDBY = 32801;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_STANDBY = 32801,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SPAN_MIN = 32811;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SPAN_MIN = 32811,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SPAN_MAX = 32812;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SPAN_MAX = 32812,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_ECO = 32814;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_ECO = 32814,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_COMFORT = 32815;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_COMFORT = 32815,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_EXTRA_COMFORT = 32816;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_EXTRA_COMFORT = 32816,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_VACATION = 32818;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_VACATION = 32818,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_STANDBY = 32898;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_STANDBY = 32898,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_ALARM_LOW_TEMP = 16389;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_ALARM_LOW_TEMP = 16389,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_ALARM_HIGH_TEMP = 16390;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_ALARM_HIGH_TEMP = 16390,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_ALARM_LOW_FLOOR_TEMP = 16401;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_ALARM_LOW_FLOOR_TEMP = 16401,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_ALARM_HIGH_FLOOR_TEMP = 16402;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_ALARM_HIGH_FLOOR_TEMP = 16402,

  /**
   * Room humidity controller
   *
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_ENABLED = 32899;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_ENABLED = 32899,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_SPAN_MIN = 34032;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_SPAN_MIN = 34032,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_SPAN_MAX = 34033;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_SPAN_MAX = 34033,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_SETPOINT_HEAT = 33851;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_SETPOINT_HEAT = 33851,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_SETPOINT_COOL = 33843;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_SETPOINT_COOL = 33843,

  /**
   * Update Controller Object
   *
   * @generated from enum value: VID_UPDATE_CONTROLLER_PROGRESS_DEVICE_IDX = 109;
   */
  VID_UPDATE_CONTROLLER_PROGRESS_DEVICE_IDX = 109,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_PROGRESS_DEVICE_PERCENT = 110;
   */
  VID_UPDATE_CONTROLLER_PROGRESS_DEVICE_PERCENT = 110,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_ONLINE_DEVICES = 112;
   */
  VID_UPDATE_CONTROLLER_ONLINE_DEVICES = 112,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_OFFLINE_DEVICES = 113;
   */
  VID_UPDATE_CONTROLLER_OFFLINE_DEVICES = 113,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_UPDATE_MODE = 33066;
   */
  VID_UPDATE_CONTROLLER_UPDATE_MODE = 33066,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_UPDATE_STATE = 33067;
   */
  VID_UPDATE_CONTROLLER_UPDATE_STATE = 33067,

  /**
   * semantic version: 1.0.0
   *
   * @generated from enum value: VID_UPDATE_CONTROLLER_STORED_FIRMWARE_PACKAGE_VERSION = 33070;
   */
  VID_UPDATE_CONTROLLER_STORED_FIRMWARE_PACKAGE_VERSION = 33070,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_DEVICES_TO_UPDATE = 33071;
   */
  VID_UPDATE_CONTROLLER_DEVICES_TO_UPDATE = 33071,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_UPDATE_ALL_TIME = 33072;
   */
  VID_UPDATE_CONTROLLER_UPDATE_ALL_TIME = 33072,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_UPDATE_CCU_TIME = 33073;
   */
  VID_UPDATE_CONTROLLER_UPDATE_CCU_TIME = 33073,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_UPDATE_CCU_TIMEOUT = 33074;
   */
  VID_UPDATE_CONTROLLER_UPDATE_CCU_TIMEOUT = 33074,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_INSTALLED_FIRMWARE_PACKAGE_VERSION = 33075;
   */
  VID_UPDATE_CONTROLLER_INSTALLED_FIRMWARE_PACKAGE_VERSION = 33075,

  /**
   * semantic version: 1.0.0
   *
   * @generated from enum value: VID_UPDATE_CONTROLLER_PACKAGE_DOWNLOAD_REQUEST = 330;
   */
  VID_UPDATE_CONTROLLER_PACKAGE_DOWNLOAD_REQUEST = 330,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_PACKAGE_DOWNLOAD_STATE = 329;
   */
  VID_UPDATE_CONTROLLER_PACKAGE_DOWNLOAD_STATE = 329,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_UPDATE_START_REQUEST = 328;
   */
  VID_UPDATE_CONTROLLER_UPDATE_START_REQUEST = 328,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_UPDATING_DEVICE_OID = 316;
   */
  VID_UPDATE_CONTROLLER_UPDATING_DEVICE_OID = 316,

  /**
   * Input Object
   *
   * @generated from enum value: VID_INPUT_VALUE = 74;
   */
  VID_INPUT_VALUE = 74,

  /**
   * Output Object
   *
   * @generated from enum value: VID_OUTPUT_OUTPUT_VALUE = 76;
   */
  VID_OUTPUT_OUTPUT_VALUE = 76,

  /**
   * @generated from enum value: VID_OUTPUT_FUNCTION = 32999;
   */
  VID_OUTPUT_FUNCTION = 32999,

  /**
   * @generated from enum value: VID_OUTPUT_MANUAL_VALUE = 33008;
   */
  VID_OUTPUT_MANUAL_VALUE = 33008,

  /**
   * DHW Calefa Controller
   *
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_TEMP_SPAN_MIN = 32914;
   */
  VID_DHW_CALEFA_CONTROLLER_TEMP_SPAN_MIN = 32914,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_TEMP_SPAN_MAX = 32915;
   */
  VID_DHW_CALEFA_CONTROLLER_TEMP_SPAN_MAX = 32915,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_TEMP_SET = 32916;
   */
  VID_DHW_CALEFA_CONTROLLER_TEMP_SET = 32916,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_ALARM_CRITICAL_LOW_PRESSURE = 16435;
   */
  VID_DHW_CALEFA_CONTROLLER_ALARM_CRITICAL_LOW_PRESSURE = 16435,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_ALARM_HIGH_PRESSURE = 16436;
   */
  VID_DHW_CALEFA_CONTROLLER_ALARM_HIGH_PRESSURE = 16436,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_ALARM_LOW_PRESSURE = 16437;
   */
  VID_DHW_CALEFA_CONTROLLER_ALARM_LOW_PRESSURE = 16437,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_ALARM_LOW_ENERGY = 16393;
   */
  VID_DHW_CALEFA_CONTROLLER_ALARM_LOW_ENERGY = 16393,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_ALARM_HIGH_TEMPERATURE = 16394;
   */
  VID_DHW_CALEFA_CONTROLLER_ALARM_HIGH_TEMPERATURE = 16394,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_ALARM_DHI_SENSOR_FAILURE = 16418;
   */
  VID_DHW_CALEFA_CONTROLLER_ALARM_DHI_SENSOR_FAILURE = 16418,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_ALARM_DHO_SENSOR_FAILURE = 16419;
   */
  VID_DHW_CALEFA_CONTROLLER_ALARM_DHO_SENSOR_FAILURE = 16419,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_ALARM_DHW_SENSOR_FAILURE = 16420;
   */
  VID_DHW_CALEFA_CONTROLLER_ALARM_DHW_SENSOR_FAILURE = 16420,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_ALARM_DCW_SENSOR_FAILURE = 16421;
   */
  VID_DHW_CALEFA_CONTROLLER_ALARM_DCW_SENSOR_FAILURE = 16421,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_ALARM_MOTOR_FAILURE = 16422;
   */
  VID_DHW_CALEFA_CONTROLLER_ALARM_MOTOR_FAILURE = 16422,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_ALARM_PRESSURE_SENSOR_FAILURE = 16499;
   */
  VID_DHW_CALEFA_CONTROLLER_ALARM_PRESSURE_SENSOR_FAILURE = 16499,

  /**
   * @generated from enum value: VID_DHW_CALEFA_CONTROLLER_ALARM_FLOW_SENSOR_FAILURE = 16502;
   */
  VID_DHW_CALEFA_CONTROLLER_ALARM_FLOW_SENSOR_FAILURE = 16502,

  /**
   * DHW Circulation Controller
   *
   * @generated from enum value: VID_DHW_CIRCULATION_CONTROLLER_ALARM_CIRCULATION_SENSOR_FAILURE = 16395;
   */
  VID_DHW_CIRCULATION_CONTROLLER_ALARM_CIRCULATION_SENSOR_FAILURE = 16395,

  /**
   * DHW Heating Controller
   *
   * @generated from enum value: VID_DHW_HEATING_CONTROLLER_ALARM_HEATING_MOTOR_FAILURE = 16500;
   */
  VID_DHW_HEATING_CONTROLLER_ALARM_HEATING_MOTOR_FAILURE = 16500,

  /**
   * @generated from enum value: VID_DHW_HEATING_CONTROLLER_ALARM_HEATING_MOTOR_STUCK = 16510;
   */
  VID_DHW_HEATING_CONTROLLER_ALARM_HEATING_MOTOR_STUCK = 16510,

  /**
   * Dryer
   *
   * @generated from enum value: VID_DRYER_ALARM_GENERAL_FAULT = 50192;
   */
  VID_DRYER_ALARM_GENERAL_FAULT = 50192,

  /**
   * @generated from enum value: VID_DRYER_ALARM_WARNING_MAINTENANCE = 50193;
   */
  VID_DRYER_ALARM_WARNING_MAINTENANCE = 50193,

  /**
   * External Device
   *
   * @generated from enum value: VID_EXTERNAL_DEVICE_ALARM_CONNECTION_LOST = 16447;
   */
  VID_EXTERNAL_DEVICE_ALARM_CONNECTION_LOST = 16447,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_ALARM_DEVICE_SPECIFIC_ERROR = 16494;
   */
  VID_EXTERNAL_DEVICE_ALARM_DEVICE_SPECIFIC_ERROR = 16494,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_ALARM_DEVICE_SPECIFIC_WARNING = 16495;
   */
  VID_EXTERNAL_DEVICE_ALARM_DEVICE_SPECIFIC_WARNING = 16495,

  /**
   * External Device - MVDI
   *
   * @generated from enum value: VID_EXTERNAL_DEVICE_MVDI_ALARM_FILTER_REPLACEMENT_NEEDED = 16449;
   */
  VID_EXTERNAL_DEVICE_MVDI_ALARM_FILTER_REPLACEMENT_NEEDED = 16449,

  /**
   * External Device - mechanical ventilation
   *
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_FEATURES = 257;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_FEATURES = 257,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_FEATURES_ENABLED = 280;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_FEATURES_ENABLED = 280,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SCHEDULER_ENABLED = 33963;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SCHEDULER_ENABLED = 33963,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MANUAL_LEVEL = 33965;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MANUAL_LEVEL = 33965,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MODE_OVERRIDE = 33989;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MODE_OVERRIDE = 33989,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MAINTENANCE_STATE = 238;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MAINTENANCE_STATE = 238,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MAINTENANCE_REQUEST = 239;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MAINTENANCE_REQUEST = 239,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MAINTENANCE_END_TIME = 296;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MAINTENANCE_END_TIME = 296,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_AIR_FILTER_LIFETIME = 33830;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_AIR_FILTER_LIFETIME = 33830,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_AIR_FILTER_LIFETIME_USED = 33831;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_AIR_FILTER_LIFETIME_USED = 33831,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_AIR_FILTER_LAST_CHANGED = 33855;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_AIR_FILTER_LAST_CHANGED = 33855,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_ALARM_RESTART = 16497;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_ALARM_RESTART = 16497,

  /**
   * Unmapped broker values
   *
   * @generated from enum value: VID_AGGREGATED_MVDI_PUMP_DEMAND = 163;
   */
  VID_AGGREGATED_MVDI_PUMP_DEMAND = 163,

  /**
   * @generated from enum value: VID_AIR_CONDITIONING_DISTRIBUTION_BLOCKING = 89;
   */
  VID_AIR_CONDITIONING_DISTRIBUTION_BLOCKING = 89,

  /**
   * @generated from enum value: VID_AIR_CONDITIONING_DISTRIBUTION_CONSUMER_LIST = 33037;
   */
  VID_AIR_CONDITIONING_DISTRIBUTION_CONSUMER_LIST = 33037,

  /**
   * @generated from enum value: VID_AIR_CONDITIONING_DISTRIBUTION_REQUIRED_LEVEL = 87;
   */
  VID_AIR_CONDITIONING_DISTRIBUTION_REQUIRED_LEVEL = 87,

  /**
   * @generated from enum value: VID_AIR_CONDITIONING_DISTRIBUTION_SOURCE_SUPPLIER_OID = 33038;
   */
  VID_AIR_CONDITIONING_DISTRIBUTION_SOURCE_SUPPLIER_OID = 33038,

  /**
   * @generated from enum value: VID_ANALOG_OUTPUT_HIGH_LEVEL_VOLTAGE = 33015;
   */
  VID_ANALOG_OUTPUT_HIGH_LEVEL_VOLTAGE = 33015,

  /**
   * @generated from enum value: VID_ANALOG_OUTPUT_LOW_LEVEL_VOLTAGE = 33016;
   */
  VID_ANALOG_OUTPUT_LOW_LEVEL_VOLTAGE = 33016,

  /**
   * @generated from enum value: VID_ANALOG_OUTPUT_NO_DEMAND_VOLTAGE = 33039;
   */
  VID_ANALOG_OUTPUT_NO_DEMAND_VOLTAGE = 33039,

  /**
   * @generated from enum value: VID_BROKER_DEBUG_WRITE_THROUGH_IN = 306;
   */
  VID_BROKER_DEBUG_WRITE_THROUGH_IN = 306,

  /**
   * @generated from enum value: VID_BROKER_DEBUG_WRITE_THROUGH_RESULT = 307;
   */
  VID_BROKER_DEBUG_WRITE_THROUGH_RESULT = 307,

  /**
   * @generated from enum value: VID_BROKER_DEBUG_WRITE_THROUGH_TARGET = 34076;
   */
  VID_BROKER_DEBUG_WRITE_THROUGH_TARGET = 34076,

  /**
   * @generated from enum value: VID_BROKER_FLASH_REWRITE_COUNT = 237;
   */
  VID_BROKER_FLASH_REWRITE_COUNT = 237,

  /**
   * @generated from enum value: VID_BROKER_FLASH_STORE_TRIGGER_TIMESTAMP = 305;
   */
  VID_BROKER_FLASH_STORE_TRIGGER_TIMESTAMP = 305,

  /**
   * @generated from enum value: VID_BROKER_USED_BROKER_CLIENTS = 73;
   */
  VID_BROKER_USED_BROKER_CLIENTS = 73,

  /**
   * @generated from enum value: VID_BROKER_USED_BROKER_INDEX_QUEUE_PRIORITY_SLOTS = 71;
   */
  VID_BROKER_USED_BROKER_INDEX_QUEUE_PRIORITY_SLOTS = 71,

  /**
   * @generated from enum value: VID_BROKER_USED_BROKER_INDEX_QUEUE_SLOTS = 70;
   */
  VID_BROKER_USED_BROKER_INDEX_QUEUE_SLOTS = 70,

  /**
   * @generated from enum value: VID_BROKER_USED_BROKER_PT_LINES = 68;
   */
  VID_BROKER_USED_BROKER_PT_LINES = 68,

  /**
   * @generated from enum value: VID_BROKER_USED_BROKER_PT_MEMBLOCKS = 69;
   */
  VID_BROKER_USED_BROKER_PT_MEMBLOCKS = 69,

  /**
   * @generated from enum value: VID_BROKER_USED_BROKER_SUBSCRIPTIONS = 72;
   */
  VID_BROKER_USED_BROKER_SUBSCRIPTIONS = 72,

  /**
   * @generated from enum value: VID_CLOUD_BACKEND_PACKET_SENT_AVERAGE = 247;
   */
  VID_CLOUD_BACKEND_PACKET_SENT_AVERAGE = 247,

  /**
   * @generated from enum value: VID_CLOUD_BACKEND_PACKET_SENT_STATISTICS = 246;
   */
  VID_CLOUD_BACKEND_PACKET_SENT_STATISTICS = 246,

  /**
   * @generated from enum value: VID_CLOUD_CLOUD_ADDRESS_OVERRIDE = 33102;
   */
  VID_CLOUD_CLOUD_ADDRESS_OVERRIDE = 33102,

  /**
   * @generated from enum value: VID_CLOUD_CLOUD_KEY_OVERRIDE = 263;
   */
  VID_CLOUD_CLOUD_KEY_OVERRIDE = 263,

  /**
   * @generated from enum value: VID_CLOUD_ROOM_ALARM_PERIPHERAL_UNREACHABLE_FILTERED = 16467;
   */
  VID_CLOUD_ROOM_ALARM_PERIPHERAL_UNREACHABLE_FILTERED = 16467,

  /**
   * @generated from enum value: VID_CLOUD_ROOM_ERROR_INCOMPATIBLE_PERIPHERAL = 16466;
   */
  VID_CLOUD_ROOM_ERROR_INCOMPATIBLE_PERIPHERAL = 16466,

  /**
   * @generated from enum value: VID_CO2_THRESHOLD_HIGH = 34081;
   */
  VID_CO2_THRESHOLD_HIGH = 34081,

  /**
   * @generated from enum value: VID_CO2_THRESHOLD_LOW = 34079;
   */
  VID_CO2_THRESHOLD_LOW = 34079,

  /**
   * @generated from enum value: VID_CO2_THRESHOLD_MEDIUM = 34080;
   */
  VID_CO2_THRESHOLD_MEDIUM = 34080,

  /**
   * @generated from enum value: VID_COMMON_DEBUG_CHANNEL = 95;
   */
  VID_COMMON_DEBUG_CHANNEL = 95,

  /**
   * @generated from enum value: VID_DEVICE_DRYER_AIR_FILTER_LAST_CHANGE = 49201;
   */
  VID_DEVICE_DRYER_AIR_FILTER_LAST_CHANGE = 49201,

  /**
   * @generated from enum value: VID_DEVICE_DRYER_AIR_FILTER_LIFETIME_HOURS = 49176;
   */
  VID_DEVICE_DRYER_AIR_FILTER_LIFETIME_HOURS = 49176,

  /**
   * @generated from enum value: VID_DEVICE_DRYER_DEVICE_STATE = 49178;
   */
  VID_DEVICE_DRYER_DEVICE_STATE = 49178,

  /**
   * @generated from enum value: VID_DEVICE_DRYER_FEATURES = 49177;
   */
  VID_DEVICE_DRYER_FEATURES = 49177,

  /**
   * @generated from enum value: VID_DEVICE_DRYER_ID = 49175;
   */
  VID_DEVICE_DRYER_ID = 49175,

  /**
   * @generated from enum value: VID_DEVICE_DRYER_MAINTENANCE_REQUEST = 49199;
   */
  VID_DEVICE_DRYER_MAINTENANCE_REQUEST = 49199,

  /**
   * @generated from enum value: VID_DEVICE_DRYER_MAINTENANCE_STATE = 49198;
   */
  VID_DEVICE_DRYER_MAINTENANCE_STATE = 49198,

  /**
   * @generated from enum value: VID_DEVICE_DRYER_MAINTENANCE_STATE_TRANSITION_TIME = 49200;
   */
  VID_DEVICE_DRYER_MAINTENANCE_STATE_TRANSITION_TIME = 49200,

  /**
   * @generated from enum value: VID_DEVICE_DRYER_NAME = 51241;
   */
  VID_DEVICE_DRYER_NAME = 51241,

  /**
   * @generated from enum value: VID_DEVICE_BASE_TEMPERATURE = 161;
   */
  VID_DEVICE_BASE_TEMPERATURE = 161,

  /**
   * @generated from enum value: VID_DEVICE_BATTERY_LEVEL = 44;
   */
  VID_DEVICE_BATTERY_LEVEL = 44,

  /**
   * @generated from enum value: VID_DEVICE_CLIENT_BROKER_ROLE = 302;
   */
  VID_DEVICE_CLIENT_BROKER_ROLE = 302,

  /**
   * @generated from enum value: VID_DEVICE_COMUNICATION_RELIABILITY_TEST_DATA = 119;
   */
  VID_DEVICE_COMUNICATION_RELIABILITY_TEST_DATA = 119,

  /**
   * @generated from enum value: VID_DEVICE_DEBUG_GROUP_MASK = 33823;
   */
  VID_DEVICE_DEBUG_GROUP_MASK = 33823,

  /**
   * @generated from enum value: VID_DEVICE_DEBUG_MODIFIABLE_OVI_LIST = 34077;
   */
  VID_DEVICE_DEBUG_MODIFIABLE_OVI_LIST = 34077,

  /**
   * @generated from enum value: VID_DEVICE_DEVICE_DESCRIPTOR_VERSION = 34012;
   */
  VID_DEVICE_DEVICE_DESCRIPTOR_VERSION = 34012,

  /**
   * @generated from enum value: VID_DEVICE_DEVICE_METADATA = 33022;
   */
  VID_DEVICE_DEVICE_METADATA = 33022,

  /**
   * @generated from enum value: VID_DEVICE_DEVICE_SHUTDOWN = 102;
   */
  VID_DEVICE_DEVICE_SHUTDOWN = 102,

  /**
   * @generated from enum value: VID_DEVICE_DEVICE_SHUTDOWN_TIME = 258;
   */
  VID_DEVICE_DEVICE_SHUTDOWN_TIME = 258,

  /**
   * @generated from enum value: VID_DEVICE_ERROR_EXTERNAL_SENSOR_FAIL = 16433;
   */
  VID_DEVICE_ERROR_EXTERNAL_SENSOR_FAIL = 16433,

  /**
   * @generated from enum value: VID_DEVICE_ERROR_INCOMPATIBLE_PERIPHERAL = 16465;
   */
  VID_DEVICE_ERROR_INCOMPATIBLE_PERIPHERAL = 16465,

  /**
   * @generated from enum value: VID_DEVICE_ERROR_INTERNAL_FAILURE = 16501;
   */
  VID_DEVICE_ERROR_INTERNAL_FAILURE = 16501,

  /**
   * @generated from enum value: VID_DEVICE_ERROR_UNDERVOLTAGE_LOCKOUT = 16426;
   */
  VID_DEVICE_ERROR_UNDERVOLTAGE_LOCKOUT = 16426,

  /**
   * @generated from enum value: VID_DEVICE_EXTERNAL_ANTENA = 32770;
   */
  VID_DEVICE_EXTERNAL_ANTENA = 32770,

  /**
   * @generated from enum value: VID_DEVICE_INTERNAL_OVERHEAT_TIME = 274;
   */
  VID_DEVICE_INTERNAL_OVERHEAT_TIME = 274,

  /**
   * @generated from enum value: VID_DEVICE_INTERNAL_TEMPERATURE = 267;
   */
  VID_DEVICE_INTERNAL_TEMPERATURE = 267,

  /**
   * @generated from enum value: VID_DEVICE_INTERNAL_TEMPERATURE_HISTOGRAM = 273;
   */
  VID_DEVICE_INTERNAL_TEMPERATURE_HISTOGRAM = 273,

  /**
   * @generated from enum value: VID_DEVICE_MODBUS_ADDRESS = 32769;
   */
  VID_DEVICE_MODBUS_ADDRESS = 32769,

  /**
   * @generated from enum value: VID_DEVICE_OWNER_ID = 32990;
   */
  VID_DEVICE_OWNER_ID = 32990,

  /**
   * @generated from enum value: VID_DEVICE_PC_TOOL_LIST = 34073;
   */
  VID_DEVICE_PC_TOOL_LIST = 34073,

  /**
   * @generated from enum value: VID_DEVICE_PERSISTENT_PARAMETERS_COMMAND = 300;
   */
  VID_DEVICE_PERSISTENT_PARAMETERS_COMMAND = 300,

  /**
   * @generated from enum value: VID_DEVICE_SIGNAL_STRENGTH_DEPRECATED = 115;
   */
  VID_DEVICE_SIGNAL_STRENGTH_DEPRECATED = 115,

  /**
   * @generated from enum value: VID_DEVICE_SIGNAL_STRENGTH_RX_OBSERVED_BY_PERIPHERAL = 117;
   */
  VID_DEVICE_SIGNAL_STRENGTH_RX_OBSERVED_BY_PERIPHERAL = 117,

  /**
   * @generated from enum value: VID_DEVICE_SIGNAL_STRENGTH_TX_OBSERVED_BY_CCU = 116;
   */
  VID_DEVICE_SIGNAL_STRENGTH_TX_OBSERVED_BY_CCU = 116,

  /**
   * @generated from enum value: VID_DEVICE_START_TIME = 236;
   */
  VID_DEVICE_START_TIME = 236,

  /**
   * @generated from enum value: VID_DEVICE_STATUS_FILTER = 186;
   */
  VID_DEVICE_STATUS_FILTER = 186,

  /**
   * @generated from enum value: VID_DEVICE_TRANSFER_REQUEST_LIST = 34071;
   */
  VID_DEVICE_TRANSFER_REQUEST_LIST = 34071,

  /**
   * @generated from enum value: VID_DEVICE_TRANSFER_STATUS_LIST = 34072;
   */
  VID_DEVICE_TRANSFER_STATUS_LIST = 34072,

  /**
   * @generated from enum value: VID_DEVICE_UPTIME = 308;
   */
  VID_DEVICE_UPTIME = 308,

  /**
   * @generated from enum value: VID_DEVICE_WARNING_LONG_POWER_FAIL = 16385;
   */
  VID_DEVICE_WARNING_LONG_POWER_FAIL = 16385,

  /**
   * @generated from enum value: VID_DEVICE_WARNING_LOST_COMMUNICATION_WITH_PERIPHERY = 16388;
   */
  VID_DEVICE_WARNING_LOST_COMMUNICATION_WITH_PERIPHERY = 16388,

  /**
   * @generated from enum value: VID_DEVICE_WARNING_POWER_FAIL = 16384;
   */
  VID_DEVICE_WARNING_POWER_FAIL = 16384,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_AIR_TEMP_SAMPLING_RATE_OVERRIDE = 312;
   */
  VID_DEVICE_RT_RS_2XX_AIR_TEMP_SAMPLING_RATE_OVERRIDE = 312,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_BRIGHTNESS_HIGH_LIMIT = 33059;
   */
  VID_DEVICE_RT_RS_2XX_BRIGHTNESS_HIGH_LIMIT = 33059,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_BRIGHTNESS_LOW_LIMIT = 33058;
   */
  VID_DEVICE_RT_RS_2XX_BRIGHTNESS_LOW_LIMIT = 33058,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_CO2_ASC_SETTINGS = 34096;
   */
  VID_DEVICE_RT_RS_2XX_CO2_ASC_SETTINGS = 34096,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_CO2_LAST_CORRECTION_PPM = 327;
   */
  VID_DEVICE_RT_RS_2XX_CO2_LAST_CORRECTION_PPM = 327,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_CO2_LED_INDICATION_SETUP = 34078;
   */
  VID_DEVICE_RT_RS_2XX_CO2_LED_INDICATION_SETUP = 34078,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_CO2_MANUAL_TARGET_CONCENTRATION_PPM = 326;
   */
  VID_DEVICE_RT_RS_2XX_CO2_MANUAL_TARGET_CONCENTRATION_PPM = 326,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_CORRECTION_AIR_TEMP = 33055;
   */
  VID_DEVICE_RT_RS_2XX_CORRECTION_AIR_TEMP = 33055,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_CORRECTION_FLOOR_TEMP = 33056;
   */
  VID_DEVICE_RT_RS_2XX_CORRECTION_FLOOR_TEMP = 33056,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_CORRECTION_HUMIDITY = 33057;
   */
  VID_DEVICE_RT_RS_2XX_CORRECTION_HUMIDITY = 33057,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_HDC2080_HEATER_HOLD_TIME = 309;
   */
  VID_DEVICE_RT_RS_2XX_HDC2080_HEATER_HOLD_TIME = 309,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_HDC2080_HEATER_SETPOINT = 310;
   */
  VID_DEVICE_RT_RS_2XX_HDC2080_HEATER_SETPOINT = 310,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_HDC2080_HEATING_HISTOGRAM = 313;
   */
  VID_DEVICE_RT_RS_2XX_HDC2080_HEATING_HISTOGRAM = 313,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_HDC2080_HEATING_RESULT = 311;
   */
  VID_DEVICE_RT_RS_2XX_HDC2080_HEATING_RESULT = 311,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_HDC2080_HUMIDITY_OFFSET_CORRECTION_LOG = 34094;
   */
  VID_DEVICE_RT_RS_2XX_HDC2080_HUMIDITY_OFFSET_CORRECTION_LOG = 34094,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_HDC2080_HUMIDITY_OFFSET_CORRECTION_SETTINGS = 315;
   */
  VID_DEVICE_RT_RS_2XX_HDC2080_HUMIDITY_OFFSET_CORRECTION_SETTINGS = 315,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_THERMOSTAT_LOCAL_LOCK = 32910;
   */
  VID_DEVICE_RT_RS_2XX_THERMOSTAT_LOCAL_LOCK = 32910,

  /**
   * @generated from enum value: VID_DEVICE_RT_RS_2XX_TOUCH_SENSITIVITY = 33060;
   */
  VID_DEVICE_RT_RS_2XX_TOUCH_SENSITIVITY = 33060,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_ACKNOWLEDGE_DEVICE_SPECIFIC_ERROR = 51249;
   */
  VID_DEVICE_VENTILATION_ACKNOWLEDGE_DEVICE_SPECIFIC_ERROR = 51249,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_ACKNOWLEDGE_DEVICE_SPECIFIC_WARNING = 51250;
   */
  VID_DEVICE_VENTILATION_ACKNOWLEDGE_DEVICE_SPECIFIC_WARNING = 51250,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_AIR_FILTER_LAST_CHANGE = 49196;
   */
  VID_DEVICE_VENTILATION_AIR_FILTER_LAST_CHANGE = 49196,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_AIR_FILTER_LIFETIME_HOURS = 49187;
   */
  VID_DEVICE_VENTILATION_AIR_FILTER_LIFETIME_HOURS = 49187,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_DEVICE_STATE = 49184;
   */
  VID_DEVICE_VENTILATION_DEVICE_STATE = 49184,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_ERROR_DEVICE_LOST = 50194;
   */
  VID_DEVICE_VENTILATION_ERROR_DEVICE_LOST = 50194,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_ERROR_DEVICE_SPECIFIC = 50197;
   */
  VID_DEVICE_VENTILATION_ERROR_DEVICE_SPECIFIC = 50197,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_FEATURES = 49181;
   */
  VID_DEVICE_VENTILATION_FEATURES = 49181,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_HEAT_RECOVERY_BYPASS_MODE = 51248;
   */
  VID_DEVICE_VENTILATION_HEAT_RECOVERY_BYPASS_MODE = 51248,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_HEAT_RECOVERY_BYPASS_STATE = 49185;
   */
  VID_DEVICE_VENTILATION_HEAT_RECOVERY_BYPASS_STATE = 49185,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_ID = 49180;
   */
  VID_DEVICE_VENTILATION_ID = 49180,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_MAINTENANCE_REQUEST = 49197;
   */
  VID_DEVICE_VENTILATION_MAINTENANCE_REQUEST = 49197,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_MAINTENANCE_STATE = 49192;
   */
  VID_DEVICE_VENTILATION_MAINTENANCE_STATE = 49192,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_MAINTENANCE_STATE_TRANSITION_TIME = 49194;
   */
  VID_DEVICE_VENTILATION_MAINTENANCE_STATE_TRANSITION_TIME = 49194,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_NAME = 51242;
   */
  VID_DEVICE_VENTILATION_NAME = 51242,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_QUIET_HOURS_ACTIVE = 49203;
   */
  VID_DEVICE_VENTILATION_QUIET_HOURS_ACTIVE = 49203,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_QUIET_HOURS_ENABLE = 51259;
   */
  VID_DEVICE_VENTILATION_QUIET_HOURS_ENABLE = 51259,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_QUIET_HOURS_INTERVAL = 51260;
   */
  VID_DEVICE_VENTILATION_QUIET_HOURS_INTERVAL = 51260,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_QUIET_HOURS_LOWERED_LEVEL = 49204;
   */
  VID_DEVICE_VENTILATION_QUIET_HOURS_LOWERED_LEVEL = 49204,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_STATE_NAME_BOOST = 51256;
   */
  VID_DEVICE_VENTILATION_STATE_NAME_BOOST = 51256,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_STATE_NAME_COMFORT = 51255;
   */
  VID_DEVICE_VENTILATION_STATE_NAME_COMFORT = 51255,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_STATE_NAME_ECO = 51254;
   */
  VID_DEVICE_VENTILATION_STATE_NAME_ECO = 51254,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_STATE_NAME_STOPPED = 51252;
   */
  VID_DEVICE_VENTILATION_STATE_NAME_STOPPED = 51252,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_STATE_NAME_UNOCCUPIED = 51253;
   */
  VID_DEVICE_VENTILATION_STATE_NAME_UNOCCUPIED = 51253,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_VACATION_IS_ALLOWED = 49186;
   */
  VID_DEVICE_VENTILATION_VACATION_IS_ALLOWED = 49186,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_VENTILATION_BLOCKING = 49182;
   */
  VID_DEVICE_VENTILATION_VENTILATION_BLOCKING = 49182,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_VENTILATION_LEVEL = 51245;
   */
  VID_DEVICE_VENTILATION_VENTILATION_LEVEL = 51245,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_VENTILATION_MODE_OVERRIDE = 51246;
   */
  VID_DEVICE_VENTILATION_VENTILATION_MODE_OVERRIDE = 51246,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_VENTILATION_MODE_OVERRIDE_SOURCE = 49188;
   */
  VID_DEVICE_VENTILATION_VENTILATION_MODE_OVERRIDE_SOURCE = 49188,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_VENTILATION_MODE_OVERRIDE_UNTIL = 51247;
   */
  VID_DEVICE_VENTILATION_VENTILATION_MODE_OVERRIDE_UNTIL = 51247,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_VENTILATION_OVERRIDE = 51257;
   */
  VID_DEVICE_VENTILATION_VENTILATION_OVERRIDE = 51257,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_VENTILATION_SCHEDULER = 51244;
   */
  VID_DEVICE_VENTILATION_VENTILATION_SCHEDULER = 51244,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_VENTILATION_SCHEDULER_ENABLED = 51243;
   */
  VID_DEVICE_VENTILATION_VENTILATION_SCHEDULER_ENABLED = 51243,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_VENTILATION_STATE = 49183;
   */
  VID_DEVICE_VENTILATION_VENTILATION_STATE = 49183,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_WARNING_DEVICE_SPECIFIC = 50198;
   */
  VID_DEVICE_VENTILATION_WARNING_DEVICE_SPECIFIC = 50198,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_WARNING_FILTER_MAINTENANCE = 50195;
   */
  VID_DEVICE_VENTILATION_WARNING_FILTER_MAINTENANCE = 50195,

  /**
   * @generated from enum value: VID_DEVICE_VENTILATION_WARNING_VENTILATION_RESTART = 50196;
   */
  VID_DEVICE_VENTILATION_WARNING_VENTILATION_RESTART = 50196,

  /**
   * @generated from enum value: VID_DEVICE_VH_250_ERROR_BADLY_INSTALLED = 16424;
   */
  VID_DEVICE_VH_250_ERROR_BADLY_INSTALLED = 16424,

  /**
   * @generated from enum value: VID_DEVICE_VH_250_ERROR_BATTERY_OPEN = 16423;
   */
  VID_DEVICE_VH_250_ERROR_BATTERY_OPEN = 16423,

  /**
   * @generated from enum value: VID_DEVICE_VH_250_ERROR_VALVE_STICKED = 16459;
   */
  VID_DEVICE_VH_250_ERROR_VALVE_STICKED = 16459,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_BACKUP_CHARGE_TIME = 277;
   */
  VID_DHW_201_CONTROLLER_BACKUP_CHARGE_TIME = 277,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_BOOST_SOURCE_PUMP_MODE = 32841;
   */
  VID_DHW_201_CONTROLLER_BOOST_SOURCE_PUMP_MODE = 32841,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_BOOST_SOURCE_PUMP_STATE = 52;
   */
  VID_DHW_201_CONTROLLER_BOOST_SOURCE_PUMP_STATE = 52,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_DEFROST_MODE = 32849;
   */
  VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_DEFROST_MODE = 32849,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_DEFROST_TEMP_ECO = 32850;
   */
  VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_DEFROST_TEMP_ECO = 32850,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_TEMP_SET_COMFORT = 32921;
   */
  VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_TEMP_SET_COMFORT = 32921,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_TEMP_SPAN_MAX = 32920;
   */
  VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_TEMP_SPAN_MAX = 32920,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_TEMP_SPAN_MIN = 32919;
   */
  VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_TEMP_SPAN_MIN = 32919,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_DYNAMIC_MODE = 34037;
   */
  VID_DHW_201_CONTROLLER_DYNAMIC_MODE = 34037,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_DYNAMIC_SETPOINT = 34039;
   */
  VID_DHW_201_CONTROLLER_DYNAMIC_SETPOINT = 34039,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_DYNAMIC_WAIT_TIME = 34038;
   */
  VID_DHW_201_CONTROLLER_DYNAMIC_WAIT_TIME = 34038,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_ENERGY_PRIORITY_MODE = 32865;
   */
  VID_DHW_201_CONTROLLER_ENERGY_PRIORITY_MODE = 32865,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_ENERGY_PRIORITY_STATE = 42;
   */
  VID_DHW_201_CONTROLLER_ENERGY_PRIORITY_STATE = 42,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_FLOW_SENSOR_TYPE = 33933;
   */
  VID_DHW_201_CONTROLLER_FLOW_SENSOR_TYPE = 33933,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_HEX_FACTOR = 34040;
   */
  VID_DHW_201_CONTROLLER_HEX_FACTOR = 34040,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_MOTOR_TEST_VALUE = 276;
   */
  VID_DHW_201_CONTROLLER_MOTOR_TEST_VALUE = 276,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_POWER_CONSUMPTION_LIMIT = 32924;
   */
  VID_DHW_201_CONTROLLER_POWER_CONSUMPTION_LIMIT = 32924,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_REGULATOR_MODE = 265;
   */
  VID_DHW_201_CONTROLLER_REGULATOR_MODE = 265,

  /**
   * @generated from enum value: VID_DHW_201_CONTROLLER_SOURCE_VALVE_LEVEL = 43;
   */
  VID_DHW_201_CONTROLLER_SOURCE_VALVE_LEVEL = 43,

  /**
   * @generated from enum value: VID_DHW_21X_CONTROLLER_ALLOW_HEATING_DURING_DHW_DEMAND = 34066;
   */
  VID_DHW_21X_CONTROLLER_ALLOW_HEATING_DURING_DHW_DEMAND = 34066,

  /**
   * @generated from enum value: VID_DHW_21X_CONTROLLER_ERROR_FROST_PROTECTION_ACTIVE = 16506;
   */
  VID_DHW_21X_CONTROLLER_ERROR_FROST_PROTECTION_ACTIVE = 16506,

  /**
   * @generated from enum value: VID_DHW_21X_CONTROLLER_ERROR_MOTOR_STUCK = 16511;
   */
  VID_DHW_21X_CONTROLLER_ERROR_MOTOR_STUCK = 16511,

  /**
   * @generated from enum value: VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_COOLDOWN_TIME = 33097;
   */
  VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_COOLDOWN_TIME = 33097,

  /**
   * @generated from enum value: VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_PUMP_PRESENT = 32846;
   */
  VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_PUMP_PRESENT = 32846,

  /**
   * @generated from enum value: VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_RETURN_TEMP = 48;
   */
  VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_RETURN_TEMP = 48,

  /**
   * @generated from enum value: VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_STATE = 53;
   */
  VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_STATE = 53,

  /**
   * @generated from enum value: VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_DIFFERENCE = 33096;
   */
  VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_DIFFERENCE = 33096,

  /**
   * @generated from enum value: VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_HYSTERESIS = 32848;
   */
  VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_HYSTERESIS = 32848,

  /**
   * @generated from enum value: VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_SET = 32847;
   */
  VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_SET = 32847,

  /**
   * @generated from enum value: VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_SPAN_MAX = 32923;
   */
  VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_SPAN_MAX = 32923,

  /**
   * @generated from enum value: VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_SPAN_MIN = 32922;
   */
  VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_SPAN_MIN = 32922,

  /**
   * @generated from enum value: VID_DHW_HEATING_CONTROLLER_HEAT_VALVE_LEVEL = 268;
   */
  VID_DHW_HEATING_CONTROLLER_HEAT_VALVE_LEVEL = 268,

  /**
   * @generated from enum value: VID_DHW_HEATING_CONTROLLER_MOTOR_TEST_VALUE = 278;
   */
  VID_DHW_HEATING_CONTROLLER_MOTOR_TEST_VALUE = 278,

  /**
   * @generated from enum value: VID_DHW_HEATING_CONTROLLER_REGULATOR_MODE = 269;
   */
  VID_DHW_HEATING_CONTROLLER_REGULATOR_MODE = 269,

  /**
   * @generated from enum value: VID_DHW_HEATING_CONTROLLER_STATE = 279;
   */
  VID_DHW_HEATING_CONTROLLER_STATE = 279,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_CONTROL = 33098;
   */
  VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_CONTROL = 33098,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_CONTROL_WRONG_TYPE = 32877;
   */
  VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_CONTROL_WRONG_TYPE = 32877,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_ERROR_SOURCE_RETURN_TEMP_SENSOR_FAIL = 16443;
   */
  VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_ERROR_SOURCE_RETURN_TEMP_SENSOR_FAIL = 16443,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_ERROR_SOURCE_RETURN_TEMP_SENSOR_FAIL_WRONG_TYPE = 16425;
   */
  VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_ERROR_SOURCE_RETURN_TEMP_SENSOR_FAIL_WRONG_TYPE = 16425,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_SERVO_REQUEST = 120;
   */
  VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_SERVO_REQUEST = 120,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_SOURCE_RETURN_TEMP_LIMIT = 32878;
   */
  VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_SOURCE_RETURN_TEMP_LIMIT = 32878,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_WARNING_LOW_ENERGY = 16440;
   */
  VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_WARNING_LOW_ENERGY = 16440,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_CLEANING_ENABLED = 33081;
   */
  VID_DHW_TANK_CONTROLLER_CLEANING_ENABLED = 33081,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_CLEANING_HOLD_TIME = 33051;
   */
  VID_DHW_TANK_CONTROLLER_CLEANING_HOLD_TIME = 33051,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_CLEANING_INTERVAL = 33050;
   */
  VID_DHW_TANK_CONTROLLER_CLEANING_INTERVAL = 33050,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_CLEANING_LAST = 33089;
   */
  VID_DHW_TANK_CONTROLLER_CLEANING_LAST = 33089,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_CLEANING_TIME_BEFORE_FAIL = 33052;
   */
  VID_DHW_TANK_CONTROLLER_CLEANING_TIME_BEFORE_FAIL = 33052,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_CLEANING_WARNING_FAIL = 16417;
   */
  VID_DHW_TANK_CONTROLLER_CLEANING_WARNING_FAIL = 16417,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_ERROR_PERIPHERAL_UNREACHABLE = 16439;
   */
  VID_DHW_TANK_CONTROLLER_ERROR_PERIPHERAL_UNREACHABLE = 16439,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_ERROR_SOURCE_INLET_SENSOR_FAIL = 16441;
   */
  VID_DHW_TANK_CONTROLLER_ERROR_SOURCE_INLET_SENSOR_FAIL = 16441,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_ERROR_TANK_SENSOR_FAIL = 16416;
   */
  VID_DHW_TANK_CONTROLLER_ERROR_TANK_SENSOR_FAIL = 16416,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_SOURCE_VALVE_LEVEL = 122;
   */
  VID_DHW_TANK_CONTROLLER_SOURCE_VALVE_LEVEL = 122,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_TANK_TEMP = 97;
   */
  VID_DHW_TANK_CONTROLLER_TANK_TEMP = 97,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_TANK_TEMP_DESIRED = 98;
   */
  VID_DHW_TANK_CONTROLLER_TANK_TEMP_DESIRED = 98,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_TANK_TEMP_HYSTERESIS = 33049;
   */
  VID_DHW_TANK_CONTROLLER_TANK_TEMP_HYSTERESIS = 33049,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET = 33093;
   */
  VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET = 33093,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_CLEANING = 33048;
   */
  VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_CLEANING = 33048,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_COMFORT = 33045;
   */
  VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_COMFORT = 33045,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_ECO = 33044;
   */
  VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_ECO = 33044,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_STANDBY = 33046;
   */
  VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_STANDBY = 33046,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_VACATION = 33047;
   */
  VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_VACATION = 33047,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_TANK_TEMP_SPAN_MAX = 33054;
   */
  VID_DHW_TANK_CONTROLLER_TANK_TEMP_SPAN_MAX = 33054,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_TANK_TEMP_SPAN_MIN = 33053;
   */
  VID_DHW_TANK_CONTROLLER_TANK_TEMP_SPAN_MIN = 33053,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_WARNING_LOW_SOURCE_TEMP = 16442;
   */
  VID_DHW_TANK_CONTROLLER_WARNING_LOW_SOURCE_TEMP = 16442,

  /**
   * @generated from enum value: VID_DHW_TANK_CONTROLLER_WARNING_PERIPHERAL_LOW_BATTERY = 16438;
   */
  VID_DHW_TANK_CONTROLLER_WARNING_PERIPHERAL_LOW_BATTERY = 16438,

  /**
   * @generated from enum value: VID_DIAGNOSTICS_SYSLOG_CHANGE_DESTINATION_IP = 33889;
   */
  VID_DIAGNOSTICS_SYSLOG_CHANGE_DESTINATION_IP = 33889,

  /**
   * @generated from enum value: VID_DIAGNOSTICS_SYSLOG_CHANGE_DESTINATION_PORT = 33914;
   */
  VID_DIAGNOSTICS_SYSLOG_CHANGE_DESTINATION_PORT = 33914,

  /**
   * @generated from enum value: VID_DIAGNOSTICS_TELEMETRY_REPORTING_DESTINATION_IP = 34097;
   */
  VID_DIAGNOSTICS_TELEMETRY_REPORTING_DESTINATION_IP = 34097,

  /**
   * @generated from enum value: VID_DIAGNOSTICS_TELEMETRY_REPORTING_DESTINATION_PORT = 34098;
   */
  VID_DIAGNOSTICS_TELEMETRY_REPORTING_DESTINATION_PORT = 34098,

  /**
   * @generated from enum value: VID_DOMESTIC_HOT_WATER_ALLOW_STANDBY = 33043;
   */
  VID_DOMESTIC_HOT_WATER_ALLOW_STANDBY = 33043,

  /**
   * @generated from enum value: VID_DOMESTIC_HOT_WATER_ALLOW_VACATION_AWAY = 32909;
   */
  VID_DOMESTIC_HOT_WATER_ALLOW_VACATION_AWAY = 32909,

  /**
   * @generated from enum value: VID_DOMESTIC_HOT_WATER_BLOCKING_SOURCE = 37;
   */
  VID_DOMESTIC_HOT_WATER_BLOCKING_SOURCE = 37,

  /**
   * @generated from enum value: VID_DOMESTIC_HOT_WATER_BLOCK_REQUEST_FROM_BMS = 33063;
   */
  VID_DOMESTIC_HOT_WATER_BLOCK_REQUEST_FROM_BMS = 33063,

  /**
   * @generated from enum value: VID_DOMESTIC_HOT_WATER_DEVICE_UI_ACCESS_LEVEL = 32908;
   */
  VID_DOMESTIC_HOT_WATER_DEVICE_UI_ACCESS_LEVEL = 32908,

  /**
   * @generated from enum value: VID_DOMESTIC_HOT_WATER_MODE = 32905;
   */
  VID_DOMESTIC_HOT_WATER_MODE = 32905,

  /**
   * @generated from enum value: VID_DOMESTIC_HOT_WATER_SCHEDULE_ECO_COMFORT = 32907;
   */
  VID_DOMESTIC_HOT_WATER_SCHEDULE_ECO_COMFORT = 32907,

  /**
   * @generated from enum value: VID_DOMESTIC_HOT_WATER_STATE = 36;
   */
  VID_DOMESTIC_HOT_WATER_STATE = 36,

  /**
   * @generated from enum value: VID_DOMESTIC_HOT_WATER_WARNING_GENERAL_PROBLEM = 16406;
   */
  VID_DOMESTIC_HOT_WATER_WARNING_GENERAL_PROBLEM = 16406,

  /**
   * @generated from enum value: VID_DRYING_BLOCKING = 148;
   */
  VID_DRYING_BLOCKING = 148,

  /**
   * @generated from enum value: VID_DRYING_CONSUMER_LIST = 33833;
   */
  VID_DRYING_CONSUMER_LIST = 33833,

  /**
   * Common ventilation
   *
   * @generated from enum value: VID_DRYING_CONTROLLER_ALLOW_MODE = 33835;
   */
  VID_DRYING_CONTROLLER_ALLOW_MODE = 33835,

  /**
   * @generated from enum value: VID_DRYING_CONTROLLER_MAINTENANCE_ENDS_SOON_WARNING = 16498;
   */
  VID_DRYING_CONTROLLER_MAINTENANCE_ENDS_SOON_WARNING = 16498,

  /**
   * @generated from enum value: VID_DRYING_CONTROLLER_REQUIRED_DRYING_COOLING_WATER_TEMP = 33852;
   */
  VID_DRYING_CONTROLLER_REQUIRED_DRYING_COOLING_WATER_TEMP = 33852,

  /**
   * @generated from enum value: VID_DRYING_SOURCE_DEMAND = 152;
   */
  VID_DRYING_SOURCE_DEMAND = 152,

  /**
   * @generated from enum value: VID_DRYING_SOURCE_STATUS = 147;
   */
  VID_DRYING_SOURCE_STATUS = 147,

  /**
   * @generated from enum value: VID_DRYING_SOURCE_SUPPLIER_OID = 33834;
   */
  VID_DRYING_SOURCE_SUPPLIER_OID = 33834,

  /**
   * @generated from enum value: VID_ETHERNET_DEFAULT_GATEWAY = 184;
   */
  VID_ETHERNET_DEFAULT_GATEWAY = 184,

  /**
   * @generated from enum value: VID_ETHERNET_DHCP_LEASE_TIME = 174;
   */
  VID_ETHERNET_DHCP_LEASE_TIME = 174,

  /**
   * @generated from enum value: VID_ETHERNET_IP_ADDRESS = 182;
   */
  VID_ETHERNET_IP_ADDRESS = 182,

  /**
   * @generated from enum value: VID_ETHERNET_IP_CONFIGURATION = 33907;
   */
  VID_ETHERNET_IP_CONFIGURATION = 33907,

  /**
   * @generated from enum value: VID_ETHERNET_MAC_ADDRESS = 33906;
   */
  VID_ETHERNET_MAC_ADDRESS = 33906,

  /**
   * @generated from enum value: VID_ETHERNET_SERVICE_MASK = 34009;
   */
  VID_ETHERNET_SERVICE_MASK = 34009,

  /**
   * @generated from enum value: VID_ETHERNET_STATIC_DEFAULT_GATEWAY = 33910;
   */
  VID_ETHERNET_STATIC_DEFAULT_GATEWAY = 33910,

  /**
   * @generated from enum value: VID_ETHERNET_STATIC_IP_ADDRESS = 33908;
   */
  VID_ETHERNET_STATIC_IP_ADDRESS = 33908,

  /**
   * @generated from enum value: VID_ETHERNET_STATIC_SUBNET_MASK = 33909;
   */
  VID_ETHERNET_STATIC_SUBNET_MASK = 33909,

  /**
   * @generated from enum value: VID_ETHERNET_SUBNET_MASK = 183;
   */
  VID_ETHERNET_SUBNET_MASK = 183,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_DEVICE_SPECIFIC_ERROR_ACKNOWLEDGE = 229;
   */
  VID_EXTERNAL_DEVICE_DEVICE_SPECIFIC_ERROR_ACKNOWLEDGE = 229,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_DEVICE_SPECIFIC_WARNING_ACKNOWLEDGE = 230;
   */
  VID_EXTERNAL_DEVICE_DEVICE_SPECIFIC_WARNING_ACKNOWLEDGE = 230,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_ERROR_HCW_SUPPLIER_NOT_SET = 16448;
   */
  VID_EXTERNAL_DEVICE_ERROR_HCW_SUPPLIER_NOT_SET = 16448,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_INTERFACE_MODBUS_SLAVE_ID = 33974;
   */
  VID_EXTERNAL_DEVICE_INTERFACE_MODBUS_SLAVE_ID = 33974,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_ALLOW_STOPPED_LEVEL = 33971;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_ALLOW_STOPPED_LEVEL = 33971,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_ALLOW_UNOCCUPIED_LEVEL = 34041;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_ALLOW_UNOCCUPIED_LEVEL = 34041,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_COOLING_INTAKE_AIR_TEMPERATURE_LIMIT = 34010;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_COOLING_INTAKE_AIR_TEMPERATURE_LIMIT = 34010,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_IDENTIFICATION_STRATEGY = 34011;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_IDENTIFICATION_STRATEGY = 34011,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_MANUFACTURER = 251;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_MANUFACTURER = 251,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_MODEL = 252;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_MODEL = 252,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_STATE = 248;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_STATE = 248,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DISTRIBUTION_STATUS = 234;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DISTRIBUTION_STATUS = 234,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_AIR_TEMPERATURE = 228;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_AIR_TEMPERATURE = 228,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_BOOST_PERCENT = 33962;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_BOOST_PERCENT = 33962,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_BOOST_M3 = 33995;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_BOOST_M3 = 33995,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_COMFORT_PERCENT = 33961;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_COMFORT_PERCENT = 33961,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_COMFORT_M3 = 33994;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_COMFORT_M3 = 33994,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_ECO_PERCENT = 33960;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_ECO_PERCENT = 33960,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_ECO_M3 = 33993;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_ECO_M3 = 33993,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_UNOCCUPIED_PERCENT = 33959;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_UNOCCUPIED_PERCENT = 33959,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_UNOCCUPIED_M3 = 33992;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_UNOCCUPIED_M3 = 33992,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_MAXIMUM_SPEED_PERCENT = 34043;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_MAXIMUM_SPEED_PERCENT = 34043,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_MAXIMUM_VOLUME_FLOW = 34000;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_MAXIMUM_VOLUME_FLOW = 34000,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_MINIMUM_SPEED_PERCENT = 34042;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_MINIMUM_SPEED_PERCENT = 34042,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_MINIMUM_VOLUME_FLOW = 34002;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_MINIMUM_VOLUME_FLOW = 34002,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_SPEED_PERCENT = 232;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_SPEED_PERCENT = 232,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_SPEED_RPM = 286;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_SPEED_RPM = 286,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_VOLUME_FLOW_M3 = 244;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_VOLUME_FLOW_M3 = 244,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXTRACT_AIR_TEMPERATURE = 227;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXTRACT_AIR_TEMPERATURE = 227,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_FREE_COOLING_ENABLED = 249;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_FREE_COOLING_ENABLED = 249,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_HEAT_RECOVERY_BYPASS_DAMPER_POSITION_PERCENT = 235;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_HEAT_RECOVERY_BYPASS_DAMPER_POSITION_PERCENT = 235,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_HEAT_RECOVERY_BYPASS_MODE = 33970;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_HEAT_RECOVERY_BYPASS_MODE = 33970,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_INTAKE_AIR_TEMPERATURE = 225;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_INTAKE_AIR_TEMPERATURE = 225,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_QUIET_HOURS_ACTIVE = 304;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_QUIET_HOURS_ACTIVE = 304,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_QUIET_HOURS_ENABLE = 34069;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_QUIET_HOURS_ENABLE = 34069,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_QUIET_HOURS_INTERVAL = 34070;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_QUIET_HOURS_INTERVAL = 34070,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_BOOST = 34008;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_BOOST = 34008,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_COMFORT = 34007;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_COMFORT = 34007,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_ECO = 34006;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_ECO = 34006,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_STOPPED = 34004;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_STOPPED = 34004,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_UNOCCUPIED = 34005;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_UNOCCUPIED = 34005,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_AIR_TEMPERATURE = 226;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_AIR_TEMPERATURE = 226,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_BOOST_PERCENT = 33982;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_BOOST_PERCENT = 33982,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_BOOST_M3 = 33999;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_BOOST_M3 = 33999,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_COMFORT_PERCENT = 33981;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_COMFORT_PERCENT = 33981,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_COMFORT_M3 = 33998;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_COMFORT_M3 = 33998,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_ECO_PERCENT = 33980;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_ECO_PERCENT = 33980,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_ECO_M3 = 33997;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_ECO_M3 = 33997,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_UNOCCUPIED_PERCENT = 33979;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_UNOCCUPIED_PERCENT = 33979,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_UNOCCUPIED_M3 = 33996;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_UNOCCUPIED_M3 = 33996,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_MAXIMUM_SPEED_PERCENT = 34045;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_MAXIMUM_SPEED_PERCENT = 34045,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_MAXIMUM_VOLUME_FLOW = 34001;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_MAXIMUM_VOLUME_FLOW = 34001,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_MINIMUM_SPEED_PERCENT = 34044;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_MINIMUM_SPEED_PERCENT = 34044,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_MINIMUM_VOLUME_FLOW = 34003;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_MINIMUM_VOLUME_FLOW = 34003,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_SPEED_PERCENT = 231;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_SPEED_PERCENT = 231,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_SPEED_RPM = 285;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_SPEED_RPM = 285,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_VOLUME_FLOW_M3 = 243;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_VOLUME_FLOW_M3 = 243,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_LEVEL_OVERRIDE = 33966;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_LEVEL_OVERRIDE = 33966,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_LEVEL_STANDBY = 33973;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_LEVEL_STANDBY = 33973,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_LEVEL_VACATION = 33972;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_LEVEL_VACATION = 33972,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_MODE_OVERRIDE = 33967;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_MODE_OVERRIDE = 33967,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_MODE_OVERRIDE_SOURCE = 33968;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_MODE_OVERRIDE_SOURCE = 33968,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_MODE_OVERRIDE_UNTIL = 33969;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_MODE_OVERRIDE_UNTIL = 33969,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_SCHEDULER = 33964;
   */
  VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_SCHEDULER = 33964,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_DRYING_DEMAND = 144;
   */
  VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_DRYING_DEMAND = 144,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_EXTA_DEMAND = 143;
   */
  VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_EXTA_DEMAND = 143,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_FAN_BOOST_DEMAND = 284;
   */
  VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_FAN_BOOST_DEMAND = 284,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_FAN_COMFORT_DEMAND = 283;
   */
  VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_FAN_COMFORT_DEMAND = 283,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_FAN_ECO_DEMAND = 282;
   */
  VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_FAN_ECO_DEMAND = 282,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_FAN_UNOCCUPIED_DEMAND = 281;
   */
  VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_FAN_UNOCCUPIED_DEMAND = 281,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_HEAT_RECOVERY_BYPASS_DAMPER_DEMAND = 233;
   */
  VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_HEAT_RECOVERY_BYPASS_DAMPER_DEMAND = 233,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_INTEGRATION_DEMAND = 145;
   */
  VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_INTEGRATION_DEMAND = 145,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_PUMP_DEMAND = 142;
   */
  VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_PUMP_DEMAND = 142,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_VENTILATION_DEMAND = 146;
   */
  VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_VENTILATION_DEMAND = 146,

  /**
   * @generated from enum value: VID_EXTERNAL_DEVICE_TYPE_SEE_OBJECT_INFO_SUBTYPE = 33832;
   */
  VID_EXTERNAL_DEVICE_TYPE_SEE_OBJECT_INFO_SUBTYPE = 33832,

  /**
   * @generated from enum value: VID_GRAPHIC_INTERFACE_BACKLIGHT_BRIGHTNESS = 34030;
   */
  VID_GRAPHIC_INTERFACE_BACKLIGHT_BRIGHTNESS = 34030,

  /**
   * @generated from enum value: VID_GRAPHIC_INTERFACE_BUTTON_PRESSED_IN_TEST_MODE = 298;
   */
  VID_GRAPHIC_INTERFACE_BUTTON_PRESSED_IN_TEST_MODE = 298,

  /**
   * @generated from enum value: VID_GRAPHIC_INTERFACE_TEST_MODE_SCREEN = 297;
   */
  VID_GRAPHIC_INTERFACE_TEST_MODE_SCREEN = 297,

  /**
   * @generated from enum value: VID_HCC_HEATING_CONTROLLER_HTCO_ALARM = 16415;
   */
  VID_HCC_HEATING_CONTROLLER_HTCO_ALARM = 16415,

  /**
   * @generated from enum value: VID_HCC_HEATING_CONTROLLER_HTCO_INTEGRATION_THRESHOLD = 33911;
   */
  VID_HCC_HEATING_CONTROLLER_HTCO_INTEGRATION_THRESHOLD = 33911,

  /**
   * @generated from enum value: VID_HCC_HEATING_CONTROLLER_HTCO_MODE = 33017;
   */
  VID_HCC_HEATING_CONTROLLER_HTCO_MODE = 33017,

  /**
   * @generated from enum value: VID_HCC_HEATING_CONTROLLER_HTCO_TEMPERATURE = 33014;
   */
  VID_HCC_HEATING_CONTROLLER_HTCO_TEMPERATURE = 33014,

  /**
   * @generated from enum value: VID_HCC_HEATING_CONTROLLER_HTCO_TEMPERATURE_RELATIVE = 33025;
   */
  VID_HCC_HEATING_CONTROLLER_HTCO_TEMPERATURE_RELATIVE = 33025,

  /**
   * @generated from enum value: VID_HCC_HEATING_CONTROLLER_OUTDOOR_TEMPERATURE_CUT_OFF = 33035;
   */
  VID_HCC_HEATING_CONTROLLER_OUTDOOR_TEMPERATURE_CUT_OFF = 33035,

  /**
   * @generated from enum value: VID_HCC_HEATING_CONTROLLER_SOURCE_DEMAND = 83;
   */
  VID_HCC_HEATING_CONTROLLER_SOURCE_DEMAND = 83,

  /**
   * @generated from enum value: VID_HCC_OBJECT_HEATING_COOLING_CONTROL_ASSOCIATED_PUMP = 33890;
   */
  VID_HCC_OBJECT_HEATING_COOLING_CONTROL_ASSOCIATED_PUMP = 33890,

  /**
   * @generated from enum value: VID_HCC_OBJECT_HEATING_COOLING_CONTROL_CONFIGURATION = 34083;
   */
  VID_HCC_OBJECT_HEATING_COOLING_CONTROL_CONFIGURATION = 34083,

  /**
   * @generated from enum value: VID_HCC_OBJECT_HEATING_COOLING_CONTROL_DESIRED_INLET_TEMP = 191;
   */
  VID_HCC_OBJECT_HEATING_COOLING_CONTROL_DESIRED_INLET_TEMP = 191,

  /**
   * @generated from enum value: VID_HCC_OBJECT_HEATING_COOLING_CONTROL_DESIRED_ROOM_TEMP_BY_CONSUMERS = 84;
   */
  VID_HCC_OBJECT_HEATING_COOLING_CONTROL_DESIRED_ROOM_TEMP_BY_CONSUMERS = 84,

  /**
   * @generated from enum value: VID_HCC_OBJECT_HEATING_COOLING_CONTROL_ERROR_INLET_SENSOR_FAILURE = 16414;
   */
  VID_HCC_OBJECT_HEATING_COOLING_CONTROL_ERROR_INLET_SENSOR_FAILURE = 16414,

  /**
   * @generated from enum value: VID_HCC_OBJECT_HEATING_COOLING_CONTROL_MODE = 33018;
   */
  VID_HCC_OBJECT_HEATING_COOLING_CONTROL_MODE = 33018,

  /**
   * @generated from enum value: VID_HCC_OBJECT_HEATING_COOLING_CONTROL_PUMP_DEMAND = 123;
   */
  VID_HCC_OBJECT_HEATING_COOLING_CONTROL_PUMP_DEMAND = 123,

  /**
   * @generated from enum value: VID_HCC_OBJECT_HEATING_COOLING_CONTROL_PUMP_STATUS = 85;
   */
  VID_HCC_OBJECT_HEATING_COOLING_CONTROL_PUMP_STATUS = 85,

  /**
   * @generated from enum value: VID_HCC_OBJECT_HEATING_COOLING_CONTROL_SERVO_REQUEST = 270;
   */
  VID_HCC_OBJECT_HEATING_COOLING_CONTROL_SERVO_REQUEST = 270,

  /**
   * @generated from enum value: VID_HCC_OBJECT_HEATING_COOLING_CONTROL_STATE = 101;
   */
  VID_HCC_OBJECT_HEATING_COOLING_CONTROL_STATE = 101,

  /**
   * @generated from enum value: VID_HEATING_COOLING_AUTO_SWITCHING_SAMPLES = 34020;
   */
  VID_HEATING_COOLING_AUTO_SWITCHING_SAMPLES = 34020,

  /**
   * @generated from enum value: VID_HEATING_COOLING_COOLING_CONSECUTIVE_DAYS = 34025;
   */
  VID_HEATING_COOLING_COOLING_CONSECUTIVE_DAYS = 34025,

  /**
   * @generated from enum value: VID_HEATING_COOLING_COOLING_INTERMITTENT_DAYS = 34026;
   */
  VID_HEATING_COOLING_COOLING_INTERMITTENT_DAYS = 34026,

  /**
   * @generated from enum value: VID_HEATING_COOLING_COOLING_INTERMITTENT_DAYS_PERIOD = 34027;
   */
  VID_HEATING_COOLING_COOLING_INTERMITTENT_DAYS_PERIOD = 34027,

  /**
   * @generated from enum value: VID_HEATING_COOLING_COOLING_MINIMUM_OUTDOOR_TEMPERATURE = 33023;
   */
  VID_HEATING_COOLING_COOLING_MINIMUM_OUTDOOR_TEMPERATURE = 33023,

  /**
   * @generated from enum value: VID_HEATING_COOLING_COOLING_PERIOD_LENGTH_HOURS = 34028;
   */
  VID_HEATING_COOLING_COOLING_PERIOD_LENGTH_HOURS = 34028,

  /**
   * @generated from enum value: VID_HEATING_COOLING_COOLING_SINCE = 32833;
   */
  VID_HEATING_COOLING_COOLING_SINCE = 32833,

  /**
   * @generated from enum value: VID_HEATING_COOLING_DEW_POINT_OFFSET = 33024;
   */
  VID_HEATING_COOLING_DEW_POINT_OFFSET = 33024,

  /**
   * @generated from enum value: VID_HEATING_COOLING_HEATING_CONSECUTIVE_DAYS = 34021;
   */
  VID_HEATING_COOLING_HEATING_CONSECUTIVE_DAYS = 34021,

  /**
   * @generated from enum value: VID_HEATING_COOLING_HEATING_COOLING_DEADBAND_TEMPERATURE = 33019;
   */
  VID_HEATING_COOLING_HEATING_COOLING_DEADBAND_TEMPERATURE = 33019,

  /**
   * @generated from enum value: VID_HEATING_COOLING_HEATING_COOLING_MODE_HW_SOURCE = 34019;
   */
  VID_HEATING_COOLING_HEATING_COOLING_MODE_HW_SOURCE = 34019,

  /**
   * @generated from enum value: VID_HEATING_COOLING_HEATING_COOLING_MODE_LAST_TRANSITION = 33013;
   */
  VID_HEATING_COOLING_HEATING_COOLING_MODE_LAST_TRANSITION = 33013,

  /**
   * @generated from enum value: VID_HEATING_COOLING_HEATING_COOLING_MODE_SWITCH_COOLDOWN = 33020;
   */
  VID_HEATING_COOLING_HEATING_COOLING_MODE_SWITCH_COOLDOWN = 33020,

  /**
   * @generated from enum value: VID_HEATING_COOLING_HEATING_INTERMITTENT_DAYS = 34022;
   */
  VID_HEATING_COOLING_HEATING_INTERMITTENT_DAYS = 34022,

  /**
   * @generated from enum value: VID_HEATING_COOLING_HEATING_INTERMITTENT_DAYS_PERIOD = 34023;
   */
  VID_HEATING_COOLING_HEATING_INTERMITTENT_DAYS_PERIOD = 34023,

  /**
   * @generated from enum value: VID_HEATING_COOLING_HEATING_MAXIMUM_OUTDOOR_TEMPERATURE = 33088;
   */
  VID_HEATING_COOLING_HEATING_MAXIMUM_OUTDOOR_TEMPERATURE = 33088,

  /**
   * @generated from enum value: VID_HEATING_COOLING_HEATING_PERIOD_LENGTH_HOURS = 34024;
   */
  VID_HEATING_COOLING_HEATING_PERIOD_LENGTH_HOURS = 34024,

  /**
   * @generated from enum value: VID_HEATING_COOLING_HEATING_SINCE = 32887;
   */
  VID_HEATING_COOLING_HEATING_SINCE = 32887,

  /**
   * @generated from enum value: VID_HEATING_COOLING_HOURS_WITHOUT_DEMAND_TO_CHANGE_OVER = 34085;
   */
  VID_HEATING_COOLING_HOURS_WITHOUT_DEMAND_TO_CHANGE_OVER = 34085,

  /**
   * @generated from enum value: VID_HEATING_COOLING_PERIOD_TIMER_START = 271;
   */
  VID_HEATING_COOLING_PERIOD_TIMER_START = 271,

  /**
   * @generated from enum value: VID_HEATING_COOLING_REFERENCE_ROOM_CHANGE_OVER_DEMAND = 314;
   */
  VID_HEATING_COOLING_REFERENCE_ROOM_CHANGE_OVER_DEMAND = 314,

  /**
   * @generated from enum value: VID_HEATING_COOLING_REFERENCE_ROOM_HISTOGRAM = 34084;
   */
  VID_HEATING_COOLING_REFERENCE_ROOM_HISTOGRAM = 34084,

  /**
   * @generated from enum value: VID_HEATING_COOLING_REFERENCE_ROOM_HISTOGRAM_COUNTER_PERIOD_SECONDS = 34086;
   */
  VID_HEATING_COOLING_REFERENCE_ROOM_HISTOGRAM_COUNTER_PERIOD_SECONDS = 34086,

  /**
   * @generated from enum value: VID_HEATING_COOLING_REFERENCE_ROOM_OID = 34082;
   */
  VID_HEATING_COOLING_REFERENCE_ROOM_OID = 34082,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_BLOCKING = 88;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_BLOCKING = 88,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_CONSUMER_LIST = 33092;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_CONSUMER_LIST = 33092,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_ERROR_SUPPLIER_UNAVAILABLE = 16507;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_ERROR_SUPPLIER_UNAVAILABLE = 16507,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_FLOW_RATE = 322;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_FLOW_RATE = 322,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_INLET_TEMP = 25;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_INLET_TEMP = 25,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_INLET_TEMP_REFERENCE = 33888;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_INLET_TEMP_REFERENCE = 33888,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_PRESSURE = 23;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_PRESSURE = 23,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_RETURN_TEMP = 26;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_RETURN_TEMP = 26,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_RETURN_TEMP_REFERENCE = 33896;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_RETURN_TEMP_REFERENCE = 33896,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_ROOM_LIST = 32952;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_ROOM_LIST = 32952,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_CAPABILITIES_MASK = 333;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_CAPABILITIES_MASK = 333,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_DEMAND = 168;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_DEMAND = 168,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_DEMAND_DEPRECATED = 104;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_DEMAND_DEPRECATED = 104,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_INLET_TEMP = 21;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_INLET_TEMP = 21,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_RETURN_TEMP = 22;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_RETURN_TEMP = 22,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_STATUS = 169;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_STATUS = 169,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_SUPPLIER_OID = 33036;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_SUPPLIER_OID = 33036,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_DISTRIBUTION_STATUS = 164;
   */
  VID_HEATING_COOLING_WATER_DISTRIBUTION_STATUS = 164,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_DESIGN_INLET_TEMP = 33031;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_DESIGN_INLET_TEMP = 33031,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_DESIGN_OUTDOOR_TEMP = 33033;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_DESIGN_OUTDOOR_TEMP = 33033,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_DESIGN_ROOM_TEMP = 33032;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_DESIGN_ROOM_TEMP = 33032,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_INLET_TEMP_MAX = 33027;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_INLET_TEMP_MAX = 33027,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_INLET_TEMP_MIN = 33026;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_INLET_TEMP_MIN = 33026,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_MANUAL = 33029;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_MANUAL = 33029,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_NO_THERMOSTAT_GAIN = 33034;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_NO_THERMOSTAT_GAIN = 33034,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_PARALEL_DISPLACEMENT = 33030;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_PARALEL_DISPLACEMENT = 33030,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_RADIATOR_INLET_TEMP_MAX_DEFAULT = 34090;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_RADIATOR_INLET_TEMP_MAX_DEFAULT = 34090,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_RADIATOR_INLET_TEMP_MIN_DEFAULT = 34089;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_RADIATOR_INLET_TEMP_MIN_DEFAULT = 34089,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_RADIATOR_SLOPE_DEFAULT = 34088;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_RADIATOR_SLOPE_DEFAULT = 34088,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_SLOPE = 86;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_SLOPE = 86,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_TYPE = 33028;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_TYPE = 33028,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_UNDERFLOOR_INLET_TEMP_MAX_DEFAULT = 34093;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_UNDERFLOOR_INLET_TEMP_MAX_DEFAULT = 34093,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_UNDERFLOOR_INLET_TEMP_MIN_DEFAULT = 34092;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_UNDERFLOOR_INLET_TEMP_MIN_DEFAULT = 34092,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_HEAT_CURVE_UNDERFLOOR_SLOPE_DEFAULT = 34091;
   */
  VID_HEATING_COOLING_WATER_HEAT_CURVE_UNDERFLOOR_SLOPE_DEFAULT = 34091,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_COOLING_DESIRED_INLET_TEMPERATURE = 34054;
   */
  VID_HEATING_COOLING_WATER_SOURCE_COOLING_DESIRED_INLET_TEMPERATURE = 34054,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_DEMAND_LIMITER = 299;
   */
  VID_HEATING_COOLING_WATER_SOURCE_DEMAND_LIMITER = 299,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ANALOG_DEMAND = 165;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ANALOG_DEMAND = 165,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ANALOG_DEMAND_MODE = 34058;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ANALOG_DEMAND_MODE = 34058,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BINARY_DEMAND = 166;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BINARY_DEMAND = 166,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK = 34068;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK = 34068,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_CHARGE_DEMAND = 301;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_CHARGE_DEMAND = 301,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_CHARGE_MODE = 34067;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_CHARGE_MODE = 34067,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_CHARGE_PRIORITY_TEMPERATURE_SENSOR = 34049;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_CHARGE_PRIORITY_TEMPERATURE_SENSOR = 34049,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_ERROR_TANK_LOWER_TEMPERATURE_SENSOR_ERROR = 16504;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_ERROR_TANK_LOWER_TEMPERATURE_SENSOR_ERROR = 16504,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_ERROR_TANK_PRIORITY_TEMPERATURE_SENSOR_ERROR = 16505;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_ERROR_TANK_PRIORITY_TEMPERATURE_SENSOR_ERROR = 16505,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_ERROR_TANK_UPPER_TEMPERATURE_SENSOR_ERROR = 16503;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_ERROR_TANK_UPPER_TEMPERATURE_SENSOR_ERROR = 16503,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_ENABLE = 34059;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_ENABLE = 34059,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_I_CAP = 34063;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_I_CAP = 34063,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_I_COEFFICIENT = 34061;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_I_COEFFICIENT = 34061,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_I_INTERVAL = 34062;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_I_INTERVAL = 34062,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_PROTECTION_INTERVAL_SECONDS = 34065;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_PROTECTION_INTERVAL_SECONDS = 34065,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_P_COEFFICIENT = 34060;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_P_COEFFICIENT = 34060,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_RESET_INTERVAL_SECONDS = 34064;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_RESET_INTERVAL_SECONDS = 34064,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_START_CHARGING_DIFFERENCE_K = 34047;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_START_CHARGING_DIFFERENCE_K = 34047,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_STOP_CHARGING_DIFFERENCE_K = 34048;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_STOP_CHARGING_DIFFERENCE_K = 34048,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_TANK_LOWER_TEMP = 290;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_TANK_LOWER_TEMP = 290,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_TANK_LOWER_TEMP_REFERENCE = 34052;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_TANK_LOWER_TEMP_REFERENCE = 34052,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_TANK_UPPER_TEMP = 289;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_TANK_UPPER_TEMP = 289,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_TANK_UPPER_TEMP_REFERENCE = 34051;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_TANK_UPPER_TEMP_REFERENCE = 34051,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_NO_DEMAND_THRESHOLD = 33872;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_NO_DEMAND_THRESHOLD = 33872,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_P1_TEMPERATURE = 33869;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_P1_TEMPERATURE = 33869,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_P1_VOLTAGE = 33868;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_P1_VOLTAGE = 33868,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_P2_TEMPERATURE = 33871;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_P2_TEMPERATURE = 33871,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_P2_VOLTAGE = 33870;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_P2_VOLTAGE = 33870,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_DEMAND_BINARY = 288;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_DEMAND_BINARY = 288,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_DEMAND_COOLDOWN_TIME_MINUTES = 33873;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_DEMAND_COOLDOWN_TIME_MINUTES = 33873,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_DEMAND_MINIMUM_TIME_MINUTES = 34046;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_DEMAND_MINIMUM_TIME_MINUTES = 34046,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ELEMENT_CAPABILITIES_MASK = 334;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ELEMENT_CAPABILITIES_MASK = 334,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ELEMENT_REQUEST_IN = 292;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ELEMENT_REQUEST_IN = 292,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ELEMENT_STATUS_OUT = 293;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ELEMENT_STATUS_OUT = 293,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ERROR_SOURCE_FAILURE = 16456;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ERROR_SOURCE_FAILURE = 16456,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ERROR_SOURCE_GENERAL_FAILURE = 16457;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ERROR_SOURCE_GENERAL_FAILURE = 16457,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_NO_DEMAND_THRESHOLD = 33866;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_NO_DEMAND_THRESHOLD = 33866,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_P1_TEMPERATURE = 33863;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_P1_TEMPERATURE = 33863,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_P1_VOLTAGE = 33862;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_P1_VOLTAGE = 33862,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_P2_TEMPERATURE = 33865;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_P2_TEMPERATURE = 33865,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_P2_VOLTAGE = 33864;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_P2_VOLTAGE = 33864,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_DEMAND_BINARY = 287;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_DEMAND_BINARY = 287,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_COMPRESSOR_STATUS = 317;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_COMPRESSOR_STATUS = 317,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_DEFROSTING_STATUS = 318;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_DEFROSTING_STATUS = 318,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_DHW_HEAT_STATUS = 324;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_DHW_HEAT_STATUS = 324,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_HEATER_OUTLET_TEMPERATURE = 320;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_HEATER_OUTLET_TEMPERATURE = 320,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_HEAT_PUMP_OUTLET_TEMPERATURE = 321;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_HEAT_PUMP_OUTLET_TEMPERATURE = 321,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_OUTDOOR_UNIT_STATUS = 319;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_OUTDOOR_UNIT_STATUS = 319,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_REFRIGERANT_TEMPERATURE = 323;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_REFRIGERANT_TEMPERATURE = 323,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_SILENT_MODE_STATUS = 325;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_SILENT_MODE_STATUS = 325,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_LAST_ACTIVITY = 170;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_LAST_ACTIVITY = 170,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_PRIORITY_COST = 34050;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_PRIORITY_COST = 34050,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_RELEASE_OUTDOOR_TEMPERATURE = 33876;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_RELEASE_OUTDOOR_TEMPERATURE = 33876,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_SOURCE_RELEASE = 167;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_SOURCE_RELEASE = 167,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_SOURCE_START_DELAY_MINUTES = 33874;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_SOURCE_START_DELAY_MINUTES = 33874,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_SOURCE_STOP_DELAY_MINUTES = 33875;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_SOURCE_STOP_DELAY_MINUTES = 33875,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_UN_RELEASE_OUTDOOR_TEMPERATURE = 33877;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_UN_RELEASE_OUTDOOR_TEMPERATURE = 33877,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_VOLTAGE_COOLING_DEMAND = 34056;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_VOLTAGE_COOLING_DEMAND = 34056,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_VOLTAGE_HEATING_DEMAND = 34055;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_VOLTAGE_HEATING_DEMAND = 34055,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_VOLTAGE_NO_DEMAND = 34057;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_VOLTAGE_NO_DEMAND = 34057,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_ERROR_INLET_TEMPERATURE_SENSOR_FAILURE = 16455;
   */
  VID_HEATING_COOLING_WATER_SOURCE_ERROR_INLET_TEMPERATURE_SENSOR_FAILURE = 16455,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_HEATING_DESIRED_INLET_TEMPERATURE = 34053;
   */
  VID_HEATING_COOLING_WATER_SOURCE_HEATING_DESIRED_INLET_TEMPERATURE = 34053,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_INLET_TEMPERATURE_SOURCE = 33878;
   */
  VID_HEATING_COOLING_WATER_SOURCE_INLET_TEMPERATURE_SOURCE = 33878,

  /**
   * @generated from enum value: VID_HEATING_COOLING_WATER_SOURCE_SOURCE_ELEMENT_LIST = 33867;
   */
  VID_HEATING_COOLING_WATER_SOURCE_SOURCE_ELEMENT_LIST = 33867,

  /**
   * @generated from enum value: VID_INPUT_OUTPUT_OBJECT_MODE = 33021;
   */
  VID_INPUT_OUTPUT_OBJECT_MODE = 33021,

  /**
   * @generated from enum value: VID_INPUT_OWNER_OID_DEPRECATED = 171;
   */
  VID_INPUT_OWNER_OID_DEPRECATED = 171,

  /**
   * @generated from enum value: VID_INPUT_OWNER_REFERENCE = 295;
   */
  VID_INPUT_OWNER_REFERENCE = 295,

  /**
   * @generated from enum value: VID_INPUT_VALUE_OVERRIDE_NON_PERSISTENT = 291;
   */
  VID_INPUT_VALUE_OVERRIDE_NON_PERSISTENT = 291,

  /**
   * @generated from enum value: VID_INPUT_VALUE_OVERRIDE_PERSISTENT = 33094;
   */
  VID_INPUT_VALUE_OVERRIDE_PERSISTENT = 33094,

  /**
   * @generated from enum value: VID_INTERFACE_ERROR_POWER_SHORTED = 16412;
   */
  VID_INTERFACE_ERROR_POWER_SHORTED = 16412,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_COOLING_INLET_TEMP_MAX = 33902;
   */
  VID_ITC_COOLING_CONTROLLER_COOLING_INLET_TEMP_MAX = 33902,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_COOLING_INLET_TEMP_MIN = 33901;
   */
  VID_ITC_COOLING_CONTROLLER_COOLING_INLET_TEMP_MIN = 33901,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_COOLING_REGULATOR_HYSTERESIS = 33900;
   */
  VID_ITC_COOLING_CONTROLLER_COOLING_REGULATOR_HYSTERESIS = 33900,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_COOLING_REGULATOR_I_TIME = 33899;
   */
  VID_ITC_COOLING_CONTROLLER_COOLING_REGULATOR_I_TIME = 33899,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_COOLING_REGULATOR_P_GAIN = 33898;
   */
  VID_ITC_COOLING_CONTROLLER_COOLING_REGULATOR_P_GAIN = 33898,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_DESIRED_SOURCE_WATER_TEMPERATURE = 58;
   */
  VID_ITC_COOLING_CONTROLLER_DESIRED_SOURCE_WATER_TEMPERATURE = 58,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_FROST_PROTECTION_TEMP = 32982;
   */
  VID_ITC_COOLING_CONTROLLER_FROST_PROTECTION_TEMP = 32982,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_HOTC_TEMP = 32963;
   */
  VID_ITC_COOLING_CONTROLLER_HOTC_TEMP = 32963,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_ITC_AUTO_REDUCTION_MODE = 32869;
   */
  VID_ITC_COOLING_CONTROLLER_ITC_AUTO_REDUCTION_MODE = 32869,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_ITC_AUTO_REDUCTION_TEMP = 32989;
   */
  VID_ITC_COOLING_CONTROLLER_ITC_AUTO_REDUCTION_TEMP = 32989,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_ITC_OUTDOOR_FILTER_SUPPRESSION = 32986;
   */
  VID_ITC_COOLING_CONTROLLER_ITC_OUTDOOR_FILTER_SUPPRESSION = 32986,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_ITC_TEMP_SET_MANUAL = 32866;
   */
  VID_ITC_COOLING_CONTROLLER_ITC_TEMP_SET_MANUAL = 32866,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_MODE = 32853;
   */
  VID_ITC_COOLING_CONTROLLER_MODE = 32853,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_PUMP_ACTIVATION_MODE = 32953;
   */
  VID_ITC_COOLING_CONTROLLER_PUMP_ACTIVATION_MODE = 32953,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_PUMP_ACTIVATION_TIME = 32955;
   */
  VID_ITC_COOLING_CONTROLLER_PUMP_ACTIVATION_TIME = 32955,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_PUMP_DELAYED_START = 32956;
   */
  VID_ITC_COOLING_CONTROLLER_PUMP_DELAYED_START = 32956,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_PUMP_DELAYED_STOP = 32957;
   */
  VID_ITC_COOLING_CONTROLLER_PUMP_DELAYED_STOP = 32957,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_PUMP_IDDLE_TIME = 32954;
   */
  VID_ITC_COOLING_CONTROLLER_PUMP_IDDLE_TIME = 32954,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_REQUIRED_COOL_SOURCE_TEMP_OFFSET = 33903;
   */
  VID_ITC_COOLING_CONTROLLER_REQUIRED_COOL_SOURCE_TEMP_OFFSET = 33903,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_VALVE_ACTIVATION_CYCLES = 32960;
   */
  VID_ITC_COOLING_CONTROLLER_VALVE_ACTIVATION_CYCLES = 32960,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_VALVE_ACTIVATION_IDDLE_TIME = 32959;
   */
  VID_ITC_COOLING_CONTROLLER_VALVE_ACTIVATION_IDDLE_TIME = 32959,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_VALVE_ACTIVATION_MODE = 32958;
   */
  VID_ITC_COOLING_CONTROLLER_VALVE_ACTIVATION_MODE = 32958,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_VALVE_STATUS = 64;
   */
  VID_ITC_COOLING_CONTROLLER_VALVE_STATUS = 64,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_WARNING_RETENTIVE_LOW_ENERGY_FOR_COOLING = 16397;
   */
  VID_ITC_COOLING_CONTROLLER_WARNING_RETENTIVE_LOW_ENERGY_FOR_COOLING = 16397,

  /**
   * @generated from enum value: VID_ITC_COOLING_CONTROLLER_WARNING_RETENTIVE_LOW_ENERGY_FOR_HEATING = 16396;
   */
  VID_ITC_COOLING_CONTROLLER_WARNING_RETENTIVE_LOW_ENERGY_FOR_HEATING = 16396,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_CURVE_DESIGN_INLET_TEMP = 32965;
   */
  VID_ITC_HEATING_CONTROLLER_CURVE_DESIGN_INLET_TEMP = 32965,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_CURVE_DESIGN_OUTDOOR_TEMP = 32967;
   */
  VID_ITC_HEATING_CONTROLLER_CURVE_DESIGN_OUTDOOR_TEMP = 32967,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_CURVE_DESIGN_ROOM_TEMP = 32966;
   */
  VID_ITC_HEATING_CONTROLLER_CURVE_DESIGN_ROOM_TEMP = 32966,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_CURVE_INLET_TEMP_MAX = 32868;
   */
  VID_ITC_HEATING_CONTROLLER_CURVE_INLET_TEMP_MAX = 32868,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_CURVE_INLET_TEMP_MIN = 32867;
   */
  VID_ITC_HEATING_CONTROLLER_CURVE_INLET_TEMP_MIN = 32867,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_CURVE_MANUAL = 32969;
   */
  VID_ITC_HEATING_CONTROLLER_CURVE_MANUAL = 32969,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_CURVE_NO_THERMOSTAT_GAIN = 32964;
   */
  VID_ITC_HEATING_CONTROLLER_CURVE_NO_THERMOSTAT_GAIN = 32964,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_CURVE_PARALEL_DISPLACEMENT = 32870;
   */
  VID_ITC_HEATING_CONTROLLER_CURVE_PARALEL_DISPLACEMENT = 32870,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_CURVE_SLOPE = 61;
   */
  VID_ITC_HEATING_CONTROLLER_CURVE_SLOPE = 61,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_CURVE_TYPE = 32968;
   */
  VID_ITC_HEATING_CONTROLLER_CURVE_TYPE = 32968,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_FROST_PROTECTION_MODE = 32981;
   */
  VID_ITC_HEATING_CONTROLLER_FROST_PROTECTION_MODE = 32981,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_HTCO_ALARM = 16398;
   */
  VID_ITC_HEATING_CONTROLLER_HTCO_ALARM = 16398,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_HTCO_INTEGRATION_THRESHOLD = 33905;
   */
  VID_ITC_HEATING_CONTROLLER_HTCO_INTEGRATION_THRESHOLD = 33905,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_HTCO_MODE = 32855;
   */
  VID_ITC_HEATING_CONTROLLER_HTCO_MODE = 32855,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_HTCO_TEMP = 32857;
   */
  VID_ITC_HEATING_CONTROLLER_HTCO_TEMP = 32857,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_OPTIMIZATION_BOOST_LEVEL = 32976;
   */
  VID_ITC_HEATING_CONTROLLER_OPTIMIZATION_BOOST_LEVEL = 32976,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_OPTIMIZATION_BOOST_MODE = 32975;
   */
  VID_ITC_HEATING_CONTROLLER_OPTIMIZATION_BOOST_MODE = 32975,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_OPTIMIZATION_RAMPING_MODE = 32977;
   */
  VID_ITC_HEATING_CONTROLLER_OPTIMIZATION_RAMPING_MODE = 32977,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_OPTIMIZATION_RAMPING_TIME_MINUTES = 32978;
   */
  VID_ITC_HEATING_CONTROLLER_OPTIMIZATION_RAMPING_TIME_MINUTES = 32978,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_REGULATOR_HYSTERESIS = 32985;
   */
  VID_ITC_HEATING_CONTROLLER_REGULATOR_HYSTERESIS = 32985,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_REGULATOR_I_TIME = 32984;
   */
  VID_ITC_HEATING_CONTROLLER_REGULATOR_I_TIME = 32984,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_REGULATOR_MODE = 32863;
   */
  VID_ITC_HEATING_CONTROLLER_REGULATOR_MODE = 32863,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_REGULATOR_P_GAIN = 32983;
   */
  VID_ITC_HEATING_CONTROLLER_REGULATOR_P_GAIN = 32983,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_REQUIRED_HEAT_SOURCE_TEMP_OFFSET = 32962;
   */
  VID_ITC_HEATING_CONTROLLER_REQUIRED_HEAT_SOURCE_TEMP_OFFSET = 32962,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_LIMITER_MODE = 32970;
   */
  VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_LIMITER_MODE = 32970,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MAX_LIMITER_GAIN = 32973;
   */
  VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MAX_LIMITER_GAIN = 32973,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MAX_LIMITER_LIMIT = 32971;
   */
  VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MAX_LIMITER_LIMIT = 32971,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MAX_LIMITER_PRIORITY = 32987;
   */
  VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MAX_LIMITER_PRIORITY = 32987,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MIN_LIMITER_GAIN = 32974;
   */
  VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MIN_LIMITER_GAIN = 32974,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MIN_LIMITER_LIMIT = 32972;
   */
  VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MIN_LIMITER_LIMIT = 32972,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_ROOM_CORRECTION_MAX_GAIN = 32979;
   */
  VID_ITC_HEATING_CONTROLLER_ROOM_CORRECTION_MAX_GAIN = 32979,

  /**
   * @generated from enum value: VID_ITC_HEATING_CONTROLLER_ROOM_CORRECTION_MIN_GAIN = 32980;
   */
  VID_ITC_HEATING_CONTROLLER_ROOM_CORRECTION_MIN_GAIN = 32980,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_ASSOCIATED_PUMP_OUTPUT = 33897;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_ASSOCIATED_PUMP_OUTPUT = 33897,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_DESIRED_INLET_TEMP = 59;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_DESIRED_INLET_TEMP = 59,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_DESIRED_ROOM_TEMP_BY_CONSUMERS = 41;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_DESIRED_ROOM_TEMP_BY_CONSUMERS = 41,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_FROST_PROTECTION_ACTIVE = 16434;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_FROST_PROTECTION_ACTIVE = 16434,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_INLET_SENSOR_FAILURE = 16429;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_INLET_SENSOR_FAILURE = 16429,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_OUTDOOR_SENSOR_FAILURE = 16432;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_OUTDOOR_SENSOR_FAILURE = 16432,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_REGULATOR_FAILURE = 16407;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_REGULATOR_FAILURE = 16407,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_RETURN_SENSOR_FAILURE = 16431;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_RETURN_SENSOR_FAILURE = 16431,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_SERVO_FAILURE = 16430;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_SERVO_FAILURE = 16430,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_HEATING_COOLING_MODE = 32854;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_HEATING_COOLING_MODE = 32854,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_HEATING_SERIAL_MODE = 32961;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_HEATING_SERIAL_MODE = 32961,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_INLET_TEMP = 62;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_INLET_TEMP = 62,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_INLET_TEMP_REFERENCE = 33895;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_INLET_TEMP_REFERENCE = 33895,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_PUMP_DEMAND = 124;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_PUMP_DEMAND = 124,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_PUMP_STATUS = 65;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_PUMP_STATUS = 65,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_RETURN_TEMP = 63;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_RETURN_TEMP = 63,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_SERVO_REQUEST = 91;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_SERVO_REQUEST = 91,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_SOURCE_DEMAND = 57;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_SOURCE_DEMAND = 57,

  /**
   * @generated from enum value: VID_ITC_OBJECT_INLET_TEMP_CONTROL_WARNING_REGULATOR_FAILURE = 16408;
   */
  VID_ITC_OBJECT_INLET_TEMP_CONTROL_WARNING_REGULATOR_FAILURE = 16408,

  /**
   * @generated from enum value: VID_LOCATION_ALLOW_TEST_SUPPORT_MODE = 33095;
   */
  VID_LOCATION_ALLOW_TEST_SUPPORT_MODE = 33095,

  /**
   * @generated from enum value: VID_LOCATION_ALLOW_UNDER_DEVELOPMENT_FEATURES = 33091;
   */
  VID_LOCATION_ALLOW_UNDER_DEVELOPMENT_FEATURES = 33091,

  /**
   * @generated from enum value: VID_LOCATION_BACKUP = 81;
   */
  VID_LOCATION_BACKUP = 81,

  /**
   * @generated from enum value: VID_LOCATION_BEHAVIOUR_VARIANT = 256;
   */
  VID_LOCATION_BEHAVIOUR_VARIANT = 256,

  /**
   * @generated from enum value: VID_LOCATION_COMMAND = 66;
   */
  VID_LOCATION_COMMAND = 66,

  /**
   * @generated from enum value: VID_LOCATION_DEBUG_LEVEL = 33930;
   */
  VID_LOCATION_DEBUG_LEVEL = 33930,

  /**
   * @generated from enum value: VID_LOCATION_DEFAULT_LANGUAGE = 255;
   */
  VID_LOCATION_DEFAULT_LANGUAGE = 255,

  /**
   * @generated from enum value: VID_LOCATION_EMULATE_FAIL = 18;
   */
  VID_LOCATION_EMULATE_FAIL = 18,

  /**
   * @generated from enum value: VID_LOCATION_EXERCISING_LAST_ACTIVATION = 33929;
   */
  VID_LOCATION_EXERCISING_LAST_ACTIVATION = 33929,

  /**
   * @generated from enum value: VID_LOCATION_EXERCISING_SCHEDULE = 33928;
   */
  VID_LOCATION_EXERCISING_SCHEDULE = 33928,

  /**
   * @generated from enum value: VID_LOCATION_EXERCISING_SCHEDULE_OVERRIDE = 189;
   */
  VID_LOCATION_EXERCISING_SCHEDULE_OVERRIDE = 189,

  /**
   * @generated from enum value: VID_LOCATION_EXERCISING_SCHEDULE_OVERRIDE_SETUP = 242;
   */
  VID_LOCATION_EXERCISING_SCHEDULE_OVERRIDE_SETUP = 242,

  /**
   * @generated from enum value: VID_LOCATION_FROST_PROTECTION_INLET_TEMPERATURE_THRESHOLD = 33086;
   */
  VID_LOCATION_FROST_PROTECTION_INLET_TEMPERATURE_THRESHOLD = 33086,

  /**
   * @generated from enum value: VID_LOCATION_FROST_PROTECTION_OUTDOOR_TEMPERATURE_THRESHOLD = 33087;
   */
  VID_LOCATION_FROST_PROTECTION_OUTDOOR_TEMPERATURE_THRESHOLD = 33087,

  /**
   * @generated from enum value: VID_LOCATION_FROST_PROTECTION_ROOM_TEMPERATURE_THRESHOLD = 33085;
   */
  VID_LOCATION_FROST_PROTECTION_ROOM_TEMPERATURE_THRESHOLD = 33085,

  /**
   * @generated from enum value: VID_LOCATION_GLOBAL_SILENT_MODE = 34100;
   */
  VID_LOCATION_GLOBAL_SILENT_MODE = 34100,

  /**
   * @generated from enum value: VID_LOCATION_GSM_LOCATION = 45;
   */
  VID_LOCATION_GSM_LOCATION = 45,

  /**
   * @generated from enum value: VID_LOCATION_HARDWARE_PROFILE = 33000;
   */
  VID_LOCATION_HARDWARE_PROFILE = 33000,

  /**
   * @generated from enum value: VID_LOCATION_LEARN_DESTINATION_OID = 32940;
   */
  VID_LOCATION_LEARN_DESTINATION_OID = 32940,

  /**
   * @generated from enum value: VID_LOCATION_LEARN_PERIPH_OID = 32941;
   */
  VID_LOCATION_LEARN_PERIPH_OID = 32941,

  /**
   * @generated from enum value: VID_LOCATION_LICENSE = 32881;
   */
  VID_LOCATION_LICENSE = 32881,

  /**
   * @generated from enum value: VID_LOCATION_LICENSE_END = 32882;
   */
  VID_LOCATION_LICENSE_END = 32882,

  /**
   * @generated from enum value: VID_LOCATION_NEW_HARDWARE_PROFILE = 33006;
   */
  VID_LOCATION_NEW_HARDWARE_PROFILE = 33006,

  /**
   * @generated from enum value: VID_LOCATION_OUTDOOR_TEMPERATURE_FILTER_SETTINGS = 34029;
   */
  VID_LOCATION_OUTDOOR_TEMPERATURE_FILTER_SETTINGS = 34029,

  /**
   * @generated from enum value: VID_LOCATION_OUTDOOR_TEMPERATURE_SOURCE = 33894;
   */
  VID_LOCATION_OUTDOOR_TEMPERATURE_SOURCE = 33894,

  /**
   * @generated from enum value: VID_LOCATION_OUTDOOR_TEMPERATURE_SOURCE_DEPRECATED = 33007;
   */
  VID_LOCATION_OUTDOOR_TEMPERATURE_SOURCE_DEPRECATED = 33007,

  /**
   * @generated from enum value: VID_LOCATION_RECYCLE_BIN = 32998;
   */
  VID_LOCATION_RECYCLE_BIN = 32998,

  /**
   * @generated from enum value: VID_LOCATION_REPUBLISH_PERIOD_TIME = 33861;
   */
  VID_LOCATION_REPUBLISH_PERIOD_TIME = 33861,

  /**
   * @generated from enum value: VID_LOCATION_RESTORE = 82;
   */
  VID_LOCATION_RESTORE = 82,

  /**
   * @generated from enum value: VID_LOCATION_SERVICE_KEY_WRITE_ONLY_DEPRECATED = 111;
   */
  VID_LOCATION_SERVICE_KEY_WRITE_ONLY_DEPRECATED = 111,

  /**
   * @generated from enum value: VID_LOCATION_SERVICE_MODE_END_TIME = 33061;
   */
  VID_LOCATION_SERVICE_MODE_END_TIME = 33061,

  /**
   * @generated from enum value: VID_LOCATION_STRUCTURE_ID = 33010;
   */
  VID_LOCATION_STRUCTURE_ID = 33010,

  /**
   * @generated from enum value: VID_LOCATION_SUPPORT_MODE = 32864;
   */
  VID_LOCATION_SUPPORT_MODE = 32864,

  /**
   * @generated from enum value: VID_LOCATION_WARNING_DEV_SERVICE = 16403;
   */
  VID_LOCATION_WARNING_DEV_SERVICE = 16403,

  /**
   * @generated from enum value: VID_LOCATION_WARNING_FAULT = 16404;
   */
  VID_LOCATION_WARNING_FAULT = 16404,

  /**
   * @generated from enum value: VID_LOCATION_WARN_MAINTENANCE = 16464;
   */
  VID_LOCATION_WARN_MAINTENANCE = 16464,

  /**
   * @generated from enum value: VID_MODBUS_MODBUS_BAUDRATE = 32823;
   */
  VID_MODBUS_MODBUS_BAUDRATE = 32823,

  /**
   * @generated from enum value: VID_MODBUS_MODBUS_MODE = 32821;
   */
  VID_MODBUS_MODBUS_MODE = 32821,

  /**
   * @generated from enum value: VID_MODBUS_MODBUS_PARITY = 33824;
   */
  VID_MODBUS_MODBUS_PARITY = 33824,

  /**
   * @generated from enum value: VID_MODBUS_MODBUS_SLAVE_ADDR = 32822;
   */
  VID_MODBUS_MODBUS_SLAVE_ADDR = 32822,

  /**
   * @generated from enum value: VID_MODBUS_MODBUS_SLAVE_PASSWORD = 33099;
   */
  VID_MODBUS_MODBUS_SLAVE_PASSWORD = 33099,

  /**
   * @generated from enum value: VID_MODBUS_MODBUS_STOP_BITS = 33825;
   */
  VID_MODBUS_MODBUS_STOP_BITS = 33825,

  /**
   * @generated from enum value: VID_NETWORKING_DNS_IP_ADDRESS = 185;
   */
  VID_NETWORKING_DNS_IP_ADDRESS = 185,

  /**
   * @generated from enum value: VID_NETWORKING_IP_ADDRESS_DEPRECATED = 114;
   */
  VID_NETWORKING_IP_ADDRESS_DEPRECATED = 114,

  /**
   * @generated from enum value: VID_NETWORKING_STATE = 172;
   */
  VID_NETWORKING_STATE = 172,

  /**
   * @generated from enum value: VID_NETWORKING_STATIC_DNS_IP_ADDRESS = 33912;
   */
  VID_NETWORKING_STATIC_DNS_IP_ADDRESS = 33912,

  /**
   * @generated from enum value: VID_OPENTHERM_HEATING_REQUEST = 266;
   */
  VID_OPENTHERM_HEATING_REQUEST = 266,

  /**
   * @generated from enum value: VID_OPENTHERM_STATE = 260;
   */
  VID_OPENTHERM_STATE = 260,

  /**
   * @generated from enum value: VID_OUTDOOR_ZONE_ERROR_GENERAL_PROBLEM = 16444;
   */
  VID_OUTDOOR_ZONE_ERROR_GENERAL_PROBLEM = 16444,

  /**
   * @generated from enum value: VID_OUTDOOR_ZONE_OUTDOOR_TEMPERATURE_BMS_OVERRIDE = 33090;
   */
  VID_OUTDOOR_ZONE_OUTDOOR_TEMPERATURE_BMS_OVERRIDE = 33090,

  /**
   * @generated from enum value: VID_OUTDOOR_ZONE_WARNING_PERIPHERAL_LOW_BATERY = 16445;
   */
  VID_OUTDOOR_ZONE_WARNING_PERIPHERAL_LOW_BATERY = 16445,

  /**
   * @generated from enum value: VID_OUTDOOR_ZONE_WARNING_PERIPHERAL_UNREACHABLE = 16446;
   */
  VID_OUTDOOR_ZONE_WARNING_PERIPHERAL_UNREACHABLE = 16446,

  /**
   * @generated from enum value: VID_OUTPUT_ALARM_OUTPUT_FAIL = 16405;
   */
  VID_OUTPUT_ALARM_OUTPUT_FAIL = 16405,

  /**
   * @generated from enum value: VID_OUTPUT_BLOCK_SOURCE = 32937;
   */
  VID_OUTPUT_BLOCK_SOURCE = 32937,

  /**
   * @generated from enum value: VID_OUTPUT_BLOCK_THRESHOLD = 33009;
   */
  VID_OUTPUT_BLOCK_THRESHOLD = 33009,

  /**
   * @generated from enum value: VID_OUTPUT_DELAY_END = 32936;
   */
  VID_OUTPUT_DELAY_END = 32936,

  /**
   * @generated from enum value: VID_OUTPUT_DELAY_START = 32935;
   */
  VID_OUTPUT_DELAY_START = 32935,

  /**
   * @generated from enum value: VID_OUTPUT_ERROR_INVALID_REFERENCE = 16453;
   */
  VID_OUTPUT_ERROR_INVALID_REFERENCE = 16453,

  /**
   * @generated from enum value: VID_OUTPUT_EXERCISE_BLOCKED = 125;
   */
  VID_OUTPUT_EXERCISE_BLOCKED = 125,

  /**
   * @generated from enum value: VID_OUTPUT_EXERCISE_CYCLE_LENGTH = 33042;
   */
  VID_OUTPUT_EXERCISE_CYCLE_LENGTH = 33042,

  /**
   * @generated from enum value: VID_OUTPUT_EXERCISE_ENABLED = 33041;
   */
  VID_OUTPUT_EXERCISE_ENABLED = 33041,

  /**
   * @generated from enum value: VID_OUTPUT_EXERCISE_IDLE_TIME_DAYS = 33040;
   */
  VID_OUTPUT_EXERCISE_IDLE_TIME_DAYS = 33040,

  /**
   * @generated from enum value: VID_OUTPUT_EXERCISE_STATUS = 93;
   */
  VID_OUTPUT_EXERCISE_STATUS = 93,

  /**
   * @generated from enum value: VID_OUTPUT_INDICATION_OVERRIDE = 188;
   */
  VID_OUTPUT_INDICATION_OVERRIDE = 188,

  /**
   * @generated from enum value: VID_OUTPUT_INVERTED = 32938;
   */
  VID_OUTPUT_INVERTED = 32938,

  /**
   * @generated from enum value: VID_OUTPUT_LAST_ACTIVATION = 33925;
   */
  VID_OUTPUT_LAST_ACTIVATION = 33925,

  /**
   * @generated from enum value: VID_OUTPUT_LAST_ACTIVATION_NON_PERSISTENT_DEPRECATED = 92;
   */
  VID_OUTPUT_LAST_ACTIVATION_NON_PERSISTENT_DEPRECATED = 92,

  /**
   * @generated from enum value: VID_OUTPUT_SERVICE_MODE_DEPRECATED = 32933;
   */
  VID_OUTPUT_SERVICE_MODE_DEPRECATED = 32933,

  /**
   * @generated from enum value: VID_OUTPUT_SERVICE_MODE_END_TIME = 33065;
   */
  VID_OUTPUT_SERVICE_MODE_END_TIME = 33065,

  /**
   * @generated from enum value: VID_OUTPUT_SERVICE_VALUE = 32934;
   */
  VID_OUTPUT_SERVICE_VALUE = 32934,

  /**
   * @generated from enum value: VID_OUTPUT_SOURCE_MAX_VALUE = 32930;
   */
  VID_OUTPUT_SOURCE_MAX_VALUE = 32930,

  /**
   * @generated from enum value: VID_OUTPUT_SOURCE_MIN_VALUE = 32929;
   */
  VID_OUTPUT_SOURCE_MIN_VALUE = 32929,

  /**
   * @generated from enum value: VID_OUTPUT_VALUE_SOURCE = 32928;
   */
  VID_OUTPUT_VALUE_SOURCE = 32928,

  /**
   * @generated from enum value: VID_OUTPUT_VALUE_THRESHOLD = 32931;
   */
  VID_OUTPUT_VALUE_THRESHOLD = 32931,

  /**
   * @generated from enum value: VID_POWER_MANAGEMENT_CONTROLLER_ACTUAL_CURRENT = 80;
   */
  VID_POWER_MANAGEMENT_CONTROLLER_ACTUAL_CURRENT = 80,

  /**
   * @generated from enum value: VID_POWER_MANAGEMENT_CONTROLLER_DYNAMIC_CURRENT_MAX = 77;
   */
  VID_POWER_MANAGEMENT_CONTROLLER_DYNAMIC_CURRENT_MAX = 77,

  /**
   * @generated from enum value: VID_POWER_MANAGEMENT_CONTROLLER_DYNAMIC_CURRENT_MIN = 78;
   */
  VID_POWER_MANAGEMENT_CONTROLLER_DYNAMIC_CURRENT_MIN = 78,

  /**
   * @generated from enum value: VID_POWER_MANAGEMENT_CONTROLLER_GRANTED_CURRENT = 79;
   */
  VID_POWER_MANAGEMENT_CONTROLLER_GRANTED_CURRENT = 79,

  /**
   * @generated from enum value: VID_POWER_MANAGEMENT_CONTROLLER_POWER_CONSUMERS_LIST = 90;
   */
  VID_POWER_MANAGEMENT_CONTROLLER_POWER_CONSUMERS_LIST = 90,

  /**
   * @generated from enum value: VID_POWER_MANAGEMENT_CONTROLLER_POWER_PRIORITY = 32951;
   */
  VID_POWER_MANAGEMENT_CONTROLLER_POWER_PRIORITY = 32951,

  /**
   * @generated from enum value: VID_POWER_MANAGEMENT_CONTROLLER_STATIC_CURRENT = 32946;
   */
  VID_POWER_MANAGEMENT_CONTROLLER_STATIC_CURRENT = 32946,

  /**
   * @generated from enum value: VID_PRESET_FUNCTION_CONFIGURATION_ASSOCIATED_MAP_INDEX = 33828;
   */
  VID_PRESET_FUNCTION_CONFIGURATION_ASSOCIATED_MAP_INDEX = 33828,

  /**
   * @generated from enum value: VID_PRESET_FUNCTION_CONFIGURATION_ASSOCIATED_MAP_INDEX_OFFSET = 33829;
   */
  VID_PRESET_FUNCTION_CONFIGURATION_ASSOCIATED_MAP_INDEX_OFFSET = 33829,

  /**
   * @generated from enum value: VID_PROFILE_CURRENT_PRESET_FUNCTION_ASSIGNMENT_MAP = 33892;
   */
  VID_PROFILE_CURRENT_PRESET_FUNCTION_ASSIGNMENT_MAP = 33892,

  /**
   * @generated from enum value: VID_PROFILE_ERROR_PROFILE_CONDITIONS_NOT_MET = 16458;
   */
  VID_PROFILE_ERROR_PROFILE_CONDITIONS_NOT_MET = 16458,

  /**
   * @generated from enum value: VID_PROFILE_PRESET_FUNCTION_ASSIGNMENT_MAP = 33827;
   */
  VID_PROFILE_PRESET_FUNCTION_ASSIGNMENT_MAP = 33827,

  /**
   * @generated from enum value: VID_PROFILE_PROFILE_NAME = 33826;
   */
  VID_PROFILE_PROFILE_NAME = 33826,

  /**
   * @generated from enum value: VID_RESOURCES_CMV_BLOCKS_REMAINING = 224;
   */
  VID_RESOURCES_CMV_BLOCKS_REMAINING = 224,

  /**
   * @generated from enum value: VID_RESOURCES_DEHUMIDIFIER_BLOCKS_REMAINING = 141;
   */
  VID_RESOURCES_DEHUMIDIFIER_BLOCKS_REMAINING = 141,

  /**
   * @generated from enum value: VID_RESOURCES_DHW_TANK_BLOCKS_REMAINING = 140;
   */
  VID_RESOURCES_DHW_TANK_BLOCKS_REMAINING = 140,

  /**
   * @generated from enum value: VID_RESOURCES_DRYING_BLOCKS_REMAINING = 155;
   */
  VID_RESOURCES_DRYING_BLOCKS_REMAINING = 155,

  /**
   * @generated from enum value: VID_RESOURCES_HCC_BLOCKS_REMAINING = 139;
   */
  VID_RESOURCES_HCC_BLOCKS_REMAINING = 139,

  /**
   * @generated from enum value: VID_RESOURCES_HP_BUFFER_TANK_BLOCKS_REMAINING = 294;
   */
  VID_RESOURCES_HP_BUFFER_TANK_BLOCKS_REMAINING = 294,

  /**
   * @generated from enum value: VID_RESOURCES_HP_HCWS_ELEMENT_BLOCKS_AVAILABLE = 303;
   */
  VID_RESOURCES_HP_HCWS_ELEMENT_BLOCKS_AVAILABLE = 303,

  /**
   * @generated from enum value: VID_RESOURCES_INTEGRATION_BLOCKS_REMAINING = 156;
   */
  VID_RESOURCES_INTEGRATION_BLOCKS_REMAINING = 156,

  /**
   * @generated from enum value: VID_RESOURCES_ITC_BLOCKS_REMAINING = 138;
   */
  VID_RESOURCES_ITC_BLOCKS_REMAINING = 138,

  /**
   * @generated from enum value: VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_CMV_FUNCTION_POOL_ALLOCATION_INTERNAL = 33958;
   */
  VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_CMV_FUNCTION_POOL_ALLOCATION_INTERNAL = 33958,

  /**
   * @generated from enum value: VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_DEHUMIDIFIERS_POOL_ALLOCATION_INTERNAL = 33840;
   */
  VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_DEHUMIDIFIERS_POOL_ALLOCATION_INTERNAL = 33840,

  /**
   * @generated from enum value: VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_DRYING_FUNCTION_POOL_ALLOCATION_INTERNAL = 33841;
   */
  VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_DRYING_FUNCTION_POOL_ALLOCATION_INTERNAL = 33841,

  /**
   * @generated from enum value: VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_HCC_FUNCTION_POOL_ALLOCATION_INTERNAL = 33891;
   */
  VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_HCC_FUNCTION_POOL_ALLOCATION_INTERNAL = 33891,

  /**
   * @generated from enum value: VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_INTEGRATION_FUNCTION_POOL_ALLOCATION_INTERNAL = 33842;
   */
  VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_INTEGRATION_FUNCTION_POOL_ALLOCATION_INTERNAL = 33842,

  /**
   * @generated from enum value: VID_ROOM_CO2_CONTROLLER_BLOCKING = 33;
   */
  VID_ROOM_CO2_CONTROLLER_BLOCKING = 33,

  /**
   * @generated from enum value: VID_ROOM_CO2_CONTROLLER_CO2_WARNING_LEVEL = 32832;
   */
  VID_ROOM_CO2_CONTROLLER_CO2_WARNING_LEVEL = 32832,

  /**
   * @generated from enum value: VID_ROOM_CO2_CONTROLLER_STATE = 32;
   */
  VID_ROOM_CO2_CONTROLLER_STATE = 32,

  /**
   * @generated from enum value: VID_ROOM_CO2_CONTROLLER_WARNING_CO2_ABOVE_WARNING_LEVEL = 16392;
   */
  VID_ROOM_CO2_CONTROLLER_WARNING_CO2_ABOVE_WARNING_LEVEL = 16392,

  /**
   * @generated from enum value: VID_ROOM_CO2_LEVEL = 49202;
   */
  VID_ROOM_CO2_LEVEL = 49202,

  /**
   * @generated from enum value: VID_ROOM_CO2_WARNING_LEVEL = 51258;
   */
  VID_ROOM_CO2_WARNING_LEVEL = 51258,

  /**
   * @generated from enum value: VID_ROOM_COOPERATION = 51251;
   */
  VID_ROOM_COOPERATION = 51251,

  /**
   * @generated from enum value: VID_ROOM_DRYER_BLOCKING = 31;
   */
  VID_ROOM_DRYER_BLOCKING = 31,

  /**
   * @generated from enum value: VID_ROOM_DRYER_STATE = 30;
   */
  VID_ROOM_DRYER_STATE = 30,

  /**
   * @generated from enum value: VID_ROOM_FEATURES = 49191;
   */
  VID_ROOM_FEATURES = 49191,

  /**
   * @generated from enum value: VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_RADIATORS_ERROR_SUPPLIER_UNAVAILABLE = 16508;
   */
  VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_RADIATORS_ERROR_SUPPLIER_UNAVAILABLE = 16508,

  /**
   * @generated from enum value: VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_RADIATORS_SOURCE_DEMAND = 177;
   */
  VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_RADIATORS_SOURCE_DEMAND = 177,

  /**
   * @generated from enum value: VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_RADIATORS_SUPPLIER_OID = 33921;
   */
  VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_RADIATORS_SUPPLIER_OID = 33921,

  /**
   * @generated from enum value: VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_UFHC_ERROR_SUPPLIER_UNAVAILABLE = 16509;
   */
  VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_UFHC_ERROR_SUPPLIER_UNAVAILABLE = 16509,

  /**
   * @generated from enum value: VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_UFHC_SOURCE_DEMAND = 178;
   */
  VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_UFHC_SOURCE_DEMAND = 178,

  /**
   * @generated from enum value: VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_UFHC_SUPPLIER_OID = 33922;
   */
  VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_UFHC_SUPPLIER_OID = 33922,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_ALARM_HUMIDITY_HIGH = 16391;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_ALARM_HUMIDITY_HIGH = 16391,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_COOLING_DEW_POINT_THRESHOLD = 33856;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_COOLING_DEW_POINT_THRESHOLD = 33856,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_COOLING_DEW_POINT_THRESHOLD_HYSTERESIS = 33857;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_COOLING_DEW_POINT_THRESHOLD_HYSTERESIS = 33857,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_DRYING_OFFSET_COOLING = 33850;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_DRYING_OFFSET_COOLING = 33850,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_DRYING_OFFSET_HEATING = 33844;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_DRYING_OFFSET_HEATING = 33844,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_DRYING_OFFSET_HYSTERESIS = 33847;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_DRYING_OFFSET_HYSTERESIS = 33847,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_ALARM_HIGH = 32828;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_ALARM_HIGH = 32828,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_ALARM_HYSTERESIS = 34074;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_ALARM_HYSTERESIS = 34074,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_HYSTERESIS = 32827;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_HYSTERESIS = 32827,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_NIGHT_SET_BACK_ENABLE = 34101;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_NIGHT_SET_BACK_ENABLE = 34101,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_NIGHT_SET_BACK_OFFSET = 34034;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_NIGHT_SET_BACK_OFFSET = 34034,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_NIGHT_SET_BACK_TIME_INTERVAL = 34035;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_NIGHT_SET_BACK_TIME_INTERVAL = 34035,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_PROGRAMMABLE_OUTPUT_LEVEL = 118;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_PROGRAMMABLE_OUTPUT_LEVEL = 118,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_CONTROLLER_THERMOSTAT_HUMIDITY_ADJUSTMENT_ENABLED = 34031;
   */
  VID_ROOM_HUMIDITY_CONTROLLER_THERMOSTAT_HUMIDITY_ADJUSTMENT_ENABLED = 34031,

  /**
   * @generated from enum value: VID_ROOM_HUMIDITY_DESIRED = 32826;
   */
  VID_ROOM_HUMIDITY_DESIRED = 32826,

  /**
   * @generated from enum value: VID_ROOM_MVDI_DEVICE_ID = 49174;
   */
  VID_ROOM_MVDI_DEVICE_ID = 49174,

  /**
   * @generated from enum value: VID_ROOM_AGGREGATED_NON_AUTONOMOUS_SRT_AIR_TEMP = 173;
   */
  VID_ROOM_AGGREGATED_NON_AUTONOMOUS_SRT_AIR_TEMP = 173,

  /**
   * @generated from enum value: VID_ROOM_ALARM_NO_TEMPERATURE_SOURCE = 16409;
   */
  VID_ROOM_ALARM_NO_TEMPERATURE_SOURCE = 16409,

  /**
   * @generated from enum value: VID_ROOM_ALARM_OUTPUT_FAIL = 16411;
   */
  VID_ROOM_ALARM_OUTPUT_FAIL = 16411,

  /**
   * @generated from enum value: VID_ROOM_ALARM_OUTPUT_LOST = 16410;
   */
  VID_ROOM_ALARM_OUTPUT_LOST = 16410,

  /**
   * @generated from enum value: VID_ROOM_COMFORT_LEVEL_DEPRECATED = 60;
   */
  VID_ROOM_COMFORT_LEVEL_DEPRECATED = 60,

  /**
   * @generated from enum value: VID_ROOM_CONTROLLER_CONFIGURATION_MASK = 34036;
   */
  VID_ROOM_CONTROLLER_CONFIGURATION_MASK = 34036,

  /**
   * @generated from enum value: VID_ROOM_HOTEL_RESET_TIME = 32775;
   */
  VID_ROOM_HOTEL_RESET_TIME = 32775,

  /**
   * @generated from enum value: VID_ROOM_MODE_OVERRIDE = 32773;
   */
  VID_ROOM_MODE_OVERRIDE = 32773,

  /**
   * @generated from enum value: VID_ROOM_MODE_OVERRIDE_END_TIME = 32774;
   */
  VID_ROOM_MODE_OVERRIDE_END_TIME = 32774,

  /**
   * @generated from enum value: VID_ROOM_OUTPUT_LIST = 32911;
   */
  VID_ROOM_OUTPUT_LIST = 32911,

  /**
   * @generated from enum value: VID_ROOM_WARNING_PERIPHERAL_LOW_BATERY = 16399;
   */
  VID_ROOM_WARNING_PERIPHERAL_LOW_BATERY = 16399,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_AGGREGATED_SRT_RADIATOR_DEMAND = 105;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_AGGREGATED_SRT_RADIATOR_DEMAND = 105,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_AGGREGATED_SRT_UFHC_DEMAND = 187;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_AGGREGATED_SRT_UFHC_DEMAND = 187,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_DEW_POINT_BLOCKING_INTEGRATION_THRESHOLD = 33904;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_DEW_POINT_BLOCKING_INTEGRATION_THRESHOLD = 33904,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_FLOOR_TEMP_HYSTERESIS = 33920;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_FLOOR_TEMP_HYSTERESIS = 33920,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_FLOOR_TEMP_LIMIT_MIN = 32824;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_FLOOR_TEMP_LIMIT_MIN = 32824,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_TEMP_HYSTERESIS = 32820;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_TEMP_HYSTERESIS = 32820,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_TEMP_SET_ADJUSTED_OVERRIDE = 32819;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_TEMP_SET_ADJUSTED_OVERRIDE = 32819,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_TEMP_SET_MANUAL = 32813;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_TEMP_SET_MANUAL = 32813,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_TEMP_SET_PARTY_OVERRIDE = 32817;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_TEMP_SET_PARTY_OVERRIDE = 32817,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOPERATION_MODE = 33919;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOPERATION_MODE = 33919,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_COOPERATION_MODE_USED = 175;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_COOPERATION_MODE_USED = 175,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_ERROR_NO_UNDERFLOOR_OUTPUT = 16461;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_ERROR_NO_UNDERFLOOR_OUTPUT = 16461,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_BLOCKING = 157;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_BLOCKING = 157,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_PROPORTIONAL_LEVEL = 103;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_PROPORTIONAL_LEVEL = 103,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_SRT_CONTROL = 159;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_SRT_CONTROL = 159,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_STATE = 181;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_STATE = 181,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_VALVE_LEVEL = 56;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_VALVE_LEVEL = 56,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_ADAPTIVE_MODE_ENABLED = 32790;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_ADAPTIVE_MODE_ENABLED = 32790,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_ADAPTIVE_MODE_VALUES = 33923;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_ADAPTIVE_MODE_VALUES = 33923,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_COOPERATION_INCREASED_DEADBAND = 33918;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_COOPERATION_INCREASED_DEADBAND = 33918,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_COOPERATION_NORMAL_DEADBAND = 33917;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_COOPERATION_NORMAL_DEADBAND = 33917,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_HYSTERESIS = 32888;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_HYSTERESIS = 32888,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MAX = 32806;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MAX = 32806,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MAX_COMFORT = 32896;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MAX_COMFORT = 32896,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MAX_ECO = 32889;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MAX_ECO = 32889,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MAX_EXTRA_COMFORT = 32897;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MAX_EXTRA_COMFORT = 32897,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MIN = 32932;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MIN = 32932,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MIN_COMFORT = 32804;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MIN_COMFORT = 32804,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MIN_ECO = 32803;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MIN_ECO = 32803,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MIN_EXTRA_COMFORT = 32805;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MIN_EXTRA_COMFORT = 32805,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_TEMP_HYSTERESIS = 32802;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_TEMP_HYSTERESIS = 32802,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_TEMP_SET_ADJUSTED_OVERRIDE = 32800;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_TEMP_SET_ADJUSTED_OVERRIDE = 32800,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_TEMP_SET_MANUAL = 32794;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_TEMP_SET_MANUAL = 32794,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_TEMP_SET_PARTY_OVERRIDE = 32798;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_TEMP_SET_PARTY_OVERRIDE = 32798,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_MODE_OVERRIDE = 34095;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_MODE_OVERRIDE = 34095,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_PROPORTIONAL_BAND_OFFSET = 33080;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_PROPORTIONAL_BAND_OFFSET = 33080,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_PROPORTIONAL_LEVEL_BAND = 33064;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_PROPORTIONAL_LEVEL_BAND = 33064,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_PROPORTIONAL_OUTPUT_OFFSET = 33083;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_PROPORTIONAL_OUTPUT_OFFSET = 33083,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_BLOCKING = 180;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_BLOCKING = 180,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_PROPORTIONAL_LEVEL = 108;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_PROPORTIONAL_LEVEL = 108,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_SRT_CONTROL = 160;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_SRT_CONTROL = 160,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_STATE = 179;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_STATE = 179,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_VALVE_LEVEL = 107;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_VALVE_LEVEL = 107,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_TEMP_DESIRED_FLOOR = 158;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_TEMP_DESIRED_FLOOR = 158,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_WARNING_RADIATORS_HAVE_NO_SUPPLIER = 16463;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_WARNING_RADIATORS_HAVE_NO_SUPPLIER = 16463,

  /**
   * @generated from enum value: VID_ROOM_TEMPERATURE_CONTROLLER_WARNING_UFHC_HAS_NO_SUPPLIER = 16462;
   */
  VID_ROOM_TEMPERATURE_CONTROLLER_WARNING_UFHC_HAS_NO_SUPPLIER = 16462,

  /**
   * @generated from enum value: VID_ROOM_TEST_CLOUD_MODEL_VENTILATION_DEVICE_ID_0XC01B = 4095;
   */
  VID_ROOM_TEST_CLOUD_MODEL_VENTILATION_DEVICE_ID_0XC01B = 4095,

  /**
   * @generated from enum value: VID_ROOM_THERMAL_INTEGRATION_HYSTERESIS = 33846;
   */
  VID_ROOM_THERMAL_INTEGRATION_HYSTERESIS = 33846,

  /**
   * @generated from enum value: VID_ROOM_THERMAL_INTEGRATION_INTEGRATION_OFFSET_HEATING = 33845;
   */
  VID_ROOM_THERMAL_INTEGRATION_INTEGRATION_OFFSET_HEATING = 33845,

  /**
   * @generated from enum value: VID_ROOM_VENTILATION_DEVICE_ID = 49179;
   */
  VID_ROOM_VENTILATION_DEVICE_ID = 49179,

  /**
   * @generated from enum value: VID_ROOM_VOC_CONTROLLER_BLOCKING = 35;
   */
  VID_ROOM_VOC_CONTROLLER_BLOCKING = 35,

  /**
   * @generated from enum value: VID_ROOM_VOC_CONTROLLER_STATE = 34;
   */
  VID_ROOM_VOC_CONTROLLER_STATE = 34,

  /**
   * @generated from enum value: VID_ROOM_WARNING_CO2_ABOVE_WARNING_LEVEL = 50199;
   */
  VID_ROOM_WARNING_CO2_ABOVE_WARNING_LEVEL = 50199,

  /**
   * @generated from enum value: VID_SERVO_OUTPUT_DEADTIME = 33005;
   */
  VID_SERVO_OUTPUT_DEADTIME = 33005,

  /**
   * @generated from enum value: VID_SERVO_OUTPUT_REBOUND = 33004;
   */
  VID_SERVO_OUTPUT_REBOUND = 33004,

  /**
   * @generated from enum value: VID_SERVO_OUTPUT_REQUEST = 67;
   */
  VID_SERVO_OUTPUT_REQUEST = 67,

  /**
   * @generated from enum value: VID_SERVO_OUTPUT_RUNTIME = 33002;
   */
  VID_SERVO_OUTPUT_RUNTIME = 33002,

  /**
   * @generated from enum value: VID_SERVO_OUTPUT_TYPE = 33001;
   */
  VID_SERVO_OUTPUT_TYPE = 33001,

  /**
   * @generated from enum value: VID_SERVO_OUTPUT_WINDUP = 33003;
   */
  VID_SERVO_OUTPUT_WINDUP = 33003,

  /**
   * @generated from enum value: VID_TEST_SUPPORT_BUS_PACKET_TIME_BRANCH_SPECIFIC = 34014;
   */
  VID_TEST_SUPPORT_BUS_PACKET_TIME_BRANCH_SPECIFIC = 34014,

  /**
   * @generated from enum value: VID_THERMAL_INTEGRATION_ALLOW_MODE = 33836;
   */
  VID_THERMAL_INTEGRATION_ALLOW_MODE = 33836,

  /**
   * @generated from enum value: VID_THERMAL_INTEGRATION_BLOCKING = 151;
   */
  VID_THERMAL_INTEGRATION_BLOCKING = 151,

  /**
   * @generated from enum value: VID_THERMAL_INTEGRATION_CONSUMER_LIST = 33838;
   */
  VID_THERMAL_INTEGRATION_CONSUMER_LIST = 33838,

  /**
   * @generated from enum value: VID_THERMAL_INTEGRATION_CONTROLLER_REQUIRED_COOLING_WATER_TEMP = 33854;
   */
  VID_THERMAL_INTEGRATION_CONTROLLER_REQUIRED_COOLING_WATER_TEMP = 33854,

  /**
   * @generated from enum value: VID_THERMAL_INTEGRATION_CONTROLLER_REQUIRED_HEATING_WATER_TEMP = 33853;
   */
  VID_THERMAL_INTEGRATION_CONTROLLER_REQUIRED_HEATING_WATER_TEMP = 33853,

  /**
   * @generated from enum value: VID_THERMAL_INTEGRATION_DEMAND_CONDITION = 33837;
   */
  VID_THERMAL_INTEGRATION_DEMAND_CONDITION = 33837,

  /**
   * @generated from enum value: VID_THERMAL_INTEGRATION_DEMAND_ROOM_OID = 33932;
   */
  VID_THERMAL_INTEGRATION_DEMAND_ROOM_OID = 33932,

  /**
   * @generated from enum value: VID_THERMAL_INTEGRATION_SOURCE_DEMAND = 149;
   */
  VID_THERMAL_INTEGRATION_SOURCE_DEMAND = 149,

  /**
   * @generated from enum value: VID_THERMAL_INTEGRATION_SOURCE_STATUS = 150;
   */
  VID_THERMAL_INTEGRATION_SOURCE_STATUS = 150,

  /**
   * @generated from enum value: VID_THERMAL_INTEGRATION_SOURCE_SUPPLIER_OID = 33839;
   */
  VID_THERMAL_INTEGRATION_SOURCE_SUPPLIER_OID = 33839,

  /**
   * @generated from enum value: VID_THERMAL_INTEGRATION_STATE = 154;
   */
  VID_THERMAL_INTEGRATION_STATE = 154,

  /**
   * @generated from enum value: VID_THERMOHEAD_OUTPUT_FIRST_OPEN_PERFORMED = 33927;
   */
  VID_THERMOHEAD_OUTPUT_FIRST_OPEN_PERFORMED = 33927,

  /**
   * @generated from enum value: VID_THERMOHEAD_OUTPUT_LOAD_DETECTED = 176;
   */
  VID_THERMOHEAD_OUTPUT_LOAD_DETECTED = 176,

  /**
   * @generated from enum value: VID_THERMOHEAD_OUTPUT_LOAD_POWER = 190;
   */
  VID_THERMOHEAD_OUTPUT_LOAD_POWER = 190,

  /**
   * @generated from enum value: VID_THERMOHEAD_OUTPUT_LOAD_TYPE = 33916;
   */
  VID_THERMOHEAD_OUTPUT_LOAD_TYPE = 33916,

  /**
   * @generated from enum value: VID_THERMOHEAD_OUTPUT_WARN_NO_LOAD = 16496;
   */
  VID_THERMOHEAD_OUTPUT_WARN_NO_LOAD = 16496,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_EMUL_END_STATE = 33068;
   */
  VID_UPDATE_CONTROLLER_EMUL_END_STATE = 33068,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_EMUL_FORCE_CCU_UPDATE = 33079;
   */
  VID_UPDATE_CONTROLLER_EMUL_FORCE_CCU_UPDATE = 33079,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_EMUL_OFFLINE_DEVICES = 33078;
   */
  VID_UPDATE_CONTROLLER_EMUL_OFFLINE_DEVICES = 33078,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_EMUL_ONLINE_DEVICES = 33069;
   */
  VID_UPDATE_CONTROLLER_EMUL_ONLINE_DEVICES = 33069,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_NEXT_UPDATE_CHECK = 33084;
   */
  VID_UPDATE_CONTROLLER_NEXT_UPDATE_CHECK = 33084,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_REPORT_STATE_TIME = 33082;
   */
  VID_UPDATE_CONTROLLER_REPORT_STATE_TIME = 33082,

  /**
   * @generated from enum value: VID_UPDATE_CONTROLLER_WARNING_CRITICAL_UPDATE_AVAILABLE = 16428;
   */
  VID_UPDATE_CONTROLLER_WARNING_CRITICAL_UPDATE_AVAILABLE = 16428,

  /**
   * @generated from enum value: VID_USB_LOG_MASK = 34017;
   */
  VID_USB_LOG_MASK = 34017,

  /**
   * @generated from enum value: VID_USB_LOG_PERIOD = 34018;
   */
  VID_USB_LOG_PERIOD = 34018,

  /**
   * @generated from enum value: VID_USB_NEW_FIRMWARE_AVAILABLE = 272;
   */
  VID_USB_NEW_FIRMWARE_AVAILABLE = 272,

  /**
   * @generated from enum value: VID_USB_STATE = 259;
   */
  VID_USB_STATE = 259,

  /**
   * @generated from enum value: VID_VALVEHEAD_OUTPUT_DISCOVERED_FULLY_CLOSED_FULLY_OPENED_POSITIONS = 264;
   */
  VID_VALVEHEAD_OUTPUT_DISCOVERED_FULLY_CLOSED_FULLY_OPENED_POSITIONS = 264,

  /**
   * @generated from enum value: VID_VALVEHEAD_OUTPUT_DISCOVERED_WORKING_SPAN_DEPRECATED = 261;
   */
  VID_VALVEHEAD_OUTPUT_DISCOVERED_WORKING_SPAN_DEPRECATED = 261,

  /**
   * @generated from enum value: VID_VALVEHEAD_OUTPUT_OUTPUT_DEMAND = 106;
   */
  VID_VALVEHEAD_OUTPUT_OUTPUT_DEMAND = 106,

  /**
   * @generated from enum value: VID_VALVEHEAD_OUTPUT_OUTPUT_DEMAND_THRESHOLD = 33893;
   */
  VID_VALVEHEAD_OUTPUT_OUTPUT_DEMAND_THRESHOLD = 33893,

  /**
   * @generated from enum value: VID_VALVEHEAD_OUTPUT_WORKING_SPAN_PRESET_MM = 34013;
   */
  VID_VALVEHEAD_OUTPUT_WORKING_SPAN_PRESET_MM = 34013,

  /**
   * @generated from enum value: VID_VELUX_CONTROLLER_BLINDS_POSITION = 133;
   */
  VID_VELUX_CONTROLLER_BLINDS_POSITION = 133,

  /**
   * @generated from enum value: VID_VELUX_CONTROLLER_BLINDS_POSITION_REQUEST = 134;
   */
  VID_VELUX_CONTROLLER_BLINDS_POSITION_REQUEST = 134,

  /**
   * @generated from enum value: VID_VELUX_CONTROLLER_BLINDS_STATE = 132;
   */
  VID_VELUX_CONTROLLER_BLINDS_STATE = 132,

  /**
   * @generated from enum value: VID_VELUX_CONTROLLER_COMMAND = 128;
   */
  VID_VELUX_CONTROLLER_COMMAND = 128,

  /**
   * @generated from enum value: VID_VELUX_CONTROLLER_CONNECTION_IP_ADDRESS = 33100;
   */
  VID_VELUX_CONTROLLER_CONNECTION_IP_ADDRESS = 33100,

  /**
   * @generated from enum value: VID_VELUX_CONTROLLER_CONNECTION_PASSWORD = 33101;
   */
  VID_VELUX_CONTROLLER_CONNECTION_PASSWORD = 33101,

  /**
   * @generated from enum value: VID_VELUX_CONTROLLER_CONNECTION_STATE = 127;
   */
  VID_VELUX_CONTROLLER_CONNECTION_STATE = 127,

  /**
   * @generated from enum value: VID_VELUX_CONTROLLER_ROLLER_SHUTTER_POSITION = 130;
   */
  VID_VELUX_CONTROLLER_ROLLER_SHUTTER_POSITION = 130,

  /**
   * @generated from enum value: VID_VELUX_CONTROLLER_ROLLER_SHUTTER_POSITION_REQUEST = 131;
   */
  VID_VELUX_CONTROLLER_ROLLER_SHUTTER_POSITION_REQUEST = 131,

  /**
   * @generated from enum value: VID_VELUX_CONTROLLER_ROLLER_SHUTTER_STATE = 129;
   */
  VID_VELUX_CONTROLLER_ROLLER_SHUTTER_STATE = 129,

  /**
   * @generated from enum value: VID_VELUX_CONTROLLER_WINDOW_OPENER_POSITION = 136;
   */
  VID_VELUX_CONTROLLER_WINDOW_OPENER_POSITION = 136,

  /**
   * @generated from enum value: VID_VELUX_CONTROLLER_WINDOW_OPENER_POSITION_REQUEST = 137;
   */
  VID_VELUX_CONTROLLER_WINDOW_OPENER_POSITION_REQUEST = 137,

  /**
   * @generated from enum value: VID_VELUX_CONTROLLER_WINDOW_OPENER_STATE = 135;
   */
  VID_VELUX_CONTROLLER_WINDOW_OPENER_STATE = 135,
}
// Retrieve enum metadata with: proto3.getEnumType(VID)
proto3.util.setEnumType(VID, "wavin.sentio.v1.VID", [
  { no: 0, name: "VID_INVALID" },
  { no: 32912, name: "VID_OBJECT_NAME" },
  { no: 32925, name: "VID_OBJECT_INFO" },
  { no: 33849, name: "VID_OBJECT_STATUS" },
  { no: 32927, name: "VID_OBJECT_RUNTIME_VALUES" },
  { no: 32939, name: "VID_OBJECT_SERVICE_VALUES" },
  { no: 32942, name: "VID_OBJECT_PERIPHERAL_LIST" },
  { no: 12, name: "VID_INDOOR_AIR_TEMPERATURE" },
  { no: 13, name: "VID_INDOOR_FLOOR_TEMPERATURE" },
  { no: 2, name: "VID_INDOOR_HUMIDITY" },
  { no: 75, name: "VID_INDOOR_DEW_POINT" },
  { no: 3, name: "VID_INDOOR_VALUES_CO2_LEVEL" },
  { no: 4, name: "VID_INDOOR_VALUES_VOC_LEVEL" },
  { no: 54, name: "VID_OUTDOOR_TEMPERATURE" },
  { no: 40, name: "VID_OUTDOOR_TEMPERATURE_FILTERED" },
  { no: 94, name: "VID_OUTDOOR_TEMPERATURE_AVERAGE" },
  { no: 17, name: "VID_DOMESTIC_HOT_WATER_TEMPERATURE" },
  { no: 20, name: "VID_DOMESTIC_COLD_WATER_TEMPERATURE" },
  { no: 19, name: "VID_DOMESTIC_COLD_WATER_FLOW" },
  { no: 240, name: "VID_VENTILATION_SOURCE_DEMAND" },
  { no: 241, name: "VID_VENTILATION_SOURCE_STATUS" },
  { no: 245, name: "VID_VENTILATION_STATE" },
  { no: 33990, name: "VID_VENTILATION_CONSUMER_LIST" },
  { no: 33991, name: "VID_VENTILATION_SUPPLIER_OID" },
  { no: 153, name: "VID_DRYING_STATE" },
  { no: 32768, name: "VID_LOCATION_NAME" },
  { no: 32862, name: "VID_LOCATION_LANGUAGE" },
  { no: 32879, name: "VID_LOCATION_TIMEZONE" },
  { no: 32884, name: "VID_LOCATION_TIMEZONE_OFFSET" },
  { no: 32885, name: "VID_LOCATION_TIMEZONE_DST_ENABLED" },
  { no: 275, name: "VID_LOCATION_TIMEZONE_DST_UPCOMING" },
  { no: 46, name: "VID_LOCATION_LOCAL_TIME_OFFSET" },
  { no: 33926, name: "VID_LOCATION_COUNTRY" },
  { no: 32988, name: "VID_LOCATION_CONTROL_UNIT_OID" },
  { no: 32901, name: "VID_LOCATION_VACATION" },
  { no: 32902, name: "VID_LOCATION_VACATION_END_TIME" },
  { no: 32903, name: "VID_LOCATION_STANDBY" },
  { no: 32829, name: "VID_LOCATION_HC_MODE" },
  { no: 32886, name: "VID_LOCATION_HC_MODE_SWITCHING" },
  { no: 34015, name: "VID_LOCATION_HC_MODE_PRIORITY" },
  { no: 34016, name: "VID_LOCATION_HC_MODE_BMS_OVERRIDE" },
  { no: 33062, name: "VID_LOCATION_INSTALLER_PASSWORD" },
  { no: 126, name: "VID_LOCATION_SERVICE_KEY" },
  { no: 33076, name: "VID_LOCATION_SERVICE_KEY_ACCESS_ALLOWED" },
  { no: 250, name: "VID_LOCATION_GENERATE_TEMPORARY_PASSWORD" },
  { no: 253, name: "VID_LOCATION_TEMPORARY_PASSWORD" },
  { no: 254, name: "VID_LOCATION_TEMPORARY_PASSWORD_EXPIRATION" },
  { no: 331, name: "VID_LOCATION_USER_ENROLLMENT_ENABLED" },
  { no: 16413, name: "VID_LOCATION_ALARM_HEAT_PUMP_FAULT" },
  { no: 16452, name: "VID_LOCATION_ALARM_EXTERNAL_DEVICE_FAULT" },
  { no: 34099, name: "VID_LOCATION_TESTING_ENDPOINT" },
  { no: 32779, name: "VID_DEVICE_NAME" },
  { no: 47, name: "VID_DEVICE_STATE" },
  { no: 32944, name: "VID_DEVICE_OBJECT_LIST" },
  { no: 32780, name: "VID_DEVICE_SERIAL" },
  { no: 32991, name: "VID_DEVICE_SERIAL_PREFIX" },
  { no: 32992, name: "VID_DEVICE_BOOTLOADER_CODE" },
  { no: 32993, name: "VID_DEVICE_BOOTLOADER_VERSION" },
  { no: 32994, name: "VID_DEVICE_BOOTLOADER_VERSION_MINOR" },
  { no: 32995, name: "VID_DEVICE_HARDWARE_NAME" },
  { no: 32782, name: "VID_DEVICE_HARDWARE_VERSION" },
  { no: 32996, name: "VID_DEVICE_SOFTWARE_NAME" },
  { no: 32997, name: "VID_DEVICE_SOFTWARE_VARIANT" },
  { no: 32781, name: "VID_DEVICE_SOFTWARE_VERSION" },
  { no: 32926, name: "VID_DEVICE_SOFTWARE_VERSION_MINOR" },
  { no: 33077, name: "VID_DEVICE_SOFTWARE_VERSION_BRANCH" },
  { no: 33931, name: "VID_DEVICE_CONFIG_VERSION" },
  { no: 32871, name: "VID_DEVICE_UPDATE_CONTROL" },
  { no: 16387, name: "VID_DEVICE_ALARM_LOW_BATTERY" },
  { no: 332, name: "VID_DEVICE_UPDATE_PRESET" },
  { no: 29, name: "VID_ROOM_TEMP_DESIRED" },
  { no: 32772, name: "VID_ROOM_MODE" },
  { no: 32776, name: "VID_ROOM_THERMOSTAT_ACCESS_LEVEL" },
  { no: 32835, name: "VID_ROOM_WEEK_SCHEDULE" },
  { no: 33848, name: "VID_ROOM_ALLOW_COOLING" },
  { no: 32900, name: "VID_ROOM_ALLOW_VACATION_AWAY" },
  { no: 16400, name: "VID_ROOM_ALARM_PERIPHERAL_UNREACHABLE" },
  { no: 16427, name: "VID_ROOM_ALARM_FROST_PROTECTION" },
  { no: 16450, name: "VID_ROOM_ALARM_BATTERY_OPEN" },
  { no: 16451, name: "VID_ROOM_ALARM_BADLY_INSTALLED" },
  { no: 16460, name: "VID_ROOM_ALARM_STICKING_VALVE" },
  { no: 16386, name: "VID_ROOM_ALARM_GENERAL_PROBLEM" },
  { no: 32783, name: "VID_ROOM_TEMPERATURE_CONTROLLER_STANDBY" },
  { no: 32859, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HC_MODE" },
  { no: 27, name: "VID_ROOM_TEMPERATURE_CONTROLLER_STATE" },
  { no: 28, name: "VID_ROOM_TEMPERATURE_CONTROLLER_BLOCKING" },
  { no: 32784, name: "VID_ROOM_TEMPERATURE_CONTROLLER_TEMP_ALARM_LOW" },
  { no: 32785, name: "VID_ROOM_TEMPERATURE_CONTROLLER_TEMP_ALARM_HIGH" },
  { no: 33011, name: "VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_TEMP_ALARM_LOW" },
  { no: 33012, name: "VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_TEMP_ALARM_HIGH" },
  { no: 32788, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_REGULATED_VALUE_SRC" },
  { no: 32792, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SPAN_MIN" },
  { no: 32793, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SPAN_MAX" },
  { no: 32795, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_ECO" },
  { no: 32796, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_COMFORT" },
  { no: 32797, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_EXTRA_COMFORT" },
  { no: 32799, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_VACATION" },
  { no: 32801, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEAT_SET_STANDBY" },
  { no: 32811, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SPAN_MIN" },
  { no: 32812, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SPAN_MAX" },
  { no: 32814, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_ECO" },
  { no: 32815, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_COMFORT" },
  { no: 32816, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_EXTRA_COMFORT" },
  { no: 32818, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_VACATION" },
  { no: 32898, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOL_SET_STANDBY" },
  { no: 16389, name: "VID_ROOM_TEMPERATURE_CONTROLLER_ALARM_LOW_TEMP" },
  { no: 16390, name: "VID_ROOM_TEMPERATURE_CONTROLLER_ALARM_HIGH_TEMP" },
  { no: 16401, name: "VID_ROOM_TEMPERATURE_CONTROLLER_ALARM_LOW_FLOOR_TEMP" },
  { no: 16402, name: "VID_ROOM_TEMPERATURE_CONTROLLER_ALARM_HIGH_FLOOR_TEMP" },
  { no: 32899, name: "VID_ROOM_HUMIDITY_CONTROLLER_ENABLED" },
  { no: 34032, name: "VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_SPAN_MIN" },
  { no: 34033, name: "VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_SPAN_MAX" },
  { no: 33851, name: "VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_SETPOINT_HEAT" },
  { no: 33843, name: "VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_SETPOINT_COOL" },
  { no: 109, name: "VID_UPDATE_CONTROLLER_PROGRESS_DEVICE_IDX" },
  { no: 110, name: "VID_UPDATE_CONTROLLER_PROGRESS_DEVICE_PERCENT" },
  { no: 112, name: "VID_UPDATE_CONTROLLER_ONLINE_DEVICES" },
  { no: 113, name: "VID_UPDATE_CONTROLLER_OFFLINE_DEVICES" },
  { no: 33066, name: "VID_UPDATE_CONTROLLER_UPDATE_MODE" },
  { no: 33067, name: "VID_UPDATE_CONTROLLER_UPDATE_STATE" },
  { no: 33070, name: "VID_UPDATE_CONTROLLER_STORED_FIRMWARE_PACKAGE_VERSION" },
  { no: 33071, name: "VID_UPDATE_CONTROLLER_DEVICES_TO_UPDATE" },
  { no: 33072, name: "VID_UPDATE_CONTROLLER_UPDATE_ALL_TIME" },
  { no: 33073, name: "VID_UPDATE_CONTROLLER_UPDATE_CCU_TIME" },
  { no: 33074, name: "VID_UPDATE_CONTROLLER_UPDATE_CCU_TIMEOUT" },
  { no: 33075, name: "VID_UPDATE_CONTROLLER_INSTALLED_FIRMWARE_PACKAGE_VERSION" },
  { no: 330, name: "VID_UPDATE_CONTROLLER_PACKAGE_DOWNLOAD_REQUEST" },
  { no: 329, name: "VID_UPDATE_CONTROLLER_PACKAGE_DOWNLOAD_STATE" },
  { no: 328, name: "VID_UPDATE_CONTROLLER_UPDATE_START_REQUEST" },
  { no: 316, name: "VID_UPDATE_CONTROLLER_UPDATING_DEVICE_OID" },
  { no: 74, name: "VID_INPUT_VALUE" },
  { no: 76, name: "VID_OUTPUT_OUTPUT_VALUE" },
  { no: 32999, name: "VID_OUTPUT_FUNCTION" },
  { no: 33008, name: "VID_OUTPUT_MANUAL_VALUE" },
  { no: 32914, name: "VID_DHW_CALEFA_CONTROLLER_TEMP_SPAN_MIN" },
  { no: 32915, name: "VID_DHW_CALEFA_CONTROLLER_TEMP_SPAN_MAX" },
  { no: 32916, name: "VID_DHW_CALEFA_CONTROLLER_TEMP_SET" },
  { no: 16435, name: "VID_DHW_CALEFA_CONTROLLER_ALARM_CRITICAL_LOW_PRESSURE" },
  { no: 16436, name: "VID_DHW_CALEFA_CONTROLLER_ALARM_HIGH_PRESSURE" },
  { no: 16437, name: "VID_DHW_CALEFA_CONTROLLER_ALARM_LOW_PRESSURE" },
  { no: 16393, name: "VID_DHW_CALEFA_CONTROLLER_ALARM_LOW_ENERGY" },
  { no: 16394, name: "VID_DHW_CALEFA_CONTROLLER_ALARM_HIGH_TEMPERATURE" },
  { no: 16418, name: "VID_DHW_CALEFA_CONTROLLER_ALARM_DHI_SENSOR_FAILURE" },
  { no: 16419, name: "VID_DHW_CALEFA_CONTROLLER_ALARM_DHO_SENSOR_FAILURE" },
  { no: 16420, name: "VID_DHW_CALEFA_CONTROLLER_ALARM_DHW_SENSOR_FAILURE" },
  { no: 16421, name: "VID_DHW_CALEFA_CONTROLLER_ALARM_DCW_SENSOR_FAILURE" },
  { no: 16422, name: "VID_DHW_CALEFA_CONTROLLER_ALARM_MOTOR_FAILURE" },
  { no: 16499, name: "VID_DHW_CALEFA_CONTROLLER_ALARM_PRESSURE_SENSOR_FAILURE" },
  { no: 16502, name: "VID_DHW_CALEFA_CONTROLLER_ALARM_FLOW_SENSOR_FAILURE" },
  { no: 16395, name: "VID_DHW_CIRCULATION_CONTROLLER_ALARM_CIRCULATION_SENSOR_FAILURE" },
  { no: 16500, name: "VID_DHW_HEATING_CONTROLLER_ALARM_HEATING_MOTOR_FAILURE" },
  { no: 16510, name: "VID_DHW_HEATING_CONTROLLER_ALARM_HEATING_MOTOR_STUCK" },
  { no: 50192, name: "VID_DRYER_ALARM_GENERAL_FAULT" },
  { no: 50193, name: "VID_DRYER_ALARM_WARNING_MAINTENANCE" },
  { no: 16447, name: "VID_EXTERNAL_DEVICE_ALARM_CONNECTION_LOST" },
  { no: 16494, name: "VID_EXTERNAL_DEVICE_ALARM_DEVICE_SPECIFIC_ERROR" },
  { no: 16495, name: "VID_EXTERNAL_DEVICE_ALARM_DEVICE_SPECIFIC_WARNING" },
  { no: 16449, name: "VID_EXTERNAL_DEVICE_MVDI_ALARM_FILTER_REPLACEMENT_NEEDED" },
  { no: 257, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_FEATURES" },
  { no: 280, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_FEATURES_ENABLED" },
  { no: 33963, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SCHEDULER_ENABLED" },
  { no: 33965, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MANUAL_LEVEL" },
  { no: 33989, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MODE_OVERRIDE" },
  { no: 238, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MAINTENANCE_STATE" },
  { no: 239, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MAINTENANCE_REQUEST" },
  { no: 296, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_MAINTENANCE_END_TIME" },
  { no: 33830, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_AIR_FILTER_LIFETIME" },
  { no: 33831, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_AIR_FILTER_LIFETIME_USED" },
  { no: 33855, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_AIR_FILTER_LAST_CHANGED" },
  { no: 16497, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_ALARM_RESTART" },
  { no: 163, name: "VID_AGGREGATED_MVDI_PUMP_DEMAND" },
  { no: 89, name: "VID_AIR_CONDITIONING_DISTRIBUTION_BLOCKING" },
  { no: 33037, name: "VID_AIR_CONDITIONING_DISTRIBUTION_CONSUMER_LIST" },
  { no: 87, name: "VID_AIR_CONDITIONING_DISTRIBUTION_REQUIRED_LEVEL" },
  { no: 33038, name: "VID_AIR_CONDITIONING_DISTRIBUTION_SOURCE_SUPPLIER_OID" },
  { no: 33015, name: "VID_ANALOG_OUTPUT_HIGH_LEVEL_VOLTAGE" },
  { no: 33016, name: "VID_ANALOG_OUTPUT_LOW_LEVEL_VOLTAGE" },
  { no: 33039, name: "VID_ANALOG_OUTPUT_NO_DEMAND_VOLTAGE" },
  { no: 306, name: "VID_BROKER_DEBUG_WRITE_THROUGH_IN" },
  { no: 307, name: "VID_BROKER_DEBUG_WRITE_THROUGH_RESULT" },
  { no: 34076, name: "VID_BROKER_DEBUG_WRITE_THROUGH_TARGET" },
  { no: 237, name: "VID_BROKER_FLASH_REWRITE_COUNT" },
  { no: 305, name: "VID_BROKER_FLASH_STORE_TRIGGER_TIMESTAMP" },
  { no: 73, name: "VID_BROKER_USED_BROKER_CLIENTS" },
  { no: 71, name: "VID_BROKER_USED_BROKER_INDEX_QUEUE_PRIORITY_SLOTS" },
  { no: 70, name: "VID_BROKER_USED_BROKER_INDEX_QUEUE_SLOTS" },
  { no: 68, name: "VID_BROKER_USED_BROKER_PT_LINES" },
  { no: 69, name: "VID_BROKER_USED_BROKER_PT_MEMBLOCKS" },
  { no: 72, name: "VID_BROKER_USED_BROKER_SUBSCRIPTIONS" },
  { no: 247, name: "VID_CLOUD_BACKEND_PACKET_SENT_AVERAGE" },
  { no: 246, name: "VID_CLOUD_BACKEND_PACKET_SENT_STATISTICS" },
  { no: 33102, name: "VID_CLOUD_CLOUD_ADDRESS_OVERRIDE" },
  { no: 263, name: "VID_CLOUD_CLOUD_KEY_OVERRIDE" },
  { no: 16467, name: "VID_CLOUD_ROOM_ALARM_PERIPHERAL_UNREACHABLE_FILTERED" },
  { no: 16466, name: "VID_CLOUD_ROOM_ERROR_INCOMPATIBLE_PERIPHERAL" },
  { no: 34081, name: "VID_CO2_THRESHOLD_HIGH" },
  { no: 34079, name: "VID_CO2_THRESHOLD_LOW" },
  { no: 34080, name: "VID_CO2_THRESHOLD_MEDIUM" },
  { no: 95, name: "VID_COMMON_DEBUG_CHANNEL" },
  { no: 49201, name: "VID_DEVICE_DRYER_AIR_FILTER_LAST_CHANGE" },
  { no: 49176, name: "VID_DEVICE_DRYER_AIR_FILTER_LIFETIME_HOURS" },
  { no: 49178, name: "VID_DEVICE_DRYER_DEVICE_STATE" },
  { no: 49177, name: "VID_DEVICE_DRYER_FEATURES" },
  { no: 49175, name: "VID_DEVICE_DRYER_ID" },
  { no: 49199, name: "VID_DEVICE_DRYER_MAINTENANCE_REQUEST" },
  { no: 49198, name: "VID_DEVICE_DRYER_MAINTENANCE_STATE" },
  { no: 49200, name: "VID_DEVICE_DRYER_MAINTENANCE_STATE_TRANSITION_TIME" },
  { no: 51241, name: "VID_DEVICE_DRYER_NAME" },
  { no: 161, name: "VID_DEVICE_BASE_TEMPERATURE" },
  { no: 44, name: "VID_DEVICE_BATTERY_LEVEL" },
  { no: 302, name: "VID_DEVICE_CLIENT_BROKER_ROLE" },
  { no: 119, name: "VID_DEVICE_COMUNICATION_RELIABILITY_TEST_DATA" },
  { no: 33823, name: "VID_DEVICE_DEBUG_GROUP_MASK" },
  { no: 34077, name: "VID_DEVICE_DEBUG_MODIFIABLE_OVI_LIST" },
  { no: 34012, name: "VID_DEVICE_DEVICE_DESCRIPTOR_VERSION" },
  { no: 33022, name: "VID_DEVICE_DEVICE_METADATA" },
  { no: 102, name: "VID_DEVICE_DEVICE_SHUTDOWN" },
  { no: 258, name: "VID_DEVICE_DEVICE_SHUTDOWN_TIME" },
  { no: 16433, name: "VID_DEVICE_ERROR_EXTERNAL_SENSOR_FAIL" },
  { no: 16465, name: "VID_DEVICE_ERROR_INCOMPATIBLE_PERIPHERAL" },
  { no: 16501, name: "VID_DEVICE_ERROR_INTERNAL_FAILURE" },
  { no: 16426, name: "VID_DEVICE_ERROR_UNDERVOLTAGE_LOCKOUT" },
  { no: 32770, name: "VID_DEVICE_EXTERNAL_ANTENA" },
  { no: 274, name: "VID_DEVICE_INTERNAL_OVERHEAT_TIME" },
  { no: 267, name: "VID_DEVICE_INTERNAL_TEMPERATURE" },
  { no: 273, name: "VID_DEVICE_INTERNAL_TEMPERATURE_HISTOGRAM" },
  { no: 32769, name: "VID_DEVICE_MODBUS_ADDRESS" },
  { no: 32990, name: "VID_DEVICE_OWNER_ID" },
  { no: 34073, name: "VID_DEVICE_PC_TOOL_LIST" },
  { no: 300, name: "VID_DEVICE_PERSISTENT_PARAMETERS_COMMAND" },
  { no: 115, name: "VID_DEVICE_SIGNAL_STRENGTH_DEPRECATED" },
  { no: 117, name: "VID_DEVICE_SIGNAL_STRENGTH_RX_OBSERVED_BY_PERIPHERAL" },
  { no: 116, name: "VID_DEVICE_SIGNAL_STRENGTH_TX_OBSERVED_BY_CCU" },
  { no: 236, name: "VID_DEVICE_START_TIME" },
  { no: 186, name: "VID_DEVICE_STATUS_FILTER" },
  { no: 34071, name: "VID_DEVICE_TRANSFER_REQUEST_LIST" },
  { no: 34072, name: "VID_DEVICE_TRANSFER_STATUS_LIST" },
  { no: 308, name: "VID_DEVICE_UPTIME" },
  { no: 16385, name: "VID_DEVICE_WARNING_LONG_POWER_FAIL" },
  { no: 16388, name: "VID_DEVICE_WARNING_LOST_COMMUNICATION_WITH_PERIPHERY" },
  { no: 16384, name: "VID_DEVICE_WARNING_POWER_FAIL" },
  { no: 312, name: "VID_DEVICE_RT_RS_2XX_AIR_TEMP_SAMPLING_RATE_OVERRIDE" },
  { no: 33059, name: "VID_DEVICE_RT_RS_2XX_BRIGHTNESS_HIGH_LIMIT" },
  { no: 33058, name: "VID_DEVICE_RT_RS_2XX_BRIGHTNESS_LOW_LIMIT" },
  { no: 34096, name: "VID_DEVICE_RT_RS_2XX_CO2_ASC_SETTINGS" },
  { no: 327, name: "VID_DEVICE_RT_RS_2XX_CO2_LAST_CORRECTION_PPM" },
  { no: 34078, name: "VID_DEVICE_RT_RS_2XX_CO2_LED_INDICATION_SETUP" },
  { no: 326, name: "VID_DEVICE_RT_RS_2XX_CO2_MANUAL_TARGET_CONCENTRATION_PPM" },
  { no: 33055, name: "VID_DEVICE_RT_RS_2XX_CORRECTION_AIR_TEMP" },
  { no: 33056, name: "VID_DEVICE_RT_RS_2XX_CORRECTION_FLOOR_TEMP" },
  { no: 33057, name: "VID_DEVICE_RT_RS_2XX_CORRECTION_HUMIDITY" },
  { no: 309, name: "VID_DEVICE_RT_RS_2XX_HDC2080_HEATER_HOLD_TIME" },
  { no: 310, name: "VID_DEVICE_RT_RS_2XX_HDC2080_HEATER_SETPOINT" },
  { no: 313, name: "VID_DEVICE_RT_RS_2XX_HDC2080_HEATING_HISTOGRAM" },
  { no: 311, name: "VID_DEVICE_RT_RS_2XX_HDC2080_HEATING_RESULT" },
  { no: 34094, name: "VID_DEVICE_RT_RS_2XX_HDC2080_HUMIDITY_OFFSET_CORRECTION_LOG" },
  { no: 315, name: "VID_DEVICE_RT_RS_2XX_HDC2080_HUMIDITY_OFFSET_CORRECTION_SETTINGS" },
  { no: 32910, name: "VID_DEVICE_RT_RS_2XX_THERMOSTAT_LOCAL_LOCK" },
  { no: 33060, name: "VID_DEVICE_RT_RS_2XX_TOUCH_SENSITIVITY" },
  { no: 51249, name: "VID_DEVICE_VENTILATION_ACKNOWLEDGE_DEVICE_SPECIFIC_ERROR" },
  { no: 51250, name: "VID_DEVICE_VENTILATION_ACKNOWLEDGE_DEVICE_SPECIFIC_WARNING" },
  { no: 49196, name: "VID_DEVICE_VENTILATION_AIR_FILTER_LAST_CHANGE" },
  { no: 49187, name: "VID_DEVICE_VENTILATION_AIR_FILTER_LIFETIME_HOURS" },
  { no: 49184, name: "VID_DEVICE_VENTILATION_DEVICE_STATE" },
  { no: 50194, name: "VID_DEVICE_VENTILATION_ERROR_DEVICE_LOST" },
  { no: 50197, name: "VID_DEVICE_VENTILATION_ERROR_DEVICE_SPECIFIC" },
  { no: 49181, name: "VID_DEVICE_VENTILATION_FEATURES" },
  { no: 51248, name: "VID_DEVICE_VENTILATION_HEAT_RECOVERY_BYPASS_MODE" },
  { no: 49185, name: "VID_DEVICE_VENTILATION_HEAT_RECOVERY_BYPASS_STATE" },
  { no: 49180, name: "VID_DEVICE_VENTILATION_ID" },
  { no: 49197, name: "VID_DEVICE_VENTILATION_MAINTENANCE_REQUEST" },
  { no: 49192, name: "VID_DEVICE_VENTILATION_MAINTENANCE_STATE" },
  { no: 49194, name: "VID_DEVICE_VENTILATION_MAINTENANCE_STATE_TRANSITION_TIME" },
  { no: 51242, name: "VID_DEVICE_VENTILATION_NAME" },
  { no: 49203, name: "VID_DEVICE_VENTILATION_QUIET_HOURS_ACTIVE" },
  { no: 51259, name: "VID_DEVICE_VENTILATION_QUIET_HOURS_ENABLE" },
  { no: 51260, name: "VID_DEVICE_VENTILATION_QUIET_HOURS_INTERVAL" },
  { no: 49204, name: "VID_DEVICE_VENTILATION_QUIET_HOURS_LOWERED_LEVEL" },
  { no: 51256, name: "VID_DEVICE_VENTILATION_STATE_NAME_BOOST" },
  { no: 51255, name: "VID_DEVICE_VENTILATION_STATE_NAME_COMFORT" },
  { no: 51254, name: "VID_DEVICE_VENTILATION_STATE_NAME_ECO" },
  { no: 51252, name: "VID_DEVICE_VENTILATION_STATE_NAME_STOPPED" },
  { no: 51253, name: "VID_DEVICE_VENTILATION_STATE_NAME_UNOCCUPIED" },
  { no: 49186, name: "VID_DEVICE_VENTILATION_VACATION_IS_ALLOWED" },
  { no: 49182, name: "VID_DEVICE_VENTILATION_VENTILATION_BLOCKING" },
  { no: 51245, name: "VID_DEVICE_VENTILATION_VENTILATION_LEVEL" },
  { no: 51246, name: "VID_DEVICE_VENTILATION_VENTILATION_MODE_OVERRIDE" },
  { no: 49188, name: "VID_DEVICE_VENTILATION_VENTILATION_MODE_OVERRIDE_SOURCE" },
  { no: 51247, name: "VID_DEVICE_VENTILATION_VENTILATION_MODE_OVERRIDE_UNTIL" },
  { no: 51257, name: "VID_DEVICE_VENTILATION_VENTILATION_OVERRIDE" },
  { no: 51244, name: "VID_DEVICE_VENTILATION_VENTILATION_SCHEDULER" },
  { no: 51243, name: "VID_DEVICE_VENTILATION_VENTILATION_SCHEDULER_ENABLED" },
  { no: 49183, name: "VID_DEVICE_VENTILATION_VENTILATION_STATE" },
  { no: 50198, name: "VID_DEVICE_VENTILATION_WARNING_DEVICE_SPECIFIC" },
  { no: 50195, name: "VID_DEVICE_VENTILATION_WARNING_FILTER_MAINTENANCE" },
  { no: 50196, name: "VID_DEVICE_VENTILATION_WARNING_VENTILATION_RESTART" },
  { no: 16424, name: "VID_DEVICE_VH_250_ERROR_BADLY_INSTALLED" },
  { no: 16423, name: "VID_DEVICE_VH_250_ERROR_BATTERY_OPEN" },
  { no: 16459, name: "VID_DEVICE_VH_250_ERROR_VALVE_STICKED" },
  { no: 277, name: "VID_DHW_201_CONTROLLER_BACKUP_CHARGE_TIME" },
  { no: 32841, name: "VID_DHW_201_CONTROLLER_BOOST_SOURCE_PUMP_MODE" },
  { no: 52, name: "VID_DHW_201_CONTROLLER_BOOST_SOURCE_PUMP_STATE" },
  { no: 32849, name: "VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_DEFROST_MODE" },
  { no: 32850, name: "VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_DEFROST_TEMP_ECO" },
  { no: 32921, name: "VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_TEMP_SET_COMFORT" },
  { no: 32920, name: "VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_TEMP_SPAN_MAX" },
  { no: 32919, name: "VID_DHW_201_CONTROLLER_BYPASS_SOURCE_INLET_TEMP_SPAN_MIN" },
  { no: 34037, name: "VID_DHW_201_CONTROLLER_DYNAMIC_MODE" },
  { no: 34039, name: "VID_DHW_201_CONTROLLER_DYNAMIC_SETPOINT" },
  { no: 34038, name: "VID_DHW_201_CONTROLLER_DYNAMIC_WAIT_TIME" },
  { no: 32865, name: "VID_DHW_201_CONTROLLER_ENERGY_PRIORITY_MODE" },
  { no: 42, name: "VID_DHW_201_CONTROLLER_ENERGY_PRIORITY_STATE" },
  { no: 33933, name: "VID_DHW_201_CONTROLLER_FLOW_SENSOR_TYPE" },
  { no: 34040, name: "VID_DHW_201_CONTROLLER_HEX_FACTOR" },
  { no: 276, name: "VID_DHW_201_CONTROLLER_MOTOR_TEST_VALUE" },
  { no: 32924, name: "VID_DHW_201_CONTROLLER_POWER_CONSUMPTION_LIMIT" },
  { no: 265, name: "VID_DHW_201_CONTROLLER_REGULATOR_MODE" },
  { no: 43, name: "VID_DHW_201_CONTROLLER_SOURCE_VALVE_LEVEL" },
  { no: 34066, name: "VID_DHW_21X_CONTROLLER_ALLOW_HEATING_DURING_DHW_DEMAND" },
  { no: 16506, name: "VID_DHW_21X_CONTROLLER_ERROR_FROST_PROTECTION_ACTIVE" },
  { no: 16511, name: "VID_DHW_21X_CONTROLLER_ERROR_MOTOR_STUCK" },
  { no: 33097, name: "VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_COOLDOWN_TIME" },
  { no: 32846, name: "VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_PUMP_PRESENT" },
  { no: 48, name: "VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_RETURN_TEMP" },
  { no: 53, name: "VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_STATE" },
  { no: 33096, name: "VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_DIFFERENCE" },
  { no: 32848, name: "VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_HYSTERESIS" },
  { no: 32847, name: "VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_SET" },
  { no: 32923, name: "VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_SPAN_MAX" },
  { no: 32922, name: "VID_DHW_CIRCULATION_CONTROLLER_CIRCULATION_TEMP_SPAN_MIN" },
  { no: 268, name: "VID_DHW_HEATING_CONTROLLER_HEAT_VALVE_LEVEL" },
  { no: 278, name: "VID_DHW_HEATING_CONTROLLER_MOTOR_TEST_VALUE" },
  { no: 269, name: "VID_DHW_HEATING_CONTROLLER_REGULATOR_MODE" },
  { no: 279, name: "VID_DHW_HEATING_CONTROLLER_STATE" },
  { no: 33098, name: "VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_CONTROL" },
  { no: 32877, name: "VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_CONTROL_WRONG_TYPE" },
  { no: 16443, name: "VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_ERROR_SOURCE_RETURN_TEMP_SENSOR_FAIL" },
  { no: 16425, name: "VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_ERROR_SOURCE_RETURN_TEMP_SENSOR_FAIL_WRONG_TYPE" },
  { no: 120, name: "VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_SERVO_REQUEST" },
  { no: 32878, name: "VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_SOURCE_RETURN_TEMP_LIMIT" },
  { no: 16440, name: "VID_DHW_TANK_CONTROLLER_ADVANCED_TANK_WARNING_LOW_ENERGY" },
  { no: 33081, name: "VID_DHW_TANK_CONTROLLER_CLEANING_ENABLED" },
  { no: 33051, name: "VID_DHW_TANK_CONTROLLER_CLEANING_HOLD_TIME" },
  { no: 33050, name: "VID_DHW_TANK_CONTROLLER_CLEANING_INTERVAL" },
  { no: 33089, name: "VID_DHW_TANK_CONTROLLER_CLEANING_LAST" },
  { no: 33052, name: "VID_DHW_TANK_CONTROLLER_CLEANING_TIME_BEFORE_FAIL" },
  { no: 16417, name: "VID_DHW_TANK_CONTROLLER_CLEANING_WARNING_FAIL" },
  { no: 16439, name: "VID_DHW_TANK_CONTROLLER_ERROR_PERIPHERAL_UNREACHABLE" },
  { no: 16441, name: "VID_DHW_TANK_CONTROLLER_ERROR_SOURCE_INLET_SENSOR_FAIL" },
  { no: 16416, name: "VID_DHW_TANK_CONTROLLER_ERROR_TANK_SENSOR_FAIL" },
  { no: 122, name: "VID_DHW_TANK_CONTROLLER_SOURCE_VALVE_LEVEL" },
  { no: 97, name: "VID_DHW_TANK_CONTROLLER_TANK_TEMP" },
  { no: 98, name: "VID_DHW_TANK_CONTROLLER_TANK_TEMP_DESIRED" },
  { no: 33049, name: "VID_DHW_TANK_CONTROLLER_TANK_TEMP_HYSTERESIS" },
  { no: 33093, name: "VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET" },
  { no: 33048, name: "VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_CLEANING" },
  { no: 33045, name: "VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_COMFORT" },
  { no: 33044, name: "VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_ECO" },
  { no: 33046, name: "VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_STANDBY" },
  { no: 33047, name: "VID_DHW_TANK_CONTROLLER_TANK_TEMP_SET_VACATION" },
  { no: 33054, name: "VID_DHW_TANK_CONTROLLER_TANK_TEMP_SPAN_MAX" },
  { no: 33053, name: "VID_DHW_TANK_CONTROLLER_TANK_TEMP_SPAN_MIN" },
  { no: 16442, name: "VID_DHW_TANK_CONTROLLER_WARNING_LOW_SOURCE_TEMP" },
  { no: 16438, name: "VID_DHW_TANK_CONTROLLER_WARNING_PERIPHERAL_LOW_BATTERY" },
  { no: 33889, name: "VID_DIAGNOSTICS_SYSLOG_CHANGE_DESTINATION_IP" },
  { no: 33914, name: "VID_DIAGNOSTICS_SYSLOG_CHANGE_DESTINATION_PORT" },
  { no: 34097, name: "VID_DIAGNOSTICS_TELEMETRY_REPORTING_DESTINATION_IP" },
  { no: 34098, name: "VID_DIAGNOSTICS_TELEMETRY_REPORTING_DESTINATION_PORT" },
  { no: 33043, name: "VID_DOMESTIC_HOT_WATER_ALLOW_STANDBY" },
  { no: 32909, name: "VID_DOMESTIC_HOT_WATER_ALLOW_VACATION_AWAY" },
  { no: 37, name: "VID_DOMESTIC_HOT_WATER_BLOCKING_SOURCE" },
  { no: 33063, name: "VID_DOMESTIC_HOT_WATER_BLOCK_REQUEST_FROM_BMS" },
  { no: 32908, name: "VID_DOMESTIC_HOT_WATER_DEVICE_UI_ACCESS_LEVEL" },
  { no: 32905, name: "VID_DOMESTIC_HOT_WATER_MODE" },
  { no: 32907, name: "VID_DOMESTIC_HOT_WATER_SCHEDULE_ECO_COMFORT" },
  { no: 36, name: "VID_DOMESTIC_HOT_WATER_STATE" },
  { no: 16406, name: "VID_DOMESTIC_HOT_WATER_WARNING_GENERAL_PROBLEM" },
  { no: 148, name: "VID_DRYING_BLOCKING" },
  { no: 33833, name: "VID_DRYING_CONSUMER_LIST" },
  { no: 33835, name: "VID_DRYING_CONTROLLER_ALLOW_MODE" },
  { no: 16498, name: "VID_DRYING_CONTROLLER_MAINTENANCE_ENDS_SOON_WARNING" },
  { no: 33852, name: "VID_DRYING_CONTROLLER_REQUIRED_DRYING_COOLING_WATER_TEMP" },
  { no: 152, name: "VID_DRYING_SOURCE_DEMAND" },
  { no: 147, name: "VID_DRYING_SOURCE_STATUS" },
  { no: 33834, name: "VID_DRYING_SOURCE_SUPPLIER_OID" },
  { no: 184, name: "VID_ETHERNET_DEFAULT_GATEWAY" },
  { no: 174, name: "VID_ETHERNET_DHCP_LEASE_TIME" },
  { no: 182, name: "VID_ETHERNET_IP_ADDRESS" },
  { no: 33907, name: "VID_ETHERNET_IP_CONFIGURATION" },
  { no: 33906, name: "VID_ETHERNET_MAC_ADDRESS" },
  { no: 34009, name: "VID_ETHERNET_SERVICE_MASK" },
  { no: 33910, name: "VID_ETHERNET_STATIC_DEFAULT_GATEWAY" },
  { no: 33908, name: "VID_ETHERNET_STATIC_IP_ADDRESS" },
  { no: 33909, name: "VID_ETHERNET_STATIC_SUBNET_MASK" },
  { no: 183, name: "VID_ETHERNET_SUBNET_MASK" },
  { no: 229, name: "VID_EXTERNAL_DEVICE_DEVICE_SPECIFIC_ERROR_ACKNOWLEDGE" },
  { no: 230, name: "VID_EXTERNAL_DEVICE_DEVICE_SPECIFIC_WARNING_ACKNOWLEDGE" },
  { no: 16448, name: "VID_EXTERNAL_DEVICE_ERROR_HCW_SUPPLIER_NOT_SET" },
  { no: 33974, name: "VID_EXTERNAL_DEVICE_INTERFACE_MODBUS_SLAVE_ID" },
  { no: 33971, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_ALLOW_STOPPED_LEVEL" },
  { no: 34041, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_ALLOW_UNOCCUPIED_LEVEL" },
  { no: 34010, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_COOLING_INTAKE_AIR_TEMPERATURE_LIMIT" },
  { no: 34011, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_IDENTIFICATION_STRATEGY" },
  { no: 251, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_MANUFACTURER" },
  { no: 252, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_MODEL" },
  { no: 248, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DEVICE_STATE" },
  { no: 234, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_DISTRIBUTION_STATUS" },
  { no: 228, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_AIR_TEMPERATURE" },
  { no: 33962, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_BOOST_PERCENT" },
  { no: 33995, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_BOOST_M3" },
  { no: 33961, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_COMFORT_PERCENT" },
  { no: 33994, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_COMFORT_M3" },
  { no: 33960, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_ECO_PERCENT" },
  { no: 33993, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_ECO_M3" },
  { no: 33959, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_UNOCCUPIED_PERCENT" },
  { no: 33992, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_LEVEL_UNOCCUPIED_M3" },
  { no: 34043, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_MAXIMUM_SPEED_PERCENT" },
  { no: 34000, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_MAXIMUM_VOLUME_FLOW" },
  { no: 34042, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_MINIMUM_SPEED_PERCENT" },
  { no: 34002, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_MINIMUM_VOLUME_FLOW" },
  { no: 232, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_SPEED_PERCENT" },
  { no: 286, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_FAN_SPEED_RPM" },
  { no: 244, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXHAUST_VOLUME_FLOW_M3" },
  { no: 227, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_EXTRACT_AIR_TEMPERATURE" },
  { no: 249, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_FREE_COOLING_ENABLED" },
  { no: 235, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_HEAT_RECOVERY_BYPASS_DAMPER_POSITION_PERCENT" },
  { no: 33970, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_HEAT_RECOVERY_BYPASS_MODE" },
  { no: 225, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_INTAKE_AIR_TEMPERATURE" },
  { no: 304, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_QUIET_HOURS_ACTIVE" },
  { no: 34069, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_QUIET_HOURS_ENABLE" },
  { no: 34070, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_QUIET_HOURS_INTERVAL" },
  { no: 34008, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_BOOST" },
  { no: 34007, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_COMFORT" },
  { no: 34006, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_ECO" },
  { no: 34004, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_STOPPED" },
  { no: 34005, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_STATE_NAME_UNOCCUPIED" },
  { no: 226, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_AIR_TEMPERATURE" },
  { no: 33982, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_BOOST_PERCENT" },
  { no: 33999, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_BOOST_M3" },
  { no: 33981, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_COMFORT_PERCENT" },
  { no: 33998, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_COMFORT_M3" },
  { no: 33980, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_ECO_PERCENT" },
  { no: 33997, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_ECO_M3" },
  { no: 33979, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_UNOCCUPIED_PERCENT" },
  { no: 33996, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_LEVEL_UNOCCUPIED_M3" },
  { no: 34045, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_MAXIMUM_SPEED_PERCENT" },
  { no: 34001, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_MAXIMUM_VOLUME_FLOW" },
  { no: 34044, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_MINIMUM_SPEED_PERCENT" },
  { no: 34003, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_MINIMUM_VOLUME_FLOW" },
  { no: 231, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_SPEED_PERCENT" },
  { no: 285, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_FAN_SPEED_RPM" },
  { no: 243, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_SUPPLY_VOLUME_FLOW_M3" },
  { no: 33966, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_LEVEL_OVERRIDE" },
  { no: 33973, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_LEVEL_STANDBY" },
  { no: 33972, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_LEVEL_VACATION" },
  { no: 33967, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_MODE_OVERRIDE" },
  { no: 33968, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_MODE_OVERRIDE_SOURCE" },
  { no: 33969, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_MODE_OVERRIDE_UNTIL" },
  { no: 33964, name: "VID_EXTERNAL_DEVICE_MECHANICAL_VENTILATION_VENTILATION_SCHEDULER" },
  { no: 144, name: "VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_DRYING_DEMAND" },
  { no: 143, name: "VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_EXTA_DEMAND" },
  { no: 284, name: "VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_FAN_BOOST_DEMAND" },
  { no: 283, name: "VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_FAN_COMFORT_DEMAND" },
  { no: 282, name: "VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_FAN_ECO_DEMAND" },
  { no: 281, name: "VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_FAN_UNOCCUPIED_DEMAND" },
  { no: 233, name: "VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_HEAT_RECOVERY_BYPASS_DAMPER_DEMAND" },
  { no: 145, name: "VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_INTEGRATION_DEMAND" },
  { no: 142, name: "VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_PUMP_DEMAND" },
  { no: 146, name: "VID_EXTERNAL_DEVICE_OUTPUT_SIGNALS_VENTILATION_DEMAND" },
  { no: 33832, name: "VID_EXTERNAL_DEVICE_TYPE_SEE_OBJECT_INFO_SUBTYPE" },
  { no: 34030, name: "VID_GRAPHIC_INTERFACE_BACKLIGHT_BRIGHTNESS" },
  { no: 298, name: "VID_GRAPHIC_INTERFACE_BUTTON_PRESSED_IN_TEST_MODE" },
  { no: 297, name: "VID_GRAPHIC_INTERFACE_TEST_MODE_SCREEN" },
  { no: 16415, name: "VID_HCC_HEATING_CONTROLLER_HTCO_ALARM" },
  { no: 33911, name: "VID_HCC_HEATING_CONTROLLER_HTCO_INTEGRATION_THRESHOLD" },
  { no: 33017, name: "VID_HCC_HEATING_CONTROLLER_HTCO_MODE" },
  { no: 33014, name: "VID_HCC_HEATING_CONTROLLER_HTCO_TEMPERATURE" },
  { no: 33025, name: "VID_HCC_HEATING_CONTROLLER_HTCO_TEMPERATURE_RELATIVE" },
  { no: 33035, name: "VID_HCC_HEATING_CONTROLLER_OUTDOOR_TEMPERATURE_CUT_OFF" },
  { no: 83, name: "VID_HCC_HEATING_CONTROLLER_SOURCE_DEMAND" },
  { no: 33890, name: "VID_HCC_OBJECT_HEATING_COOLING_CONTROL_ASSOCIATED_PUMP" },
  { no: 34083, name: "VID_HCC_OBJECT_HEATING_COOLING_CONTROL_CONFIGURATION" },
  { no: 191, name: "VID_HCC_OBJECT_HEATING_COOLING_CONTROL_DESIRED_INLET_TEMP" },
  { no: 84, name: "VID_HCC_OBJECT_HEATING_COOLING_CONTROL_DESIRED_ROOM_TEMP_BY_CONSUMERS" },
  { no: 16414, name: "VID_HCC_OBJECT_HEATING_COOLING_CONTROL_ERROR_INLET_SENSOR_FAILURE" },
  { no: 33018, name: "VID_HCC_OBJECT_HEATING_COOLING_CONTROL_MODE" },
  { no: 123, name: "VID_HCC_OBJECT_HEATING_COOLING_CONTROL_PUMP_DEMAND" },
  { no: 85, name: "VID_HCC_OBJECT_HEATING_COOLING_CONTROL_PUMP_STATUS" },
  { no: 270, name: "VID_HCC_OBJECT_HEATING_COOLING_CONTROL_SERVO_REQUEST" },
  { no: 101, name: "VID_HCC_OBJECT_HEATING_COOLING_CONTROL_STATE" },
  { no: 34020, name: "VID_HEATING_COOLING_AUTO_SWITCHING_SAMPLES" },
  { no: 34025, name: "VID_HEATING_COOLING_COOLING_CONSECUTIVE_DAYS" },
  { no: 34026, name: "VID_HEATING_COOLING_COOLING_INTERMITTENT_DAYS" },
  { no: 34027, name: "VID_HEATING_COOLING_COOLING_INTERMITTENT_DAYS_PERIOD" },
  { no: 33023, name: "VID_HEATING_COOLING_COOLING_MINIMUM_OUTDOOR_TEMPERATURE" },
  { no: 34028, name: "VID_HEATING_COOLING_COOLING_PERIOD_LENGTH_HOURS" },
  { no: 32833, name: "VID_HEATING_COOLING_COOLING_SINCE" },
  { no: 33024, name: "VID_HEATING_COOLING_DEW_POINT_OFFSET" },
  { no: 34021, name: "VID_HEATING_COOLING_HEATING_CONSECUTIVE_DAYS" },
  { no: 33019, name: "VID_HEATING_COOLING_HEATING_COOLING_DEADBAND_TEMPERATURE" },
  { no: 34019, name: "VID_HEATING_COOLING_HEATING_COOLING_MODE_HW_SOURCE" },
  { no: 33013, name: "VID_HEATING_COOLING_HEATING_COOLING_MODE_LAST_TRANSITION" },
  { no: 33020, name: "VID_HEATING_COOLING_HEATING_COOLING_MODE_SWITCH_COOLDOWN" },
  { no: 34022, name: "VID_HEATING_COOLING_HEATING_INTERMITTENT_DAYS" },
  { no: 34023, name: "VID_HEATING_COOLING_HEATING_INTERMITTENT_DAYS_PERIOD" },
  { no: 33088, name: "VID_HEATING_COOLING_HEATING_MAXIMUM_OUTDOOR_TEMPERATURE" },
  { no: 34024, name: "VID_HEATING_COOLING_HEATING_PERIOD_LENGTH_HOURS" },
  { no: 32887, name: "VID_HEATING_COOLING_HEATING_SINCE" },
  { no: 34085, name: "VID_HEATING_COOLING_HOURS_WITHOUT_DEMAND_TO_CHANGE_OVER" },
  { no: 271, name: "VID_HEATING_COOLING_PERIOD_TIMER_START" },
  { no: 314, name: "VID_HEATING_COOLING_REFERENCE_ROOM_CHANGE_OVER_DEMAND" },
  { no: 34084, name: "VID_HEATING_COOLING_REFERENCE_ROOM_HISTOGRAM" },
  { no: 34086, name: "VID_HEATING_COOLING_REFERENCE_ROOM_HISTOGRAM_COUNTER_PERIOD_SECONDS" },
  { no: 34082, name: "VID_HEATING_COOLING_REFERENCE_ROOM_OID" },
  { no: 88, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_BLOCKING" },
  { no: 33092, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_CONSUMER_LIST" },
  { no: 16507, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_ERROR_SUPPLIER_UNAVAILABLE" },
  { no: 322, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_FLOW_RATE" },
  { no: 25, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_INLET_TEMP" },
  { no: 33888, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_INLET_TEMP_REFERENCE" },
  { no: 23, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_PRESSURE" },
  { no: 26, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_RETURN_TEMP" },
  { no: 33896, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_RETURN_TEMP_REFERENCE" },
  { no: 32952, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_ROOM_LIST" },
  { no: 333, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_CAPABILITIES_MASK" },
  { no: 168, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_DEMAND" },
  { no: 104, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_DEMAND_DEPRECATED" },
  { no: 21, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_INLET_TEMP" },
  { no: 22, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_RETURN_TEMP" },
  { no: 169, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_STATUS" },
  { no: 33036, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_SOURCE_SUPPLIER_OID" },
  { no: 164, name: "VID_HEATING_COOLING_WATER_DISTRIBUTION_STATUS" },
  { no: 33031, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_DESIGN_INLET_TEMP" },
  { no: 33033, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_DESIGN_OUTDOOR_TEMP" },
  { no: 33032, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_DESIGN_ROOM_TEMP" },
  { no: 33027, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_INLET_TEMP_MAX" },
  { no: 33026, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_INLET_TEMP_MIN" },
  { no: 33029, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_MANUAL" },
  { no: 33034, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_NO_THERMOSTAT_GAIN" },
  { no: 33030, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_PARALEL_DISPLACEMENT" },
  { no: 34090, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_RADIATOR_INLET_TEMP_MAX_DEFAULT" },
  { no: 34089, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_RADIATOR_INLET_TEMP_MIN_DEFAULT" },
  { no: 34088, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_RADIATOR_SLOPE_DEFAULT" },
  { no: 86, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_SLOPE" },
  { no: 33028, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_TYPE" },
  { no: 34093, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_UNDERFLOOR_INLET_TEMP_MAX_DEFAULT" },
  { no: 34092, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_UNDERFLOOR_INLET_TEMP_MIN_DEFAULT" },
  { no: 34091, name: "VID_HEATING_COOLING_WATER_HEAT_CURVE_UNDERFLOOR_SLOPE_DEFAULT" },
  { no: 34054, name: "VID_HEATING_COOLING_WATER_SOURCE_COOLING_DESIRED_INLET_TEMPERATURE" },
  { no: 299, name: "VID_HEATING_COOLING_WATER_SOURCE_DEMAND_LIMITER" },
  { no: 165, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ANALOG_DEMAND" },
  { no: 34058, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ANALOG_DEMAND_MODE" },
  { no: 166, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BINARY_DEMAND" },
  { no: 34068, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK" },
  { no: 301, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_CHARGE_DEMAND" },
  { no: 34067, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_CHARGE_MODE" },
  { no: 34049, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_CHARGE_PRIORITY_TEMPERATURE_SENSOR" },
  { no: 16504, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_ERROR_TANK_LOWER_TEMPERATURE_SENSOR_ERROR" },
  { no: 16505, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_ERROR_TANK_PRIORITY_TEMPERATURE_SENSOR_ERROR" },
  { no: 16503, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_ERROR_TANK_UPPER_TEMPERATURE_SENSOR_ERROR" },
  { no: 34059, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_ENABLE" },
  { no: 34063, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_I_CAP" },
  { no: 34061, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_I_COEFFICIENT" },
  { no: 34062, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_I_INTERVAL" },
  { no: 34065, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_PROTECTION_INTERVAL_SECONDS" },
  { no: 34060, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_P_COEFFICIENT" },
  { no: 34064, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_FLOW_BALANCING_RESET_INTERVAL_SECONDS" },
  { no: 34047, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_START_CHARGING_DIFFERENCE_K" },
  { no: 34048, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_STOP_CHARGING_DIFFERENCE_K" },
  { no: 290, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_TANK_LOWER_TEMP" },
  { no: 34052, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_TANK_LOWER_TEMP_REFERENCE" },
  { no: 289, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_TANK_UPPER_TEMP" },
  { no: 34051, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_BUFFER_TANK_TANK_UPPER_TEMP_REFERENCE" },
  { no: 33872, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_NO_DEMAND_THRESHOLD" },
  { no: 33869, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_P1_TEMPERATURE" },
  { no: 33868, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_P1_VOLTAGE" },
  { no: 33871, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_P2_TEMPERATURE" },
  { no: 33870, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_ANALOG_CURVE_P2_VOLTAGE" },
  { no: 288, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_COOLING_DEMAND_BINARY" },
  { no: 33873, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_DEMAND_COOLDOWN_TIME_MINUTES" },
  { no: 34046, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_DEMAND_MINIMUM_TIME_MINUTES" },
  { no: 334, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ELEMENT_CAPABILITIES_MASK" },
  { no: 292, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ELEMENT_REQUEST_IN" },
  { no: 293, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ELEMENT_STATUS_OUT" },
  { no: 16456, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ERROR_SOURCE_FAILURE" },
  { no: 16457, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_ERROR_SOURCE_GENERAL_FAILURE" },
  { no: 33866, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_NO_DEMAND_THRESHOLD" },
  { no: 33863, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_P1_TEMPERATURE" },
  { no: 33862, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_P1_VOLTAGE" },
  { no: 33865, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_P2_TEMPERATURE" },
  { no: 33864, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_ANALOG_CURVE_P2_VOLTAGE" },
  { no: 287, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEATING_DEMAND_BINARY" },
  { no: 317, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_COMPRESSOR_STATUS" },
  { no: 318, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_DEFROSTING_STATUS" },
  { no: 324, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_DHW_HEAT_STATUS" },
  { no: 320, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_HEATER_OUTLET_TEMPERATURE" },
  { no: 321, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_HEAT_PUMP_OUTLET_TEMPERATURE" },
  { no: 319, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_OUTDOOR_UNIT_STATUS" },
  { no: 323, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_REFRIGERANT_TEMPERATURE" },
  { no: 325, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_HEAT_PUMP_SILENT_MODE_STATUS" },
  { no: 170, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_LAST_ACTIVITY" },
  { no: 34050, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_PRIORITY_COST" },
  { no: 33876, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_RELEASE_OUTDOOR_TEMPERATURE" },
  { no: 167, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_SOURCE_RELEASE" },
  { no: 33874, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_SOURCE_START_DELAY_MINUTES" },
  { no: 33875, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_SOURCE_STOP_DELAY_MINUTES" },
  { no: 33877, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_UN_RELEASE_OUTDOOR_TEMPERATURE" },
  { no: 34056, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_VOLTAGE_COOLING_DEMAND" },
  { no: 34055, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_VOLTAGE_HEATING_DEMAND" },
  { no: 34057, name: "VID_HEATING_COOLING_WATER_SOURCE_ELEMENT_VOLTAGE_NO_DEMAND" },
  { no: 16455, name: "VID_HEATING_COOLING_WATER_SOURCE_ERROR_INLET_TEMPERATURE_SENSOR_FAILURE" },
  { no: 34053, name: "VID_HEATING_COOLING_WATER_SOURCE_HEATING_DESIRED_INLET_TEMPERATURE" },
  { no: 33878, name: "VID_HEATING_COOLING_WATER_SOURCE_INLET_TEMPERATURE_SOURCE" },
  { no: 33867, name: "VID_HEATING_COOLING_WATER_SOURCE_SOURCE_ELEMENT_LIST" },
  { no: 33021, name: "VID_INPUT_OUTPUT_OBJECT_MODE" },
  { no: 171, name: "VID_INPUT_OWNER_OID_DEPRECATED" },
  { no: 295, name: "VID_INPUT_OWNER_REFERENCE" },
  { no: 291, name: "VID_INPUT_VALUE_OVERRIDE_NON_PERSISTENT" },
  { no: 33094, name: "VID_INPUT_VALUE_OVERRIDE_PERSISTENT" },
  { no: 16412, name: "VID_INTERFACE_ERROR_POWER_SHORTED" },
  { no: 33902, name: "VID_ITC_COOLING_CONTROLLER_COOLING_INLET_TEMP_MAX" },
  { no: 33901, name: "VID_ITC_COOLING_CONTROLLER_COOLING_INLET_TEMP_MIN" },
  { no: 33900, name: "VID_ITC_COOLING_CONTROLLER_COOLING_REGULATOR_HYSTERESIS" },
  { no: 33899, name: "VID_ITC_COOLING_CONTROLLER_COOLING_REGULATOR_I_TIME" },
  { no: 33898, name: "VID_ITC_COOLING_CONTROLLER_COOLING_REGULATOR_P_GAIN" },
  { no: 58, name: "VID_ITC_COOLING_CONTROLLER_DESIRED_SOURCE_WATER_TEMPERATURE" },
  { no: 32982, name: "VID_ITC_COOLING_CONTROLLER_FROST_PROTECTION_TEMP" },
  { no: 32963, name: "VID_ITC_COOLING_CONTROLLER_HOTC_TEMP" },
  { no: 32869, name: "VID_ITC_COOLING_CONTROLLER_ITC_AUTO_REDUCTION_MODE" },
  { no: 32989, name: "VID_ITC_COOLING_CONTROLLER_ITC_AUTO_REDUCTION_TEMP" },
  { no: 32986, name: "VID_ITC_COOLING_CONTROLLER_ITC_OUTDOOR_FILTER_SUPPRESSION" },
  { no: 32866, name: "VID_ITC_COOLING_CONTROLLER_ITC_TEMP_SET_MANUAL" },
  { no: 32853, name: "VID_ITC_COOLING_CONTROLLER_MODE" },
  { no: 32953, name: "VID_ITC_COOLING_CONTROLLER_PUMP_ACTIVATION_MODE" },
  { no: 32955, name: "VID_ITC_COOLING_CONTROLLER_PUMP_ACTIVATION_TIME" },
  { no: 32956, name: "VID_ITC_COOLING_CONTROLLER_PUMP_DELAYED_START" },
  { no: 32957, name: "VID_ITC_COOLING_CONTROLLER_PUMP_DELAYED_STOP" },
  { no: 32954, name: "VID_ITC_COOLING_CONTROLLER_PUMP_IDDLE_TIME" },
  { no: 33903, name: "VID_ITC_COOLING_CONTROLLER_REQUIRED_COOL_SOURCE_TEMP_OFFSET" },
  { no: 32960, name: "VID_ITC_COOLING_CONTROLLER_VALVE_ACTIVATION_CYCLES" },
  { no: 32959, name: "VID_ITC_COOLING_CONTROLLER_VALVE_ACTIVATION_IDDLE_TIME" },
  { no: 32958, name: "VID_ITC_COOLING_CONTROLLER_VALVE_ACTIVATION_MODE" },
  { no: 64, name: "VID_ITC_COOLING_CONTROLLER_VALVE_STATUS" },
  { no: 16397, name: "VID_ITC_COOLING_CONTROLLER_WARNING_RETENTIVE_LOW_ENERGY_FOR_COOLING" },
  { no: 16396, name: "VID_ITC_COOLING_CONTROLLER_WARNING_RETENTIVE_LOW_ENERGY_FOR_HEATING" },
  { no: 32965, name: "VID_ITC_HEATING_CONTROLLER_CURVE_DESIGN_INLET_TEMP" },
  { no: 32967, name: "VID_ITC_HEATING_CONTROLLER_CURVE_DESIGN_OUTDOOR_TEMP" },
  { no: 32966, name: "VID_ITC_HEATING_CONTROLLER_CURVE_DESIGN_ROOM_TEMP" },
  { no: 32868, name: "VID_ITC_HEATING_CONTROLLER_CURVE_INLET_TEMP_MAX" },
  { no: 32867, name: "VID_ITC_HEATING_CONTROLLER_CURVE_INLET_TEMP_MIN" },
  { no: 32969, name: "VID_ITC_HEATING_CONTROLLER_CURVE_MANUAL" },
  { no: 32964, name: "VID_ITC_HEATING_CONTROLLER_CURVE_NO_THERMOSTAT_GAIN" },
  { no: 32870, name: "VID_ITC_HEATING_CONTROLLER_CURVE_PARALEL_DISPLACEMENT" },
  { no: 61, name: "VID_ITC_HEATING_CONTROLLER_CURVE_SLOPE" },
  { no: 32968, name: "VID_ITC_HEATING_CONTROLLER_CURVE_TYPE" },
  { no: 32981, name: "VID_ITC_HEATING_CONTROLLER_FROST_PROTECTION_MODE" },
  { no: 16398, name: "VID_ITC_HEATING_CONTROLLER_HTCO_ALARM" },
  { no: 33905, name: "VID_ITC_HEATING_CONTROLLER_HTCO_INTEGRATION_THRESHOLD" },
  { no: 32855, name: "VID_ITC_HEATING_CONTROLLER_HTCO_MODE" },
  { no: 32857, name: "VID_ITC_HEATING_CONTROLLER_HTCO_TEMP" },
  { no: 32976, name: "VID_ITC_HEATING_CONTROLLER_OPTIMIZATION_BOOST_LEVEL" },
  { no: 32975, name: "VID_ITC_HEATING_CONTROLLER_OPTIMIZATION_BOOST_MODE" },
  { no: 32977, name: "VID_ITC_HEATING_CONTROLLER_OPTIMIZATION_RAMPING_MODE" },
  { no: 32978, name: "VID_ITC_HEATING_CONTROLLER_OPTIMIZATION_RAMPING_TIME_MINUTES" },
  { no: 32985, name: "VID_ITC_HEATING_CONTROLLER_REGULATOR_HYSTERESIS" },
  { no: 32984, name: "VID_ITC_HEATING_CONTROLLER_REGULATOR_I_TIME" },
  { no: 32863, name: "VID_ITC_HEATING_CONTROLLER_REGULATOR_MODE" },
  { no: 32983, name: "VID_ITC_HEATING_CONTROLLER_REGULATOR_P_GAIN" },
  { no: 32962, name: "VID_ITC_HEATING_CONTROLLER_REQUIRED_HEAT_SOURCE_TEMP_OFFSET" },
  { no: 32970, name: "VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_LIMITER_MODE" },
  { no: 32973, name: "VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MAX_LIMITER_GAIN" },
  { no: 32971, name: "VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MAX_LIMITER_LIMIT" },
  { no: 32987, name: "VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MAX_LIMITER_PRIORITY" },
  { no: 32974, name: "VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MIN_LIMITER_GAIN" },
  { no: 32972, name: "VID_ITC_HEATING_CONTROLLER_RETURN_TEMP_MIN_LIMITER_LIMIT" },
  { no: 32979, name: "VID_ITC_HEATING_CONTROLLER_ROOM_CORRECTION_MAX_GAIN" },
  { no: 32980, name: "VID_ITC_HEATING_CONTROLLER_ROOM_CORRECTION_MIN_GAIN" },
  { no: 33897, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_ASSOCIATED_PUMP_OUTPUT" },
  { no: 59, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_DESIRED_INLET_TEMP" },
  { no: 41, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_DESIRED_ROOM_TEMP_BY_CONSUMERS" },
  { no: 16434, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_FROST_PROTECTION_ACTIVE" },
  { no: 16429, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_INLET_SENSOR_FAILURE" },
  { no: 16432, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_OUTDOOR_SENSOR_FAILURE" },
  { no: 16407, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_REGULATOR_FAILURE" },
  { no: 16431, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_RETURN_SENSOR_FAILURE" },
  { no: 16430, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_ERROR_SERVO_FAILURE" },
  { no: 32854, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_HEATING_COOLING_MODE" },
  { no: 32961, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_HEATING_SERIAL_MODE" },
  { no: 62, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_INLET_TEMP" },
  { no: 33895, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_INLET_TEMP_REFERENCE" },
  { no: 124, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_PUMP_DEMAND" },
  { no: 65, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_PUMP_STATUS" },
  { no: 63, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_RETURN_TEMP" },
  { no: 91, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_SERVO_REQUEST" },
  { no: 57, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_SOURCE_DEMAND" },
  { no: 16408, name: "VID_ITC_OBJECT_INLET_TEMP_CONTROL_WARNING_REGULATOR_FAILURE" },
  { no: 33095, name: "VID_LOCATION_ALLOW_TEST_SUPPORT_MODE" },
  { no: 33091, name: "VID_LOCATION_ALLOW_UNDER_DEVELOPMENT_FEATURES" },
  { no: 81, name: "VID_LOCATION_BACKUP" },
  { no: 256, name: "VID_LOCATION_BEHAVIOUR_VARIANT" },
  { no: 66, name: "VID_LOCATION_COMMAND" },
  { no: 33930, name: "VID_LOCATION_DEBUG_LEVEL" },
  { no: 255, name: "VID_LOCATION_DEFAULT_LANGUAGE" },
  { no: 18, name: "VID_LOCATION_EMULATE_FAIL" },
  { no: 33929, name: "VID_LOCATION_EXERCISING_LAST_ACTIVATION" },
  { no: 33928, name: "VID_LOCATION_EXERCISING_SCHEDULE" },
  { no: 189, name: "VID_LOCATION_EXERCISING_SCHEDULE_OVERRIDE" },
  { no: 242, name: "VID_LOCATION_EXERCISING_SCHEDULE_OVERRIDE_SETUP" },
  { no: 33086, name: "VID_LOCATION_FROST_PROTECTION_INLET_TEMPERATURE_THRESHOLD" },
  { no: 33087, name: "VID_LOCATION_FROST_PROTECTION_OUTDOOR_TEMPERATURE_THRESHOLD" },
  { no: 33085, name: "VID_LOCATION_FROST_PROTECTION_ROOM_TEMPERATURE_THRESHOLD" },
  { no: 34100, name: "VID_LOCATION_GLOBAL_SILENT_MODE" },
  { no: 45, name: "VID_LOCATION_GSM_LOCATION" },
  { no: 33000, name: "VID_LOCATION_HARDWARE_PROFILE" },
  { no: 32940, name: "VID_LOCATION_LEARN_DESTINATION_OID" },
  { no: 32941, name: "VID_LOCATION_LEARN_PERIPH_OID" },
  { no: 32881, name: "VID_LOCATION_LICENSE" },
  { no: 32882, name: "VID_LOCATION_LICENSE_END" },
  { no: 33006, name: "VID_LOCATION_NEW_HARDWARE_PROFILE" },
  { no: 34029, name: "VID_LOCATION_OUTDOOR_TEMPERATURE_FILTER_SETTINGS" },
  { no: 33894, name: "VID_LOCATION_OUTDOOR_TEMPERATURE_SOURCE" },
  { no: 33007, name: "VID_LOCATION_OUTDOOR_TEMPERATURE_SOURCE_DEPRECATED" },
  { no: 32998, name: "VID_LOCATION_RECYCLE_BIN" },
  { no: 33861, name: "VID_LOCATION_REPUBLISH_PERIOD_TIME" },
  { no: 82, name: "VID_LOCATION_RESTORE" },
  { no: 111, name: "VID_LOCATION_SERVICE_KEY_WRITE_ONLY_DEPRECATED" },
  { no: 33061, name: "VID_LOCATION_SERVICE_MODE_END_TIME" },
  { no: 33010, name: "VID_LOCATION_STRUCTURE_ID" },
  { no: 32864, name: "VID_LOCATION_SUPPORT_MODE" },
  { no: 16403, name: "VID_LOCATION_WARNING_DEV_SERVICE" },
  { no: 16404, name: "VID_LOCATION_WARNING_FAULT" },
  { no: 16464, name: "VID_LOCATION_WARN_MAINTENANCE" },
  { no: 32823, name: "VID_MODBUS_MODBUS_BAUDRATE" },
  { no: 32821, name: "VID_MODBUS_MODBUS_MODE" },
  { no: 33824, name: "VID_MODBUS_MODBUS_PARITY" },
  { no: 32822, name: "VID_MODBUS_MODBUS_SLAVE_ADDR" },
  { no: 33099, name: "VID_MODBUS_MODBUS_SLAVE_PASSWORD" },
  { no: 33825, name: "VID_MODBUS_MODBUS_STOP_BITS" },
  { no: 185, name: "VID_NETWORKING_DNS_IP_ADDRESS" },
  { no: 114, name: "VID_NETWORKING_IP_ADDRESS_DEPRECATED" },
  { no: 172, name: "VID_NETWORKING_STATE" },
  { no: 33912, name: "VID_NETWORKING_STATIC_DNS_IP_ADDRESS" },
  { no: 266, name: "VID_OPENTHERM_HEATING_REQUEST" },
  { no: 260, name: "VID_OPENTHERM_STATE" },
  { no: 16444, name: "VID_OUTDOOR_ZONE_ERROR_GENERAL_PROBLEM" },
  { no: 33090, name: "VID_OUTDOOR_ZONE_OUTDOOR_TEMPERATURE_BMS_OVERRIDE" },
  { no: 16445, name: "VID_OUTDOOR_ZONE_WARNING_PERIPHERAL_LOW_BATERY" },
  { no: 16446, name: "VID_OUTDOOR_ZONE_WARNING_PERIPHERAL_UNREACHABLE" },
  { no: 16405, name: "VID_OUTPUT_ALARM_OUTPUT_FAIL" },
  { no: 32937, name: "VID_OUTPUT_BLOCK_SOURCE" },
  { no: 33009, name: "VID_OUTPUT_BLOCK_THRESHOLD" },
  { no: 32936, name: "VID_OUTPUT_DELAY_END" },
  { no: 32935, name: "VID_OUTPUT_DELAY_START" },
  { no: 16453, name: "VID_OUTPUT_ERROR_INVALID_REFERENCE" },
  { no: 125, name: "VID_OUTPUT_EXERCISE_BLOCKED" },
  { no: 33042, name: "VID_OUTPUT_EXERCISE_CYCLE_LENGTH" },
  { no: 33041, name: "VID_OUTPUT_EXERCISE_ENABLED" },
  { no: 33040, name: "VID_OUTPUT_EXERCISE_IDLE_TIME_DAYS" },
  { no: 93, name: "VID_OUTPUT_EXERCISE_STATUS" },
  { no: 188, name: "VID_OUTPUT_INDICATION_OVERRIDE" },
  { no: 32938, name: "VID_OUTPUT_INVERTED" },
  { no: 33925, name: "VID_OUTPUT_LAST_ACTIVATION" },
  { no: 92, name: "VID_OUTPUT_LAST_ACTIVATION_NON_PERSISTENT_DEPRECATED" },
  { no: 32933, name: "VID_OUTPUT_SERVICE_MODE_DEPRECATED" },
  { no: 33065, name: "VID_OUTPUT_SERVICE_MODE_END_TIME" },
  { no: 32934, name: "VID_OUTPUT_SERVICE_VALUE" },
  { no: 32930, name: "VID_OUTPUT_SOURCE_MAX_VALUE" },
  { no: 32929, name: "VID_OUTPUT_SOURCE_MIN_VALUE" },
  { no: 32928, name: "VID_OUTPUT_VALUE_SOURCE" },
  { no: 32931, name: "VID_OUTPUT_VALUE_THRESHOLD" },
  { no: 80, name: "VID_POWER_MANAGEMENT_CONTROLLER_ACTUAL_CURRENT" },
  { no: 77, name: "VID_POWER_MANAGEMENT_CONTROLLER_DYNAMIC_CURRENT_MAX" },
  { no: 78, name: "VID_POWER_MANAGEMENT_CONTROLLER_DYNAMIC_CURRENT_MIN" },
  { no: 79, name: "VID_POWER_MANAGEMENT_CONTROLLER_GRANTED_CURRENT" },
  { no: 90, name: "VID_POWER_MANAGEMENT_CONTROLLER_POWER_CONSUMERS_LIST" },
  { no: 32951, name: "VID_POWER_MANAGEMENT_CONTROLLER_POWER_PRIORITY" },
  { no: 32946, name: "VID_POWER_MANAGEMENT_CONTROLLER_STATIC_CURRENT" },
  { no: 33828, name: "VID_PRESET_FUNCTION_CONFIGURATION_ASSOCIATED_MAP_INDEX" },
  { no: 33829, name: "VID_PRESET_FUNCTION_CONFIGURATION_ASSOCIATED_MAP_INDEX_OFFSET" },
  { no: 33892, name: "VID_PROFILE_CURRENT_PRESET_FUNCTION_ASSIGNMENT_MAP" },
  { no: 16458, name: "VID_PROFILE_ERROR_PROFILE_CONDITIONS_NOT_MET" },
  { no: 33827, name: "VID_PROFILE_PRESET_FUNCTION_ASSIGNMENT_MAP" },
  { no: 33826, name: "VID_PROFILE_PROFILE_NAME" },
  { no: 224, name: "VID_RESOURCES_CMV_BLOCKS_REMAINING" },
  { no: 141, name: "VID_RESOURCES_DEHUMIDIFIER_BLOCKS_REMAINING" },
  { no: 140, name: "VID_RESOURCES_DHW_TANK_BLOCKS_REMAINING" },
  { no: 155, name: "VID_RESOURCES_DRYING_BLOCKS_REMAINING" },
  { no: 139, name: "VID_RESOURCES_HCC_BLOCKS_REMAINING" },
  { no: 294, name: "VID_RESOURCES_HP_BUFFER_TANK_BLOCKS_REMAINING" },
  { no: 303, name: "VID_RESOURCES_HP_HCWS_ELEMENT_BLOCKS_AVAILABLE" },
  { no: 156, name: "VID_RESOURCES_INTEGRATION_BLOCKS_REMAINING" },
  { no: 138, name: "VID_RESOURCES_ITC_BLOCKS_REMAINING" },
  { no: 33958, name: "VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_CMV_FUNCTION_POOL_ALLOCATION_INTERNAL" },
  { no: 33840, name: "VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_DEHUMIDIFIERS_POOL_ALLOCATION_INTERNAL" },
  { no: 33841, name: "VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_DRYING_FUNCTION_POOL_ALLOCATION_INTERNAL" },
  { no: 33891, name: "VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_HCC_FUNCTION_POOL_ALLOCATION_INTERNAL" },
  { no: 33842, name: "VID_RESOURCE_ALLOCATION_DATA_STRUCTURES_INTEGRATION_FUNCTION_POOL_ALLOCATION_INTERNAL" },
  { no: 33, name: "VID_ROOM_CO2_CONTROLLER_BLOCKING" },
  { no: 32832, name: "VID_ROOM_CO2_CONTROLLER_CO2_WARNING_LEVEL" },
  { no: 32, name: "VID_ROOM_CO2_CONTROLLER_STATE" },
  { no: 16392, name: "VID_ROOM_CO2_CONTROLLER_WARNING_CO2_ABOVE_WARNING_LEVEL" },
  { no: 49202, name: "VID_ROOM_CO2_LEVEL" },
  { no: 51258, name: "VID_ROOM_CO2_WARNING_LEVEL" },
  { no: 51251, name: "VID_ROOM_COOPERATION" },
  { no: 31, name: "VID_ROOM_DRYER_BLOCKING" },
  { no: 30, name: "VID_ROOM_DRYER_STATE" },
  { no: 49191, name: "VID_ROOM_FEATURES" },
  { no: 16508, name: "VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_RADIATORS_ERROR_SUPPLIER_UNAVAILABLE" },
  { no: 177, name: "VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_RADIATORS_SOURCE_DEMAND" },
  { no: 33921, name: "VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_RADIATORS_SUPPLIER_OID" },
  { no: 16509, name: "VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_UFHC_ERROR_SUPPLIER_UNAVAILABLE" },
  { no: 178, name: "VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_UFHC_SOURCE_DEMAND" },
  { no: 33922, name: "VID_ROOM_HEATING_COOLING_WATER_DISTRIBUTION_FOR_UFHC_SUPPLIER_OID" },
  { no: 16391, name: "VID_ROOM_HUMIDITY_CONTROLLER_ALARM_HUMIDITY_HIGH" },
  { no: 33856, name: "VID_ROOM_HUMIDITY_CONTROLLER_COOLING_DEW_POINT_THRESHOLD" },
  { no: 33857, name: "VID_ROOM_HUMIDITY_CONTROLLER_COOLING_DEW_POINT_THRESHOLD_HYSTERESIS" },
  { no: 33850, name: "VID_ROOM_HUMIDITY_CONTROLLER_DRYING_OFFSET_COOLING" },
  { no: 33844, name: "VID_ROOM_HUMIDITY_CONTROLLER_DRYING_OFFSET_HEATING" },
  { no: 33847, name: "VID_ROOM_HUMIDITY_CONTROLLER_DRYING_OFFSET_HYSTERESIS" },
  { no: 32828, name: "VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_ALARM_HIGH" },
  { no: 34074, name: "VID_ROOM_HUMIDITY_CONTROLLER_HUMIDITY_ALARM_HYSTERESIS" },
  { no: 32827, name: "VID_ROOM_HUMIDITY_CONTROLLER_HYSTERESIS" },
  { no: 34101, name: "VID_ROOM_HUMIDITY_CONTROLLER_NIGHT_SET_BACK_ENABLE" },
  { no: 34034, name: "VID_ROOM_HUMIDITY_CONTROLLER_NIGHT_SET_BACK_OFFSET" },
  { no: 34035, name: "VID_ROOM_HUMIDITY_CONTROLLER_NIGHT_SET_BACK_TIME_INTERVAL" },
  { no: 118, name: "VID_ROOM_HUMIDITY_CONTROLLER_PROGRAMMABLE_OUTPUT_LEVEL" },
  { no: 34031, name: "VID_ROOM_HUMIDITY_CONTROLLER_THERMOSTAT_HUMIDITY_ADJUSTMENT_ENABLED" },
  { no: 32826, name: "VID_ROOM_HUMIDITY_DESIRED" },
  { no: 49174, name: "VID_ROOM_MVDI_DEVICE_ID" },
  { no: 173, name: "VID_ROOM_AGGREGATED_NON_AUTONOMOUS_SRT_AIR_TEMP" },
  { no: 16409, name: "VID_ROOM_ALARM_NO_TEMPERATURE_SOURCE" },
  { no: 16411, name: "VID_ROOM_ALARM_OUTPUT_FAIL" },
  { no: 16410, name: "VID_ROOM_ALARM_OUTPUT_LOST" },
  { no: 60, name: "VID_ROOM_COMFORT_LEVEL_DEPRECATED" },
  { no: 34036, name: "VID_ROOM_CONTROLLER_CONFIGURATION_MASK" },
  { no: 32775, name: "VID_ROOM_HOTEL_RESET_TIME" },
  { no: 32773, name: "VID_ROOM_MODE_OVERRIDE" },
  { no: 32774, name: "VID_ROOM_MODE_OVERRIDE_END_TIME" },
  { no: 32911, name: "VID_ROOM_OUTPUT_LIST" },
  { no: 16399, name: "VID_ROOM_WARNING_PERIPHERAL_LOW_BATERY" },
  { no: 105, name: "VID_ROOM_TEMPERATURE_CONTROLLER_AGGREGATED_SRT_RADIATOR_DEMAND" },
  { no: 187, name: "VID_ROOM_TEMPERATURE_CONTROLLER_AGGREGATED_SRT_UFHC_DEMAND" },
  { no: 33904, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_DEW_POINT_BLOCKING_INTEGRATION_THRESHOLD" },
  { no: 33920, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_FLOOR_TEMP_HYSTERESIS" },
  { no: 32824, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_FLOOR_TEMP_LIMIT_MIN" },
  { no: 32820, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_TEMP_HYSTERESIS" },
  { no: 32819, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_TEMP_SET_ADJUSTED_OVERRIDE" },
  { no: 32813, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_TEMP_SET_MANUAL" },
  { no: 32817, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOLING_TEMP_SET_PARTY_OVERRIDE" },
  { no: 33919, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOPERATION_MODE" },
  { no: 175, name: "VID_ROOM_TEMPERATURE_CONTROLLER_COOPERATION_MODE_USED" },
  { no: 16461, name: "VID_ROOM_TEMPERATURE_CONTROLLER_ERROR_NO_UNDERFLOOR_OUTPUT" },
  { no: 157, name: "VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_BLOCKING" },
  { no: 103, name: "VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_PROPORTIONAL_LEVEL" },
  { no: 159, name: "VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_SRT_CONTROL" },
  { no: 181, name: "VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_STATE" },
  { no: 56, name: "VID_ROOM_TEMPERATURE_CONTROLLER_FLOOR_VALVE_LEVEL" },
  { no: 32790, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_ADAPTIVE_MODE_ENABLED" },
  { no: 33923, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_ADAPTIVE_MODE_VALUES" },
  { no: 33918, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_COOPERATION_INCREASED_DEADBAND" },
  { no: 33917, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_COOPERATION_NORMAL_DEADBAND" },
  { no: 32888, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_HYSTERESIS" },
  { no: 32806, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MAX" },
  { no: 32896, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MAX_COMFORT" },
  { no: 32889, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MAX_ECO" },
  { no: 32897, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MAX_EXTRA_COMFORT" },
  { no: 32932, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MIN" },
  { no: 32804, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MIN_COMFORT" },
  { no: 32803, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MIN_ECO" },
  { no: 32805, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_FLOOR_TEMP_LIMIT_MIN_EXTRA_COMFORT" },
  { no: 32802, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_TEMP_HYSTERESIS" },
  { no: 32800, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_TEMP_SET_ADJUSTED_OVERRIDE" },
  { no: 32794, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_TEMP_SET_MANUAL" },
  { no: 32798, name: "VID_ROOM_TEMPERATURE_CONTROLLER_HEATING_TEMP_SET_PARTY_OVERRIDE" },
  { no: 34095, name: "VID_ROOM_TEMPERATURE_CONTROLLER_MODE_OVERRIDE" },
  { no: 33080, name: "VID_ROOM_TEMPERATURE_CONTROLLER_PROPORTIONAL_BAND_OFFSET" },
  { no: 33064, name: "VID_ROOM_TEMPERATURE_CONTROLLER_PROPORTIONAL_LEVEL_BAND" },
  { no: 33083, name: "VID_ROOM_TEMPERATURE_CONTROLLER_PROPORTIONAL_OUTPUT_OFFSET" },
  { no: 180, name: "VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_BLOCKING" },
  { no: 108, name: "VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_PROPORTIONAL_LEVEL" },
  { no: 160, name: "VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_SRT_CONTROL" },
  { no: 179, name: "VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_STATE" },
  { no: 107, name: "VID_ROOM_TEMPERATURE_CONTROLLER_RADIATOR_VALVE_LEVEL" },
  { no: 158, name: "VID_ROOM_TEMPERATURE_CONTROLLER_TEMP_DESIRED_FLOOR" },
  { no: 16463, name: "VID_ROOM_TEMPERATURE_CONTROLLER_WARNING_RADIATORS_HAVE_NO_SUPPLIER" },
  { no: 16462, name: "VID_ROOM_TEMPERATURE_CONTROLLER_WARNING_UFHC_HAS_NO_SUPPLIER" },
  { no: 4095, name: "VID_ROOM_TEST_CLOUD_MODEL_VENTILATION_DEVICE_ID_0XC01B" },
  { no: 33846, name: "VID_ROOM_THERMAL_INTEGRATION_HYSTERESIS" },
  { no: 33845, name: "VID_ROOM_THERMAL_INTEGRATION_INTEGRATION_OFFSET_HEATING" },
  { no: 49179, name: "VID_ROOM_VENTILATION_DEVICE_ID" },
  { no: 35, name: "VID_ROOM_VOC_CONTROLLER_BLOCKING" },
  { no: 34, name: "VID_ROOM_VOC_CONTROLLER_STATE" },
  { no: 50199, name: "VID_ROOM_WARNING_CO2_ABOVE_WARNING_LEVEL" },
  { no: 33005, name: "VID_SERVO_OUTPUT_DEADTIME" },
  { no: 33004, name: "VID_SERVO_OUTPUT_REBOUND" },
  { no: 67, name: "VID_SERVO_OUTPUT_REQUEST" },
  { no: 33002, name: "VID_SERVO_OUTPUT_RUNTIME" },
  { no: 33001, name: "VID_SERVO_OUTPUT_TYPE" },
  { no: 33003, name: "VID_SERVO_OUTPUT_WINDUP" },
  { no: 34014, name: "VID_TEST_SUPPORT_BUS_PACKET_TIME_BRANCH_SPECIFIC" },
  { no: 33836, name: "VID_THERMAL_INTEGRATION_ALLOW_MODE" },
  { no: 151, name: "VID_THERMAL_INTEGRATION_BLOCKING" },
  { no: 33838, name: "VID_THERMAL_INTEGRATION_CONSUMER_LIST" },
  { no: 33854, name: "VID_THERMAL_INTEGRATION_CONTROLLER_REQUIRED_COOLING_WATER_TEMP" },
  { no: 33853, name: "VID_THERMAL_INTEGRATION_CONTROLLER_REQUIRED_HEATING_WATER_TEMP" },
  { no: 33837, name: "VID_THERMAL_INTEGRATION_DEMAND_CONDITION" },
  { no: 33932, name: "VID_THERMAL_INTEGRATION_DEMAND_ROOM_OID" },
  { no: 149, name: "VID_THERMAL_INTEGRATION_SOURCE_DEMAND" },
  { no: 150, name: "VID_THERMAL_INTEGRATION_SOURCE_STATUS" },
  { no: 33839, name: "VID_THERMAL_INTEGRATION_SOURCE_SUPPLIER_OID" },
  { no: 154, name: "VID_THERMAL_INTEGRATION_STATE" },
  { no: 33927, name: "VID_THERMOHEAD_OUTPUT_FIRST_OPEN_PERFORMED" },
  { no: 176, name: "VID_THERMOHEAD_OUTPUT_LOAD_DETECTED" },
  { no: 190, name: "VID_THERMOHEAD_OUTPUT_LOAD_POWER" },
  { no: 33916, name: "VID_THERMOHEAD_OUTPUT_LOAD_TYPE" },
  { no: 16496, name: "VID_THERMOHEAD_OUTPUT_WARN_NO_LOAD" },
  { no: 33068, name: "VID_UPDATE_CONTROLLER_EMUL_END_STATE" },
  { no: 33079, name: "VID_UPDATE_CONTROLLER_EMUL_FORCE_CCU_UPDATE" },
  { no: 33078, name: "VID_UPDATE_CONTROLLER_EMUL_OFFLINE_DEVICES" },
  { no: 33069, name: "VID_UPDATE_CONTROLLER_EMUL_ONLINE_DEVICES" },
  { no: 33084, name: "VID_UPDATE_CONTROLLER_NEXT_UPDATE_CHECK" },
  { no: 33082, name: "VID_UPDATE_CONTROLLER_REPORT_STATE_TIME" },
  { no: 16428, name: "VID_UPDATE_CONTROLLER_WARNING_CRITICAL_UPDATE_AVAILABLE" },
  { no: 34017, name: "VID_USB_LOG_MASK" },
  { no: 34018, name: "VID_USB_LOG_PERIOD" },
  { no: 272, name: "VID_USB_NEW_FIRMWARE_AVAILABLE" },
  { no: 259, name: "VID_USB_STATE" },
  { no: 264, name: "VID_VALVEHEAD_OUTPUT_DISCOVERED_FULLY_CLOSED_FULLY_OPENED_POSITIONS" },
  { no: 261, name: "VID_VALVEHEAD_OUTPUT_DISCOVERED_WORKING_SPAN_DEPRECATED" },
  { no: 106, name: "VID_VALVEHEAD_OUTPUT_OUTPUT_DEMAND" },
  { no: 33893, name: "VID_VALVEHEAD_OUTPUT_OUTPUT_DEMAND_THRESHOLD" },
  { no: 34013, name: "VID_VALVEHEAD_OUTPUT_WORKING_SPAN_PRESET_MM" },
  { no: 133, name: "VID_VELUX_CONTROLLER_BLINDS_POSITION" },
  { no: 134, name: "VID_VELUX_CONTROLLER_BLINDS_POSITION_REQUEST" },
  { no: 132, name: "VID_VELUX_CONTROLLER_BLINDS_STATE" },
  { no: 128, name: "VID_VELUX_CONTROLLER_COMMAND" },
  { no: 33100, name: "VID_VELUX_CONTROLLER_CONNECTION_IP_ADDRESS" },
  { no: 33101, name: "VID_VELUX_CONTROLLER_CONNECTION_PASSWORD" },
  { no: 127, name: "VID_VELUX_CONTROLLER_CONNECTION_STATE" },
  { no: 130, name: "VID_VELUX_CONTROLLER_ROLLER_SHUTTER_POSITION" },
  { no: 131, name: "VID_VELUX_CONTROLLER_ROLLER_SHUTTER_POSITION_REQUEST" },
  { no: 129, name: "VID_VELUX_CONTROLLER_ROLLER_SHUTTER_STATE" },
  { no: 136, name: "VID_VELUX_CONTROLLER_WINDOW_OPENER_POSITION" },
  { no: 137, name: "VID_VELUX_CONTROLLER_WINDOW_OPENER_POSITION_REQUEST" },
  { no: 135, name: "VID_VELUX_CONTROLLER_WINDOW_OPENER_STATE" },
]);

/**
 * BrokerConfig is the internal model
 *
 * @generated from message wavin.sentio.v1.BrokerConfig
 */
export class BrokerConfig extends Message<BrokerConfig> {
  /**
   * @generated from field: repeated wavin.sentio.v1.BrokerValue broker_values = 1;
   */
  brokerValues: BrokerValue[] = [];

  /**
   * @generated from field: bool full_config = 2;
   */
  fullConfig = false;

  constructor(data?: PartialMessage<BrokerConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.BrokerConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "broker_values", kind: "message", T: BrokerValue, repeated: true },
    { no: 2, name: "full_config", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BrokerConfig {
    return new BrokerConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BrokerConfig {
    return new BrokerConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BrokerConfig {
    return new BrokerConfig().fromJsonString(jsonString, options);
  }

  static equals(a: BrokerConfig | PlainMessage<BrokerConfig> | undefined, b: BrokerConfig | PlainMessage<BrokerConfig> | undefined): boolean {
    return proto3.util.equals(BrokerConfig, a, b);
  }
}

