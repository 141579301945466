// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file wavin/blaze/v1/device.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { DeviceConfig } from "./device_config_pb.js";

/**
 * The physical device which acts as the controller for the device and connected peripherals.
 *
 * @generated from message wavin.blaze.v1.Device
 */
export class Device extends Message<Device> {
  /**
   * Device resource name, format: devices/{device}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 2;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 3;
   */
  updateTime?: Timestamp;

  /**
   * @generated from field: string serial_number = 6;
   */
  serialNumber = "";

  /**
   * @generated from field: string registration_key = 7;
   */
  registrationKey = "";

  /**
   * i.e. 1.0.0
   *
   * @generated from field: string firmware_available = 8;
   */
  firmwareAvailable = "";

  /**
   * i.e. 1.0.0
   *
   * @generated from field: string firmware_installed = 9;
   */
  firmwareInstalled = "";

  /**
   * @generated from field: wavin.blaze.v1.Device.Type type = 10;
   */
  type = Device_Type.UNSPECIFIED;

  /**
   * @generated from field: wavin.blaze.v1.Device.ReleaseMode release_mode = 11;
   */
  releaseMode = Device_ReleaseMode.AUTOMATIC;

  /**
   * @generated from field: wavin.blaze.v1.Device.FirmwareUpdateStatus firmware_update_status = 12;
   */
  firmwareUpdateStatus = Device_FirmwareUpdateStatus.IDLE;

  /**
   * @generated from field: wavin.blaze.v1.Device.Status status = 13;
   */
  status = Device_Status.OK;

  /**
   * @generated from field: wavin.blaze.v1.Device.Platform platform = 14;
   */
  platform = Device_Platform.CURRENT;

  /**
   * Most recent heartbeat
   *
   * @generated from field: google.protobuf.Timestamp last_heartbeat = 20;
   */
  lastHeartbeat?: Timestamp;

  /**
   * Most recent device config
   *
   * @generated from field: wavin.blaze.v1.DeviceConfig last_config = 21;
   */
  lastConfig?: DeviceConfig;

  constructor(data?: PartialMessage<Device>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.Device";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "create_time", kind: "message", T: Timestamp },
    { no: 3, name: "update_time", kind: "message", T: Timestamp },
    { no: 6, name: "serial_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "registration_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "firmware_available", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "firmware_installed", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "type", kind: "enum", T: proto3.getEnumType(Device_Type) },
    { no: 11, name: "release_mode", kind: "enum", T: proto3.getEnumType(Device_ReleaseMode) },
    { no: 12, name: "firmware_update_status", kind: "enum", T: proto3.getEnumType(Device_FirmwareUpdateStatus) },
    { no: 13, name: "status", kind: "enum", T: proto3.getEnumType(Device_Status) },
    { no: 14, name: "platform", kind: "enum", T: proto3.getEnumType(Device_Platform) },
    { no: 20, name: "last_heartbeat", kind: "message", T: Timestamp },
    { no: 21, name: "last_config", kind: "message", T: DeviceConfig },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Device {
    return new Device().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Device {
    return new Device().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Device {
    return new Device().fromJsonString(jsonString, options);
  }

  static equals(a: Device | PlainMessage<Device> | undefined, b: Device | PlainMessage<Device> | undefined): boolean {
    return proto3.util.equals(Device, a, b);
  }
}

/**
 * @generated from enum wavin.blaze.v1.Device.Type
 */
export enum Device_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TYPE_SENTIO_CCU = 1;
   */
  SENTIO_CCU = 1,

  /**
   * @generated from enum value: TYPE_CALEFA_DHW = 2;
   */
  CALEFA_DHW = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Device_Type)
proto3.util.setEnumType(Device_Type, "wavin.blaze.v1.Device.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_SENTIO_CCU" },
  { no: 2, name: "TYPE_CALEFA_DHW" },
]);

/**
 * @generated from enum wavin.blaze.v1.Device.ReleaseMode
 */
export enum Device_ReleaseMode {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * firmware releases are made automatic available
   *
   * @generated from enum value: RELEASE_MODE_AUTOMATIC = 0;
   */
  AUTOMATIC = 0,

  /**
   * firmware releases are made manual available
   *
   * @generated from enum value: RELEASE_MODE_MANUAL = 1;
   */
  MANUAL = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(Device_ReleaseMode)
proto3.util.setEnumType(Device_ReleaseMode, "wavin.blaze.v1.Device.ReleaseMode", [
  { no: 0, name: "RELEASE_MODE_AUTOMATIC" },
  { no: 1, name: "RELEASE_MODE_MANUAL" },
]);

/**
 * @generated from enum wavin.blaze.v1.Device.FirmwareUpdateStatus
 */
export enum Device_FirmwareUpdateStatus {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * no update available or finished updating
   *
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_IDLE = 0;
   */
  IDLE = 0,

  /**
   * update available for download to device
   *
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_AVAILABLE = 1;
   */
  AVAILABLE = 1,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_DOWNLOADING = 2;
   */
  DOWNLOADING = 2,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_DOWNLOAD_FAILED = 3;
   */
  DOWNLOAD_FAILED = 3,

  /**
   * update stored on device, ready to install
   *
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_READY_TO_INSTALL = 4;
   */
  READY_TO_INSTALL = 4,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UPDATING = 5;
   */
  UPDATING = 5,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UPDATE_FAILED = 6;
   */
  UPDATE_FAILED = 6,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UPDATE_REJECTED = 7;
   */
  UPDATE_REJECTED = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(Device_FirmwareUpdateStatus)
proto3.util.setEnumType(Device_FirmwareUpdateStatus, "wavin.blaze.v1.Device.FirmwareUpdateStatus", [
  { no: 0, name: "FIRMWARE_UPDATE_STATUS_IDLE" },
  { no: 1, name: "FIRMWARE_UPDATE_STATUS_AVAILABLE" },
  { no: 2, name: "FIRMWARE_UPDATE_STATUS_DOWNLOADING" },
  { no: 3, name: "FIRMWARE_UPDATE_STATUS_DOWNLOAD_FAILED" },
  { no: 4, name: "FIRMWARE_UPDATE_STATUS_READY_TO_INSTALL" },
  { no: 5, name: "FIRMWARE_UPDATE_STATUS_UPDATING" },
  { no: 6, name: "FIRMWARE_UPDATE_STATUS_UPDATE_FAILED" },
  { no: 7, name: "FIRMWARE_UPDATE_STATUS_UPDATE_REJECTED" },
]);

/**
 * @generated from enum wavin.blaze.v1.Device.Status
 */
export enum Device_Status {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: STATUS_OK = 0;
   */
  OK = 0,

  /**
   * @generated from enum value: STATUS_UNAVAILABLE = 1;
   */
  UNAVAILABLE = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(Device_Status)
proto3.util.setEnumType(Device_Status, "wavin.blaze.v1.Device.Status", [
  { no: 0, name: "STATUS_OK" },
  { no: 1, name: "STATUS_UNAVAILABLE" },
]);

/**
 * @generated from enum wavin.blaze.v1.Device.Platform
 */
export enum Device_Platform {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: PLATFORM_CURRENT = 0;
   */
  CURRENT = 0,

  /**
   * Legacy implies a required platform update
   *
   * @generated from enum value: PLATFORM_LEGACY = 1;
   */
  LEGACY = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(Device_Platform)
proto3.util.setEnumType(Device_Platform, "wavin.blaze.v1.Device.Platform", [
  { no: 0, name: "PLATFORM_CURRENT" },
  { no: 1, name: "PLATFORM_LEGACY" },
]);

