import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '~/services/auth/auth-service';
import { createRoutes } from '~/routes';

export function useCloseSession() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useCallback(async () => {
    queryClient.clear();

    await authService.signOut();
    navigate(createRoutes.SignIn);
  }, [navigate, queryClient]);
}
