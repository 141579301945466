import debounce from '@mui/material/utils/debounce';
import { ListDevicesResponse } from '~/types/wavin/blaze/v1/blaze_device_service_pb';
import { DeviceModel } from '~/shared/models/device/DeviceModel';
import { normalizeString } from '~/shared/utils/normalizeString';

const TIMEOUT = 300;

export class DevicesListModel {
  readonly devices: DeviceModel[];

  constructor(listDevicesResponse: ListDevicesResponse) {
    this.devices = listDevicesResponse.devices.map(
      (device) => new DeviceModel(device),
    );
  }

  searchDevices = debounce(
    ({
      callback,
      searchTerm = '',
    }: {
      callback: (results: DeviceModel[]) => void;
      searchTerm?: string;
    }) => {
      const results = this.devices.filter((device) =>
        device.searchKeywords.includes(normalizeString(searchTerm)),
      );

      callback(results);
    },
    TIMEOUT,
  );
}
