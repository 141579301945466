import { t } from 'i18next';
import { formatTimestamp } from '~/shared/utils/formatTimestamp';
import {
  DeviceClaim,
  DeviceClaim_Method,
  DeviceClaim_Type,
} from '~/types/wavin/blaze/v1/device_claim_pb';
import { User } from '~/types/wavin/blaze/v1/user_pb';

const getUntilText = (item: DeviceClaim) => {
  const { deleteTime, type } = item;

  switch (type) {
    case DeviceClaim_Type.PERMANENT_ACCESS:
      return t('devicePage.claims.permanent');
    case DeviceClaim_Type.TEMPORARY_ACCESS:
      return formatTimestamp(deleteTime);
    default: {
      throw new Error('Unknown device claim type');
    }
  }
};

export class DeviceClaimModel {
  readonly name: string;

  readonly user?: User;

  readonly createTime?: string;

  readonly deleteTime?: string;

  readonly method: string;

  constructor(deviceClaim: DeviceClaim) {
    this.name = deviceClaim.name;
    this.user = deviceClaim.user;
    this.createTime = formatTimestamp(deviceClaim.createTime);
    this.deleteTime = getUntilText(deviceClaim);
    this.method = DeviceClaim_Method[deviceClaim.method];
  }
}
