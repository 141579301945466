// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file wavin/ignite/v1/user.proto (package wavin.ignite.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum wavin.ignite.v1.Role
 */
export enum Role {
  /**
   * @generated from enum value: ROLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * View & assign roles of users.
   *
   * @generated from enum value: ROLE_IAM_ADMIN = 1;
   */
  IAM_ADMIN = 1,

  /**
   * View telemetry data for all devices.
   *
   * @generated from enum value: ROLE_TELEMETRY_ADMIN = 2;
   */
  TELEMETRY_ADMIN = 2,

  /**
   * View & edit configuration for claimed devices.
   *
   * @generated from enum value: ROLE_CONFIG_EDITOR = 3;
   */
  CONFIG_EDITOR = 3,

  /**
   * Release firmware version GA for all devices.
   *
   * @generated from enum value: ROLE_FIRMWARE_ADMIN = 4;
   */
  FIRMWARE_ADMIN = 4,

  /**
   * List & download GA & Beta firmware releases. Upload firmware. Release to claimed devices.
   *
   * @generated from enum value: ROLE_FIRMWARE_EDITOR = 5;
   */
  FIRMWARE_EDITOR = 5,

  /**
   * List & download GA firmware releases.
   *
   * @generated from enum value: ROLE_FIRMWARE_VIEWER = 6;
   */
  FIRMWARE_VIEWER = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(Role)
proto3.util.setEnumType(Role, "wavin.ignite.v1.Role", [
  { no: 0, name: "ROLE_UNSPECIFIED" },
  { no: 1, name: "ROLE_IAM_ADMIN" },
  { no: 2, name: "ROLE_TELEMETRY_ADMIN" },
  { no: 3, name: "ROLE_CONFIG_EDITOR" },
  { no: 4, name: "ROLE_FIRMWARE_ADMIN" },
  { no: 5, name: "ROLE_FIRMWARE_EDITOR" },
  { no: 6, name: "ROLE_FIRMWARE_VIEWER" },
]);

/**
 * @generated from message wavin.ignite.v1.User
 */
export class User extends Message<User> {
  /**
   * Resource name of users, format: users/{usersID}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: optional string language_code = 3;
   */
  languageCode?: string;

  /**
   * @generated from field: optional string terms_accepted_version = 4;
   */
  termsAcceptedVersion?: string;

  /**
   * @generated from field: optional string company_name = 5;
   */
  companyName?: string;

  /**
   * @generated from field: optional string first_name = 6;
   */
  firstName?: string;

  /**
   * @generated from field: optional string last_name = 7;
   */
  lastName?: string;

  /**
   * @generated from field: optional string street_address_1 = 8;
   */
  streetAddress1?: string;

  /**
   * @generated from field: optional string street_address_2 = 9;
   */
  streetAddress2?: string;

  /**
   * @generated from field: optional string zip_code = 10;
   */
  zipCode?: string;

  /**
   * @generated from field: optional string city = 11;
   */
  city?: string;

  /**
   * @generated from field: optional string country = 12;
   */
  country?: string;

  /**
   * @generated from field: repeated wavin.ignite.v1.Device devices = 13;
   */
  devices: Device[] = [];

  /**
   * @generated from field: repeated wavin.ignite.v1.Role roles = 14;
   */
  roles: Role[] = [];

  /**
   * @generated from field: optional string terms_accepted_version_smart_connect = 100;
   */
  termsAcceptedVersionSmartConnect?: string;

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.ignite.v1.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "language_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "terms_accepted_version", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "company_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "street_address_1", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "street_address_2", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "devices", kind: "message", T: Device, repeated: true },
    { no: 14, name: "roles", kind: "enum", T: proto3.getEnumType(Role), repeated: true },
    { no: 100, name: "terms_accepted_version_smart_connect", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * @generated from message wavin.ignite.v1.Device
 */
export class Device extends Message<Device> {
  /**
   * uniquely identifies an ios or android device
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * registration key that is used to send notifications to a device
   *
   * @generated from field: string registration_key = 2;
   */
  registrationKey = "";

  constructor(data?: PartialMessage<Device>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.ignite.v1.Device";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "registration_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Device {
    return new Device().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Device {
    return new Device().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Device {
    return new Device().fromJsonString(jsonString, options);
  }

  static equals(a: Device | PlainMessage<Device> | undefined, b: Device | PlainMessage<Device> | undefined): boolean {
    return proto3.util.equals(Device, a, b);
  }
}

