import { queryOptions } from '@tanstack/react-query';
import { listDeviceAccessKeys } from '~/pages/Device/api/listDeviceAccessKeys.api';
import { DeviceAccessKeysListModel } from '~/pages/Device/models/DeviceAccessKeysListModel';
import { BlazeDeviceQueryKey } from '~/services/queryKeys';
import { createNameFromDeviceId } from '~/shared/models/id-utils';

export const listDeviceAccessKeysQuery = ({ deviceId }: { deviceId: string }) =>
  queryOptions({
    queryKey: [BlazeDeviceQueryKey.LIST_DEVICE_ACCESS_KEYS, deviceId],
    queryFn: async () => {
      const response = await listDeviceAccessKeys({
        deviceId: createNameFromDeviceId(deviceId),
      });
      return new DeviceAccessKeysListModel(response);
    },
  });
