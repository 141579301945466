import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '~/services/i18n/en.json';

export const defaultNS = 'translation';
export const resources = {
  en: {
    translation: en,
  },
} as const;

export function configLocales() {
  i18n.use(initReactI18next).init({
    resources,
    ns: defaultNS,
    defaultNS,
    fallbackLng: 'en',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      transKeepBasicHtmlNodesFor: ['ol', 'li', 'ul', 'p'],
    },
  });
  return i18n;
}
