import { LoaderFunctionArgs } from 'react-router-dom';
import { checkAuthAndTerms } from '~/shared/auth/auth-utils';

export const deviceClaimLoader =
  () =>
  async ({ request }: LoaderFunctionArgs) => {
    await checkAuthAndTerms(request);

    return null;
  };
