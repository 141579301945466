import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { authService } from '~/services/auth/auth-service';

const AuthContext = createContext<boolean>(false);

export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [isSignedIn, setIsSignedIn] = useState(authService.isSignedIn);

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged((newIsSignedIn) => {
      setIsSignedIn(newIsSignedIn);
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={isSignedIn}>{children}</AuthContext.Provider>
  );
}

export function useIsSignedIn() {
  return useContext(AuthContext);
}
