import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { useState } from 'react';
import { Drawer } from '~/pages/layout/components/Drawer';
import { DRAWER_WIDTH } from '~/shared/contants/layout-constants';
import { MenuIcon } from '~/shared/components/Icons/Icons';
import { Logo } from './Logo';
import { theme } from '~/shared/theme/theme';

export function DrawerContainer() {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: theme.palette.background.paper,
          width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { md: `${DRAWER_WIDTH}px` },
          display: { md: 'none' },
        }}
      >
        <Toolbar sx={{ px: { xs: 6 } }}>
          <IconButton
            color="primary"
            aria-label="open navigation"
            edge="start"
            onClick={() => setMobileOpen(true)}
            sx={{ mr: 4 }}
          >
            <MenuIcon />
          </IconButton>
          <Logo />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { md: DRAWER_WIDTH },
          flexShrink: { sm: 0 },
        }}
      >
        <Drawer isOpen={mobileOpen} onClose={() => setMobileOpen(false)} />
      </Box>
    </>
  );
}
