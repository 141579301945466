import { ListDeviceClaimsResponse } from '~/types/wavin/blaze/v1/blaze_device_service_pb';
import { DeviceClaimModel } from '~/pages/Device/models/DeviceClaimModel';

export class DeviceClaimsListModel {
  readonly claims: DeviceClaimModel[];

  constructor(listDeviceClaimsResponse: ListDeviceClaimsResponse) {
    this.claims = listDeviceClaimsResponse.claims.map(
      (claim) => new DeviceClaimModel(claim),
    );
  }
}
