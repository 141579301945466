import { Device_FirmwareUpdateStatus } from '~/types/wavin/blaze/v1/device_pb';

export class DeviceFirmwareModel {
  readonly availableVersion: string;

  readonly installedVersion: string;

  canUpdate: boolean;

  isUpdating: boolean;

  firmwareStatus: Device_FirmwareUpdateStatus;

  constructor(
    firmwareAvailable: string,
    firmwareInstalled: string,
    firmwareUpdateStatus: Device_FirmwareUpdateStatus,
  ) {
    this.availableVersion = firmwareAvailable;
    this.installedVersion = firmwareInstalled;
    this.firmwareStatus = firmwareUpdateStatus;
    this.canUpdate = DeviceFirmwareModel.canUpdate(firmwareUpdateStatus);
    this.isUpdating = DeviceFirmwareModel.isUpdating(firmwareUpdateStatus);
  }

  updateStatus = (firmwareUpdateStatus: Device_FirmwareUpdateStatus) => {
    this.firmwareStatus = firmwareUpdateStatus;
    this.canUpdate = DeviceFirmwareModel.canUpdate(firmwareUpdateStatus);
    this.isUpdating = DeviceFirmwareModel.isUpdating(firmwareUpdateStatus);
  };

  private static canUpdate = (
    firmwareUpdateStatus: Device_FirmwareUpdateStatus,
  ) => firmwareUpdateStatus === Device_FirmwareUpdateStatus.AVAILABLE;

  private static isUpdating = (
    firmwareUpdateStatus: Device_FirmwareUpdateStatus,
  ) =>
    firmwareUpdateStatus === Device_FirmwareUpdateStatus.DOWNLOADING ||
    firmwareUpdateStatus === Device_FirmwareUpdateStatus.READY_TO_INSTALL ||
    firmwareUpdateStatus === Device_FirmwareUpdateStatus.UPDATING;
}
