// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file wavin/blaze/v1/device_updater.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * The device updater for updating individual components
 *
 * @generated from message wavin.blaze.v1.DeviceUpdater
 */
export class DeviceUpdater extends Message<DeviceUpdater> {
  /**
   * DeviceUpdater resource name, format: devices/{device}/updater
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: repeated wavin.blaze.v1.DeviceUpdater.Component components = 2;
   */
  components: DeviceUpdater_Component[] = [];

  constructor(data?: PartialMessage<DeviceUpdater>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.DeviceUpdater";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "components", kind: "message", T: DeviceUpdater_Component, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceUpdater {
    return new DeviceUpdater().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceUpdater {
    return new DeviceUpdater().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceUpdater {
    return new DeviceUpdater().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceUpdater | PlainMessage<DeviceUpdater> | undefined, b: DeviceUpdater | PlainMessage<DeviceUpdater> | undefined): boolean {
    return proto3.util.equals(DeviceUpdater, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.DeviceUpdater.Component
 */
export class DeviceUpdater_Component extends Message<DeviceUpdater_Component> {
  /**
   * Component resource name, format: devices/{device}/components/{component}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string serial_number = 4;
   */
  serialNumber = "";

  /**
   * @generated from field: string firmware_installed = 5;
   */
  firmwareInstalled = "";

  /**
   * @generated from field: wavin.blaze.v1.DeviceUpdater.Component.Status status = 6;
   */
  status = DeviceUpdater_Component_Status.OK;

  /**
   * @generated from field: wavin.blaze.v1.DeviceUpdater.Component.FirmwareUpdateStatus firmware_update_status = 7;
   */
  firmwareUpdateStatus = DeviceUpdater_Component_FirmwareUpdateStatus.UNSPECIFIED;

  /**
   * Only when firmware_name is given for compatibility check
   *
   * @generated from field: wavin.blaze.v1.DeviceUpdater.Component.FirmwareCompatibility firmware_compatibility = 8;
   */
  firmwareCompatibility = DeviceUpdater_Component_FirmwareCompatibility.UNSPECIFIED;

  /**
   * Only when firmware_name is given for compatibility check
   *
   * @generated from field: optional string firmware_available = 9;
   */
  firmwareAvailable?: string;

  constructor(data?: PartialMessage<DeviceUpdater_Component>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.DeviceUpdater.Component";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "serial_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "firmware_installed", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(DeviceUpdater_Component_Status) },
    { no: 7, name: "firmware_update_status", kind: "enum", T: proto3.getEnumType(DeviceUpdater_Component_FirmwareUpdateStatus) },
    { no: 8, name: "firmware_compatibility", kind: "enum", T: proto3.getEnumType(DeviceUpdater_Component_FirmwareCompatibility) },
    { no: 9, name: "firmware_available", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceUpdater_Component {
    return new DeviceUpdater_Component().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceUpdater_Component {
    return new DeviceUpdater_Component().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceUpdater_Component {
    return new DeviceUpdater_Component().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceUpdater_Component | PlainMessage<DeviceUpdater_Component> | undefined, b: DeviceUpdater_Component | PlainMessage<DeviceUpdater_Component> | undefined): boolean {
    return proto3.util.equals(DeviceUpdater_Component, a, b);
  }
}

/**
 * @generated from enum wavin.blaze.v1.DeviceUpdater.Component.Status
 */
export enum DeviceUpdater_Component_Status {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: STATUS_OK = 0;
   */
  OK = 0,

  /**
   * @generated from enum value: STATUS_UNAVAILABLE = 1;
   */
  UNAVAILABLE = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceUpdater_Component_Status)
proto3.util.setEnumType(DeviceUpdater_Component_Status, "wavin.blaze.v1.DeviceUpdater.Component.Status", [
  { no: 0, name: "STATUS_OK" },
  { no: 1, name: "STATUS_UNAVAILABLE" },
]);

/**
 * @generated from enum wavin.blaze.v1.DeviceUpdater.Component.FirmwareUpdateStatus
 */
export enum DeviceUpdater_Component_FirmwareUpdateStatus {
  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_IDLE = 1;
   */
  IDLE = 1,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_AVAILABLE = 2;
   */
  AVAILABLE = 2,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_AVAILABLE_FORCED_UPDATE = 3;
   */
  AVAILABLE_FORCED_UPDATE = 3,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_NOT_AVAILABLE = 4;
   */
  NOT_AVAILABLE = 4,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UPDATING = 5;
   */
  UPDATING = 5,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UPDATE_FAILED = 6;
   */
  UPDATE_FAILED = 6,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_IGNORED = 7;
   */
  IGNORED = 7,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UNCHECKED = 8;
   */
  UNCHECKED = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceUpdater_Component_FirmwareUpdateStatus)
proto3.util.setEnumType(DeviceUpdater_Component_FirmwareUpdateStatus, "wavin.blaze.v1.DeviceUpdater.Component.FirmwareUpdateStatus", [
  { no: 0, name: "FIRMWARE_UPDATE_STATUS_UNSPECIFIED" },
  { no: 1, name: "FIRMWARE_UPDATE_STATUS_IDLE" },
  { no: 2, name: "FIRMWARE_UPDATE_STATUS_AVAILABLE" },
  { no: 3, name: "FIRMWARE_UPDATE_STATUS_AVAILABLE_FORCED_UPDATE" },
  { no: 4, name: "FIRMWARE_UPDATE_STATUS_NOT_AVAILABLE" },
  { no: 5, name: "FIRMWARE_UPDATE_STATUS_UPDATING" },
  { no: 6, name: "FIRMWARE_UPDATE_STATUS_UPDATE_FAILED" },
  { no: 7, name: "FIRMWARE_UPDATE_STATUS_IGNORED" },
  { no: 8, name: "FIRMWARE_UPDATE_STATUS_UNCHECKED" },
]);

/**
 * @generated from enum wavin.blaze.v1.DeviceUpdater.Component.FirmwareCompatibility
 */
export enum DeviceUpdater_Component_FirmwareCompatibility {
  /**
   * @generated from enum value: FIRMWARE_COMPATIBILITY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * update possible for component, flash to new version
   *
   * @generated from enum value: FIRMWARE_COMPATIBILITY_OK = 1;
   */
  OK = 1,

  /**
   * update possible, re-flash to same version
   *
   * @generated from enum value: FIRMWARE_COMPATIBILITY_OK_SAME_VERSION = 2;
   */
  OK_SAME_VERSION = 2,

  /**
   * no update file for component
   *
   * @generated from enum value: FIRMWARE_COMPATIBILITY_NO_FILE = 3;
   */
  NO_FILE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceUpdater_Component_FirmwareCompatibility)
proto3.util.setEnumType(DeviceUpdater_Component_FirmwareCompatibility, "wavin.blaze.v1.DeviceUpdater.Component.FirmwareCompatibility", [
  { no: 0, name: "FIRMWARE_COMPATIBILITY_UNSPECIFIED" },
  { no: 1, name: "FIRMWARE_COMPATIBILITY_OK" },
  { no: 2, name: "FIRMWARE_COMPATIBILITY_OK_SAME_VERSION" },
  { no: 3, name: "FIRMWARE_COMPATIBILITY_NO_FILE" },
]);

