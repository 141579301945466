import { Timestamp } from '@bufbuild/protobuf';
import dayjs from 'dayjs';

export function formatTimestamp(
  timestamp?: Timestamp,
  { includeTime = true, includeMilliseconds = false } = {},
) {
  if (!timestamp) return '';

  const date = timestamp.toDate();

  let formatString = 'YYYY-MM-DD';

  if (includeTime) formatString += ' HH:mm:ss';

  if (includeMilliseconds) formatString += '.SSS';

  return dayjs(date).format(formatString);
}
