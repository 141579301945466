import { createReleaseVersion } from './create-release-version';

/* eslint-disable no-restricted-syntax */
function queryEnv(name: string): string | undefined {
  return import.meta.env[name] || undefined;
}

function getEnv(name: string): string {
  const result = queryEnv(name);
  if (!result) {
    throw new Error(`Env var ${name} was not defined`);
  }
  return result;
}

export function createReleaseVersionFromEnv(): string {
  const buildTime = queryEnv('VITE_BUILD_TIME');
  const tagName = queryEnv('VITE_TAG_NAME');
  const shortSha = queryEnv('VITE_SHORT_SHA');
  const environment = queryEnv('VITE_ENVIRONMENT');

  return createReleaseVersion({
    buildTime,
    tagName,
    environment,
    shortSha,
  });
}

export const BLAZE_TRANSPORT_URL = getEnv('VITE_BLAZE_TRANSPORT_URL');
export const BUILD_ID = queryEnv('VITE_BUILD_ID');
export const DEVELOPMENT_MODE = queryEnv('MODE') === 'development';
export const ENABLE_MOCKS = queryEnv('VITE_ENABLE_MOCKS') === 'true';
export const ENVIRONMENT = getEnv('VITE_ENVIRONMENT');
export const FIREBASE_API_KEY = getEnv('VITE_FIREBASE_API_KEY');
export const FIREBASE_AUTH_DOMAIN = getEnv('VITE_FIREBASE_AUTH_DOMAIN');
export const FIREBASE_MICROSOFT_TENANT = getEnv(
  'VITE_FIREBASE_MICROSOFT_TENANT',
);
export const IGNITE_TRANSPORT_URL = getEnv('VITE_IGNITE_TRANSPORT_URL');
export const PRODUCTION_ENVIRONMENT = ENVIRONMENT === 'prod';
export const PRODUCTION_MODE = import.meta.env.PROD;
export const RELEASE_VERSION = createReleaseVersionFromEnv();
export const SENTRY_DSN = queryEnv('VITE_SENTRY_DSN');
export const SKIP_SIGNIN = queryEnv('VITE_SKIP_SIGNIN') === 'true';
