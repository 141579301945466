import { DeviceAccessKeyModel } from '~/pages/Device/models/DeviceAccessKeyModel';
import { ListDeviceAccessKeysResponse } from '~/types/wavin/blaze/v1/blaze_device_service_pb';
import { DeviceAccessKey } from '~/types/wavin/blaze/v1/device_access_key_pb';

export class DeviceAccessKeysListModel {
  readonly accessKeys: DeviceAccessKeyModel[];

  constructor(listDeviceClaimsResponse: ListDeviceAccessKeysResponse) {
    this.accessKeys = listDeviceClaimsResponse.accessKeys.map(
      (accessKey: DeviceAccessKey) => new DeviceAccessKeyModel(accessKey),
    );
  }
}
