import { queryOptions } from '@tanstack/react-query';
import { DeviceModel } from '~/shared/models/device/DeviceModel';
import { getDevice } from '~/services/api/fetchers/getDevice.api';
import { BlazeDeviceQueryKey } from '~/services/queryKeys';
import { createNameFromDeviceId } from '~/shared/models/id-utils';

type GetDeviceQueryProps = {
  deviceId: string;
  includeSource?: boolean;
};

export const getDeviceQuery = ({
  deviceId,
  includeSource = true,
}: GetDeviceQueryProps) => {
  const nameFromDeviceId = createNameFromDeviceId(deviceId);
  return queryOptions({
    queryKey: [BlazeDeviceQueryKey.DEVICE, nameFromDeviceId],
    queryFn: async () => {
      const device = await getDevice({
        deviceId: nameFromDeviceId,
        includeSource,
      });
      return new DeviceModel(device);
    },
  });
};
