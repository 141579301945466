// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file wavin/sentio/v1/device_config.proto (package wavin.sentio.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * internal
 *
 * @generated from enum wavin.sentio.v1.PersonalizationType
 */
export enum PersonalizationType {
  /**
   * @generated from enum value: PERSONALIZATION_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PERSONALIZATION_TYPE_CONFIG = 1;
   */
  CONFIG = 1,

  /**
   * @generated from enum value: PERSONALIZATION_TYPE_ROOM = 2;
   */
  ROOM = 2,

  /**
   * @generated from enum value: PERSONALIZATION_TYPE_VENTILATION_CONTROL = 3;
   */
  VENTILATION_CONTROL = 3,

  /**
   * @generated from enum value: PERSONALIZATION_TYPE_VFR = 4;
   */
  VFR = 4,

  /**
   * @generated from enum value: PERSONALIZATION_TYPE_DHW = 5;
   */
  DHW = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(PersonalizationType)
proto3.util.setEnumType(PersonalizationType, "wavin.sentio.v1.PersonalizationType", [
  { no: 0, name: "PERSONALIZATION_TYPE_UNSPECIFIED" },
  { no: 1, name: "PERSONALIZATION_TYPE_CONFIG" },
  { no: 2, name: "PERSONALIZATION_TYPE_ROOM" },
  { no: 3, name: "PERSONALIZATION_TYPE_VENTILATION_CONTROL" },
  { no: 4, name: "PERSONALIZATION_TYPE_VFR" },
  { no: 5, name: "PERSONALIZATION_TYPE_DHW" },
]);

/**
 * Sentio specific config
 *
 * @generated from message wavin.sentio.v1.DeviceConfig
 */
export class DeviceConfig extends Message<DeviceConfig> {
  /**
   * @generated from field: optional string title = 12;
   */
  title?: string;

  /**
   * A custom title set on the device config per user
   *
   * @generated from field: optional string title_personalized = 13;
   */
  titlePersonalized?: string;

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.Room rooms = 1;
   */
  rooms: DeviceConfig_Room[] = [];

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.VentilationControl ventilation_controls = 2;
   */
  ventilationControls: DeviceConfig_VentilationControl[] = [];

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.VoltageFreeRelay voltage_free_relays = 3;
   */
  voltageFreeRelays: DeviceConfig_VoltageFreeRelay[] = [];

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.OutdoorTemperatureSensor outdoor_temperature_sensors = 4;
   */
  outdoorTemperatureSensors: DeviceConfig_OutdoorTemperatureSensor[] = [];

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.DomesticHotWaterUnit domestic_hot_water_units = 5;
   */
  domesticHotWaterUnits: DeviceConfig_DomesticHotWaterUnit[] = [];

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.HCMode hc_mode = 6;
   */
  hcMode = DeviceConfig_HCMode.HC_MODE_UNSPECIFIED;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.AutomaticHCMode automatic_hc_mode = 7;
   */
  automaticHcMode = DeviceConfig_AutomaticHCMode.AUTOMATIC_HC_MODE_UNSPECIFIED;

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.HCMode available_hc_modes = 11;
   */
  availableHcModes: DeviceConfig_HCMode[] = [];

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.StandbyMode standby_mode = 8;
   */
  standbyMode = DeviceConfig_StandbyMode.UNSPECIFIED;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.VacationSettings vacation_settings = 9;
   */
  vacationSettings?: DeviceConfig_VacationSettings;

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.Notification notifications = 10;
   */
  notifications: DeviceConfig_Notification[] = [];

  constructor(data?: PartialMessage<DeviceConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 12, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "title_personalized", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 1, name: "rooms", kind: "message", T: DeviceConfig_Room, repeated: true },
    { no: 2, name: "ventilation_controls", kind: "message", T: DeviceConfig_VentilationControl, repeated: true },
    { no: 3, name: "voltage_free_relays", kind: "message", T: DeviceConfig_VoltageFreeRelay, repeated: true },
    { no: 4, name: "outdoor_temperature_sensors", kind: "message", T: DeviceConfig_OutdoorTemperatureSensor, repeated: true },
    { no: 5, name: "domestic_hot_water_units", kind: "message", T: DeviceConfig_DomesticHotWaterUnit, repeated: true },
    { no: 6, name: "hc_mode", kind: "enum", T: proto3.getEnumType(DeviceConfig_HCMode) },
    { no: 7, name: "automatic_hc_mode", kind: "enum", T: proto3.getEnumType(DeviceConfig_AutomaticHCMode) },
    { no: 11, name: "available_hc_modes", kind: "enum", T: proto3.getEnumType(DeviceConfig_HCMode), repeated: true },
    { no: 8, name: "standby_mode", kind: "enum", T: proto3.getEnumType(DeviceConfig_StandbyMode) },
    { no: 9, name: "vacation_settings", kind: "message", T: DeviceConfig_VacationSettings },
    { no: 10, name: "notifications", kind: "message", T: DeviceConfig_Notification, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig {
    return new DeviceConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig {
    return new DeviceConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig {
    return new DeviceConfig().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig | PlainMessage<DeviceConfig> | undefined, b: DeviceConfig | PlainMessage<DeviceConfig> | undefined): boolean {
    return proto3.util.equals(DeviceConfig, a, b);
  }
}

/**
 * Heating and Cooling mode
 *
 * @generated from enum wavin.sentio.v1.DeviceConfig.HCMode
 */
export enum DeviceConfig_HCMode {
  /**
   * @generated from enum value: HC_MODE_UNSPECIFIED = 0;
   */
  HC_MODE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: HC_MODE_HEATING = 1;
   */
  HC_MODE_HEATING = 1,

  /**
   * @generated from enum value: HC_MODE_COOLING = 2;
   */
  HC_MODE_COOLING = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_HCMode)
proto3.util.setEnumType(DeviceConfig_HCMode, "wavin.sentio.v1.DeviceConfig.HCMode", [
  { no: 0, name: "HC_MODE_UNSPECIFIED" },
  { no: 1, name: "HC_MODE_HEATING" },
  { no: 2, name: "HC_MODE_COOLING" },
]);

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.AutomaticHCMode
 */
export enum DeviceConfig_AutomaticHCMode {
  /**
   * @generated from enum value: AUTOMATIC_HC_MODE_UNSPECIFIED = 0;
   */
  AUTOMATIC_HC_MODE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: AUTOMATIC_HC_MODE_OFF = 1;
   */
  AUTOMATIC_HC_MODE_OFF = 1,

  /**
   * @generated from enum value: AUTOMATIC_HC_MODE_ON = 2;
   */
  AUTOMATIC_HC_MODE_ON = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_AutomaticHCMode)
proto3.util.setEnumType(DeviceConfig_AutomaticHCMode, "wavin.sentio.v1.DeviceConfig.AutomaticHCMode", [
  { no: 0, name: "AUTOMATIC_HC_MODE_UNSPECIFIED" },
  { no: 1, name: "AUTOMATIC_HC_MODE_OFF" },
  { no: 2, name: "AUTOMATIC_HC_MODE_ON" },
]);

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.StandbyMode
 */
export enum DeviceConfig_StandbyMode {
  /**
   * @generated from enum value: STANDBY_MODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: STANDBY_MODE_OFF = 1;
   */
  OFF = 1,

  /**
   * @generated from enum value: STANDBY_MODE_ON = 2;
   */
  ON = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_StandbyMode)
proto3.util.setEnumType(DeviceConfig_StandbyMode, "wavin.sentio.v1.DeviceConfig.StandbyMode", [
  { no: 0, name: "STANDBY_MODE_UNSPECIFIED" },
  { no: 1, name: "STANDBY_MODE_OFF" },
  { no: 2, name: "STANDBY_MODE_ON" },
]);

/**
 * @generated from message wavin.sentio.v1.DeviceConfig.Room
 */
export class DeviceConfig_Room extends Message<DeviceConfig_Room> {
  /**
   * read
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * read/write
   *
   * @generated from field: optional string title = 2;
   */
  title?: string;

  /**
   * @generated from field: optional string title_personalized = 17;
   */
  titlePersonalized?: string;

  /**
   * @generated from field: optional double air_temperature = 3;
   */
  airTemperature?: number;

  /**
   * @generated from field: optional double floor_temperature = 4;
   */
  floorTemperature?: number;

  /**
   * @generated from field: optional double humidity = 6;
   */
  humidity?: number;

  /**
   * @generated from field: optional double setpoint_temperature = 5;
   */
  setpointTemperature?: number;

  /**
   * @generated from field: optional double min_setpoint_temperature = 7;
   */
  minSetpointTemperature?: number;

  /**
   * @generated from field: optional double max_setpoint_temperature = 9;
   */
  maxSetpointTemperature?: number;

  /**
   * @generated from field: optional wavin.sentio.v1.DeviceConfig.VacationSettings.VacationMode vacation_mode = 10;
   */
  vacationMode?: DeviceConfig_VacationSettings_VacationMode;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.Room.Type type = 20;
   */
  type = DeviceConfig_Room_Type.DEFAULT;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.Room.LockMode lock_mode = 11;
   */
  lockMode = DeviceConfig_Room_LockMode.UNSPECIFIED;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.Room.TemperatureState temperature_state = 12;
   */
  temperatureState = DeviceConfig_Room_TemperatureState.UNSPECIFIED;

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.Room.TemperaturePreset temperature_presets = 13;
   */
  temperaturePresets: DeviceConfig_Room_TemperaturePreset[] = [];

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.Room.SystemMode system_modes = 15;
   */
  systemModes: DeviceConfig_Room_SystemMode[] = [];

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.Room.DehumidificationPreset dehumidification_presets = 18;
   */
  dehumidificationPresets: DeviceConfig_Room_DehumidificationPreset[] = [];

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.Room.DehumidifierState dehumidifier_state = 19;
   */
  dehumidifierState = DeviceConfig_Room_DehumidifierState.UNSPECIFIED;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.Room.WeeklySchedule weekly_schedule = 14;
   */
  weeklySchedule?: DeviceConfig_Room_WeeklySchedule;

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.Notification notifications = 16;
   */
  notifications: DeviceConfig_Notification[] = [];

  constructor(data?: PartialMessage<DeviceConfig_Room>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig.Room";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "title_personalized", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "air_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 4, name: "floor_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 6, name: "humidity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 5, name: "setpoint_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 7, name: "min_setpoint_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 9, name: "max_setpoint_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 10, name: "vacation_mode", kind: "enum", T: proto3.getEnumType(DeviceConfig_VacationSettings_VacationMode), opt: true },
    { no: 20, name: "type", kind: "enum", T: proto3.getEnumType(DeviceConfig_Room_Type) },
    { no: 11, name: "lock_mode", kind: "enum", T: proto3.getEnumType(DeviceConfig_Room_LockMode) },
    { no: 12, name: "temperature_state", kind: "enum", T: proto3.getEnumType(DeviceConfig_Room_TemperatureState) },
    { no: 13, name: "temperature_presets", kind: "message", T: DeviceConfig_Room_TemperaturePreset, repeated: true },
    { no: 15, name: "system_modes", kind: "message", T: DeviceConfig_Room_SystemMode, repeated: true },
    { no: 18, name: "dehumidification_presets", kind: "message", T: DeviceConfig_Room_DehumidificationPreset, repeated: true },
    { no: 19, name: "dehumidifier_state", kind: "enum", T: proto3.getEnumType(DeviceConfig_Room_DehumidifierState) },
    { no: 14, name: "weekly_schedule", kind: "message", T: DeviceConfig_Room_WeeklySchedule },
    { no: 16, name: "notifications", kind: "message", T: DeviceConfig_Notification, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig_Room {
    return new DeviceConfig_Room().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig_Room {
    return new DeviceConfig_Room().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig_Room {
    return new DeviceConfig_Room().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig_Room | PlainMessage<DeviceConfig_Room> | undefined, b: DeviceConfig_Room | PlainMessage<DeviceConfig_Room> | undefined): boolean {
    return proto3.util.equals(DeviceConfig_Room, a, b);
  }
}

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.Room.Type
 */
export enum DeviceConfig_Room_Type {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: TYPE_DEFAULT = 0;
   */
  DEFAULT = 0,

  /**
   * @generated from enum value: TYPE_DUMMY = 1;
   */
  DUMMY = 1,

  /**
   * @generated from enum value: TYPE_REFERENCE = 2;
   */
  REFERENCE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_Room_Type)
proto3.util.setEnumType(DeviceConfig_Room_Type, "wavin.sentio.v1.DeviceConfig.Room.Type", [
  { no: 0, name: "TYPE_DEFAULT" },
  { no: 1, name: "TYPE_DUMMY" },
  { no: 2, name: "TYPE_REFERENCE" },
]);

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.Room.LockMode
 */
export enum DeviceConfig_Room_LockMode {
  /**
   * @generated from enum value: LOCK_MODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: LOCK_MODE_UNLOCKED = 1;
   */
  UNLOCKED = 1,

  /**
   * @generated from enum value: LOCK_MODE_LOCKED = 2;
   */
  LOCKED = 2,

  /**
   * @generated from enum value: LOCK_MODE_HOTEL = 3;
   */
  HOTEL = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_Room_LockMode)
proto3.util.setEnumType(DeviceConfig_Room_LockMode, "wavin.sentio.v1.DeviceConfig.Room.LockMode", [
  { no: 0, name: "LOCK_MODE_UNSPECIFIED" },
  { no: 1, name: "LOCK_MODE_UNLOCKED" },
  { no: 2, name: "LOCK_MODE_LOCKED" },
  { no: 3, name: "LOCK_MODE_HOTEL" },
]);

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.Room.TemperatureState
 */
export enum DeviceConfig_Room_TemperatureState {
  /**
   * @generated from enum value: TEMPERATURE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TEMPERATURE_STATE_IDLE = 1;
   */
  IDLE = 1,

  /**
   * @generated from enum value: TEMPERATURE_STATE_HEATING = 2;
   */
  HEATING = 2,

  /**
   * @generated from enum value: TEMPERATURE_STATE_COOLING = 3;
   */
  COOLING = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_Room_TemperatureState)
proto3.util.setEnumType(DeviceConfig_Room_TemperatureState, "wavin.sentio.v1.DeviceConfig.Room.TemperatureState", [
  { no: 0, name: "TEMPERATURE_STATE_UNSPECIFIED" },
  { no: 1, name: "TEMPERATURE_STATE_IDLE" },
  { no: 2, name: "TEMPERATURE_STATE_HEATING" },
  { no: 3, name: "TEMPERATURE_STATE_COOLING" },
]);

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.Room.DehumidifierState
 */
export enum DeviceConfig_Room_DehumidifierState {
  /**
   * @generated from enum value: DEHUMIDIFIER_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DEHUMIDIFIER_STATE_OFF = 1;
   */
  OFF = 1,

  /**
   * IDLE is OFF and waiting for demand
   *
   * @generated from enum value: DEHUMIDIFIER_STATE_IDLE = 2;
   */
  IDLE = 2,

  /**
   * @generated from enum value: DEHUMIDIFIER_STATE_ACTIVE = 3;
   */
  ACTIVE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_Room_DehumidifierState)
proto3.util.setEnumType(DeviceConfig_Room_DehumidifierState, "wavin.sentio.v1.DeviceConfig.Room.DehumidifierState", [
  { no: 0, name: "DEHUMIDIFIER_STATE_UNSPECIFIED" },
  { no: 1, name: "DEHUMIDIFIER_STATE_OFF" },
  { no: 2, name: "DEHUMIDIFIER_STATE_IDLE" },
  { no: 3, name: "DEHUMIDIFIER_STATE_ACTIVE" },
]);

/**
 * @generated from message wavin.sentio.v1.DeviceConfig.Room.TemperaturePreset
 */
export class DeviceConfig_Room_TemperaturePreset extends Message<DeviceConfig_Room_TemperaturePreset> {
  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.Room.TemperaturePreset.Type type = 1;
   */
  type = DeviceConfig_Room_TemperaturePreset_Type.UNSPECIFIED;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.HCMode hc_mode = 2;
   */
  hcMode = DeviceConfig_HCMode.HC_MODE_UNSPECIFIED;

  /**
   * @generated from field: double setpoint_temperature = 3;
   */
  setpointTemperature = 0;

  /**
   * @generated from field: optional double min_setpoint_temperature = 4;
   */
  minSetpointTemperature?: number;

  /**
   * @generated from field: optional double max_setpoint_temperature = 5;
   */
  maxSetpointTemperature?: number;

  constructor(data?: PartialMessage<DeviceConfig_Room_TemperaturePreset>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig.Room.TemperaturePreset";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(DeviceConfig_Room_TemperaturePreset_Type) },
    { no: 2, name: "hc_mode", kind: "enum", T: proto3.getEnumType(DeviceConfig_HCMode) },
    { no: 3, name: "setpoint_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "min_setpoint_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 5, name: "max_setpoint_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig_Room_TemperaturePreset {
    return new DeviceConfig_Room_TemperaturePreset().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig_Room_TemperaturePreset {
    return new DeviceConfig_Room_TemperaturePreset().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig_Room_TemperaturePreset {
    return new DeviceConfig_Room_TemperaturePreset().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig_Room_TemperaturePreset | PlainMessage<DeviceConfig_Room_TemperaturePreset> | undefined, b: DeviceConfig_Room_TemperaturePreset | PlainMessage<DeviceConfig_Room_TemperaturePreset> | undefined): boolean {
    return proto3.util.equals(DeviceConfig_Room_TemperaturePreset, a, b);
  }
}

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.Room.TemperaturePreset.Type
 */
export enum DeviceConfig_Room_TemperaturePreset_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TYPE_ECO = 1;
   */
  ECO = 1,

  /**
   * @generated from enum value: TYPE_COMFORT = 2;
   */
  COMFORT = 2,

  /**
   * @generated from enum value: TYPE_EXTRA_COMFORT = 3;
   */
  EXTRA_COMFORT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_Room_TemperaturePreset_Type)
proto3.util.setEnumType(DeviceConfig_Room_TemperaturePreset_Type, "wavin.sentio.v1.DeviceConfig.Room.TemperaturePreset.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_ECO" },
  { no: 2, name: "TYPE_COMFORT" },
  { no: 3, name: "TYPE_EXTRA_COMFORT" },
]);

/**
 * @generated from message wavin.sentio.v1.DeviceConfig.Room.SystemMode
 */
export class DeviceConfig_Room_SystemMode extends Message<DeviceConfig_Room_SystemMode> {
  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.Room.SystemMode.Type type = 1;
   */
  type = DeviceConfig_Room_SystemMode_Type.UNSPECIFIED;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.HCMode hc_mode = 2;
   */
  hcMode = DeviceConfig_HCMode.HC_MODE_UNSPECIFIED;

  /**
   * @generated from field: double setpoint_temperature = 3;
   */
  setpointTemperature = 0;

  /**
   * @generated from field: optional double min_setpoint_temperature = 4;
   */
  minSetpointTemperature?: number;

  /**
   * @generated from field: optional double max_setpoint_temperature = 5;
   */
  maxSetpointTemperature?: number;

  constructor(data?: PartialMessage<DeviceConfig_Room_SystemMode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig.Room.SystemMode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(DeviceConfig_Room_SystemMode_Type) },
    { no: 2, name: "hc_mode", kind: "enum", T: proto3.getEnumType(DeviceConfig_HCMode) },
    { no: 3, name: "setpoint_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "min_setpoint_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 5, name: "max_setpoint_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig_Room_SystemMode {
    return new DeviceConfig_Room_SystemMode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig_Room_SystemMode {
    return new DeviceConfig_Room_SystemMode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig_Room_SystemMode {
    return new DeviceConfig_Room_SystemMode().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig_Room_SystemMode | PlainMessage<DeviceConfig_Room_SystemMode> | undefined, b: DeviceConfig_Room_SystemMode | PlainMessage<DeviceConfig_Room_SystemMode> | undefined): boolean {
    return proto3.util.equals(DeviceConfig_Room_SystemMode, a, b);
  }
}

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.Room.SystemMode.Type
 */
export enum DeviceConfig_Room_SystemMode_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TYPE_STANDBY = 1;
   */
  STANDBY = 1,

  /**
   * @generated from enum value: TYPE_VACATION = 2;
   */
  VACATION = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_Room_SystemMode_Type)
proto3.util.setEnumType(DeviceConfig_Room_SystemMode_Type, "wavin.sentio.v1.DeviceConfig.Room.SystemMode.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_STANDBY" },
  { no: 2, name: "TYPE_VACATION" },
]);

/**
 * @generated from message wavin.sentio.v1.DeviceConfig.Room.DehumidificationPreset
 */
export class DeviceConfig_Room_DehumidificationPreset extends Message<DeviceConfig_Room_DehumidificationPreset> {
  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.HCMode hc_mode = 1;
   */
  hcMode = DeviceConfig_HCMode.HC_MODE_UNSPECIFIED;

  /**
   * @generated from field: double setpoint = 2;
   */
  setpoint = 0;

  /**
   * @generated from field: optional double min_humidity_setpoint = 3;
   */
  minHumiditySetpoint?: number;

  /**
   * @generated from field: optional double max_humidity_setpoint = 4;
   */
  maxHumiditySetpoint?: number;

  constructor(data?: PartialMessage<DeviceConfig_Room_DehumidificationPreset>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig.Room.DehumidificationPreset";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hc_mode", kind: "enum", T: proto3.getEnumType(DeviceConfig_HCMode) },
    { no: 2, name: "setpoint", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "min_humidity_setpoint", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 4, name: "max_humidity_setpoint", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig_Room_DehumidificationPreset {
    return new DeviceConfig_Room_DehumidificationPreset().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig_Room_DehumidificationPreset {
    return new DeviceConfig_Room_DehumidificationPreset().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig_Room_DehumidificationPreset {
    return new DeviceConfig_Room_DehumidificationPreset().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig_Room_DehumidificationPreset | PlainMessage<DeviceConfig_Room_DehumidificationPreset> | undefined, b: DeviceConfig_Room_DehumidificationPreset | PlainMessage<DeviceConfig_Room_DehumidificationPreset> | undefined): boolean {
    return proto3.util.equals(DeviceConfig_Room_DehumidificationPreset, a, b);
  }
}

/**
 * @generated from message wavin.sentio.v1.DeviceConfig.Room.WeeklySchedule
 */
export class DeviceConfig_Room_WeeklySchedule extends Message<DeviceConfig_Room_WeeklySchedule> {
  /**
   * currently defaults to Eco, could change in future
   *
   * @generated from field: wavin.sentio.v1.DeviceConfig.Room.TemperaturePreset.Type default_preset_type = 1;
   */
  defaultPresetType = DeviceConfig_Room_TemperaturePreset_Type.UNSPECIFIED;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.Room.WeeklySchedule.Mode schedule_mode = 3;
   */
  scheduleMode = DeviceConfig_Room_WeeklySchedule_Mode.UNSPECIFIED;

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.Room.WeeklySchedule.Interval intervals = 4;
   */
  intervals: DeviceConfig_Room_WeeklySchedule_Interval[] = [];

  constructor(data?: PartialMessage<DeviceConfig_Room_WeeklySchedule>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig.Room.WeeklySchedule";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "default_preset_type", kind: "enum", T: proto3.getEnumType(DeviceConfig_Room_TemperaturePreset_Type) },
    { no: 3, name: "schedule_mode", kind: "enum", T: proto3.getEnumType(DeviceConfig_Room_WeeklySchedule_Mode) },
    { no: 4, name: "intervals", kind: "message", T: DeviceConfig_Room_WeeklySchedule_Interval, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig_Room_WeeklySchedule {
    return new DeviceConfig_Room_WeeklySchedule().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig_Room_WeeklySchedule {
    return new DeviceConfig_Room_WeeklySchedule().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig_Room_WeeklySchedule {
    return new DeviceConfig_Room_WeeklySchedule().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig_Room_WeeklySchedule | PlainMessage<DeviceConfig_Room_WeeklySchedule> | undefined, b: DeviceConfig_Room_WeeklySchedule | PlainMessage<DeviceConfig_Room_WeeklySchedule> | undefined): boolean {
    return proto3.util.equals(DeviceConfig_Room_WeeklySchedule, a, b);
  }
}

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.Room.WeeklySchedule.Mode
 */
export enum DeviceConfig_Room_WeeklySchedule_Mode {
  /**
   * @generated from enum value: MODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: MODE_OFF = 1;
   */
  OFF = 1,

  /**
   * @generated from enum value: MODE_ON = 2;
   */
  ON = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_Room_WeeklySchedule_Mode)
proto3.util.setEnumType(DeviceConfig_Room_WeeklySchedule_Mode, "wavin.sentio.v1.DeviceConfig.Room.WeeklySchedule.Mode", [
  { no: 0, name: "MODE_UNSPECIFIED" },
  { no: 1, name: "MODE_OFF" },
  { no: 2, name: "MODE_ON" },
]);

/**
 * @generated from message wavin.sentio.v1.DeviceConfig.Room.WeeklySchedule.Interval
 */
export class DeviceConfig_Room_WeeklySchedule_Interval extends Message<DeviceConfig_Room_WeeklySchedule_Interval> {
  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.Room.WeeklySchedule.Interval.DayOfWeek day = 1;
   */
  day = DeviceConfig_Room_WeeklySchedule_Interval_DayOfWeek.UNSPECIFIED;

  /**
   * A fixed array of 96 preset types. Each entry is a timeframe of 15 minutes, starting from 00:00
   *
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.Room.TemperaturePreset.Type preset_timeframes = 2;
   */
  presetTimeframes: DeviceConfig_Room_TemperaturePreset_Type[] = [];

  constructor(data?: PartialMessage<DeviceConfig_Room_WeeklySchedule_Interval>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig.Room.WeeklySchedule.Interval";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "day", kind: "enum", T: proto3.getEnumType(DeviceConfig_Room_WeeklySchedule_Interval_DayOfWeek) },
    { no: 2, name: "preset_timeframes", kind: "enum", T: proto3.getEnumType(DeviceConfig_Room_TemperaturePreset_Type), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig_Room_WeeklySchedule_Interval {
    return new DeviceConfig_Room_WeeklySchedule_Interval().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig_Room_WeeklySchedule_Interval {
    return new DeviceConfig_Room_WeeklySchedule_Interval().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig_Room_WeeklySchedule_Interval {
    return new DeviceConfig_Room_WeeklySchedule_Interval().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig_Room_WeeklySchedule_Interval | PlainMessage<DeviceConfig_Room_WeeklySchedule_Interval> | undefined, b: DeviceConfig_Room_WeeklySchedule_Interval | PlainMessage<DeviceConfig_Room_WeeklySchedule_Interval> | undefined): boolean {
    return proto3.util.equals(DeviceConfig_Room_WeeklySchedule_Interval, a, b);
  }
}

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.Room.WeeklySchedule.Interval.DayOfWeek
 */
export enum DeviceConfig_Room_WeeklySchedule_Interval_DayOfWeek {
  /**
   * @generated from enum value: DAY_OF_WEEK_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DAY_OF_WEEK_MONDAY = 1;
   */
  MONDAY = 1,

  /**
   * @generated from enum value: DAY_OF_WEEK_TUESDAY = 2;
   */
  TUESDAY = 2,

  /**
   * @generated from enum value: DAY_OF_WEEK_WEDNESDAY = 3;
   */
  WEDNESDAY = 3,

  /**
   * @generated from enum value: DAY_OF_WEEK_THURSDAY = 4;
   */
  THURSDAY = 4,

  /**
   * @generated from enum value: DAY_OF_WEEK_FRIDAY = 5;
   */
  FRIDAY = 5,

  /**
   * @generated from enum value: DAY_OF_WEEK_SATURDAY = 6;
   */
  SATURDAY = 6,

  /**
   * @generated from enum value: DAY_OF_WEEK_SUNDAY = 7;
   */
  SUNDAY = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_Room_WeeklySchedule_Interval_DayOfWeek)
proto3.util.setEnumType(DeviceConfig_Room_WeeklySchedule_Interval_DayOfWeek, "wavin.sentio.v1.DeviceConfig.Room.WeeklySchedule.Interval.DayOfWeek", [
  { no: 0, name: "DAY_OF_WEEK_UNSPECIFIED" },
  { no: 1, name: "DAY_OF_WEEK_MONDAY" },
  { no: 2, name: "DAY_OF_WEEK_TUESDAY" },
  { no: 3, name: "DAY_OF_WEEK_WEDNESDAY" },
  { no: 4, name: "DAY_OF_WEEK_THURSDAY" },
  { no: 5, name: "DAY_OF_WEEK_FRIDAY" },
  { no: 6, name: "DAY_OF_WEEK_SATURDAY" },
  { no: 7, name: "DAY_OF_WEEK_SUNDAY" },
]);

/**
 * @generated from message wavin.sentio.v1.DeviceConfig.VentilationControl
 */
export class DeviceConfig_VentilationControl extends Message<DeviceConfig_VentilationControl> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional string title = 2;
   */
  title?: string;

  /**
   * @generated from field: optional string title_personalized = 8;
   */
  titlePersonalized?: string;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.VentilationControl.Mode mode = 3;
   */
  mode = DeviceConfig_VentilationControl_Mode.UNSPECIFIED;

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.VentilationControl.Mode allowed_modes = 4;
   */
  allowedModes: DeviceConfig_VentilationControl_Mode[] = [];

  /**
   * @generated from field: optional google.protobuf.Timestamp boost_until = 5;
   */
  boostUntil?: Timestamp;

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.Notification notifications = 6;
   */
  notifications: DeviceConfig_Notification[] = [];

  /**
   * @generated from field: optional wavin.sentio.v1.DeviceConfig.VentilationControl.VentilationFilter ventilation_filter = 7;
   */
  ventilationFilter?: DeviceConfig_VentilationControl_VentilationFilter;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.VentilationControl.Control control = 9;
   */
  control = DeviceConfig_VentilationControl_Control.UNSPECIFIED;

  constructor(data?: PartialMessage<DeviceConfig_VentilationControl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig.VentilationControl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "title_personalized", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "mode", kind: "enum", T: proto3.getEnumType(DeviceConfig_VentilationControl_Mode) },
    { no: 4, name: "allowed_modes", kind: "enum", T: proto3.getEnumType(DeviceConfig_VentilationControl_Mode), repeated: true },
    { no: 5, name: "boost_until", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "notifications", kind: "message", T: DeviceConfig_Notification, repeated: true },
    { no: 7, name: "ventilation_filter", kind: "message", T: DeviceConfig_VentilationControl_VentilationFilter, opt: true },
    { no: 9, name: "control", kind: "enum", T: proto3.getEnumType(DeviceConfig_VentilationControl_Control) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig_VentilationControl {
    return new DeviceConfig_VentilationControl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig_VentilationControl {
    return new DeviceConfig_VentilationControl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig_VentilationControl {
    return new DeviceConfig_VentilationControl().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig_VentilationControl | PlainMessage<DeviceConfig_VentilationControl> | undefined, b: DeviceConfig_VentilationControl | PlainMessage<DeviceConfig_VentilationControl> | undefined): boolean {
    return proto3.util.equals(DeviceConfig_VentilationControl, a, b);
  }
}

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.VentilationControl.Mode
 */
export enum DeviceConfig_VentilationControl_Mode {
  /**
   * @generated from enum value: MODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: MODE_STOPPED = 1;
   */
  STOPPED = 1,

  /**
   * @generated from enum value: MODE_UNOCCUPIED = 2;
   */
  UNOCCUPIED = 2,

  /**
   * @generated from enum value: MODE_ECO = 3;
   */
  ECO = 3,

  /**
   * @generated from enum value: MODE_COMFORT = 4;
   */
  COMFORT = 4,

  /**
   * @generated from enum value: MODE_BOOST = 5;
   */
  BOOST = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_VentilationControl_Mode)
proto3.util.setEnumType(DeviceConfig_VentilationControl_Mode, "wavin.sentio.v1.DeviceConfig.VentilationControl.Mode", [
  { no: 0, name: "MODE_UNSPECIFIED" },
  { no: 1, name: "MODE_STOPPED" },
  { no: 2, name: "MODE_UNOCCUPIED" },
  { no: 3, name: "MODE_ECO" },
  { no: 4, name: "MODE_COMFORT" },
  { no: 5, name: "MODE_BOOST" },
]);

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.VentilationControl.Control
 */
export enum DeviceConfig_VentilationControl_Control {
  /**
   * @generated from enum value: CONTROL_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CONTROL_USER = 1;
   */
  USER = 1,

  /**
   * @generated from enum value: CONTROL_HUMIDITY = 2;
   */
  HUMIDITY = 2,

  /**
   * @generated from enum value: CONTROL_FREE_COOLING = 3;
   */
  FREE_COOLING = 3,

  /**
   * @generated from enum value: CONTROL_EXTERNAL = 4;
   */
  EXTERNAL = 4,

  /**
   * @generated from enum value: CONTROL_CO2 = 5;
   */
  CO2 = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_VentilationControl_Control)
proto3.util.setEnumType(DeviceConfig_VentilationControl_Control, "wavin.sentio.v1.DeviceConfig.VentilationControl.Control", [
  { no: 0, name: "CONTROL_UNSPECIFIED" },
  { no: 1, name: "CONTROL_USER" },
  { no: 2, name: "CONTROL_HUMIDITY" },
  { no: 3, name: "CONTROL_FREE_COOLING" },
  { no: 4, name: "CONTROL_EXTERNAL" },
  { no: 5, name: "CONTROL_CO2" },
]);

/**
 * @generated from message wavin.sentio.v1.DeviceConfig.VentilationControl.VentilationFilter
 */
export class DeviceConfig_VentilationControl_VentilationFilter extends Message<DeviceConfig_VentilationControl_VentilationFilter> {
  /**
   * @generated from field: optional google.protobuf.Timestamp last_changed = 1;
   */
  lastChanged?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp lifetime = 2;
   */
  lifetime?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp lifetime_used = 3;
   */
  lifetimeUsed?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp maintenance_end_time = 4;
   */
  maintenanceEndTime?: Timestamp;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.VentilationControl.VentilationFilter.MaintenanceState maintenance_state = 5;
   */
  maintenanceState = DeviceConfig_VentilationControl_VentilationFilter_MaintenanceState.UNSPECIFIED;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.VentilationControl.VentilationFilter.Command command = 6;
   */
  command = DeviceConfig_VentilationControl_VentilationFilter_Command.UNSPECIFIED;

  constructor(data?: PartialMessage<DeviceConfig_VentilationControl_VentilationFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig.VentilationControl.VentilationFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "last_changed", kind: "message", T: Timestamp, opt: true },
    { no: 2, name: "lifetime", kind: "message", T: Timestamp, opt: true },
    { no: 3, name: "lifetime_used", kind: "message", T: Timestamp, opt: true },
    { no: 4, name: "maintenance_end_time", kind: "message", T: Timestamp, opt: true },
    { no: 5, name: "maintenance_state", kind: "enum", T: proto3.getEnumType(DeviceConfig_VentilationControl_VentilationFilter_MaintenanceState) },
    { no: 6, name: "command", kind: "enum", T: proto3.getEnumType(DeviceConfig_VentilationControl_VentilationFilter_Command) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig_VentilationControl_VentilationFilter {
    return new DeviceConfig_VentilationControl_VentilationFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig_VentilationControl_VentilationFilter {
    return new DeviceConfig_VentilationControl_VentilationFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig_VentilationControl_VentilationFilter {
    return new DeviceConfig_VentilationControl_VentilationFilter().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig_VentilationControl_VentilationFilter | PlainMessage<DeviceConfig_VentilationControl_VentilationFilter> | undefined, b: DeviceConfig_VentilationControl_VentilationFilter | PlainMessage<DeviceConfig_VentilationControl_VentilationFilter> | undefined): boolean {
    return proto3.util.equals(DeviceConfig_VentilationControl_VentilationFilter, a, b);
  }
}

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.VentilationControl.VentilationFilter.MaintenanceState
 */
export enum DeviceConfig_VentilationControl_VentilationFilter_MaintenanceState {
  /**
   * @generated from enum value: MAINTENANCE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: MAINTENANCE_STATE_INACTIVE = 1;
   */
  INACTIVE = 1,

  /**
   * @generated from enum value: MAINTENANCE_STATE_REQUIRED = 2;
   */
  REQUIRED = 2,

  /**
   * @generated from enum value: MAINTENANCE_STATE_AWAITING_TIMED = 3;
   */
  AWAITING_TIMED = 3,

  /**
   * @generated from enum value: MAINTENANCE_STATE_SHUTTING_DOWN = 4;
   */
  SHUTTING_DOWN = 4,

  /**
   * @generated from enum value: MAINTENANCE_STATE_STARTING_UP = 5;
   */
  STARTING_UP = 5,

  /**
   * @generated from enum value: MAINTENANCE_STATE_ERROR = 6;
   */
  ERROR = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_VentilationControl_VentilationFilter_MaintenanceState)
proto3.util.setEnumType(DeviceConfig_VentilationControl_VentilationFilter_MaintenanceState, "wavin.sentio.v1.DeviceConfig.VentilationControl.VentilationFilter.MaintenanceState", [
  { no: 0, name: "MAINTENANCE_STATE_UNSPECIFIED" },
  { no: 1, name: "MAINTENANCE_STATE_INACTIVE" },
  { no: 2, name: "MAINTENANCE_STATE_REQUIRED" },
  { no: 3, name: "MAINTENANCE_STATE_AWAITING_TIMED" },
  { no: 4, name: "MAINTENANCE_STATE_SHUTTING_DOWN" },
  { no: 5, name: "MAINTENANCE_STATE_STARTING_UP" },
  { no: 6, name: "MAINTENANCE_STATE_ERROR" },
]);

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.VentilationControl.VentilationFilter.Command
 */
export enum DeviceConfig_VentilationControl_VentilationFilter_Command {
  /**
   * @generated from enum value: COMMAND_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: COMMAND_STOP_OPERATION = 1;
   */
  STOP_OPERATION = 1,

  /**
   * @generated from enum value: COMMAND_RESUME_OPERATION = 2;
   */
  RESUME_OPERATION = 2,

  /**
   * @generated from enum value: COMMAND_RESET_AIR_FILTER = 3;
   */
  RESET_AIR_FILTER = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_VentilationControl_VentilationFilter_Command)
proto3.util.setEnumType(DeviceConfig_VentilationControl_VentilationFilter_Command, "wavin.sentio.v1.DeviceConfig.VentilationControl.VentilationFilter.Command", [
  { no: 0, name: "COMMAND_UNSPECIFIED" },
  { no: 1, name: "COMMAND_STOP_OPERATION" },
  { no: 2, name: "COMMAND_RESUME_OPERATION" },
  { no: 3, name: "COMMAND_RESET_AIR_FILTER" },
]);

/**
 * VFR as abbreviation
 *
 * @generated from message wavin.sentio.v1.DeviceConfig.VoltageFreeRelay
 */
export class DeviceConfig_VoltageFreeRelay extends Message<DeviceConfig_VoltageFreeRelay> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional bool enabled = 2;
   */
  enabled?: boolean;

  /**
   * @generated from field: optional string title = 3;
   */
  title?: string;

  /**
   * @generated from field: optional string title_personalized = 5;
   */
  titlePersonalized?: string;

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.Notification notifications = 4;
   */
  notifications: DeviceConfig_Notification[] = [];

  constructor(data?: PartialMessage<DeviceConfig_VoltageFreeRelay>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig.VoltageFreeRelay";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "title_personalized", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "notifications", kind: "message", T: DeviceConfig_Notification, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig_VoltageFreeRelay {
    return new DeviceConfig_VoltageFreeRelay().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig_VoltageFreeRelay {
    return new DeviceConfig_VoltageFreeRelay().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig_VoltageFreeRelay {
    return new DeviceConfig_VoltageFreeRelay().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig_VoltageFreeRelay | PlainMessage<DeviceConfig_VoltageFreeRelay> | undefined, b: DeviceConfig_VoltageFreeRelay | PlainMessage<DeviceConfig_VoltageFreeRelay> | undefined): boolean {
    return proto3.util.equals(DeviceConfig_VoltageFreeRelay, a, b);
  }
}

/**
 * @generated from message wavin.sentio.v1.DeviceConfig.OutdoorTemperatureSensor
 */
export class DeviceConfig_OutdoorTemperatureSensor extends Message<DeviceConfig_OutdoorTemperatureSensor> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional double outdoor_temperature = 2;
   */
  outdoorTemperature?: number;

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.Notification notifications = 3;
   */
  notifications: DeviceConfig_Notification[] = [];

  constructor(data?: PartialMessage<DeviceConfig_OutdoorTemperatureSensor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig.OutdoorTemperatureSensor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "outdoor_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 3, name: "notifications", kind: "message", T: DeviceConfig_Notification, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig_OutdoorTemperatureSensor {
    return new DeviceConfig_OutdoorTemperatureSensor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig_OutdoorTemperatureSensor {
    return new DeviceConfig_OutdoorTemperatureSensor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig_OutdoorTemperatureSensor {
    return new DeviceConfig_OutdoorTemperatureSensor().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig_OutdoorTemperatureSensor | PlainMessage<DeviceConfig_OutdoorTemperatureSensor> | undefined, b: DeviceConfig_OutdoorTemperatureSensor | PlainMessage<DeviceConfig_OutdoorTemperatureSensor> | undefined): boolean {
    return proto3.util.equals(DeviceConfig_OutdoorTemperatureSensor, a, b);
  }
}

/**
 * DHW as abbreviation
 *
 * @generated from message wavin.sentio.v1.DeviceConfig.DomesticHotWaterUnit
 */
export class DeviceConfig_DomesticHotWaterUnit extends Message<DeviceConfig_DomesticHotWaterUnit> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional double setpoint_temperature = 2;
   */
  setpointTemperature?: number;

  /**
   * @generated from field: optional string title = 3;
   */
  title?: string;

  /**
   * @generated from field: optional string title_personalized = 7;
   */
  titlePersonalized?: string;

  /**
   * @generated from field: optional double min_temperature = 4;
   */
  minTemperature?: number;

  /**
   * @generated from field: optional double max_temperature = 5;
   */
  maxTemperature?: number;

  /**
   * @generated from field: repeated wavin.sentio.v1.DeviceConfig.Notification notifications = 6;
   */
  notifications: DeviceConfig_Notification[] = [];

  constructor(data?: PartialMessage<DeviceConfig_DomesticHotWaterUnit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig.DomesticHotWaterUnit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "setpoint_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "title_personalized", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "min_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 5, name: "max_temperature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 6, name: "notifications", kind: "message", T: DeviceConfig_Notification, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig_DomesticHotWaterUnit {
    return new DeviceConfig_DomesticHotWaterUnit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig_DomesticHotWaterUnit {
    return new DeviceConfig_DomesticHotWaterUnit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig_DomesticHotWaterUnit {
    return new DeviceConfig_DomesticHotWaterUnit().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig_DomesticHotWaterUnit | PlainMessage<DeviceConfig_DomesticHotWaterUnit> | undefined, b: DeviceConfig_DomesticHotWaterUnit | PlainMessage<DeviceConfig_DomesticHotWaterUnit> | undefined): boolean {
    return proto3.util.equals(DeviceConfig_DomesticHotWaterUnit, a, b);
  }
}

/**
 * @generated from message wavin.sentio.v1.DeviceConfig.VacationSettings
 */
export class DeviceConfig_VacationSettings extends Message<DeviceConfig_VacationSettings> {
  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.VacationSettings.VacationMode vacation_mode = 1;
   */
  vacationMode = DeviceConfig_VacationSettings_VacationMode.UNSPECIFIED;

  /**
   * @generated from field: optional google.protobuf.Timestamp vacation_mode_until = 3;
   */
  vacationModeUntil?: Timestamp;

  constructor(data?: PartialMessage<DeviceConfig_VacationSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig.VacationSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vacation_mode", kind: "enum", T: proto3.getEnumType(DeviceConfig_VacationSettings_VacationMode) },
    { no: 3, name: "vacation_mode_until", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig_VacationSettings {
    return new DeviceConfig_VacationSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig_VacationSettings {
    return new DeviceConfig_VacationSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig_VacationSettings {
    return new DeviceConfig_VacationSettings().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig_VacationSettings | PlainMessage<DeviceConfig_VacationSettings> | undefined, b: DeviceConfig_VacationSettings | PlainMessage<DeviceConfig_VacationSettings> | undefined): boolean {
    return proto3.util.equals(DeviceConfig_VacationSettings, a, b);
  }
}

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.VacationSettings.VacationMode
 */
export enum DeviceConfig_VacationSettings_VacationMode {
  /**
   * @generated from enum value: VACATION_MODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: VACATION_MODE_OFF = 1;
   */
  OFF = 1,

  /**
   * @generated from enum value: VACATION_MODE_ON = 2;
   */
  ON = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_VacationSettings_VacationMode)
proto3.util.setEnumType(DeviceConfig_VacationSettings_VacationMode, "wavin.sentio.v1.DeviceConfig.VacationSettings.VacationMode", [
  { no: 0, name: "VACATION_MODE_UNSPECIFIED" },
  { no: 1, name: "VACATION_MODE_OFF" },
  { no: 2, name: "VACATION_MODE_ON" },
]);

/**
 * @generated from message wavin.sentio.v1.DeviceConfig.Notification
 */
export class DeviceConfig_Notification extends Message<DeviceConfig_Notification> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.Notification.Severity severity = 3;
   */
  severity = DeviceConfig_Notification_Severity.UNSPECIFIED;

  /**
   * @generated from field: wavin.sentio.v1.DeviceConfig.Notification.Key key = 4;
   */
  key = DeviceConfig_Notification_Key.UNSPECIFIED;

  /**
   * Arguments for key
   *
   * @generated from field: repeated string args = 5;
   */
  args: string[] = [];

  constructor(data?: PartialMessage<DeviceConfig_Notification>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.DeviceConfig.Notification";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "timestamp", kind: "message", T: Timestamp },
    { no: 3, name: "severity", kind: "enum", T: proto3.getEnumType(DeviceConfig_Notification_Severity) },
    { no: 4, name: "key", kind: "enum", T: proto3.getEnumType(DeviceConfig_Notification_Key) },
    { no: 5, name: "args", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceConfig_Notification {
    return new DeviceConfig_Notification().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceConfig_Notification {
    return new DeviceConfig_Notification().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceConfig_Notification {
    return new DeviceConfig_Notification().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceConfig_Notification | PlainMessage<DeviceConfig_Notification> | undefined, b: DeviceConfig_Notification | PlainMessage<DeviceConfig_Notification> | undefined): boolean {
    return proto3.util.equals(DeviceConfig_Notification, a, b);
  }
}

/**
 * @generated from enum wavin.sentio.v1.DeviceConfig.Notification.Severity
 */
export enum DeviceConfig_Notification_Severity {
  /**
   * @generated from enum value: SEVERITY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SEVERITY_INFO = 1;
   */
  INFO = 1,

  /**
   * @generated from enum value: SEVERITY_WARNING = 2;
   */
  WARNING = 2,

  /**
   * @generated from enum value: SEVERITY_ERROR = 3;
   */
  ERROR = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_Notification_Severity)
proto3.util.setEnumType(DeviceConfig_Notification_Severity, "wavin.sentio.v1.DeviceConfig.Notification.Severity", [
  { no: 0, name: "SEVERITY_UNSPECIFIED" },
  { no: 1, name: "SEVERITY_INFO" },
  { no: 2, name: "SEVERITY_WARNING" },
  { no: 3, name: "SEVERITY_ERROR" },
]);

/**
 * prefixed with topology objects: room, ventilation, vfr etc.
 *
 * @generated from enum wavin.sentio.v1.DeviceConfig.Notification.Key
 */
export enum DeviceConfig_Notification_Key {
  /**
   * @generated from enum value: KEY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: KEY_ROOM_PERIPHERAL_LOST = 100;
   */
  ROOM_PERIPHERAL_LOST = 100,

  /**
   * @generated from enum value: KEY_ROOM_TEMPERATURE_CRITICALLY_LOW = 101;
   */
  ROOM_TEMPERATURE_CRITICALLY_LOW = 101,

  /**
   * @generated from enum value: KEY_ROOM_TEMPERATURE_CRITICALLY_HIGH = 102;
   */
  ROOM_TEMPERATURE_CRITICALLY_HIGH = 102,

  /**
   * @generated from enum value: KEY_ROOM_FLOOR_TEMPERATURE_CRITICALLY_LOW = 103;
   */
  ROOM_FLOOR_TEMPERATURE_CRITICALLY_LOW = 103,

  /**
   * @generated from enum value: KEY_ROOM_FLOOR_TEMPERATURE_CRITICALLY_HIGH = 104;
   */
  ROOM_FLOOR_TEMPERATURE_CRITICALLY_HIGH = 104,

  /**
   * @generated from enum value: KEY_ROOM_FROST_PROTECTION_ACTIVE = 105;
   */
  ROOM_FROST_PROTECTION_ACTIVE = 105,

  /**
   * @generated from enum value: KEY_ROOM_BATTERY_COVER_OPEN = 106;
   */
  ROOM_BATTERY_COVER_OPEN = 106,

  /**
   * @generated from enum value: KEY_ROOM_INCORRECT_PERIPHERAL_INSTALLED = 107;
   */
  ROOM_INCORRECT_PERIPHERAL_INSTALLED = 107,

  /**
   * @generated from enum value: KEY_ROOM_VALVE_STICKING = 108;
   */
  ROOM_VALVE_STICKING = 108,

  /**
   * @generated from enum value: KEY_ROOM_GENERAL_PROBLEM = 109;
   */
  ROOM_GENERAL_PROBLEM = 109,

  /**
   * @generated from enum value: KEY_ROOM_DRYER_GENERAL_FAULT = 110;
   */
  ROOM_DRYER_GENERAL_FAULT = 110,

  /**
   * @generated from enum value: KEY_ROOM_DRYER_MAINTENANCE = 111;
   */
  ROOM_DRYER_MAINTENANCE = 111,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_UNSPECIFIED = 150;
   */
  ROOM_BLOCKING_HEATING_UNSPECIFIED = 150,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_UNKNOWN = 151;
   */
  ROOM_BLOCKING_HEATING_UNKNOWN = 151,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_FLOOR_TEMP = 152;
   */
  ROOM_BLOCKING_HEATING_FLOOR_TEMP = 152,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_AIR_TEMP = 153;
   */
  ROOM_BLOCKING_HEATING_AIR_TEMP = 153,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_DEW_POINT = 154;
   */
  ROOM_BLOCKING_HEATING_DEW_POINT = 154,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_OUTDOOR_TEMP = 155;
   */
  ROOM_BLOCKING_HEATING_OUTDOOR_TEMP = 155,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_FAULT = 156;
   */
  ROOM_BLOCKING_HEATING_FAULT = 156,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_FAULT_HTCO = 157;
   */
  ROOM_BLOCKING_HEATING_FAULT_HTCO = 157,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_PERIODIC_ACTIVATION = 158;
   */
  ROOM_BLOCKING_HEATING_PERIODIC_ACTIVATION = 158,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_HCW_SOURCE_NOT_RELEASED = 159;
   */
  ROOM_BLOCKING_HEATING_HCW_SOURCE_NOT_RELEASED = 159,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_ROOM_MODE = 160;
   */
  ROOM_BLOCKING_HEATING_ROOM_MODE = 160,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_NO_OUTPUT = 161;
   */
  ROOM_BLOCKING_HEATING_NO_OUTPUT = 161,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_FIRST_OPEN_ACTIVATION = 162;
   */
  ROOM_BLOCKING_HEATING_FIRST_OPEN_ACTIVATION = 162,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_ROOM_WITH_NO_TEMP_SOURCE = 163;
   */
  ROOM_BLOCKING_HEATING_ROOM_WITH_NO_TEMP_SOURCE = 163,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_HCWS_ELEMENTS_BLOCKED = 164;
   */
  ROOM_BLOCKING_HEATING_HCWS_ELEMENTS_BLOCKED = 164,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_SUPPLIER_UNAVAILABLE = 165;
   */
  ROOM_BLOCKING_HEATING_SUPPLIER_UNAVAILABLE = 165,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_HEATING_NO_SUPPLIER = 166;
   */
  ROOM_BLOCKING_HEATING_NO_SUPPLIER = 166,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_UNSPECIFIED = 170;
   */
  ROOM_BLOCKING_COOLING_UNSPECIFIED = 170,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_UNKNOWN = 171;
   */
  ROOM_BLOCKING_COOLING_UNKNOWN = 171,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_FLOOR_TEMP = 172;
   */
  ROOM_BLOCKING_COOLING_FLOOR_TEMP = 172,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_AIR_TEMP = 173;
   */
  ROOM_BLOCKING_COOLING_AIR_TEMP = 173,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_DEW_POINT = 174;
   */
  ROOM_BLOCKING_COOLING_DEW_POINT = 174,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_OUTDOOR_TEMP = 175;
   */
  ROOM_BLOCKING_COOLING_OUTDOOR_TEMP = 175,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_FAULT = 176;
   */
  ROOM_BLOCKING_COOLING_FAULT = 176,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_FAULT_HTCO = 177;
   */
  ROOM_BLOCKING_COOLING_FAULT_HTCO = 177,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_PERIODIC_ACTIVATION = 178;
   */
  ROOM_BLOCKING_COOLING_PERIODIC_ACTIVATION = 178,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_HCW_SOURCE_NOT_RELEASED = 179;
   */
  ROOM_BLOCKING_COOLING_HCW_SOURCE_NOT_RELEASED = 179,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_ROOM_MODE = 180;
   */
  ROOM_BLOCKING_COOLING_ROOM_MODE = 180,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_NO_OUTPUT = 181;
   */
  ROOM_BLOCKING_COOLING_NO_OUTPUT = 181,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_FIRST_OPEN_ACTIVATION = 182;
   */
  ROOM_BLOCKING_COOLING_FIRST_OPEN_ACTIVATION = 182,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_ROOM_WITH_NO_TEMP_SOURCE = 183;
   */
  ROOM_BLOCKING_COOLING_ROOM_WITH_NO_TEMP_SOURCE = 183,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_HCWS_ELEMENTS_BLOCKED = 184;
   */
  ROOM_BLOCKING_COOLING_HCWS_ELEMENTS_BLOCKED = 184,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_SUPPLIER_UNAVAILABLE = 185;
   */
  ROOM_BLOCKING_COOLING_SUPPLIER_UNAVAILABLE = 185,

  /**
   * @generated from enum value: KEY_ROOM_BLOCKING_COOLING_NO_SUPPLIER = 186;
   */
  ROOM_BLOCKING_COOLING_NO_SUPPLIER = 186,

  /**
   * Domestic Hot Water Unit
   *
   * @generated from enum value: KEY_DHW_CRITICALLY_LOW_PRESSURE = 200;
   */
  DHW_CRITICALLY_LOW_PRESSURE = 200,

  /**
   * @generated from enum value: KEY_DHW_LOW_PRESSURE = 201;
   */
  DHW_LOW_PRESSURE = 201,

  /**
   * @generated from enum value: KEY_DHW_HIGH_PRESSURE = 202;
   */
  DHW_HIGH_PRESSURE = 202,

  /**
   * @generated from enum value: KEY_DHW_LOW_ENERGY = 203;
   */
  DHW_LOW_ENERGY = 203,

  /**
   * @generated from enum value: KEY_DHW_HIGH_TEMPERATURE = 204;
   */
  DHW_HIGH_TEMPERATURE = 204,

  /**
   * @generated from enum value: KEY_DHW_DHI_SENSOR_FAILURE = 205;
   */
  DHW_DHI_SENSOR_FAILURE = 205,

  /**
   * @generated from enum value: KEY_DHW_DHO_SENSOR_FAILURE = 206;
   */
  DHW_DHO_SENSOR_FAILURE = 206,

  /**
   * @generated from enum value: KEY_DHW_DHW_SENSOR_FAILURE = 207;
   */
  DHW_DHW_SENSOR_FAILURE = 207,

  /**
   * @generated from enum value: KEY_DHW_DCW_SENSOR_FAILURE = 208;
   */
  DHW_DCW_SENSOR_FAILURE = 208,

  /**
   * @generated from enum value: KEY_DHW_MOTOR_FAILURE = 209;
   */
  DHW_MOTOR_FAILURE = 209,

  /**
   * @generated from enum value: KEY_DHW_PRESSURE_SENSOR_FAILURE = 210;
   */
  DHW_PRESSURE_SENSOR_FAILURE = 210,

  /**
   * @generated from enum value: KEY_DHW_FLOW_SENSOR_FAILURE = 211;
   */
  DHW_FLOW_SENSOR_FAILURE = 211,

  /**
   * @generated from enum value: KEY_DHW_CIRCULATION_SENSOR_FAILURE = 212;
   */
  DHW_CIRCULATION_SENSOR_FAILURE = 212,

  /**
   * @generated from enum value: KEY_DHW_HEATING_MOTOR_FAILURE = 213;
   */
  DHW_HEATING_MOTOR_FAILURE = 213,

  /**
   * @generated from enum value: KEY_DHW_HEATING_MOTOR_STUCK = 214;
   */
  DHW_HEATING_MOTOR_STUCK = 214,

  /**
   * @generated from enum value: KEY_VENTILATION_FILTERS_TO_BE_REPLACED = 300;
   */
  VENTILATION_FILTERS_TO_BE_REPLACED = 300,

  /**
   * @generated from enum value: KEY_VENTILATION_CONNECTION_LOST = 301;
   */
  VENTILATION_CONNECTION_LOST = 301,

  /**
   * @generated from enum value: KEY_VENTILATION_RESTART = 302;
   */
  VENTILATION_RESTART = 302,

  /**
   * @generated from enum value: KEY_VENTILATION_GENERAL_ISSUE = 303;
   */
  VENTILATION_GENERAL_ISSUE = 303,

  /**
   * @generated from enum value: KEY_SYSTEM_EXTERNAL_DEVICE_ISSUE = 400;
   */
  SYSTEM_EXTERNAL_DEVICE_ISSUE = 400,

  /**
   * @generated from enum value: KEY_SYSTEM_HEAT_PUMP_ISSUE = 401;
   */
  SYSTEM_HEAT_PUMP_ISSUE = 401,

  /**
   * @generated from enum value: KEY_SYSTEM_INCOMPATIBLE_PERIPHERAL = 402;
   */
  SYSTEM_INCOMPATIBLE_PERIPHERAL = 402,

  /**
   * @generated from enum value: KEY_SYSTEM_EXTERNAL_HEAT_PUMP_ISSUE = 403;
   */
  SYSTEM_EXTERNAL_HEAT_PUMP_ISSUE = 403,

  /**
   * @generated from enum value: KEY_SYSTEM_HC_MODE_UPDATED = 450;
   */
  SYSTEM_HC_MODE_UPDATED = 450,

  /**
   * @generated from enum value: KEY_SYSTEM_VACATION_MODE_UPDATED = 451;
   */
  SYSTEM_VACATION_MODE_UPDATED = 451,

  /**
   * @generated from enum value: KEY_SYSTEM_STANDBY_MODE_UPDATED = 452;
   */
  SYSTEM_STANDBY_MODE_UPDATED = 452,

  /**
   * Cloud exclusive
   *
   * @generated from enum value: KEY_DEVICE_FIRMWARE_UPDATE_AVAILABLE = 500;
   */
  DEVICE_FIRMWARE_UPDATE_AVAILABLE = 500,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceConfig_Notification_Key)
proto3.util.setEnumType(DeviceConfig_Notification_Key, "wavin.sentio.v1.DeviceConfig.Notification.Key", [
  { no: 0, name: "KEY_UNSPECIFIED" },
  { no: 100, name: "KEY_ROOM_PERIPHERAL_LOST" },
  { no: 101, name: "KEY_ROOM_TEMPERATURE_CRITICALLY_LOW" },
  { no: 102, name: "KEY_ROOM_TEMPERATURE_CRITICALLY_HIGH" },
  { no: 103, name: "KEY_ROOM_FLOOR_TEMPERATURE_CRITICALLY_LOW" },
  { no: 104, name: "KEY_ROOM_FLOOR_TEMPERATURE_CRITICALLY_HIGH" },
  { no: 105, name: "KEY_ROOM_FROST_PROTECTION_ACTIVE" },
  { no: 106, name: "KEY_ROOM_BATTERY_COVER_OPEN" },
  { no: 107, name: "KEY_ROOM_INCORRECT_PERIPHERAL_INSTALLED" },
  { no: 108, name: "KEY_ROOM_VALVE_STICKING" },
  { no: 109, name: "KEY_ROOM_GENERAL_PROBLEM" },
  { no: 110, name: "KEY_ROOM_DRYER_GENERAL_FAULT" },
  { no: 111, name: "KEY_ROOM_DRYER_MAINTENANCE" },
  { no: 150, name: "KEY_ROOM_BLOCKING_HEATING_UNSPECIFIED" },
  { no: 151, name: "KEY_ROOM_BLOCKING_HEATING_UNKNOWN" },
  { no: 152, name: "KEY_ROOM_BLOCKING_HEATING_FLOOR_TEMP" },
  { no: 153, name: "KEY_ROOM_BLOCKING_HEATING_AIR_TEMP" },
  { no: 154, name: "KEY_ROOM_BLOCKING_HEATING_DEW_POINT" },
  { no: 155, name: "KEY_ROOM_BLOCKING_HEATING_OUTDOOR_TEMP" },
  { no: 156, name: "KEY_ROOM_BLOCKING_HEATING_FAULT" },
  { no: 157, name: "KEY_ROOM_BLOCKING_HEATING_FAULT_HTCO" },
  { no: 158, name: "KEY_ROOM_BLOCKING_HEATING_PERIODIC_ACTIVATION" },
  { no: 159, name: "KEY_ROOM_BLOCKING_HEATING_HCW_SOURCE_NOT_RELEASED" },
  { no: 160, name: "KEY_ROOM_BLOCKING_HEATING_ROOM_MODE" },
  { no: 161, name: "KEY_ROOM_BLOCKING_HEATING_NO_OUTPUT" },
  { no: 162, name: "KEY_ROOM_BLOCKING_HEATING_FIRST_OPEN_ACTIVATION" },
  { no: 163, name: "KEY_ROOM_BLOCKING_HEATING_ROOM_WITH_NO_TEMP_SOURCE" },
  { no: 164, name: "KEY_ROOM_BLOCKING_HEATING_HCWS_ELEMENTS_BLOCKED" },
  { no: 165, name: "KEY_ROOM_BLOCKING_HEATING_SUPPLIER_UNAVAILABLE" },
  { no: 166, name: "KEY_ROOM_BLOCKING_HEATING_NO_SUPPLIER" },
  { no: 170, name: "KEY_ROOM_BLOCKING_COOLING_UNSPECIFIED" },
  { no: 171, name: "KEY_ROOM_BLOCKING_COOLING_UNKNOWN" },
  { no: 172, name: "KEY_ROOM_BLOCKING_COOLING_FLOOR_TEMP" },
  { no: 173, name: "KEY_ROOM_BLOCKING_COOLING_AIR_TEMP" },
  { no: 174, name: "KEY_ROOM_BLOCKING_COOLING_DEW_POINT" },
  { no: 175, name: "KEY_ROOM_BLOCKING_COOLING_OUTDOOR_TEMP" },
  { no: 176, name: "KEY_ROOM_BLOCKING_COOLING_FAULT" },
  { no: 177, name: "KEY_ROOM_BLOCKING_COOLING_FAULT_HTCO" },
  { no: 178, name: "KEY_ROOM_BLOCKING_COOLING_PERIODIC_ACTIVATION" },
  { no: 179, name: "KEY_ROOM_BLOCKING_COOLING_HCW_SOURCE_NOT_RELEASED" },
  { no: 180, name: "KEY_ROOM_BLOCKING_COOLING_ROOM_MODE" },
  { no: 181, name: "KEY_ROOM_BLOCKING_COOLING_NO_OUTPUT" },
  { no: 182, name: "KEY_ROOM_BLOCKING_COOLING_FIRST_OPEN_ACTIVATION" },
  { no: 183, name: "KEY_ROOM_BLOCKING_COOLING_ROOM_WITH_NO_TEMP_SOURCE" },
  { no: 184, name: "KEY_ROOM_BLOCKING_COOLING_HCWS_ELEMENTS_BLOCKED" },
  { no: 185, name: "KEY_ROOM_BLOCKING_COOLING_SUPPLIER_UNAVAILABLE" },
  { no: 186, name: "KEY_ROOM_BLOCKING_COOLING_NO_SUPPLIER" },
  { no: 200, name: "KEY_DHW_CRITICALLY_LOW_PRESSURE" },
  { no: 201, name: "KEY_DHW_LOW_PRESSURE" },
  { no: 202, name: "KEY_DHW_HIGH_PRESSURE" },
  { no: 203, name: "KEY_DHW_LOW_ENERGY" },
  { no: 204, name: "KEY_DHW_HIGH_TEMPERATURE" },
  { no: 205, name: "KEY_DHW_DHI_SENSOR_FAILURE" },
  { no: 206, name: "KEY_DHW_DHO_SENSOR_FAILURE" },
  { no: 207, name: "KEY_DHW_DHW_SENSOR_FAILURE" },
  { no: 208, name: "KEY_DHW_DCW_SENSOR_FAILURE" },
  { no: 209, name: "KEY_DHW_MOTOR_FAILURE" },
  { no: 210, name: "KEY_DHW_PRESSURE_SENSOR_FAILURE" },
  { no: 211, name: "KEY_DHW_FLOW_SENSOR_FAILURE" },
  { no: 212, name: "KEY_DHW_CIRCULATION_SENSOR_FAILURE" },
  { no: 213, name: "KEY_DHW_HEATING_MOTOR_FAILURE" },
  { no: 214, name: "KEY_DHW_HEATING_MOTOR_STUCK" },
  { no: 300, name: "KEY_VENTILATION_FILTERS_TO_BE_REPLACED" },
  { no: 301, name: "KEY_VENTILATION_CONNECTION_LOST" },
  { no: 302, name: "KEY_VENTILATION_RESTART" },
  { no: 303, name: "KEY_VENTILATION_GENERAL_ISSUE" },
  { no: 400, name: "KEY_SYSTEM_EXTERNAL_DEVICE_ISSUE" },
  { no: 401, name: "KEY_SYSTEM_HEAT_PUMP_ISSUE" },
  { no: 402, name: "KEY_SYSTEM_INCOMPATIBLE_PERIPHERAL" },
  { no: 403, name: "KEY_SYSTEM_EXTERNAL_HEAT_PUMP_ISSUE" },
  { no: 450, name: "KEY_SYSTEM_HC_MODE_UPDATED" },
  { no: 451, name: "KEY_SYSTEM_VACATION_MODE_UPDATED" },
  { no: 452, name: "KEY_SYSTEM_STANDBY_MODE_UPDATED" },
  { no: 500, name: "KEY_DEVICE_FIRMWARE_UPDATE_AVAILABLE" },
]);

