import { Property } from '~/types/wavin/sentio/v1/device_config_source_pb';

type PropertyValue = typeof Property.prototype.value;

export class ValueModel {
  displayValue: string | number | Uint8Array | undefined;

  value: PropertyValue;

  constructor(value: PropertyValue) {
    this.value = value;
    this.displayValue = ValueModel.createDisplayValue(value);
  }

  static createDisplayValue(propertyValue: PropertyValue) {
    const { value, case: valueCase } = propertyValue;
    switch (valueCase) {
      case 'valD1':
      case 'valD2':
      case 'valD4':
      case 'valU1':
      case 'valU2':
      case 'valU4':
        return value.toString();

      case 'valText':
      case 'valData':
      case 'valServiceCommand':
        return value;

      case 'valD2Fp10':
      case 'valU2Fp10':
        return (value / 10).toFixed(1);

      case 'valD2Fp100':
      case 'valU2Fp100':
        return (value / 100).toFixed(2);

      default:
        return '';
    }
  }

  createNewOriginalValue(
    newValue: string | number | Uint8Array,
  ): PropertyValue {
    const { case: valueCase } = this.value;
    switch (valueCase) {
      case 'valD1':
      case 'valD2':
      case 'valD4':
      case 'valU1':
      case 'valU2':
      case 'valU4':
        return {
          value: parseInt(newValue.toString(), 10),
          case: valueCase,
        };

      case 'valText':
        return { value: newValue.toString(), case: valueCase };
      case 'valData':
      case 'valServiceCommand':
        return { value: newValue as Uint8Array, case: valueCase };

      case 'valD2Fp10':
      case 'valU2Fp10':
        return { value: parseFloat(newValue.toString()) * 10, case: valueCase };

      case 'valD2Fp100':
      case 'valU2Fp100':
        return {
          value: parseFloat(newValue.toString()) * 100,
          case: valueCase,
        };

      default:
        return {
          case: undefined,
        };
    }
  }
}
