import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';

declare module 'notistack' {
  interface VariantOverrides {
    loading: true;
  }
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    '&.notistack-MuiContent-info': {
      backgroundColor: theme.palette.info.main,
    },
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.main,
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: theme.palette.warning.main,
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: theme.palette.error.main,
    },
  }),
);

export function Snackbar() {
  return (
    <SnackbarProvider
      maxSnack={1}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      iconVariant={{
        loading: <CircularProgress size={20} sx={{ mr: 1 }} />,
      }}
      Components={{
        info: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
      }}
    />
  );
}
