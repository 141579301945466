import { Drawer as MUIDrawer, Grid2, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Logo } from '~/pages/layout/components/Logo';
import { DRAWER_WIDTH } from '~/shared/contants/layout-constants';
import { CloseIcon } from '~/shared/components/Icons/Icons';
import { useAppTheme } from '~/shared/theme/theme';
import { DrawerContent } from './DrawerContent';

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function Drawer({ isOpen, onClose }: DrawerProps) {
  const theme = useAppTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MUIDrawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={isMobile ? isOpen : true}
      onClose={onClose}
      ModalProps={{
        keepMounted: isMobile,
      }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: DRAWER_WIDTH,
          borderRight: 'none',
        },
      }}
    >
      <Grid2
        container
        direction="column"
        sx={{
          minHeight: '100%',
          height: '100%',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          py: 10,
        }}
      >
        <Grid2>
          {isMobile && (
            <IconButton
              onClick={onClose}
              sx={{ position: 'absolute', top: 3, left: 3 }}
            >
              <CloseIcon color="primary" />
            </IconButton>
          )}
          <Logo />
        </Grid2>
        <DrawerContent isMobile={isMobile} onClose={onClose} />
      </Grid2>
    </MUIDrawer>
  );
}
