import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { checkAuthAndTerms } from '~/shared/auth/auth-utils';
import { assertIsNonBlankString } from '~/types/assert-type';
import { listDeviceClaimsQuery } from '~/pages/Device/api/listDeviceClaims.queries';
import { DeviceClaimsListModel } from '~/pages/Device/models/DeviceClaimsListModel';

export type DeviceClaimsLoaderData = DeviceClaimsListModel;

export const deviceClaimsLoader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    await checkAuthAndTerms(request);

    const { deviceId } = params;

    assertIsNonBlankString(deviceId);

    return await queryClient.ensureQueryData(listDeviceClaimsQuery(deviceId));
  };
