import { Role } from '~/types/wavin/ignite/v1/user_pb';

export class RoleModel {
  readonly role: Role;

  readonly title: string;

  constructor(role: Role) {
    this.role = role;
    this.title = Role[role];
  }

  isEqual(role: RoleModel) {
    return this.role === role.role;
  }
}
