import { assertIsNonBlankString } from '~/types/assert-type';

export const createIdFromDeviceName = (name: string) => {
  assertIsNonBlankString(name);
  return name.replace('devices/', '');
};

export const createNameFromDeviceId = (id: string) => {
  assertIsNonBlankString(id);
  return `devices/${id}`;
};

export const createFirmwareNameFromDeviceId = (id: string) => {
  assertIsNonBlankString(id);
  return `devices/${id}/updater`;
};
