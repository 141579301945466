import { RoleModel } from '~/shared/models/role/RoleModel';
import { Device, User } from '~/types/wavin/ignite/v1/user_pb';

export class UserModel {
  readonly name: string;

  readonly devices: Device[];

  readonly termsAcceptedVersion?: string;

  readonly languageCode?: string;

  readonly roles: RoleModel[];

  readonly email: string;

  constructor(user: User) {
    this.name = user.name;
    this.devices = user.devices;
    this.termsAcceptedVersion = user.termsAcceptedVersion;
    this.languageCode = user.languageCode;
    this.roles = user.roles.map((role) => new RoleModel(role));
    this.email = user.email;
  }
}
