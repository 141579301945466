import { queryOptions } from '@tanstack/react-query';
import { BlazeDeviceQueryKey } from '~/services/queryKeys';
import { listFirmwares } from '~/services/api/fetchers/listFirmware.api';
import { FirmwareListModel } from '~/shared/models/firmwareList/FirmwareListModel';

export const listFirmwareQuery = queryOptions({
  queryKey: [BlazeDeviceQueryKey.LIST_FIRMWARES],
  queryFn: async () => {
    const response = await listFirmwares();
    return new FirmwareListModel(response);
  },
});
