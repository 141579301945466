import { formatTimestamp } from '~/shared/utils/formatTimestamp';
import { DeviceAccessKey } from '~/types/wavin/blaze/v1/device_access_key_pb';
import { User } from '~/types/wavin/blaze/v1/user_pb';

export class DeviceAccessKeyModel {
  name: string;

  user?: User;

  accessKey: string;

  expirationTime?: string;

  claimExpirationTime?: string;

  constructor(accessKey: DeviceAccessKey) {
    this.name = accessKey.name;
    this.user = accessKey.user;
    this.accessKey = accessKey.accessKey;
    this.expirationTime = formatTimestamp(accessKey.expirationTime);
    this.claimExpirationTime = formatTimestamp(accessKey.claimExpirationTime);
  }
}
