// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file wavin/blaze/v1/device_claim.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { User } from "./user_pb.js";

/**
 * A device claim is a permission to access a device by a user
 *
 * @generated from message wavin.blaze.v1.DeviceClaim
 */
export class DeviceClaim extends Message<DeviceClaim> {
  /**
   * DeviceClaim resource name, format: devices/{device}/claims/{claim}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 2;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp delete_time = 3;
   */
  deleteTime?: Timestamp;

  /**
   * User who holds the claim
   *
   * @generated from field: wavin.blaze.v1.User user = 4;
   */
  user?: User;

  /**
   * @generated from field: wavin.blaze.v1.DeviceClaim.Type type = 5;
   */
  type = DeviceClaim_Type.UNSPECIFIED;

  /**
   * @generated from field: wavin.blaze.v1.DeviceClaim.Method method = 6;
   */
  method = DeviceClaim_Method.CCU_BUTTON_CONFIRMATION;

  constructor(data?: PartialMessage<DeviceClaim>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.DeviceClaim";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "create_time", kind: "message", T: Timestamp },
    { no: 3, name: "delete_time", kind: "message", T: Timestamp },
    { no: 4, name: "user", kind: "message", T: User },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(DeviceClaim_Type) },
    { no: 6, name: "method", kind: "enum", T: proto3.getEnumType(DeviceClaim_Method) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceClaim {
    return new DeviceClaim().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceClaim {
    return new DeviceClaim().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceClaim {
    return new DeviceClaim().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceClaim | PlainMessage<DeviceClaim> | undefined, b: DeviceClaim | PlainMessage<DeviceClaim> | undefined): boolean {
    return proto3.util.equals(DeviceClaim, a, b);
  }
}

/**
 * @generated from enum wavin.blaze.v1.DeviceClaim.Type
 */
export enum DeviceClaim_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TYPE_PERMANENT_ACCESS = 1;
   */
  PERMANENT_ACCESS = 1,

  /**
   * For temporary access delete time indicates when claim is deleted
   *
   * @generated from enum value: TYPE_TEMPORARY_ACCESS = 2;
   */
  TEMPORARY_ACCESS = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceClaim_Type)
proto3.util.setEnumType(DeviceClaim_Type, "wavin.blaze.v1.DeviceClaim.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_PERMANENT_ACCESS" },
  { no: 2, name: "TYPE_TEMPORARY_ACCESS" },
]);

/**
 * @generated from enum wavin.blaze.v1.DeviceClaim.Method
 */
export enum DeviceClaim_Method {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: METHOD_CCU_BUTTON_CONFIRMATION = 0;
   */
  CCU_BUTTON_CONFIRMATION = 0,

  /**
   * @generated from enum value: METHOD_CCU_ACCESS_CODE = 1;
   */
  CCU_ACCESS_CODE = 1,

  /**
   * @generated from enum value: METHOD_ACCESS_KEY = 2;
   */
  ACCESS_KEY = 2,

  /**
   * @generated from enum value: METHOD_INSTALLER_PASSWORD = 3;
   */
  INSTALLER_PASSWORD = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceClaim_Method)
proto3.util.setEnumType(DeviceClaim_Method, "wavin.blaze.v1.DeviceClaim.Method", [
  { no: 0, name: "METHOD_CCU_BUTTON_CONFIRMATION" },
  { no: 1, name: "METHOD_CCU_ACCESS_CODE" },
  { no: 2, name: "METHOD_ACCESS_KEY" },
  { no: 3, name: "METHOD_INSTALLER_PASSWORD" },
]);

