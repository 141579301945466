// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file wavin/blaze/v1/firmware.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { User } from "./user_pb.js";

/**
 * A firmware package for a device
 *
 * @generated from message wavin.blaze.v1.Firmware
 */
export class Firmware extends Message<Firmware> {
  /**
   * Firmware resource name, format: firmwares/{firmware}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 2;
   */
  createTime?: Timestamp;

  /**
   * Time binary package is created
   *
   * @generated from field: google.protobuf.Timestamp package_time = 3;
   */
  packageTime?: Timestamp;

  /**
   * Timestamp when firmware is released GA
   *
   * @generated from field: optional google.protobuf.Timestamp release_time = 4;
   */
  releaseTime?: Timestamp;

  /**
   * @generated from field: string version = 5;
   */
  version = "";

  /**
   * @generated from field: wavin.blaze.v1.Firmware.Status status = 6;
   */
  status = Firmware_Status.UNSPECIFIED;

  /**
   * @generated from field: wavin.blaze.v1.User uploaded_by = 7;
   */
  uploadedBy?: User;

  /**
   * @generated from field: wavin.blaze.v1.User released_by = 8;
   */
  releasedBy?: User;

  /**
   * The binary package data
   *
   * @generated from field: optional bytes package = 9;
   */
  package?: Uint8Array;

  /**
   * @generated from field: optional string title = 10;
   */
  title?: string;

  /**
   * @generated from field: optional string release_notes = 11;
   */
  releaseNotes?: string;

  constructor(data?: PartialMessage<Firmware>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.Firmware";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "create_time", kind: "message", T: Timestamp },
    { no: 3, name: "package_time", kind: "message", T: Timestamp },
    { no: 4, name: "release_time", kind: "message", T: Timestamp, opt: true },
    { no: 5, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(Firmware_Status) },
    { no: 7, name: "uploaded_by", kind: "message", T: User },
    { no: 8, name: "released_by", kind: "message", T: User },
    { no: 9, name: "package", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
    { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "release_notes", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Firmware {
    return new Firmware().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Firmware {
    return new Firmware().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Firmware {
    return new Firmware().fromJsonString(jsonString, options);
  }

  static equals(a: Firmware | PlainMessage<Firmware> | undefined, b: Firmware | PlainMessage<Firmware> | undefined): boolean {
    return proto3.util.equals(Firmware, a, b);
  }
}

/**
 * @generated from enum wavin.blaze.v1.Firmware.Status
 */
export enum Firmware_Status {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * General availability
   *
   * @generated from enum value: STATUS_GA = 1;
   */
  GA = 1,

  /**
   * @generated from enum value: STATUS_BETA = 2;
   */
  BETA = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Firmware_Status)
proto3.util.setEnumType(Firmware_Status, "wavin.blaze.v1.Firmware.Status", [
  { no: 0, name: "STATUS_UNSPECIFIED" },
  { no: 1, name: "STATUS_GA" },
  { no: 2, name: "STATUS_BETA" },
]);

