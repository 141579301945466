// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file wavin/blaze/v1/blaze_device_service.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Device, Device_ReleaseMode } from "./device_pb.js";
import { DeviceConfig } from "./device_config_pb.js";
import { DeviceAccessKey } from "./device_access_key_pb.js";
import { DeviceClaim } from "./device_claim_pb.js";
import { Firmware } from "./firmware_pb.js";
import { BrokerConfig } from "../../sentio/v1/broker_pb.js";

/**
 * @generated from message wavin.blaze.v1.GetDeviceRequest
 */
export class GetDeviceRequest extends Message<GetDeviceRequest> {
  /**
   * Resource name of device, format: devices/{device}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Include source data
   *
   * @generated from field: bool include_source = 2;
   */
  includeSource = false;

  constructor(data?: PartialMessage<GetDeviceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.GetDeviceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "include_source", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDeviceRequest {
    return new GetDeviceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDeviceRequest {
    return new GetDeviceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDeviceRequest {
    return new GetDeviceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDeviceRequest | PlainMessage<GetDeviceRequest> | undefined, b: GetDeviceRequest | PlainMessage<GetDeviceRequest> | undefined): boolean {
    return proto3.util.equals(GetDeviceRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.SetDeviceReleaseModeRequest
 */
export class SetDeviceReleaseModeRequest extends Message<SetDeviceReleaseModeRequest> {
  /**
   * Resource name of device, format: devices/{device}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: wavin.blaze.v1.Device.ReleaseMode release_mode = 2;
   */
  releaseMode = Device_ReleaseMode.AUTOMATIC;

  constructor(data?: PartialMessage<SetDeviceReleaseModeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.SetDeviceReleaseModeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "release_mode", kind: "enum", T: proto3.getEnumType(Device_ReleaseMode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetDeviceReleaseModeRequest {
    return new SetDeviceReleaseModeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetDeviceReleaseModeRequest {
    return new SetDeviceReleaseModeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetDeviceReleaseModeRequest {
    return new SetDeviceReleaseModeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetDeviceReleaseModeRequest | PlainMessage<SetDeviceReleaseModeRequest> | undefined, b: SetDeviceReleaseModeRequest | PlainMessage<SetDeviceReleaseModeRequest> | undefined): boolean {
    return proto3.util.equals(SetDeviceReleaseModeRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.ListDevicesRequest
 */
export class ListDevicesRequest extends Message<ListDevicesRequest> {
  /**
   * Requested page size. Server may return fewer items than requested.
   * If unspecified, server will pick an appropriate default.
   *
   * @generated from field: int64 page_size = 1;
   */
  pageSize = protoInt64.zero;

  /**
   * A token identifying a page of results the server should return.
   *
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  /**
   * Number of results to skip after page_token
   *
   * @generated from field: int64 skip = 3;
   */
  skip = protoInt64.zero;

  constructor(data?: PartialMessage<ListDevicesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.ListDevicesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "skip", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDevicesRequest {
    return new ListDevicesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDevicesRequest {
    return new ListDevicesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDevicesRequest {
    return new ListDevicesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListDevicesRequest | PlainMessage<ListDevicesRequest> | undefined, b: ListDevicesRequest | PlainMessage<ListDevicesRequest> | undefined): boolean {
    return proto3.util.equals(ListDevicesRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.ListDevicesResponse
 */
export class ListDevicesResponse extends Message<ListDevicesResponse> {
  /**
   * @generated from field: repeated wavin.blaze.v1.Device devices = 1;
   */
  devices: Device[] = [];

  /**
   * A token to retrieve next page of results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListDevicesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.ListDevicesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "devices", kind: "message", T: Device, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDevicesResponse {
    return new ListDevicesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDevicesResponse {
    return new ListDevicesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDevicesResponse {
    return new ListDevicesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListDevicesResponse | PlainMessage<ListDevicesResponse> | undefined, b: ListDevicesResponse | PlainMessage<ListDevicesResponse> | undefined): boolean {
    return proto3.util.equals(ListDevicesResponse, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.ResetDeviceRequest
 */
export class ResetDeviceRequest extends Message<ResetDeviceRequest> {
  /**
   * Resource name of device, format: devices/{device}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<ResetDeviceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.ResetDeviceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetDeviceRequest {
    return new ResetDeviceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetDeviceRequest {
    return new ResetDeviceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetDeviceRequest {
    return new ResetDeviceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResetDeviceRequest | PlainMessage<ResetDeviceRequest> | undefined, b: ResetDeviceRequest | PlainMessage<ResetDeviceRequest> | undefined): boolean {
    return proto3.util.equals(ResetDeviceRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.SendDeviceConfigRequest
 */
export class SendDeviceConfigRequest extends Message<SendDeviceConfigRequest> {
  /**
   * Resource name of device, format: devices/{device}
   *
   * @generated from field: string device_name = 1;
   */
  deviceName = "";

  /**
   * Device config with only changed properties (set on the wire will be applied)
   *
   * @generated from field: wavin.blaze.v1.DeviceConfig config = 2;
   */
  config?: DeviceConfig;

  constructor(data?: PartialMessage<SendDeviceConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.SendDeviceConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "device_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "config", kind: "message", T: DeviceConfig },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendDeviceConfigRequest {
    return new SendDeviceConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendDeviceConfigRequest {
    return new SendDeviceConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendDeviceConfigRequest {
    return new SendDeviceConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendDeviceConfigRequest | PlainMessage<SendDeviceConfigRequest> | undefined, b: SendDeviceConfigRequest | PlainMessage<SendDeviceConfigRequest> | undefined): boolean {
    return proto3.util.equals(SendDeviceConfigRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.StreamDeviceRequest
 */
export class StreamDeviceRequest extends Message<StreamDeviceRequest> {
  /**
   * Resource names of devices, format: devices/{device}
   *
   * @generated from field: repeated string device_names = 1;
   */
  deviceNames: string[] = [];

  /**
   * Attributes of stream
   *
   * @generated from field: map<string, string> attributes = 2;
   */
  attributes: { [key: string]: string } = {};

  /**
   * Include device.last_config only when config has changed. Default: last_config is included when device changed.
   *
   * @generated from field: bool include_config_on_change_only = 3;
   */
  includeConfigOnChangeOnly = false;

  constructor(data?: PartialMessage<StreamDeviceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.StreamDeviceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "device_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "attributes", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 3, name: "include_config_on_change_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StreamDeviceRequest {
    return new StreamDeviceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StreamDeviceRequest {
    return new StreamDeviceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StreamDeviceRequest {
    return new StreamDeviceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StreamDeviceRequest | PlainMessage<StreamDeviceRequest> | undefined, b: StreamDeviceRequest | PlainMessage<StreamDeviceRequest> | undefined): boolean {
    return proto3.util.equals(StreamDeviceRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.StreamDeviceResponse
 */
export class StreamDeviceResponse extends Message<StreamDeviceResponse> {
  /**
   * Resource name of device, format: devices/{device}
   *
   * @generated from field: string device_name = 1;
   */
  deviceName = "";

  /**
   * Attributes of stream
   *
   * @generated from field: map<string, string> attributes = 2;
   */
  attributes: { [key: string]: string } = {};

  /**
   * Last known device state (in case of error, last known state is also sent to allow recovery)
   *
   * @generated from field: wavin.blaze.v1.Device device = 3;
   */
  device?: Device;

  constructor(data?: PartialMessage<StreamDeviceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.StreamDeviceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "device_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "attributes", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 3, name: "device", kind: "message", T: Device },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StreamDeviceResponse {
    return new StreamDeviceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StreamDeviceResponse {
    return new StreamDeviceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StreamDeviceResponse {
    return new StreamDeviceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StreamDeviceResponse | PlainMessage<StreamDeviceResponse> | undefined, b: StreamDeviceResponse | PlainMessage<StreamDeviceResponse> | undefined): boolean {
    return proto3.util.equals(StreamDeviceResponse, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.CreateDeviceAccessKeyRequest
 */
export class CreateDeviceAccessKeyRequest extends Message<CreateDeviceAccessKeyRequest> {
  /**
   * Resource name of parent device, format: devices/{device}
   *
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: wavin.blaze.v1.DeviceAccessKey access_key = 2;
   */
  accessKey?: DeviceAccessKey;

  constructor(data?: PartialMessage<CreateDeviceAccessKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.CreateDeviceAccessKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "access_key", kind: "message", T: DeviceAccessKey },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDeviceAccessKeyRequest {
    return new CreateDeviceAccessKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDeviceAccessKeyRequest {
    return new CreateDeviceAccessKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDeviceAccessKeyRequest {
    return new CreateDeviceAccessKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDeviceAccessKeyRequest | PlainMessage<CreateDeviceAccessKeyRequest> | undefined, b: CreateDeviceAccessKeyRequest | PlainMessage<CreateDeviceAccessKeyRequest> | undefined): boolean {
    return proto3.util.equals(CreateDeviceAccessKeyRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.DeleteDeviceAccessKeyRequest
 */
export class DeleteDeviceAccessKeyRequest extends Message<DeleteDeviceAccessKeyRequest> {
  /**
   * Resource name of device access key to be deleted, format: devices/{device}/access-keys/{access-key}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteDeviceAccessKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.DeleteDeviceAccessKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteDeviceAccessKeyRequest {
    return new DeleteDeviceAccessKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteDeviceAccessKeyRequest {
    return new DeleteDeviceAccessKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteDeviceAccessKeyRequest {
    return new DeleteDeviceAccessKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteDeviceAccessKeyRequest | PlainMessage<DeleteDeviceAccessKeyRequest> | undefined, b: DeleteDeviceAccessKeyRequest | PlainMessage<DeleteDeviceAccessKeyRequest> | undefined): boolean {
    return proto3.util.equals(DeleteDeviceAccessKeyRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.ListDeviceAccessKeysRequest
 */
export class ListDeviceAccessKeysRequest extends Message<ListDeviceAccessKeysRequest> {
  /**
   * Resource name of parent device, format: devices/{device}
   *
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * Requested page size. Server may return fewer items than requested.
   * If unspecified, server will pick an appropriate default.
   *
   * @generated from field: int64 page_size = 2;
   */
  pageSize = protoInt64.zero;

  /**
   * A token identifying a page of results the server should return.
   *
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  /**
   * Number of results to skip after page_token
   *
   * @generated from field: int64 skip = 4;
   */
  skip = protoInt64.zero;

  constructor(data?: PartialMessage<ListDeviceAccessKeysRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.ListDeviceAccessKeysRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "skip", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDeviceAccessKeysRequest {
    return new ListDeviceAccessKeysRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDeviceAccessKeysRequest {
    return new ListDeviceAccessKeysRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDeviceAccessKeysRequest {
    return new ListDeviceAccessKeysRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListDeviceAccessKeysRequest | PlainMessage<ListDeviceAccessKeysRequest> | undefined, b: ListDeviceAccessKeysRequest | PlainMessage<ListDeviceAccessKeysRequest> | undefined): boolean {
    return proto3.util.equals(ListDeviceAccessKeysRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.ListDeviceAccessKeysResponse
 */
export class ListDeviceAccessKeysResponse extends Message<ListDeviceAccessKeysResponse> {
  /**
   * @generated from field: repeated wavin.blaze.v1.DeviceAccessKey access_keys = 1;
   */
  accessKeys: DeviceAccessKey[] = [];

  /**
   * A token to retrieve next page of results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListDeviceAccessKeysResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.ListDeviceAccessKeysResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_keys", kind: "message", T: DeviceAccessKey, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDeviceAccessKeysResponse {
    return new ListDeviceAccessKeysResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDeviceAccessKeysResponse {
    return new ListDeviceAccessKeysResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDeviceAccessKeysResponse {
    return new ListDeviceAccessKeysResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListDeviceAccessKeysResponse | PlainMessage<ListDeviceAccessKeysResponse> | undefined, b: ListDeviceAccessKeysResponse | PlainMessage<ListDeviceAccessKeysResponse> | undefined): boolean {
    return proto3.util.equals(ListDeviceAccessKeysResponse, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.CreateDeviceClaimRequest
 */
export class CreateDeviceClaimRequest extends Message<CreateDeviceClaimRequest> {
  /**
   * Registration key on device or from QR code: format: XXXXX-XXXXX-XXXX
   *
   * @generated from field: string registration_key = 1;
   */
  registrationKey = "";

  constructor(data?: PartialMessage<CreateDeviceClaimRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.CreateDeviceClaimRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "registration_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDeviceClaimRequest {
    return new CreateDeviceClaimRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDeviceClaimRequest {
    return new CreateDeviceClaimRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDeviceClaimRequest {
    return new CreateDeviceClaimRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDeviceClaimRequest | PlainMessage<CreateDeviceClaimRequest> | undefined, b: CreateDeviceClaimRequest | PlainMessage<CreateDeviceClaimRequest> | undefined): boolean {
    return proto3.util.equals(CreateDeviceClaimRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.CreateDeviceClaimByKeyRequest
 */
export class CreateDeviceClaimByKeyRequest extends Message<CreateDeviceClaimByKeyRequest> {
  /**
   * Registration key on device or from QR code: format: XXXXX-XXXXX-XXXX
   *
   * @generated from field: string registration_key = 1;
   */
  registrationKey = "";

  /**
   * @generated from oneof wavin.blaze.v1.CreateDeviceClaimByKeyRequest.key
   */
  key: {
    /**
     * The device claim is valid for 14 days
     *
     * @generated from field: string ccu_access_code = 2;
     */
    value: string;
    case: "ccuAccessCode";
  } | {
    /**
     * The device claim is valid for the period defined when access_key is generated: format XXXX-XXXX-XXXX-XXXX
     *
     * @generated from field: string access_key = 3;
     */
    value: string;
    case: "accessKey";
  } | {
    /**
     * The device claim is permanently valid
     *
     * @generated from field: string installer_password = 4;
     */
    value: string;
    case: "installerPassword";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CreateDeviceClaimByKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.CreateDeviceClaimByKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "registration_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ccu_access_code", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "key" },
    { no: 3, name: "access_key", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "key" },
    { no: 4, name: "installer_password", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "key" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDeviceClaimByKeyRequest {
    return new CreateDeviceClaimByKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDeviceClaimByKeyRequest {
    return new CreateDeviceClaimByKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDeviceClaimByKeyRequest {
    return new CreateDeviceClaimByKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDeviceClaimByKeyRequest | PlainMessage<CreateDeviceClaimByKeyRequest> | undefined, b: CreateDeviceClaimByKeyRequest | PlainMessage<CreateDeviceClaimByKeyRequest> | undefined): boolean {
    return proto3.util.equals(CreateDeviceClaimByKeyRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.CreateFirmwareRequest
 */
export class CreateFirmwareRequest extends Message<CreateFirmwareRequest> {
  /**
   * @generated from field: bytes firmware_package = 1;
   */
  firmwarePackage = new Uint8Array(0);

  constructor(data?: PartialMessage<CreateFirmwareRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.CreateFirmwareRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "firmware_package", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFirmwareRequest {
    return new CreateFirmwareRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFirmwareRequest {
    return new CreateFirmwareRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFirmwareRequest {
    return new CreateFirmwareRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFirmwareRequest | PlainMessage<CreateFirmwareRequest> | undefined, b: CreateFirmwareRequest | PlainMessage<CreateFirmwareRequest> | undefined): boolean {
    return proto3.util.equals(CreateFirmwareRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.DeleteDeviceClaimRequest
 */
export class DeleteDeviceClaimRequest extends Message<DeleteDeviceClaimRequest> {
  /**
   * Resource name of device claim to be deleted, format: devices/{device}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteDeviceClaimRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.DeleteDeviceClaimRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteDeviceClaimRequest {
    return new DeleteDeviceClaimRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteDeviceClaimRequest {
    return new DeleteDeviceClaimRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteDeviceClaimRequest {
    return new DeleteDeviceClaimRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteDeviceClaimRequest | PlainMessage<DeleteDeviceClaimRequest> | undefined, b: DeleteDeviceClaimRequest | PlainMessage<DeleteDeviceClaimRequest> | undefined): boolean {
    return proto3.util.equals(DeleteDeviceClaimRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.DeleteAllDeviceClaimsRequest
 */
export class DeleteAllDeviceClaimsRequest extends Message<DeleteAllDeviceClaimsRequest> {
  constructor(data?: PartialMessage<DeleteAllDeviceClaimsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.DeleteAllDeviceClaimsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAllDeviceClaimsRequest {
    return new DeleteAllDeviceClaimsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAllDeviceClaimsRequest {
    return new DeleteAllDeviceClaimsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAllDeviceClaimsRequest {
    return new DeleteAllDeviceClaimsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAllDeviceClaimsRequest | PlainMessage<DeleteAllDeviceClaimsRequest> | undefined, b: DeleteAllDeviceClaimsRequest | PlainMessage<DeleteAllDeviceClaimsRequest> | undefined): boolean {
    return proto3.util.equals(DeleteAllDeviceClaimsRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.ListDeviceClaimsRequest
 */
export class ListDeviceClaimsRequest extends Message<ListDeviceClaimsRequest> {
  /**
   * Resource name of parent device, format: devices/{device}
   *
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * Requested page size. Server may return fewer items than requested.
   * If unspecified, server will pick an appropriate default.
   *
   * @generated from field: int64 page_size = 2;
   */
  pageSize = protoInt64.zero;

  /**
   * A token identifying a page of results the server should return.
   *
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  /**
   * Number of results to skip after page_token
   *
   * @generated from field: int64 skip = 4;
   */
  skip = protoInt64.zero;

  constructor(data?: PartialMessage<ListDeviceClaimsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.ListDeviceClaimsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "skip", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDeviceClaimsRequest {
    return new ListDeviceClaimsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDeviceClaimsRequest {
    return new ListDeviceClaimsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDeviceClaimsRequest {
    return new ListDeviceClaimsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListDeviceClaimsRequest | PlainMessage<ListDeviceClaimsRequest> | undefined, b: ListDeviceClaimsRequest | PlainMessage<ListDeviceClaimsRequest> | undefined): boolean {
    return proto3.util.equals(ListDeviceClaimsRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.ListDeviceClaimsResponse
 */
export class ListDeviceClaimsResponse extends Message<ListDeviceClaimsResponse> {
  /**
   * @generated from field: repeated wavin.blaze.v1.DeviceClaim claims = 1;
   */
  claims: DeviceClaim[] = [];

  /**
   * A token to retrieve next page of results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListDeviceClaimsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.ListDeviceClaimsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "claims", kind: "message", T: DeviceClaim, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDeviceClaimsResponse {
    return new ListDeviceClaimsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDeviceClaimsResponse {
    return new ListDeviceClaimsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDeviceClaimsResponse {
    return new ListDeviceClaimsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListDeviceClaimsResponse | PlainMessage<ListDeviceClaimsResponse> | undefined, b: ListDeviceClaimsResponse | PlainMessage<ListDeviceClaimsResponse> | undefined): boolean {
    return proto3.util.equals(ListDeviceClaimsResponse, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.UpdateDeviceFirmwareRequest
 */
export class UpdateDeviceFirmwareRequest extends Message<UpdateDeviceFirmwareRequest> {
  /**
   * The resource name of a device, format: devices/{device}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<UpdateDeviceFirmwareRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.UpdateDeviceFirmwareRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDeviceFirmwareRequest {
    return new UpdateDeviceFirmwareRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDeviceFirmwareRequest {
    return new UpdateDeviceFirmwareRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDeviceFirmwareRequest {
    return new UpdateDeviceFirmwareRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDeviceFirmwareRequest | PlainMessage<UpdateDeviceFirmwareRequest> | undefined, b: UpdateDeviceFirmwareRequest | PlainMessage<UpdateDeviceFirmwareRequest> | undefined): boolean {
    return proto3.util.equals(UpdateDeviceFirmwareRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.ListFirmwaresRequest
 */
export class ListFirmwaresRequest extends Message<ListFirmwaresRequest> {
  /**
   * Requested page size. Server may return fewer items than requested.
   * If unspecified, server will pick an appropriate default.
   *
   * @generated from field: int64 page_size = 1;
   */
  pageSize = protoInt64.zero;

  /**
   * A token identifying a page of results the server should return.
   *
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  /**
   * Number of results to skip after page_token
   *
   * @generated from field: int64 skip = 3;
   */
  skip = protoInt64.zero;

  constructor(data?: PartialMessage<ListFirmwaresRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.ListFirmwaresRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "skip", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFirmwaresRequest {
    return new ListFirmwaresRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFirmwaresRequest {
    return new ListFirmwaresRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFirmwaresRequest {
    return new ListFirmwaresRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListFirmwaresRequest | PlainMessage<ListFirmwaresRequest> | undefined, b: ListFirmwaresRequest | PlainMessage<ListFirmwaresRequest> | undefined): boolean {
    return proto3.util.equals(ListFirmwaresRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.ListFirmwaresResponse
 */
export class ListFirmwaresResponse extends Message<ListFirmwaresResponse> {
  /**
   * @generated from field: repeated wavin.blaze.v1.Firmware firmwares = 1;
   */
  firmwares: Firmware[] = [];

  /**
   * A token to retrieve next page of results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListFirmwaresResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.ListFirmwaresResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "firmwares", kind: "message", T: Firmware, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFirmwaresResponse {
    return new ListFirmwaresResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFirmwaresResponse {
    return new ListFirmwaresResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFirmwaresResponse {
    return new ListFirmwaresResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListFirmwaresResponse | PlainMessage<ListFirmwaresResponse> | undefined, b: ListFirmwaresResponse | PlainMessage<ListFirmwaresResponse> | undefined): boolean {
    return proto3.util.equals(ListFirmwaresResponse, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.DownloadFirmwareRequest
 */
export class DownloadFirmwareRequest extends Message<DownloadFirmwareRequest> {
  /**
   * The resource name of a firmware, format: firmwares/{firmware}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DownloadFirmwareRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.DownloadFirmwareRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadFirmwareRequest {
    return new DownloadFirmwareRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadFirmwareRequest {
    return new DownloadFirmwareRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadFirmwareRequest {
    return new DownloadFirmwareRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DownloadFirmwareRequest | PlainMessage<DownloadFirmwareRequest> | undefined, b: DownloadFirmwareRequest | PlainMessage<DownloadFirmwareRequest> | undefined): boolean {
    return proto3.util.equals(DownloadFirmwareRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.ReleaseFirmwareRequest
 */
export class ReleaseFirmwareRequest extends Message<ReleaseFirmwareRequest> {
  /**
   * Firmware resource name, format: firmwares/{firmware}
   *
   * @generated from field: string firmware_name = 1;
   */
  firmwareName = "";

  /**
   * Resource name of device, format: devices/{device}
   *
   * @generated from field: string device_name = 2;
   */
  deviceName = "";

  constructor(data?: PartialMessage<ReleaseFirmwareRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.ReleaseFirmwareRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "firmware_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "device_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReleaseFirmwareRequest {
    return new ReleaseFirmwareRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReleaseFirmwareRequest {
    return new ReleaseFirmwareRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReleaseFirmwareRequest {
    return new ReleaseFirmwareRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ReleaseFirmwareRequest | PlainMessage<ReleaseFirmwareRequest> | undefined, b: ReleaseFirmwareRequest | PlainMessage<ReleaseFirmwareRequest> | undefined): boolean {
    return proto3.util.equals(ReleaseFirmwareRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.ReleaseFirmwareGARequest
 */
export class ReleaseFirmwareGARequest extends Message<ReleaseFirmwareGARequest> {
  /**
   * Firmware resource name, format: firmwares/{firmware}
   *
   * @generated from field: string firmware_name = 1;
   */
  firmwareName = "";

  constructor(data?: PartialMessage<ReleaseFirmwareGARequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.ReleaseFirmwareGARequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "firmware_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReleaseFirmwareGARequest {
    return new ReleaseFirmwareGARequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReleaseFirmwareGARequest {
    return new ReleaseFirmwareGARequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReleaseFirmwareGARequest {
    return new ReleaseFirmwareGARequest().fromJsonString(jsonString, options);
  }

  static equals(a: ReleaseFirmwareGARequest | PlainMessage<ReleaseFirmwareGARequest> | undefined, b: ReleaseFirmwareGARequest | PlainMessage<ReleaseFirmwareGARequest> | undefined): boolean {
    return proto3.util.equals(ReleaseFirmwareGARequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.PatchFirmwareRequest
 */
export class PatchFirmwareRequest extends Message<PatchFirmwareRequest> {
  /**
   * @generated from field: wavin.blaze.v1.Firmware firmware = 1;
   */
  firmware?: Firmware;

  constructor(data?: PartialMessage<PatchFirmwareRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.PatchFirmwareRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "firmware", kind: "message", T: Firmware },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PatchFirmwareRequest {
    return new PatchFirmwareRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PatchFirmwareRequest {
    return new PatchFirmwareRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PatchFirmwareRequest {
    return new PatchFirmwareRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PatchFirmwareRequest | PlainMessage<PatchFirmwareRequest> | undefined, b: PatchFirmwareRequest | PlainMessage<PatchFirmwareRequest> | undefined): boolean {
    return proto3.util.equals(PatchFirmwareRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.GetDeviceUpdaterRequest
 */
export class GetDeviceUpdaterRequest extends Message<GetDeviceUpdaterRequest> {
  /**
   * DeviceUpdater resource name, format: devices/{device}/updater
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Firmware resource name, format: firmwares/{firmware}
   * Current device state will be checked for compatibility with this firmware package
   *
   * @generated from field: optional string firmware_name = 2;
   */
  firmwareName?: string;

  constructor(data?: PartialMessage<GetDeviceUpdaterRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.GetDeviceUpdaterRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "firmware_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDeviceUpdaterRequest {
    return new GetDeviceUpdaterRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDeviceUpdaterRequest {
    return new GetDeviceUpdaterRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDeviceUpdaterRequest {
    return new GetDeviceUpdaterRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDeviceUpdaterRequest | PlainMessage<GetDeviceUpdaterRequest> | undefined, b: GetDeviceUpdaterRequest | PlainMessage<GetDeviceUpdaterRequest> | undefined): boolean {
    return proto3.util.equals(GetDeviceUpdaterRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.StartDeviceUpdaterRequest
 */
export class StartDeviceUpdaterRequest extends Message<StartDeviceUpdaterRequest> {
  /**
   * DeviceUpdater resource name, format: devices/{device}/updater
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Firmware resource name, format: firmwares/{firmware}
   *
   * @generated from field: string firmware_name = 2;
   */
  firmwareName = "";

  /**
   * Component names that needs to be flashed to given firmware
   *
   * @generated from field: repeated string component_names = 3;
   */
  componentNames: string[] = [];

  constructor(data?: PartialMessage<StartDeviceUpdaterRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.StartDeviceUpdaterRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "firmware_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "component_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartDeviceUpdaterRequest {
    return new StartDeviceUpdaterRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartDeviceUpdaterRequest {
    return new StartDeviceUpdaterRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartDeviceUpdaterRequest {
    return new StartDeviceUpdaterRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StartDeviceUpdaterRequest | PlainMessage<StartDeviceUpdaterRequest> | undefined, b: StartDeviceUpdaterRequest | PlainMessage<StartDeviceUpdaterRequest> | undefined): boolean {
    return proto3.util.equals(StartDeviceUpdaterRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.StreamSentioBrokerConfigRequest
 */
export class StreamSentioBrokerConfigRequest extends Message<StreamSentioBrokerConfigRequest> {
  /**
   * Device resource name, format: devices/{device}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<StreamSentioBrokerConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.StreamSentioBrokerConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StreamSentioBrokerConfigRequest {
    return new StreamSentioBrokerConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StreamSentioBrokerConfigRequest {
    return new StreamSentioBrokerConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StreamSentioBrokerConfigRequest {
    return new StreamSentioBrokerConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StreamSentioBrokerConfigRequest | PlainMessage<StreamSentioBrokerConfigRequest> | undefined, b: StreamSentioBrokerConfigRequest | PlainMessage<StreamSentioBrokerConfigRequest> | undefined): boolean {
    return proto3.util.equals(StreamSentioBrokerConfigRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.SendSentioBrokerConfigRequest
 */
export class SendSentioBrokerConfigRequest extends Message<SendSentioBrokerConfigRequest> {
  /**
   * Resource name of device, format: devices/{device}
   *
   * @generated from field: string device_name = 1;
   */
  deviceName = "";

  /**
   * Device config with only changed properties (set on the wire will be applied)
   *
   * @generated from field: wavin.sentio.v1.BrokerConfig config = 2;
   */
  config?: BrokerConfig;

  constructor(data?: PartialMessage<SendSentioBrokerConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.SendSentioBrokerConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "device_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "config", kind: "message", T: BrokerConfig },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendSentioBrokerConfigRequest {
    return new SendSentioBrokerConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendSentioBrokerConfigRequest {
    return new SendSentioBrokerConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendSentioBrokerConfigRequest {
    return new SendSentioBrokerConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendSentioBrokerConfigRequest | PlainMessage<SendSentioBrokerConfigRequest> | undefined, b: SendSentioBrokerConfigRequest | PlainMessage<SendSentioBrokerConfigRequest> | undefined): boolean {
    return proto3.util.equals(SendSentioBrokerConfigRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.GetDevicePlatformStatusRequest
 */
export class GetDevicePlatformStatusRequest extends Message<GetDevicePlatformStatusRequest> {
  /**
   * @generated from oneof wavin.blaze.v1.GetDevicePlatformStatusRequest.device
   */
  device: {
    /**
     * Resource name of device, format: devices/{device}
     *
     * @generated from field: string device_name = 1;
     */
    value: string;
    case: "deviceName";
  } | {
    /**
     * Registration key on device or from QR code: format: XXXXX-XXXXX-XXXX
     *
     * @generated from field: string registration_key = 2;
     */
    value: string;
    case: "registrationKey";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GetDevicePlatformStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.GetDevicePlatformStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "device_name", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "device" },
    { no: 2, name: "registration_key", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "device" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDevicePlatformStatusRequest {
    return new GetDevicePlatformStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDevicePlatformStatusRequest {
    return new GetDevicePlatformStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDevicePlatformStatusRequest {
    return new GetDevicePlatformStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDevicePlatformStatusRequest | PlainMessage<GetDevicePlatformStatusRequest> | undefined, b: GetDevicePlatformStatusRequest | PlainMessage<GetDevicePlatformStatusRequest> | undefined): boolean {
    return proto3.util.equals(GetDevicePlatformStatusRequest, a, b);
  }
}

/**
 * @generated from message wavin.blaze.v1.UpdateDevicePlatformRequest
 */
export class UpdateDevicePlatformRequest extends Message<UpdateDevicePlatformRequest> {
  /**
   * Resource name of device, format: devices/{device}
   *
   * @generated from field: string device_name = 1;
   */
  deviceName = "";

  constructor(data?: PartialMessage<UpdateDevicePlatformRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.UpdateDevicePlatformRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "device_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDevicePlatformRequest {
    return new UpdateDevicePlatformRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDevicePlatformRequest {
    return new UpdateDevicePlatformRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDevicePlatformRequest {
    return new UpdateDevicePlatformRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDevicePlatformRequest | PlainMessage<UpdateDevicePlatformRequest> | undefined, b: UpdateDevicePlatformRequest | PlainMessage<UpdateDevicePlatformRequest> | undefined): boolean {
    return proto3.util.equals(UpdateDevicePlatformRequest, a, b);
  }
}

