import { Grid2 } from '@mui/material';
import { DrawerContainer } from '~/pages/layout/components/DrawerContainer';
import { Snackbar } from '~/pages/layout/components/Snackbar';
import { DRAWER_WIDTH } from '~/shared/contants/layout-constants';

export function AppLayout({ children }: { children?: React.ReactNode }) {
  return (
    <Grid2 container>
      <DrawerContainer />

      <Grid2
        container
        flexDirection="column"
        flexWrap="nowrap"
        height="100vh"
        width={{
          xs: '100%',
          md: `calc(100% - ${DRAWER_WIDTH}px)`,
        }}
        pb={10}
        pt={{ xs: 24, md: 8 }}
        px={{
          xs: 4,
          md: 8,
          lg: 12,
        }}
      >
        {children}
      </Grid2>

      <Snackbar />
    </Grid2>
  );
}
