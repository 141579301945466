// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file wavin/blaze/v1/device_access_key.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { User } from "./user_pb.js";

/**
 * A device access key is generated key to allow a user to claim a device
 *
 * @generated from message wavin.blaze.v1.DeviceAccessKey
 */
export class DeviceAccessKey extends Message<DeviceAccessKey> {
  /**
   * DeviceAccessKey resource name, format: devices/{device}/access-keys/{access-key}
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 2;
   */
  createTime?: Timestamp;

  /**
   * Expiration time of the access key
   *
   * @generated from field: google.protobuf.Timestamp expiration_time = 3;
   */
  expirationTime?: Timestamp;

  /**
   * Expiration time of the claim that is created by this access key
   *
   * @generated from field: google.protobuf.Timestamp claim_expiration_time = 4;
   */
  claimExpirationTime?: Timestamp;

  /**
   * The generated access_key: format XXXX-XXXX-XXXX-XXXX
   *
   * @generated from field: string access_key = 5;
   */
  accessKey = "";

  /**
   * User who created the access key
   *
   * @generated from field: wavin.blaze.v1.User user = 6;
   */
  user?: User;

  constructor(data?: PartialMessage<DeviceAccessKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.blaze.v1.DeviceAccessKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "create_time", kind: "message", T: Timestamp },
    { no: 3, name: "expiration_time", kind: "message", T: Timestamp },
    { no: 4, name: "claim_expiration_time", kind: "message", T: Timestamp },
    { no: 5, name: "access_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "user", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceAccessKey {
    return new DeviceAccessKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceAccessKey {
    return new DeviceAccessKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceAccessKey {
    return new DeviceAccessKey().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceAccessKey | PlainMessage<DeviceAccessKey> | undefined, b: DeviceAccessKey | PlainMessage<DeviceAccessKey> | undefined): boolean {
    return proto3.util.equals(DeviceAccessKey, a, b);
  }
}

