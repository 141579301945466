import { igniteUserPromiseClient } from '~/services/ignite-client';
import { User } from '~/types/wavin/ignite/v1/user_pb';

export const patchUser = async ({ user }: { user?: Partial<User> }) =>
  await igniteUserPromiseClient.patchUser({
    user,
  });

export const getUser = async () =>
  await igniteUserPromiseClient.getUser({ name: 'users/me' });
