import { Box, Skeleton } from '@mui/material';
import { Link, useNavigation } from 'react-router-dom';
import LogoImg from '~/pages/layout/images/logo.png';
import { createRoutes } from '~/routes';
import { theme } from '~/shared/theme/theme';

const loadTime = Date.now();

const LOAD_TIME_TIMEOUT = 5000;

export function Logo({
  loadTimeTimeout = LOAD_TIME_TIMEOUT,
}: {
  loadTimeTimeout?: number;
}) {
  const navigation = useNavigation();

  const initialPageLoad = Date.now() - loadTime < loadTimeTimeout;

  const showLoading =
    navigation.state === 'loading' || navigation.state === 'submitting';

  return (
    <Box
      sx={{
        px: { xs: 0, md: 6 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: {
          xs: 'center',
          md: 'flex-start',
        },
        minHeight: theme.spacing(8),
      }}
    >
      {showLoading && !initialPageLoad ? (
        <Skeleton width={90} data-testid="logo-skeleton" />
      ) : (
        <Link to={createRoutes.Devices}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              src={LogoImg}
              alt="Wavin logo"
              sx={{ width: { md: '100%', xs: '80px' } }}
            />
          </Box>
        </Link>
      )}
    </Box>
  );
}
