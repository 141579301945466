// Heartbeat API between Sentio CCU and Wavin Cloud
//
// HTTP POST /heartbeat HeartbeatRequest/HeartbeatResponse
// HTTP POST /log LogRequest/LogResponse
// HTTP POST /package PackageRequest/PackageResponse
//

// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file wavin/sentio/v1/heartbeat.proto (package wavin.sentio.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * HeartbeatRequest is initiated by CCU to cloud to handle long poll loop
 *
 * @generated from message wavin.sentio.v1.HeartbeatRequest
 */
export class HeartbeatRequest extends Message<HeartbeatRequest> {
  /**
   * Broker values must be in top-level message for performance reasons with CCU)
   * Config changes from CCU to Cloud
   *
   * @generated from field: repeated wavin.sentio.v1.BrokerValue broker_values = 1;
   */
  brokerValues: BrokerValue[] = [];

  /**
   * CCU is sending full config
   *
   * @generated from field: bool full_config = 2;
   */
  fullConfig = false;

  constructor(data?: PartialMessage<HeartbeatRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.HeartbeatRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "broker_values", kind: "message", T: BrokerValue, repeated: true },
    { no: 2, name: "full_config", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HeartbeatRequest {
    return new HeartbeatRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HeartbeatRequest {
    return new HeartbeatRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HeartbeatRequest {
    return new HeartbeatRequest().fromJsonString(jsonString, options);
  }

  static equals(a: HeartbeatRequest | PlainMessage<HeartbeatRequest> | undefined, b: HeartbeatRequest | PlainMessage<HeartbeatRequest> | undefined): boolean {
    return proto3.util.equals(HeartbeatRequest, a, b);
  }
}

/**
 * @generated from message wavin.sentio.v1.HeartbeatResponse
 */
export class HeartbeatResponse extends Message<HeartbeatResponse> {
  /**
   * Config changes to be applied at CCU from Cloud, contains only changed broker values
   *
   * @generated from field: repeated wavin.sentio.v1.BrokerValue broker_values = 1;
   */
  brokerValues: BrokerValue[] = [];

  /**
   * Cloud requests CCU to send the full configuration.
   *
   * @generated from field: bool full_config = 2;
   */
  fullConfig = false;

  constructor(data?: PartialMessage<HeartbeatResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.HeartbeatResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "broker_values", kind: "message", T: BrokerValue, repeated: true },
    { no: 2, name: "full_config", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HeartbeatResponse {
    return new HeartbeatResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HeartbeatResponse {
    return new HeartbeatResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HeartbeatResponse {
    return new HeartbeatResponse().fromJsonString(jsonString, options);
  }

  static equals(a: HeartbeatResponse | PlainMessage<HeartbeatResponse> | undefined, b: HeartbeatResponse | PlainMessage<HeartbeatResponse> | undefined): boolean {
    return proto3.util.equals(HeartbeatResponse, a, b);
  }
}

/**
 * Roxi Broker Value type object
 *
 * @generated from message wavin.sentio.v1.BrokerValue
 */
export class BrokerValue extends Message<BrokerValue> {
  /**
   * object ID of value,
   *
   * @generated from field: uint32 oid = 1;
   */
  oid = 0;

  /**
   * Value ID
   *
   * @generated from field: uint32 vid = 2;
   */
  vid = 0;

  /**
   * Timestamp in unix time (seconds) of last change (used for decide which value is last when communication delay occurs)
   *
   * @generated from field: uint32 timestamp = 3;
   */
  timestamp = 0;

  /**
   * Nanoseconds part of timestamp if available (note: CCU has only second precision so nanos will be zero)
   *
   * @generated from field: uint32 timestamp_nanos = 18;
   */
  timestampNanos = 0;

  /**
   * False: value is invalid, True: value is valid
   *
   * @generated from field: bool valid = 4;
   */
  valid = false;

  /**
   * Variable is the value
   *
   * @generated from oneof wavin.sentio.v1.BrokerValue.variable
   */
  variable: {
    /**
     * 1 byte signed integer
     *
     * @generated from field: int32 val_d1 = 5;
     */
    value: number;
    case: "valD1";
  } | {
    /**
     * 2 bytes signed integer
     *
     * @generated from field: int32 val_d2 = 6;
     */
    value: number;
    case: "valD2";
  } | {
    /**
     * 4 bytes signed integer
     *
     * @generated from field: int32 val_d4 = 7;
     */
    value: number;
    case: "valD4";
  } | {
    /**
     * 1 byte unsigned integer
     *
     * @generated from field: uint32 val_u1 = 8;
     */
    value: number;
    case: "valU1";
  } | {
    /**
     * 2 bytes unsigned integer
     *
     * @generated from field: uint32 val_u2 = 9;
     */
    value: number;
    case: "valU2";
  } | {
    /**
     * 4 bytes unsigned integer
     *
     * @generated from field: uint32 val_u4 = 10;
     */
    value: number;
    case: "valU4";
  } | {
    /**
     * max 256 ASCII value
     *
     * @generated from field: string val_text = 11;
     */
    value: string;
    case: "valText";
  } | {
    /**
     * max 256 bytes of data
     *
     * @generated from field: bytes val_data = 12;
     */
    value: Uint8Array;
    case: "valData";
  } | {
    /**
     * 2 bytes signed decimal fixed point number. (value * 10) (-1.1 -> -11)
     *
     * @generated from field: int32 val_d2_fp10 = 13;
     */
    value: number;
    case: "valD2Fp10";
  } | {
    /**
     * 2 bytes signed fixed point number. (value * 100) (-1.11 -> -111)
     *
     * @generated from field: int32 val_d2_fp100 = 14;
     */
    value: number;
    case: "valD2Fp100";
  } | {
    /**
     * 2 bytes unsigned fixed point number. (value * 10) (1.1 -> 11)
     *
     * @generated from field: uint32 val_u2_fp10 = 15;
     */
    value: number;
    case: "valU2Fp10";
  } | {
    /**
     * 2 bytes unsigned fixed point number. (value * 100) (1.11 -> 111)
     *
     * @generated from field: uint32 val_u2_fp100 = 16;
     */
    value: number;
    case: "valU2Fp100";
  } | {
    /**
     * max 512 bytes of data
     *
     * @generated from field: bytes val_service_command = 17;
     */
    value: Uint8Array;
    case: "valServiceCommand";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * Timestamp sent to CCU in unix time (seconds)
   *
   * @generated from field: uint32 timestamp_sent = 19;
   */
  timestampSent = 0;

  /**
   * Timestamp sent to CCU nanoseconds part of timestamp_sent
   *
   * @generated from field: uint32 timestamp_sent_nanos = 20;
   */
  timestampSentNanos = 0;

  constructor(data?: PartialMessage<BrokerValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.BrokerValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "oid", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "vid", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "timestamp", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 18, name: "timestamp_nanos", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "val_d1", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "variable" },
    { no: 6, name: "val_d2", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "variable" },
    { no: 7, name: "val_d4", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "variable" },
    { no: 8, name: "val_u1", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "variable" },
    { no: 9, name: "val_u2", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "variable" },
    { no: 10, name: "val_u4", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "variable" },
    { no: 11, name: "val_text", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "variable" },
    { no: 12, name: "val_data", kind: "scalar", T: 12 /* ScalarType.BYTES */, oneof: "variable" },
    { no: 13, name: "val_d2_fp10", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "variable" },
    { no: 14, name: "val_d2_fp100", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "variable" },
    { no: 15, name: "val_u2_fp10", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "variable" },
    { no: 16, name: "val_u2_fp100", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "variable" },
    { no: 17, name: "val_service_command", kind: "scalar", T: 12 /* ScalarType.BYTES */, oneof: "variable" },
    { no: 19, name: "timestamp_sent", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 20, name: "timestamp_sent_nanos", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BrokerValue {
    return new BrokerValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BrokerValue {
    return new BrokerValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BrokerValue {
    return new BrokerValue().fromJsonString(jsonString, options);
  }

  static equals(a: BrokerValue | PlainMessage<BrokerValue> | undefined, b: BrokerValue | PlainMessage<BrokerValue> | undefined): boolean {
    return proto3.util.equals(BrokerValue, a, b);
  }
}

/**
 * LogRequest is initiated by CCU to cloud to send a text log
 * Device information from originating device will be automatically related.
 *
 * @generated from message wavin.sentio.v1.LogRequest
 */
export class LogRequest extends Message<LogRequest> {
  /**
   * @generated from field: wavin.sentio.v1.LogRequest.Type type = 1;
   */
  type = LogRequest_Type.UNSPECIFIED;

  /**
   * Payload represented as a Unicode string (UTF-8).
   *
   * @generated from field: string text_payload = 2;
   */
  textPayload = "";

  constructor(data?: PartialMessage<LogRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.LogRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(LogRequest_Type) },
    { no: 2, name: "text_payload", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogRequest {
    return new LogRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogRequest {
    return new LogRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogRequest {
    return new LogRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LogRequest | PlainMessage<LogRequest> | undefined, b: LogRequest | PlainMessage<LogRequest> | undefined): boolean {
    return proto3.util.equals(LogRequest, a, b);
  }
}

/**
 * Type of log entry to determine post-processing step
 *
 * @generated from enum wavin.sentio.v1.LogRequest.Type
 */
export enum LogRequest_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TYPE_LOOGER = 1;
   */
  LOOGER = 1,

  /**
   * @generated from enum value: TYPE_TELEMETRY = 2;
   */
  TELEMETRY = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(LogRequest_Type)
proto3.util.setEnumType(LogRequest_Type, "wavin.sentio.v1.LogRequest.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_LOOGER" },
  { no: 2, name: "TYPE_TELEMETRY" },
]);

/**
 * Empty response
 *
 * @generated from message wavin.sentio.v1.LogResponse
 */
export class LogResponse extends Message<LogResponse> {
  constructor(data?: PartialMessage<LogResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.LogResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogResponse {
    return new LogResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogResponse {
    return new LogResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogResponse {
    return new LogResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LogResponse | PlainMessage<LogResponse> | undefined, b: LogResponse | PlainMessage<LogResponse> | undefined): boolean {
    return proto3.util.equals(LogResponse, a, b);
  }
}

/**
 * PackageRequest is request initiated by CCU to cloud to download firmware package (CCU determines size of byte slice)
 * The version is determined by broker value update_ctrl.download_version and the state is stored in update_ctrl.download_state (IDLE, DOWNLOADING, FAILED)
 *
 * 400 Bad request: range out of bounds
 * 404 Not found: version not found
 *
 * @generated from message wavin.sentio.v1.PackageRequest
 */
export class PackageRequest extends Message<PackageRequest> {
  /**
   * semantic version, i.e. 15.120.1
   *
   * @generated from field: string version = 1;
   */
  version = "";

  /**
   * Start index of byte range to retrieve (zero-indexed)
   *
   * @generated from field: uint32 range_start = 2;
   */
  rangeStart = 0;

  /**
   * End index of byte range to retrieve (inclusive), if zero until end is retrieved.
   *
   * @generated from field: uint32 range_end = 3;
   */
  rangeEnd = 0;

  constructor(data?: PartialMessage<PackageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.PackageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "range_start", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "range_end", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PackageRequest {
    return new PackageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PackageRequest {
    return new PackageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PackageRequest {
    return new PackageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PackageRequest | PlainMessage<PackageRequest> | undefined, b: PackageRequest | PlainMessage<PackageRequest> | undefined): boolean {
    return proto3.util.equals(PackageRequest, a, b);
  }
}

/**
 * @generated from message wavin.sentio.v1.PackageResponse
 */
export class PackageResponse extends Message<PackageResponse> {
  /**
   * Content size of full package in bytes
   *
   * @generated from field: uint32 content_size = 1;
   */
  contentSize = 0;

  /**
   * Content length of requested byte slice
   *
   * @generated from field: uint32 content_length = 2;
   */
  contentLength = 0;

  /**
   * Start index of requested byte range (zero-indexed)
   *
   * @generated from field: uint32 content_range_start = 3;
   */
  contentRangeStart = 0;

  /**
   * End index of requested byte range (inclusive)
   *
   * @generated from field: uint32 content_range_end = 4;
   */
  contentRangeEnd = 0;

  /**
   * Slice of requested bytes
   *
   * @generated from field: bytes slice = 5;
   */
  slice = new Uint8Array(0);

  constructor(data?: PartialMessage<PackageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "wavin.sentio.v1.PackageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content_size", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "content_length", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "content_range_start", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "content_range_end", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "slice", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PackageResponse {
    return new PackageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PackageResponse {
    return new PackageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PackageResponse {
    return new PackageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PackageResponse | PlainMessage<PackageResponse> | undefined, b: PackageResponse | PlainMessage<PackageResponse> | undefined): boolean {
    return proto3.util.equals(PackageResponse, a, b);
  }
}

