import { Interceptor, StreamRequest, UnaryRequest } from '@connectrpc/connect';
import { authService } from '~/services/auth/auth-service';
import { ENABLE_MOCKS } from '~/services/environment/environment';

export const authInterceptor: Interceptor =
  (next) => async (req: UnaryRequest | StreamRequest) => {
    if (!ENABLE_MOCKS) {
      const token = await authService.getIdentityToken();

      if (token) {
        req.header.set('Authorization', `Bearer ${token}`);
      } else {
        return Promise.reject(new Error('No identity token found.'));
      }
    }

    if (req.header.get('Content-Type') === 'text/html') {
      return Promise.reject(
        new Error('Invalid content type. Please refresh the page.'),
      );
    }

    return next(req);
  };
