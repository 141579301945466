import { queryOptions } from '@tanstack/react-query';
import { BlazeDeviceQueryKey } from '~/services/queryKeys';
import { listDevices } from '~/services/api/fetchers/listDevices.api';
import { DevicesListModel } from '~/shared/models/devicesList/DevicesListModel';

export const listDevicesQuery = queryOptions({
  queryKey: [BlazeDeviceQueryKey.LIST_DEVICES],
  queryFn: async () => {
    const response = await listDevices();
    return new DevicesListModel(response);
  },
});
